import { Component, Input, OnInit } from '@angular/core';
import { ElementHelper } from '../../../../elements/elements_helper';

@Component({
  selector: 'app-aboutus-business-description',
  templateUrl: './aboutus-business-description.component.html',
  styleUrls: ['./aboutus-business-description.component.scss']
})
export class AboutusBusinessDescriptionComponent implements OnInit{
  @Input() responseData : any;
  constructor(
    private _elementHelper : ElementHelper
  ){}
  webComponentName : any  = 'aboutus_business_description';
  ngOnInit(): void {
    this.itemData = this.responseData.styles;
    this.data = this.responseData.styles;
  }
  data : any = {
    "title":this._elementHelper.getTextTitleElement('A few words -','',''),
   }
   itemData : any = 
   [
    {
      "description":this._elementHelper.getTextDescElement('Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris ','',''),
    },
    {
      "description":this._elementHelper.getTextDescElement('Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris s','',''),
    }
   ]
}
