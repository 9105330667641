<div class="main_section">
    <div class="container">
        <div class="content_section">
            <div class="item-title">
                <app-text-element [data] = "data.welcometext"></app-text-element>
            </div>
            <div class="title">
                <app-text-element [data] = "data.title"></app-text-element>
            </div>
            <div class="description">
                <app-text-element [data] = "data.description"></app-text-element>
            </div>
        </div>
        <div class="button_section">
            <app-button-element [data] = "data.button"></app-button-element>
        </div>
    </div>
</div>