import { Component, Input, OnInit } from '@angular/core';
import { ElementHelper } from '../../../../elements/elements_helper';

@Component({
  selector: 'app-contact-us-input-text',
  templateUrl: './contact-us-input-text.component.html',
  styleUrls: ['./contact-us-input-text.component.scss']
})
export class ContactUsInputTextComponent implements OnInit {
  @Input() responseData : any;
  constructor(
    private _elementHelper : ElementHelper
  ){}
  webComponentName : any  = 'contact_us_input_text';
  ngOnInit(): void {
    this.data = this.responseData.styles;
    // this.itemData = this.responseData.styles;
  }

  data : any = {
    "title":this._elementHelper.getTextTitleElement('Get In Touch','',''),
    "description":this._elementHelper.getTextDescElement('Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Etiam tempus magna vel turpis pharetra dictum. Sed blandit tempus purus, sed sodales leo rutrum vel. Nam vulputate ipsum ac est congue, eget commodo magna lobortis.','',''),
    "button":this._elementHelper.getButtonOutlineRoundedElement('SEND','blue','blue',''),
  }
}
