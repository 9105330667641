<section class="complete-section">
  <div class="content-section" [ngStyle]="{'height' : data.folderId != folderId || data.addToFolder != addToFolder ? '84vh' : '95vh'}">
    <div class="header-section">
      <div class="header-text">
        {{data.pageName}}
      </div>
      <div class="close-btn" (click)="closeTab()">
        <mat-icon>close</mat-icon>
      </div>
    </div>

    <div class="middle-section">
      <div class="middle-top-section">
        <div class="middle-text">
          <div class="middle-text-left">
            Label
          </div>
        </div>
        <input type="text" class="middle-input" [(ngModel)]="data.pageName" (ngModelChange)="changeNameCheck()"
          [disabled]="disablePageName(data.pageName)">
        <div class="middle-description">
          This is the word shown in the navigation.
        </div>
      </div>
      <hr class="line">
    </div>

    <div class="folder-data" *ngIf="this.folderList.length > 0">
      <div class="display-flex mt-15">
        <div>
          <p class="mb-0 heading">Add to folder</p>
          <p class="desc">Add this page to an existing folder</p>
        </div>
        <div class="form-check form-switch ml-auto">
          <input class="form-check-input switch-btn" type="checkbox" [(ngModel)]="data.addToFolder" (ngModelChange)="changeAddToFolder()" role="switch">
        </div>
      </div>
      <mat-form-field appearance="outline" style="width: 100%;margin-top: 15px;" *ngIf="data.addToFolder">
        <mat-select [(ngModel)]="data.folderId">
          <ng-container *ngFor="let folder of folderList">
            <mat-option [value]="folder.folderId">{{folder.folderName | titlecase}}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <hr class="line">
    </div>

    <div class="folder-data" *ngIf="this.folderList.length === 0">
      <div class="display-flex mt-15">
        <div>
          <p class="mb-0 heading">No folders</p>
          <p class="desc">To add this page to an existing folder please create a folder</p>
        </div>
      </div>
      <hr class="line">
    </div>

    <div class="third-section">
      <div class="heading">
        SEO
      </div>
      <div class="third-description">
        Improve your website's rank on search engines like Google with SEO.
      </div>
    </div>
    <div class="third-section" *ngIf="viewAccess">
      <div class="third-top-section">
        <div class="third-image-section" style="margin-bottom: 26px;">
          <div class="sec-text">
            Image
          </div>
          <p class="desc">Select the image you would like to display</p>
          <div class="upload-image" *ngIf="!data.seoData.image" (click)="replaceImage()">
            <img src="https://prod-simpo.s3.ap-south-1.amazonaws.com/library-media/354514c1711643199684photo.png" alt=""
              style="height: 40px;">
            <p class="upload-file mb-0">Upload a file</p>
            <p class="mb-0">or select an existing one</p>
            <span style="color: #989DA7;">up to 32MB</span>
            <input type="file" name="myfile" accept="image/x-png,image/gif,image/jpeg,image/jpg" hidden
              (change)="replaceImage()" class="browse-btn display_image" id="browse_bg_img" #uploadImage />
          </div>
          <div class="sec" *ngIf="data.seoData.image">
            <img [src]="data.seoData.image">
            <div class="replace-delete">
              <div class="repalce-image cursor-pointer" (click)="replaceImage()">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" width="24"
                  height="24" class="icon w-4 h-4" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                    d="m4 16 4.58579-4.5858c.78104-.781 2.04741-.781 2.82841 0L16 16m-2-2 1.5858-1.5858c.781-.781 2.0474-.781 2.8284 0L20 14m-6-6h.01M6 20h12c1.1046 0 2-.8954 2-2V6c0-1.10457-.8954-2-2-2H6c-1.10457 0-2 .89543-2 2v12c0 1.1046.89543 2 2 2Z">
                  </path>
                </svg>
                <span>Replace</span>
              </div>
              <input type="file" name="myfile" accept="image/x-png,image/gif,image/jpeg,image/jpg" hidden
                class="browse-btn display_image" id="browse_bg_img" />
              <div class="delete-image cursor-pointer ">
                <mat-icon (click)="deleteImage()">delete</mat-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="middle-top-section">
        <div class="middle-text">
          <div class="middle-text-left">
            Title
          </div>
          <!-- <div class="middle-text-right">
            Regenerate
          </div> -->
        </div>
        <input type="text" class="middle-input" [(ngModel)]="data.seoData.metaTitle" [disabled]="!editAccess">
        <div class="middle-description">
          The title tag is displayed as the title in search results. We recommend between 50 and 60 characters.
        </div>
      </div>
      <div class="middle-top-section" *ngIf="!isChecked">
        <div class="middle-text">
          <div class="middle-text-left">
            Description
          </div>
          <!-- <div class="middle-text-right">
            Regenerate
          </div> -->
        </div>
        <input type="text" class="middle-input" [(ngModel)]="data.seoData.metaDescription" [disabled]="!editAccess">
        <div class="middle-description">
          A meta description is a short, relevant summary of this page. Search engines will sometimes use this in search
          results. We recommend between 110 and 160 characters.
        </div>
      </div>

      <div class="form-switch" style="padding-left: initial; display: flex; margin-top: 5%; align-items: flex-start;">
        <div class="form-heading">
          <div class="form-top">
            Mark this page as canonical
          </div>
          <div class="form-bottom">
            Enable this option to mark this page as the most representative from a set of duplicate pages
          </div>
        </div>
        <input class="form-check-input switch-btn" type="checkbox" role="switch" [(ngModel)]="isChecked"  [disabled]="!editAccess"
          (ngModelChange)="markAsCanonical()">
      </div>

      <div class="middle-top-section" *ngIf="isChecked">
        <div class="middle-text">
          <div class="middle-text-left">
            Colonial Url
          </div>
          <!-- <div class="middle-text-right">
            Regenerate
          </div> -->
        </div>
        <input type="text" class="middle-input" [(ngModel)]="data.seoData.canonicalUrl"  [disabled]="!editAccess">
      </div>

      <div class="form-switch"
        style="padding-left: initial; display: flex; margin-top: 5%; align-items: flex-start; justify-content: space-between;">
        <div class="form-heading">
          <div class="form-top">
            Remove from sitemap
          </div>
        </div>
        <input class="form-check-input switch-btn" type="checkbox" role="switch" [(ngModel)]="siteMapChecked"
          (ngModelChange)="removeSiteMap()"  [disabled]="!editAccess">
      </div>

      <div class="middle-top-section">
        <div class="middle-text">
          <div class="middle-text-left">
            Keywords
          </div>
          <!-- <div class="middle-text-right">
            Regenerate
          </div> -->
        </div>
        <input type="text" class="middle-input" [(ngModel)]="data.seoData.keywords"  [disabled]="!editAccess">
      </div>
    </div>
    <div *ngIf="!viewAccess" class="not-access-text">You don't have view access</div>
  </div>

  <div class="bottom-section" *ngIf="data.folderId != folderId || data.addToFolder != addToFolder">
    <p>Unsaved</p>
    <div>
      <button class="discard-btn" (click)="discardData()">Discard</button>
      <button class="save-btn" (click)="saveData()">Save</button>
    </div>
  </div>


</section>
