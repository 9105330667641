import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ElementHelper } from '../../../elements/elements_helper';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';
import { WebisiteService } from 'src/app/master-config-components/micro-apps/website/webisite.service';

@Component({
  selector: 'app-horizontal-service-details',
  templateUrl: './horizontal-service-details.component.html',
  styleUrls: ['./horizontal-service-details.component.scss']
})
export class HorizontalServiceDetailsComponent implements OnInit{

  // @Input() dynamicData :any;
  @Input() responseData : any ;
  @Input() id : any;
  @Input() all : any;
  @Input() index : any;

  styles : any ;


  constructor(
    private _elementHelper : ElementHelper,
    private _eventHelper : EventEmmiterService,
    private _websiteService : WebisiteService
  ){
    this.getScreenSize()
  }

  screenWidth:any;
  @HostListener("window: resize", ["$event"])
  getScreenSize(){
    this.screenWidth = window.innerWidth;
  }

  ngOnInit(): void {
    // console.log(this.responseData);
    this.styles = this.responseData.styles;
    this.itemData = this.responseData.itemStyles;
  }

  webComponentName : any =  'service_listing_horizontal_simple';

  itemData : any = [
    {
      "image":{
        "attachment":'https://dev-beeos.s3.amazonaws.com/prod-images/149914c1679071077987web1%20%281%29.jpg',
        "altName":"second banner image",
        "lm_type":"attachment",
        "lm_title":"Second Banner Image",
        "attachment_type":"IMAGE"
      },
      "title":this._elementHelper.getTextItemTitleElement('Custom Web Design','black','First Item Title'),
      "description":this._elementHelper.getTextDescElement('Our custom web design service helps you create a unique, professional website that stands out from the competition','black','First Item Description'),
    },
    {
      "image":{
        "attachment":'https://dev-beeos.s3.amazonaws.com/prod-images/149914c1679071077987web1%20%281%29.jpg',
        "altName":"second banner image",
        "lm_type":"attachment",
        "lm_title":"Second Banner Image",
        "attachment_type":"IMAGE"
      },
      "title":this._elementHelper.getTextItemTitleElement('Custom Web Design','black','First Item Title'),
      "description":this._elementHelper.getTextDescElement('Our custom web design service helps you create a unique, professional website that stands out from the competition','black','First Item Description'),
    },
    {
      "image":{
        "attachment":'https://dev-beeos.s3.amazonaws.com/prod-images/149914c1679071077987web1%20%281%29.jpg',
        "altName":"second banner image",
        "lm_type":"attachment",
        "lm_title":"Second Banner Image",
        "attachment_type":"IMAGE"
      },
      "title":this._elementHelper.getTextItemTitleElement('Custom Web Design','black','First Item Title'),
      "description":this._elementHelper.getTextDescElement('Our custom web design service helps you create a unique, professional website that stands out from the competition','black','First Item Description'),
    },
  ];

  editSection(){
    this._eventHelper.editSection.emit({index:this.index,data:this.responseData});
  }
}
