<section>
  <div class="display-flex justify-sb">
    <p class="heading">Fields</p>
    <p class="manage-heading" (click)="manageFields()">Manage fields</p>
  </div>

  <div class="field-section" cdkDropList (cdkDropListDropped)="drop($event)">
    <ng-container *ngFor="let field of contactField?.fields">
      <div class="display-flex gap-10" style="cursor: move;" cdkDrag *ngIf="canShowContactField(field)">
          <div class="field-value"> <mat-icon style="cursor: grab; font-size: 23px; color: #555555;">drag_indicator</mat-icon> {{field.value}}</div>
          <span class="field-required" *ngIf="field.required">&#x2022; Required</span>
      </div>
    </ng-container>
  </div>

  <hr class="line">

  <div>
    <div class="display-flex justify-sb" style="margin-bottom: 1.05rem;">
      <div class="heading">Custom input labels</div>
      <div class="form-check form-switch ml-auto">
        <input class="form-check-input switch-btn" type="checkbox" [(ngModel)]="contactField.customLabel" role="switch">
      </div>
    </div>

    <ng-container *ngIf="contactField.customLabel">
      <div class="custom-inputs" *ngFor="let field of contactField?.fields">
        <label class="input-label">{{field.label}}</label>
        <input class="input-text" type="text" [(ngModel)]="field.value">
      </div>
      <div class="custom-inputs">
        <label class="input-label">Send Button</label>
      <input class="input-text" type="text" [(ngModel)]="contactField.button">
      </div>
    </ng-container>
  </div>
  <hr class="line">
  <div>
    <p class="success-message">Form success message</p>
    <quill-editor [(ngModel)]="contactField.successMessage"></quill-editor>
  </div>
</section>
