<section class="main_section">
    <section class="top_layer" *ngIf="!urlShared">
        <div class="d-flex justify-content-between align-items-center w-100">
            <div class="left_container" (click)="goBack()">
                <mat-icon>keyboard_arrow_left</mat-icon>
                <img src="./../../assets/images/logo.svg" alt="" class="logo">
            </div>
            <div class="right_container flex-grow-1" *ngIf="srcWidth > 475">
                <ng-container>
                    <ng-template *ngTemplateOutlet="CustomizeActions"></ng-template>

                    <div class="publish d-flex" *ngIf="!staffId || !business.length">
                        <button (click)="!staffId ? signUp('NOT_PUBLISHED') : createBusiness('NOT_PUBLISHED')" class="save-btn">Save</button>
                        <button (click)="!staffId ? signUp('PUBLISHED') : createBusiness('PUBLISHED')" *ngIf="!googleTokenId" class="publish_btn">Publish</button>
                        <button (click)="signUp('PUBLISHED')" *ngIf="googleTokenId">Done</button>
                    </div>

                    <div class="btn-group publish" role="group" *ngIf="staffId && business.length">
                        <button type="button" class="dropdown-toggle publish_btn" data-bs-toggle="dropdown" aria-expanded="false">
                            Actions
                        </button>
                        <ul class="dropdown-menu" style="z-index: 1001;">
                            <li><a class="dropdown-item cursor-pointer" (click)="createBusiness('PUBLISHED')">Publish</a></li>
                            <!-- <li><a class="dropdown-item cursor-pointer" (click)="goToHome()">Go To Home</a></li> -->
                            <li><a class="dropdown-item cursor-pointer" (click)="createBusiness('NOT_PUBLISHED')">Save</a></li>
                        </ul>
                    </div>
                </ng-container>
            </div>
            <!-- <div class="left_container" (click)="goBack()" *ngIf="srcWidth <= 475">
                <mat-icon class="mat-back-arrow">keyboard_arrow_left</mat-icon>
            </div> -->
            <ng-container *ngIf="srcWidth <= 475">
                <div style="display: flex;gap: 5px; height: 28px;">
                    <img src="../../../assets/images/editor/regenerate.svg" alt="" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">
                    <div class="mobile-header" data-bs-toggle="offcanvas" data-bs-target="#addPageBottomSheet" aria-controls="addPageBottomSheet">
                        <span style="position: relative;left: 13px;">Home </span><mat-icon class="mat-dropdown">keyboard_arrow_down</mat-icon>
                    </div>
                    <!-- <img src="../../../assets/images/editor/share.svg" alt="" (click)="shareWebsiteMobile()"> -->
                    <div class="btn-group publish" role="group" *ngIf="staffId && business.length">
                        <button type="button" class="publish_btn"  aria-expanded="false" (click)="createBusiness('NOT_PUBLISHED')">
                            Save
                        </button>
                        <!-- <ul class="dropdown-menu" style="z-index: 1001;">
                            <li><a class="dropdown-item cursor-pointer" (click)="createBusiness('PUBLISHED')">Publish</a></li>
                            <li><a class="dropdown-item cursor-pointer" (click)="createBusiness('NOT_PUBLISHED')">Save</a></li>
                        </ul> -->
                    </div>
                </div>
            </ng-container>
        </div>
    </section>

    <!-- component Dynamic pickup section -->
    <section class="bottom_layer" [ngClass]="urlShared ? 'full_bottom_layer' : 'bottom_layer'">
        <ng-template #websiteStructure></ng-template>
    </section>


    <!-- singup tab appearce in bottom of the screen -->
    <div class="signup-tab" *ngIf="!staffId && !googleTokenId && !urlShared">
        <div class="signupsection" [ngClass]="{'shaking-animation': needUserToLogin}">
            <div class="signUp-text">
                Edit and publish your website, get a custom domain, analytics and more
            </div>
            <div class="signUp-btn">
                <button (click)="signUp('PUBLISHED')">Sign up free</button>
            </div>
        </div>
    </div>

    <div class="signup-tab" *ngIf="staffId || googleTokenId">
        <div *ngIf="srcWidth <= 475" class="signupsection" style="flex-direction: row;gap: 30PX;
            align-items: center;
            width: 95%;" [ngClass]="{'shaking-animation': needUserToLogin}">
            <div class="signUp-text" style="margin-bottom: 0; font-weight: bold;
            color: black;">
                Publish your website
            </div>
            <div class="signUp-btn">
                <button *ngIf="!googleTokenId" (click)="createBusiness('PUBLISHED')">Publish</button>
                <button *ngIf="googleTokenId" (click)="signUp('PUBLISHED')">Publish</button>
            </div>
        </div>
    </div>
    <!-- Global Regenerate -->
    <!-- <section class="restyle_component" *ngIf="globalRegenerateComponent"
        [ngClass]="srcWidth > 475 ? 'restyle_component' : 'mobile_restyle_component'">
        <div class="regenerate_center_component">
            <div class="heading_style">
                <div class="heading_text">Regenerate Website</div>
                <div class="cross_icon" (click)="cancelRegenerate()"><mat-icon>close</mat-icon></div>
            </div>
            <div class="hr-line"></div>
            <div class="location_change">
                <div class="regenerate_location">
                    <div class="label_heading">Location</div>
                    <mat-form-field class="sfull-width input_card" appearance="outline">
                        <input type="text" placeholder="Search for location" matInput [formControl]="searchBox"
                            [matAutocomplete]="auto2" [(ngModel)]="searchLocation" (keyup)="search()" />
                        <mat-autocomplete #auto2="matAutocomplete">
                            <mat-option *ngFor="
                                  let option of searchResults | async;
                                  index as i
                              " [value]="option.displayName" (click)="selectedLocations(option)">
                                {{ option.displayName | titlecase }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
            <div class="location_change">
                <div class="regenerate_location">
                    <div class="label_heading">Industry Type</div>
                    <mat-form-field class="sfull-width domain-input input_card" appearance="outline">
                        <input type="text" placeholder="Enter business type" matInput [formControl]="myControl"
                            [matAutocomplete]="auto1" [(ngModel)]="selectedIndustry.name" />
                        <mat-autocomplete #auto1="matAutocomplete">
                            <mat-option *ngFor="
                                let option of filteredOptions | async;
                                index as i
                              " [value]="option.name" (click)="selectBusinessType(option)"
                                (keyup.enter)="selectBusinessType(option)">
                                {{ option.name}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
            </div>
            <div class="location_change">
                <div class="regenerate_location">
                    <div class="label_heading">Name</div>
                    <mat-form-field class="sfull-width input_card" appearance="outline">
                        <input matInput [(ngModel)]="bName" />
                    </mat-form-field>
                </div>
            </div>
            <div class="location_change">
                <div class="warning_text">
                    <img src="./../../../assets/images/common/info.svg" alt="info">
                    <div class="info_text">
                        Are you sure you want to regenerate the website? All content, images and styles will change.
                    </div>
                </div>
            </div>
            <div class="regenerate_btns">
                <button class="cancel_btn" (click)="cancelRegenerate()">Cancel</button>
                <button class="restyle_website" [disabled]="searchLocation === '' || bName === '' || !selectedIndustry.name"
                    (click)="doneGlobalRegenerate()">Regenerate</button>
            </div>
        </div>
    </section> -->


    <!-- Global Restyle -->

    <!-- <section *ngIf="restyleComponent" [ngClass]="srcWidth > 475 ? 'restyle_component' : 'mobile_restyle_component'">
        <div class="center_component">
            <div class="heading_style">
                <div class="heading_text">Restyle Website</div>
                <div class="cross_icon" (click)="cancelRestyleComponent()"><mat-icon>close</mat-icon></div>
            </div>
            <div class="hr-line"></div>
            <div class="button_styles">
                <div class="heading_text">Button Style</div>
                <div class="different_styles">
                    <div class="style">
                        <div class="label_heading">Style</div>
                        <mat-form-field appearance="outline" class="sfull-width">
                            <mat-select [(ngModel)]="bStyle">
                                <mat-option *ngFor="let item of buttonStyles" [value]="item?.value">
                                    {{item?.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="shape">
                        <div class="label_heading">Shape</div>
                        <mat-form-field appearance="outline" class="sfull-width">
                            <mat-select [(ngModel)]="bShape">
                                <mat-option *ngFor="let item of buttonShape" [value]="item.value">
                                    {{item.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="button_styles">
                <div class="heading_text">Colors</div>
                <div class="different_styles">
                    <div class="style">
                        <div class="label_heading">Brand</div>
                        <div class="color_picking">
                            <input type="color" [value]="btnColor" [(ngModel)]="btnColor" />
                            <span class="color_value">{{btnColor}}</span>
                        </div>
                    </div>
                    <div class="shape">
                        <div class="label_heading">Text Color</div>
                        <div class="color_picking">
                            <input type="color" [value]="btnTextColor" [(ngModel)]="btnTextColor" />
                            <span class="color_value">{{btnTextColor}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="button_styles">
                  <div class="heading_text">Fonts</div>
                  <div class="text_looks_like">
                      <div class="text_showing" [ngStyle]="{'font-family':globalStyles.headingFontFamily['font-family']}">Heading</div>
                      <div class="hint" [ngStyle]="{'font-family': globalStyles.bodyFontFamily['font-family']}">Body text will look like this.</div>
                  </div>
                  <div class="font_family">
                      <div class="label_heading">Select a font pair</div>
                      <mat-form-field appearance="outline" class="font-sfull-width">
                          <mat-select [(ngModel)]="selectingFontFamily" (ngModelChange)="changeFontFamily()">
                              <mat-option *ngFor="let item of fontFamilies" [value]="item?.value">
                                  {{item?.name}}
                              </mat-option>
                          </mat-select>
                      </mat-form-field>
                  </div>
              </div>
            <div class="btns">
                <button class="cancel_btn" (click)="cancelRestyleComponent()">Cancel</button>
                <button class="restyle_website" (click)="saveGlobalRestyle()">Restyle website</button>
            </div>
        </div>
    </section> -->
</section>
<div class="loading" id="animationLoader" *ngIf="screenLoader">
    <section class="loader_section">
        <!-- <app-loader [textLoop]="textsForScreenLoader"></app-loader> -->
        <app-lottie-loader></app-lottie-loader>
    </section>
    <div class="loading_text" id="addingText">{{loadingText}}</div>
</div>
<div class="loading" *ngIf="poppperLoader" id="popperLoader">
    <app-lottie-poppper></app-lottie-poppper>
</div>

<ng-template #CustomizeActions>
    <div class="d-flex align-items-center customize-items">
        <div class="page-dropdown whitebackgroundInput">
            <mat-form-field appearance="outline" class="days_dropdown">
                <mat-select [(ngModel)]="pageSeoslected">
                    <mat-option [value]="item.id" (click)="redirectToPage(item,i)" *ngFor="let item of templatePages;let i = index">{{item.pageName}}</mat-option>
                    <div class="border_bottom" ></div>
                    <mat-option  class="button-align" value="addPage"  (click)="openingSignupPopup()">
                        <!-- <mat-icon>add</mat-icon>  -->
                        <span class="button_test" > + Add Page</span>
                        <!-- <button class="button_test">Add Page</button> -->
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="re-style" id="restyle_component" (click)="changeSiteStyle()">
            <button *ngIf="srcWidth > 475">Restyle <img src="./../../assets/images/restyle.svg" alt="restyle"></button>
            <!-- <img src="./../../assets/images/restyle.svg" alt="restyle" *ngIf="srcWidth <= 475"> -->
        </div>
        <div class="re-style" (click)="doneGlobalRegenerate()">
            <button *ngIf="srcWidth > 475">Regenerate <img src="./../../assets/images/global-regeneration.svg"
                    alt="global-regeneration"></button>
            <!-- <img src="./../../assets/images/global-regeneration.svg"
                    alt="global-regeneration" *ngIf="srcWidth <= 475"> -->
        </div>
        <div class="re-style" *ngIf="srcWidth > 475">
            <button (click)="shareWebsite()" *ngIf="srcWidth > 475">Share <img src="./../../assets/images/share.svg" alt="Share"></button>
            <img src="./../../assets/images/share.svg" alt="Share" *ngIf="srcWidth <= 475">
        </div>

        <div class="dropdown" *ngIf="srcWidth <= 475">
            <div class="dropdown-toggle my-dropdown-toggle d-flex justify-content-center align-items-center" data-bs-toggle="dropdown">
                <img src="../../../assets/images/list-business/three_dots.svg" alt="">
            </div>

            <ul class="dropdown-menu dropdown-menu-dark bg-white">
                <li><a class="dropdown-item text-black d-flex align-items-center" (click)="!staffId ? signUp('PUBLISHED') : createBusiness('PUBLISHED')"><mat-icon class="me-2">publish</mat-icon> Publish</a></li>
                <li><a class="dropdown-item text-black d-flex align-items-center" (click)="shareWebsite()"><mat-icon class="me-2">share</mat-icon> Share</a></li>
              </ul>
        </div>
    </div>
</ng-template>

<div class="offcanvas offcanvas-bottom" style="width: 100%;height: auto!important;" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
    <div class="regenerate-top-header">
        <img src="../../../assets/images/regeneratename.svg" class="regenerate-image" alt="">
        <div data-bs-dismiss="offcanvas" id="closeRegenerateSheet" data-bs-target="#offcanvasBottom" aria-label="Close" class="close-bottom-sheet"><mat-icon>close</mat-icon></div>
    </div>
    <div class="ptb-20 regenerate-bottom">
        <div class="entire-website" (click)="doneGlobalRegenerate()">
            <img src="../../../assets/images/regenerate-website.svg"> <span>Entire Website</span>
        </div>
        <div class="entire-website">
            <img src="../../../assets/images/font-color.svg" (click)="changeSiteStyle()"> <span>Font & Colors</span>
        </div>
    </div>
</div>

<div class="offcanvas offcanvas-bottom page-bottom-sheet" style="width: 100%;height: auto!important;" tabindex="-1" id="addPageBottomSheet" aria-labelledby="addPageBottomSheetLabel">
    <div class="heading_section">
        <div class="heading">
            Page
        </div>
        <div class="closing_bar" data-bs-dismiss="offcanvas" data-bs-target="#addPageBottomSheet" aria-label="Close"><mat-icon>close</mat-icon></div>
    </div>
    <div class="hr_line"></div>
    <div *ngFor="let page of templatePages;let i = index">
        <div class="heading_section">
            <div class="heading">
                {{page.pageName}}
            </div>
            <div class="done_btn">
                <button class="home-icon" *ngIf="page.pageName != 'Home'" (click)="deletePage()"><mat-icon>delete</mat-icon></button>
                <button class="home-icon" (click)="openEditor()"><mat-icon>settings</mat-icon></button>
            </div>
        </div>
    </div>
    <div class="button-parent">
        <button class="cancel-button" id="closeaddPageBottomSheet" data-bs-dismiss="offcanvas" data-bs-target="#addPageBottomSheet" aria-label="Close">Cancel</button>
        <button class="add-new-page" (click)="pageSelectionChange({})">Add New Page</button>
    </div>
</div>
