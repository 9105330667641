import { Injectable } from "@angular/core";
import { ANIMATION_DIRECTION, ANIMATION_SPEED, BUTTON_SHAPE, BUTTON_TYPE, Corners, EventsService } from "simpo-component-library";

@Injectable({
  providedIn: 'root'
})


export class WebsiteEditor {

  constructor(private eventService: EventsService){}

  applyGlobalThemeColor(theme: any,templatePage: any[]){
    for(let page of templatePage){
      let themeIndex:number = 0;
      page.websiteThemeId = theme.id;


      for(let component of page.components){
        let color = theme.componentStyles[themeIndex % theme.componentStyles.length].bgColor
        let accentColor = theme.componentStyles[themeIndex % theme.componentStyles.length].accentColor

        if(component?.styles?.background){
          component.styles.background.color = color;
          component.styles.background.accentColor = accentColor;
          this.eventService.backgroundImageChangeCheck.emit({id: component.id, data: component.styles.background})
          themeIndex++;
        }
      }
    }

  }

  applyThemeForSinglePage(theme: any,page: any){
    let themeIndex:number = 0;
    page.websiteThemeId = theme.id;
    for(let component of page.components){
      let color = theme.componentStyles[themeIndex % theme.componentStyles.length].bgColor
      let accentColor = theme.componentStyles[themeIndex % theme.componentStyles.length].accentColor

      if(component?.styles?.background){
        component.styles.background.color = color;
        component.styles.background.accentColor = accentColor;
        this.eventService.backgroundImageChangeCheck.emit({id: component.id, data: component.styles.background})
        themeIndex++;
      }
    }
  }

  changeGlobalButtonStyle(style: number,type: BUTTON_TYPE, shape: BUTTON_SHAPE, templatePage:any[]){
    for(let page of templatePage){
      for(let component of page.components) {
        if(component.action && component.action.buttons
          && component.action.buttons[style - 1]){
          component.action.buttons[style - 1].styles.type = type;
          component.action.buttons[style - 1].styles.shape = shape;

          this.eventService.buttonStyleChangeChecks.emit({id: component.id + component.action.buttons[style - 1].id, buttonStyle: component.action.buttons[style - 1].styles, color: component.styles.background.accentColor})
        }
      }
    }
  }

  changeGlobalAnimation(animationType: string, animatonSpeed: string, templatePage: any[]){
    for(let page of templatePage){
      for(let component of page.components){
        if(component?.styles?.animation){
          component.styles.animation.type = animationType;
          component.styles.animation.speed = animatonSpeed;

          this.eventService.animationChangeChecks.emit({id: component.id,data: component.styles.animation})
        }
      }
    }
  }

  changeGlobalCorners(corner: Corners,templatePage: any[]){
    for(let page of templatePage){
      for(let component of page.components) {
        if(component?.styles?.corners){
          component.styles.corners = corner;
          this.eventService.cornerChangeChecks.emit({id: component.id, size: component.styles.corners})
        }
      }
    }
  }

  changeCustomColor(oldColor: string, newColor: string, accentColor: string, templatePage: any){
    for(let page of templatePage){
      for(let component of page.components){
        if(component?.styles?.background && component.styles.background.color === oldColor){
          component.styles.background.color = newColor;
          component.styles.background.accentColor = accentColor;
          this.eventService.backgroundImageChangeCheck.emit({id: component.id, data: component.styles.background})
        }
      }
    }
  }
}
