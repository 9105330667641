import { Component } from '@angular/core';

@Component({
  selector: 'app-blog-left-text-side-cards',
  templateUrl: './blog-left-text-side-cards.component.html',
  styleUrls: ['./blog-left-text-side-cards.component.scss', './mq-blog-left-text-side-cards.component.scss']
})
export class BlogLeftTextSideCardsComponent {

  templateData: any = {
    'componentCategory': "blog-listing",
    'ranking': 0,
    'businessCategoryId': "",
    "businessCategoryName": "All category",
    "createdTimeStamp": "",
    "language": "English",
    "componentName": "BlogListing3",
    "data" : [
      {
        value:"We share regular updates in Rareblocks",
        type:"Text",
        field:"Text",
        style:{"font-weight": 700}
      },
      {
        value:"The unseen of spending three years at Pixelgrade",
        type:"Text",
        field:"Text",
        style:{"font-weight": 500}
      },
      {
        value:"Lorem ipsum dolor sit amet. consectetur adipiscing elit.",
        type:"Text",
        field:"Text",
        style:{"color": "#535256"}
      },
      {
        value:"LIFESTYLE",
        type:"Text",
        field:"Text",
        style:{"color":"#727176"}
      },
      {
        value:"Why choose a theme that looks good with WooCommerce",
        type:"Text",
        field:"Text",
        style:{"font-weight": 500}
      },
      {
        value:"Lorem ipsum dolor sit amet. consectetur adipiscing elit.",
        type:"Text",
        field:"Text",
        style:{"color": "#535256"}
      },
      {
        value:"TUTORIAL",
        type:"Text",
        field:"Text",
        style:{"color":"#727176"}
      },
      {
        value:"How does writing influence your personal brand?",
        type:"Text",
        field:"Text",
        style:{"font-weight": 500}
      },
      {
        value:"Lorem ipsum dolor sit amet. consectetur adipiscing elit.",
        type:"Text",
        field:"Text",
        style:{"color": "#535256"}
      },
      {
        value:"PERSONAL",
        type:"Text",
        field:"Text",
        style:{"color":"#727176"}
      },
      {
        imgUrl:"../../../../../../../../assets/images/blog-website/blog-listing.jpg",
        type:"Image",
        field:"Image",
        style:{}
      },
      {
        imgUrl:"../../../../../../../../assets/images/blog-website/blog-listing.jpg",
        type:"Image",
        field:"Image",
        style:{}
      },
      {
        imgUrl:"../../../../../../../../assets/images/blog-website/blog-listing.jpg",
        type:"Image",
        field:"Image",
        style:{}
      },
      {
        value:"Read all blog posts",
        type:"Text",
        field:"Text",
        style:{"font-weight":500}
      },
      {
        value:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestiae iste dolorem error quam vitae eos alias iure nisi consectetur, vero distinctio suscipit quidem. Magni, at. Commodi fugiat distinctio tempora omnis.",
        type:"Text",
        field:"Text",
        style:{"font-weight":400}
      },
      {
        value:"Growth .",
        type:"Text",
        field:"Text",
        style:{"font-weight":500}
      },
      {
        value:"7 Mins Read",
        type:"Text",
        field:"Text",
        style:{"font-weight":500}
      }
    ]
  }

  data = this.templateData.data
  heading = this.data[0]
  title1 = this.data[1]
  description1 = this.data[2]
  lifeStyleText = this.data[3]
  title2 = this.data[4]
  description2 = this.data[5]
  tutorialText = this.data[6]
  title3 = this.data[7]
  description3 = this.data[8]
  personalText = this.data[9]
  image1 = this.data[10]
  image2 = this.data[11]
  image3 = this.data[12]
  readAllBlogText = this.data[13]
  subtext = this.data[14]
  growthText = this.data[15]
  date = this.data[16]
}