import { Component } from '@angular/core';

@Component({
  selector: 'app-multi-testimonial',
  templateUrl: './multi-testimonial.component.html',
  styleUrls: ['./multi-testimonial.component.scss','./mq-multi-testimonial.component.scss']
})
export class MultiTestimonialComponent {

}
