<div class="main-section">
    <div class="main-header-section">
        <div class="main-header-heading" [ngStyle]="data[15].style?data[15].style:{}">
            {{data[15].value}}
        </div>
        <div class="main-header-sub-heading" [ngStyle]="data[16].style?data[16].style:{}">
            {{data[16].value}}
        </div>
    </div>
    <div class="main-middle-section">
    <div class="left">
            <div class="flex_row">
                <div class="name_input">
                    <div class="label">Name</div>
                    <input placeholder="Please enter your name">
                </div>
                <div class="name_input">
                    <div class="label">Email</div>
                    <input placeholder="Please enter your mail id">
                </div>
            </div>
            <div class="flex_row_message">
                <div class="label">Message</div>
                <textarea name="" id="" cols="" rows="" placeholder="Enter message"
                    class="text_box"></textarea>
            </div>
            <div class="button_section">
                <button [ngStyle]="data[14].style?data[14].style:{}" class="send_btn" (click)="showMessage()">{{data[14].value}}</button>
            </div>
    </div>
    <div class="right">
        <div  [ngStyle]="heading.style?heading.style:{}"  class="right-subtext">{{heading.value}}</div>
        <div class="details">
            <div class="subtext-section">
                <div  class="subsection">
                    <div>
                        <img src="{{icon1.imgUrl}}" alt="" [ngStyle]="icon1.style?icon1.style:{}" class="icon-img">
                    </div>
                    <div>
                        <div [ngStyle]="subtext1.style?subtext1.style:{}" class="sub_heading">{{subtext1.value}}</div>
                        <div [ngStyle]="text1.style?text1.style:{}" class="sub_text">{{text1.value[0]}} <br> {{text1.value[1]}}</div>
                    </div>
                </div>
                <div  class="subsection">
                    <div>
                        <img src="{{icon2.imgUrl}}" alt="" [ngStyle]="icon1.style?icon1.style:{}"  class="icon-img">
                    </div>
                    <div>
                        <div [ngStyle]="subtext2.style?subtext2.style:{}"  class="sub_heading">{{subtext2.value}}</div>
                        <div [ngStyle]="text1.style?text1.style:{}" class="sub_text">{{text2.value}}</div>
                    </div>
                </div>
            </div>
            <div class="subtext-section">
                <div class="subsection">
                    <div>
                        <img src="{{icon3.imgUrl}}" alt="" [ngStyle]="icon1.style?icon1.style:{}"  class="icon-img">
                    </div>
                    <div>
                        <div [ngStyle]="subtext3.style?subtext3.style:{}" class="sub_heading">{{subtext3.value}}</div>
                        <div [ngStyle]="text1.style?text1.style:{}" class="sub_text">{{text3.value}}</div>
                    </div>
                </div>
                <div class="subsection">
                    <div>
                        <img src="{{icon4.imgUrl}}" alt="" [ngStyle]="icon1.style?icon1.style:{}"  class="icon-img">
                    </div>
                    <div>
                        <div [ngStyle]="subtext4.style?subtext4.style:{}" class="sub_heading">{{subtext4.value}}</div>
                        <div [ngStyle]="text1.style?text1.style:{}" class="sub_text">{{text4.value[0]}} <br> {{text4.value[1]}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</div>
