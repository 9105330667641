import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ComponentImageUploadService } from 'src/app/services/component-image-upload.service';
import { TemplateService } from 'src/app/services/template.service';
import { MediaSelectorComponent } from '../../popups/media-selector/media-selector.component';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';

@Component({
  selector: 'app-image-editor-card',
  templateUrl: './image-editor-card.component.html',
  styleUrls: ['./image-editor-card.component.scss']
})
export class ImageEditorCardComponent implements OnInit{

  @Input() data : any;
  imageData : any;

  constructor(
    private imageUploadService:ComponentImageUploadService,
    private templateService: TemplateService,
    private _eventHelper : EventEmmiterService,
  ){
    this.getScreenSize();
  }

  ngOnInit(): void {
    console.log('normal Image Editor',this.data);
  }
  pageLoader:boolean=false;
  addImage(item){
    if(this.srcWidth > 475){
    // this.pageLoader = true;
    // this.imageData = item;
    // let elementId = document.getElementById('browse_img');
    // if(!elementId) return;
    // elementId.click();
    // this.pageLoader = false;
    let dialog = this.templateService.openDialogWithData(
      MediaSelectorComponent,
      '90vh',
      '50%',
      {multiple:false}
    )
      dialog.afterClosed().subscribe(
        (res:any)=>{
          this.profileImg = res[0].assets[0].url;
          this.data.attachment = this.profileImg;
          this._eventHelper.showSaveOption.emit({data:true});   
      }
      )
    }
    if(this.srcWidth < 475){
      let dialog = this.templateService.openDialogWithData(
        MediaSelectorComponent,
        '100vh',
        '100%',
        {multiple:false}
      )
        dialog.afterClosed().subscribe(
          (res:any)=>{
            this.profileImg = res[0].assets[0].url;
            this.data.attachment = this.profileImg;
            this._eventHelper.showSaveOption.emit({data:true});   
        }
        )

    }
  }

  file : any ;
  profileImg : any = '';
  imageUploadingLoader : boolean = false;

  async updateImage(ev:any){
    this.pageLoader = true;
    console.log(this.data);
    const file = ev.srcElement.files[0];
    this.profileImg = await this.imageUploadService.uploadComponentsImages(file);
    this.profileImg = this.profileImg.Location;
    this.imageData.attachment = this.profileImg;
    this._eventHelper.showSaveOption.emit({data:true});
    this.pageLoader = false;
  }

  srcHeight: any;
  srcWidth: any;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.srcHeight = window.innerHeight;
    this.srcWidth = window.innerWidth;
    ////console.log(this.scrHeight, this.scrWidth);
  }

}
