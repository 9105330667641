import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-process-count-image-side-card',
  templateUrl: './process-count-image-side-card.component.html',
  styleUrls: ['./process-count-image-side-card.component.scss']
})
export class ProcessCountImageSideCardComponent implements OnInit{

  @Input() dynamicData : any;
  @Input() indexValue : any;

  even : boolean = false;

  constructor(){}

  ngOnInit(): void {

    if((this.indexValue%2 === 0)){
      this.even = true
    }
    else{
      this.even = false
    }
  }

}
