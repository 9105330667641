import { Component, Input } from '@angular/core';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';

@Component({
  selector: 'app-plan-button-element',
  templateUrl: './plan-button-element.component.html',
  styleUrls: ['./plan-button-element.component.scss']
})
export class PlanButtonElementComponent {

  constructor(
    private _eventHelper : EventEmmiterService
  ){}

  @Input() data:any;
  @Input() styles:any;

  ngOnInit(){
    if(this.data.hasOwnProperty('status')){
    }
    else{
      this.data.status = true
    }
    
  }
  clickFunction() {
    this._eventHelper.buttonCick.emit({buttonData:this.data});
  }

}
