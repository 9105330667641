import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TemplateService } from 'src/app/services/template.service';

@Component({
  selector: 'app-contact-us-domain',
  templateUrl: './contact-us-domain.component.html',
  styleUrls: ['./contact-us-domain.component.scss']
})
export class ContactUsDomainComponent {

  constructor(
    private templateService : TemplateService,
    public dialogRef: MatDialogRef<ContactUsDomainComponent>,

  ){

  }

  close(){
    this.dialogRef.close();
  }
}
