<section class="main_section">
    <div class="top_section">
        <div class="section_heading">Add Section</div>
        <div class="closing_bar" (click)="close()">
          <mat-icon>
            <span *ngIf="pageLoader"><i class="fas fa-spinner fa-spin"></i>&nbsp;Loading</span>
            close</mat-icon></div>
    </div>
    <div class="middle_section_bar">
        <ng-container *ngFor="let item of tabsSection">
            <div class="single_tab" [ngClass]="item.status ? 'single_tab_active' : ''" (click)="changingTab(item)">
                {{item.name | titlecase}}
                <div [ngClass]="item.status ? 'colored_hr_line' : 'normal_hr_line'"></div>
            </div>
        </ng-container>
    </div>
    <div class="bottom_section">
        <ng-container *ngIf="selectedTab === 'CUSTOME_COMPONENTS'">
            <div class="custome_chek">
                <div class="all_custome_elements">
                    <ng-container *ngFor="let item of customElements">
                        <div class="single_custome_element" *ngIf="item.category.name === 'text element'" (click)="clickedOnCustomElement(item)">
                            <div class="single_custome_left_part">
                                <div class="cutome_element_heading">
                                    Text
                                </div>
                                <div class="custome_element_description">
                                    Top section of web template, including logo and navigation.
                                </div>
                            </div>
                            <div class="single_custome_right_part">
                                <img src="./../../../../../../../assets/images/editor/custome_text_element.svg" alt="only_text_element">
                            </div>
                        </div>
                        <div class="single_custome_element" *ngIf="item.category.name === 'image element'" (click)="clickedOnCustomElement(item)">
                            <div class="single_custome_left_part">
                                <div class="cutome_element_heading">
                                    Image
                                </div>
                                <div class="custome_element_description">
                                    Top section of web template, including logo and navigation.
                                </div>
                            </div>
                            <div class="single_custome_right_part">
                                <img src="./../../../../../../../assets/images/editor/custome_image_element.svg" alt="only_single_image_element">
                            </div>
                        </div>
                        <!-- <div class="single_custome_element" *ngIf="item.category.name === 'multi image gallery element'" (click)="clickedOnCustomElement(item)">
                            <div class="single_custome_left_part">
                                <div class="cutome_element_heading">
                                    Image Gallery
                                </div>
                                <div class="custome_element_description">
                                    Top section of web template, including logo and navigation.
                                </div>
                            </div>
                            <div class="single_custome_right_part">
                                <img src="./../../../../../../../assets/images/editor/custome_multi_image_element.svg" alt="only_single_image_element">
                            </div>
                        </div> -->
                    </ng-container>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="selectedTab === 'WEB_COMPONENTS' ||  selectedTab === 'PRIVATE_COMPONENTS'">
            <div class="multi_tabs_section">
                <ng-container *ngIf="srcWidth > 475">
                <div class="multi_left_part">
                    <div class="center_tab">
                        <ng-container *ngFor="let item of subCategories">
                            <div class="single_key" *ngIf="item.name != 'Footer'" [ngClass]="item.status ? 'single_key_active' : ''"
                            (click)="changeSubCategory(item , selectedTab)">{{item.name}}</div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="srcWidth < 475">
                <div class="multi_left">
                <mat-form-field appearance="outline" class="days_dropdown">
                    <mat-select  [(ngModel)]="webComponentValue" >
                        <mat-option [value]="item.name" *ngFor="let item of subCategories" (click)="changeSubCategory(item , selectedTab)"  >
                            {{item.name}}
                        </mat-option>

                    </mat-select>
                </mat-form-field>
            </div>
            </ng-container>

                <div class="multi_right_part">
                    <div class="web_components_list">
                        <ng-container *ngFor="let item of selectedComponents" >
                            <ng-container>

                            <div class="single_web_component" (click)="localElementsClicked(item)" >
                                <div class="component_image">
                                    <img [src]="item.imgUrl" alt="">
                                </div>
                            </div>
                        </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</section>
