<div class="main-section">
    <div class="top-section">
      <div>
        <img _ngcontent-mat-c279 src="../../../../../../assets/images/invoice/delete-invoice.svg" alt class="delete-img">
    </div>
    <div class="popup-profile-name">Are you sure</div>
    </div>
    <div class="sub-text">You want to delete this Blog?</div>
    <div class="light-text">If you delete the blog, you can’t recover it.</div>
    <div class="button-section">
        <div class="delete-btn">
            <button class="sec-btn" (click)="cancelBlog()">Cancel</button>
        </div>
        <div class="delete-btn">
            <button class="btn-name" (click)="deleteBlog()" [disabled]="isLoading">Delete</button>
        </div>
    </div>
</div>
