<section class="main-section">
    <!-- header section  -->
    <div class="header-section">
        <img src="../../../../../../../../../assets/images/blogs/delete_feature_icon.svg" alt="delete_icon" />
    </div>
    <div class="header-left-side">
        Delete business
    </div>
    <div class="header-sub-text">
        Are you sure you want to delete this business? This action cannot be undone.
    </div>

    <div class="button-section">
        <div class="left-side">
            <div class="cancel-button" (click)="closeBtn()">Cancel</div>
        </div>
        <div class="right-side">
            <div class="apply-button" (click)="deleteBusiness()" *ngIf="!deleteLoader">
                Delete 
            </div>
            <div class="apply-button" *ngIf="deleteLoader">
                <i class="fas fa-spinner fa-spin"></i>&nbsp;Loading
            </div>
        </div>
    </div>
</section>
