import { AfterViewInit, Component, ComponentFactoryResolver, ElementRef, HostListener, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DbService } from 'src/app/services/db-service/db.service';
import { TemplateService } from './../../../../services/template.service';
import { DynamicpickcomponentsService } from 'src/app/services/dynamicpickcomponents.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';
import { Industry, StorageKeys } from 'src/app/shared-module-files/simpo.constant';
import { WebisiteService } from '../webisite.service';
import { environment } from 'src/environments/environment';
import { StaffServiceService } from '../../staff/service/staff-service.service';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss', './mq-preview.component.scss']
})
export class PreviewComponent implements OnInit, AfterViewInit {

  @ViewChild('compFrame', {static: false}) compFrame: ElementRef | undefined;
  doc: any;

  @ViewChild('mobileDisplay', { read: ViewContainerRef })
  mobileDisplay: ViewContainerRef | any;

  @ViewChild('displayStructure', { read: ViewContainerRef })
  displayStructure: ViewContainerRef | any;

  componentNames: any = [];

  viewType: "MOBILE" | "DESKTOP" | "TABLET" = "DESKTOP";


  constructor(
    private templateService: TemplateService,
    private router: Router,
    private _dbService: DbService,
    private pickupService: DynamicpickcomponentsService,
    public ds: DomSanitizer,
    private _eventHelper: EventEmmiterService,
    private websiteService:WebisiteService,
    private staffService: StaffServiceService
  ) { this.getScreenSize() }

  monitorScreen: boolean = true;
  mobileScreen: boolean = false;

  heroBannerComponent: boolean = false;
  heroBanner_ComponentData: any;
  businessDetails: any;
  websiteStatus: any;
  pageLoader: boolean = false;

  ngAfterViewInit(): void {
    //this.getTemplateDataById();
  }
  ngOnDestroy(){
    if(this.swithchBusiness){
      this.swithchBusiness.unsubscribe();
    }
  }
  editAccess: boolean = true;
  ngOnInit(): void {
    this.editAccess = this.staffService.getStaffPermission('WEBSITE', 'EDIT');
    let bDetails = window.localStorage.getItem('bDetails');
    if (bDetails) {
      this.businessDetails = JSON.parse(bDetails);
    }
    this.getBusinessDetailsByBusinessId();
    this.getblogsByBusinessId();
    this.getUrlData();
    this.checkingSwithBusiness();
  }

  swithchBusiness : any ;

  checkingSwithBusiness() {
    this.swithchBusiness = this._eventHelper.switchBusiness.subscribe(
      (res: any) => {
        if (res.switchBusiness) {
          this.gettingWebsiteDataLoader = true
          let bDetails = window.localStorage.getItem('bDetails');
          if (bDetails) {
            this.businessDetails = JSON.parse(bDetails);
          }
          //this.getTemplateDataById();
          this.editAccess = this.staffService.getStaffPermission('WEBSITE', 'EDIT');
          this.getBusinessDetailsByBusinessId()
          this.getblogsByBusinessId();
          this.getUrlData();
        }
      }
    )

  }

  path: any;
  buttonCickedChecks(res:any) {
        if (res.buttonData.redirectionType === 'SAME_PAGE') {
          this.pickupService.samePageRedirection(this.selectedPageData, res.buttonData.redirectTo);
        }
        else if (res.buttonData.redirectionType === 'INTERNAL') {
          this.displayStructure.clear()

          this.websiteData.forEach(webData => {
            if(webData.path.split('/')[1] != undefined){
              if ((res.buttonData.redirectTo).toLowerCase() === (webData.path).toLowerCase()) {
                if (webData.path != '' && webData.components[0].category.name != 'Top Nav Bar') {
                  webData.components.splice(0, 0, this.websiteData[0].components[0])
                  // console.log(webData.components.length)
                  webData.components.splice(webData.components.length, 0, this.websiteData[0].components[this.websiteData[0].components.length - 1]);
                }
                else { }
                this.pickupService.dyanmicComponentPickup(this.displayStructure, webData.components, false);
                this.path = webData.path;
                this.selectedPageData = webData;
              }
            }
            else{
              this.path = webData.path;
              this.selectedPageData = webData;
              this.pickupService.dyanmicComponentPickup(this.displayStructure, webData.components, false);
            }
          });
          this.websiteData[0].components[0].itemStyles.forEach(checkHighlight => {
            if(checkHighlight.item.redirectTo === res.buttonData.redirectTo){
              checkHighlight.item.highlight = true;
            }
            else{
              checkHighlight.item.highlight = false;
            }
          });
        }
        else {
          this.pickupService.externelUrls(res.buttonData.redirectTo)
        }
  }

  //get url data means get the website data which is already generated

  website: any;
  getUrlData() {
    let fetchingWebsite = this.ds.bypassSecurityTrustHtml(this.businessDetails?.websiteUrl);
    // this.website =  fetchingWebsite.text();
    // console.log(fetchingWebsite);
    this.templateService.getWebsiteData(this.businessDetails?.websiteUrl).subscribe(
      (res: any) => {
        console.log(res)
        this.website = res
      },
      (err) => {
        console.log(err)
      }
    )
  }

  screenWidth: any;
  @HostListener("window: resize", ["$event"])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }


  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
    this.templateService.openSnack("Link copied.", "Close");
  }

  changeScreen(element: any) {
    if (element === 'monitor') {
      this.monitorScreen = true;
      this.mobileScreen = false;
      if (this.monitorScreen) {
        this.getTemplateDataById()
      }
    }
    else {
      this.mobileScreen = true;
      this.monitorScreen = false;
      // this.getTemplateDataById()
      // this.getIframeValuesForMobile();
    }
  }

  changeMonitors(type){
    // console.log(type);
    this.viewType = type;
    // if(this.viewType === 'DESKTOP'){
    //   this.getTemplateDataById()
    // }
  }

  public getIframeValuesForMobile(){
    setTimeout(() => {
      this.doc = this.compFrame?.nativeElement.contentDocument || this.compFrame?.nativeElement.CompFrame.contentWindow;
      this.pickupService.dynamicMobilePreviewPickup(this.doc,this.mobileDisplay, this.websiteData[0].components, false);
    }, 2000);
  }

  private createComponents(docValue):void{
    console.log(docValue)
    this.pickupService.dynamicMobilePreviewPickup(docValue,this.compFrame, this.websiteData[0].components, false);
  }


  editWebsite(element:any) {
    //
    this.router.navigate(['/website-editor'] , {queryParams:{status:element}})
  }


  //getting data based on buisiness id and templateid

  // async templateDataAssign(){
  //   this.componentNames.forEach((component:any) => {
  //       this.templateService.regenerateComponentCategory(component.componentCategory,'1eda9215-46e6-6984-a675-130cb2740cf4').subscribe(
  //         {
  //           next:(value:any)=>{
  //             this.templateData.componentContentList.push(value.data);
  //           },
  //           error:(err)=>{
  //             this.templateService.openSnack(`${component.componentCategory} Not Found`,"Close");
  //           },
  //         }
  //       )
  //   });
  // }

  sendingColor: any;
  bgColorCountValue = 1;
  websiteData: any;
  selectedPageData: any;

  gettingWebsiteDataLoader: boolean = true;
  getTemplateDataById() {
    this.templateService.getWebsiteWithAllPages(this.businessDetails.templateId).subscribe(
      (res: any) => {
        // this.websiteData = res.data;
        // this.path = res.data[0].path
        // this.selectedPageData = res.data[0];
        // res.data.forEach(element => {
        //   res.data[0].components[0].itemStyles.forEach(iStyle => {
        //     if(iStyle.item.redirectTo === this.selectedPageData.path){
        //       iStyle.item.highlight = true;
        //     }
        //     else{
        //       iStyle.item.highlight = false;
        //     }
        //   });
        // });
        // const instance: any = this.pickupService.dyanmicComponentPickup(this.displayStructure, res.data[0].components, false);
        // this.initiateSubscription(instance)
        // this.pageLoader = false;
        this.getBusinessDetailsByBusinessId();
        // setTimeout(() => {
        //this.gettingWebsiteDataLoader = false
        // }, 150000);
      },
      (err) => {
        // this.pageLoader = false;
        this.gettingWebsiteDataLoader = false
        this.templateService.openSnack(
          `${err.error.message}`,
          'Close'
        )
      }
    )
  }

  private initiateSubscription(instance: any) {
    if(!instance) return;
    instance.buttonCick.subscribe((response) => {
      this.buttonCickedChecks(response);
    })
  }

  websiteUrl: any = '';
  getBusinessDetailsByBusinessId() {
    this.templateService.getBusinessDetailsById(this.businessDetails.id).subscribe(
      (res: any) => {
        this.websiteStatus = res.data.website.status;
        this.websiteUrl = res.data.website.domainVerified ? res.data.website.domainUrl : res.data.website.websiteUrl;
        this.pageLoader = false;
        if (this.websiteStatus != 'DISABLED') {

        }
        else {
          let getId = document.getElementById('main_section');
          if (!getId) return;
          getId.insertAdjacentHTML('beforeend', '<div class="disabled_text">Disabled</div>')
        }
        this.safeUrl = null
        this.getSafeUrl();
      },
      (err) => {
        // //console.log(err.error.message);
        this.pageLoader = false;
        this.templateService.openSnack(
          `${err.error.message}`, 'Close'
        )
      }
    )
  }


  connectCustomDomain() {
    this.router.navigate([`/admin/website/settings`]);
    this.templateService.scrollToComponent.emit({ goTo: 'custom_domain', component: 'settings' })
  }
  previewCurrentWebsite() {
    window.open(this.websiteUrl, '_blank')?.focus();
  }
  PUBLISHED(){
    console.log('');
  }

  blogData: any = "";
  displayBlogs: boolean = false;
  getblogsByBusinessId() {
    this.templateService.getBlogsByBuisnessId(this.businessDetails.id, "ALL").subscribe
      ({
        next: (res: any) => {
          this.blogData = res.data;
          if (this.blogData.length > 0) {
            this.displayBlogs = true;
          }
          else {
            this.displayBlogs = false;
          }
          //console.log("displayBlogs",this.displayBlogs);

        },
        error: (err: any) => {
        }
      })

  }
  loader = false;
  changeStatus(){
    this.loader = true;
    let data = {
      bId:this.businessDetails.id,
      status : 'PUBLISHED'
    }
    this.websiteService.updateWebsiteStatus(data).subscribe((res:any) => {
      this.websiteStatus = 'PUBLISHED';
      this.loader = false;
      window.localStorage.setItem('status','PUBLISHED');
    },() => {
      this.loader = false;
    })
  }
  safeUrl:any;
  getSafeUrl(): SafeResourceUrl {
    if (!this.safeUrl) {
      let url : any;
      if(window.location.href.includes('localhost')){
        url = 'http://localhost:4200/website-preview'
      }
      else{
        url = environment.iframeRelated + '/website-preview'
      }
      this.safeUrl = this.ds.bypassSecurityTrustResourceUrl(url);
    }
    return this.safeUrl;
  }

  private iframeLoaded: boolean = false;
  onIframeLoad(): void {
    if(this.iframeLoaded){
      this.gettingWebsiteDataLoader = false
      console.log('Iframe has loaded!');
    } else {
      this.iframeLoaded = true;
    }
  }

}
