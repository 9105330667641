import { Component, HostListener, Inject, Optional } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TemplateService } from 'src/app/services/template.service';

@Component({
  selector: 'app-share-website',
  templateUrl: './share-website.component.html',
  styleUrls: [
    './share-website.component.scss',
    './mq-share-website.component.scss',
  ],
})
export class ShareWebsiteComponent {
  webSiteUrl: any = '';
  clipboard: boolean = false;
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<ShareWebsiteComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public bottomSheet: MatBottomSheetRef<ShareWebsiteComponent>,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData:any,
    private templateService: TemplateService
  ) {
    this.getCurrentRoute();
    this.getScreenSize();
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    if(this.screenWidth > 475) 
      this.webSiteUrl = this.data?.webId;
    else{
      this.webSiteUrl = this.bottomSheetData.webId;
    }
    // if (this.screenWidth < 475) {
    //   this.dialogRef.updatePosition({ top: '55%'});
    // }
    // if(this.screenWidth > 475){
    //   this.dialogRef.updatePosition({top: '55%'})
    // }
  }

  screenWidth: any;
  @HostListener('window: resize', ['$event'])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  getCurrentRoute() {
    //console.log(this.data);

    //console.log("routing",window.location.href);
    this.webSiteUrl = window.location.href;
    if (this.screenWidth > 475 ? this.data.webId == undefined : this.bottomSheetData.webId == undefined) {
      this.webSiteUrl = this.webSiteUrl + `?webId=${this.webSiteUrl}`;
    }
  }
  clipboardCopy() {
    this.clipboard = true;
    let val = this.webSiteUrl;
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    // this.templateService.openSnack("link copied successfully","Close");
    setTimeout(() => {
      this.clipboard = false;
    }, 5000);
  }

  closeDilog() {
    this.dialogRef.close();
  }

  share(item: any) {
    if (item === 'facebook') {
      window.open(
        'https://www.facebook.com/sharer/sharer.php?u=' +
          encodeURIComponent(this.screenWidth > 475 ? `${this.data.webId}` : `${this.bottomSheetData.webId}`)
      );
    } else if (item === 'twitter') {
      window.open(
        'https://twitter.com/intent/tweet?text=%20Check%20ou%20this%20website%20builder.%20Really%20awesome%20' +
          encodeURIComponent(this.screenWidth > 475 ? `${this.data.webId}` : `${this.bottomSheetData.webId}`)
      );
    } else if (item === 'instagram') {
      window.open(
        'https://instagram.com/sharer/sharer.php?u=' +
          encodeURIComponent(this.screenWidth > 475 ? `${this.data.webId}` : `${this.bottomSheetData.webId}`)
      );
    }
  }

  closeBottomSheet(){
    this.bottomSheet.dismiss();
  }
}
