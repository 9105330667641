import { Component, Input, Inject } from '@angular/core';
import { ElementHelper } from '../../../elements/elements_helper';
import {MatSliderModule} from '@angular/material/slider';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';
import { fontSizes } from '../../../../../../../../constants/common-constant'

@Component({
  selector: 'app-contactus-editor-card',
  templateUrl: './contactus-editor-card.component.html',
  styleUrls: ['./contactus-editor-card.component.scss']
})
export class ContactusEditorCardComponent {

  fontSizes : any = fontSizes

  // @Input() data : any;

  itemData : any ;
  value : any;
  selectedField : any;

  constructor(
    public dialogRef: MatDialogRef<ContactusEditorCardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _elementHelper : ElementHelper,
    private _eventHelper : EventEmmiterService
  ){}

  ngOnInit(){
    // this.formatLabel(24)

    if(this.data.edit){
      this.itemData = this.data.item;

      if(this.itemData.details.type === 'DROPDOWN'){
        if(this.itemData.hasOwnProperty('options')){}
        else{
          this.itemData.options = []
        }
      }
      this.value = this.itemData.details.hint
    }
    else{
      // console.log(this.data)
      this.itemData = this.data.a;
      this.itemData.title.value = '';
      this.itemData.details.hint = '';
      this.itemData.details.fixedParam = true;
      this.itemData.details.paramType = '';
      this.itemData.config.width = '100%'
    }
    this.checkingType();
  }
  fontSize: number | any ;

  onTypeChange(type) {

  }

  checkingType(){
    this.textTypeArray.forEach((tt:any,i) => {
      if(this.itemData.details.type === 'INPUT' || this.itemData.details.type === 'TEXTAREA'){
        this.selectedField = this.textTypeArray[0];
      }
      else{
        this.selectedField = tt
      }
    });
  }

  textTypeArray = [
    {
      img:'./../../../../../../../../../assets/images/editor/text_field.svg',
      title:'Text Field',
      description:'A single line of text for anything you want to keep track',
      type:'INPUT',
      inputType : 'text'
    },
    {
      img:'./../../../../../../../../../assets/images/editor/number_field.svg',
      title:'Number Field',
      description:'A single line of text for anything you want to keep track',
      type:'NUMBER',
      inputType : 'number'
    },
    {
      img:'./../../../../../../../../../assets/images/editor/dropdown_field.svg',
      title:'Dropdown',
      description:'A single line of text for anything you want to keep track',
      type:'DROPDOWN',
      inputType : 'dropdown'
    },
    // {
    //   img:'./../../../../../../../../../assets/images/editor/checkbox_field.svg',
    //   title:'Checkbox',
    //   description:'A single line of text for anything you want to keep track',
    //   type:'CHECKBOX',
    //   inputType : 'checkbox'
    // },
    // {
    //   img:'./../../../../../../../../../assets/images/editor/date_field.svg',
    //   title:'Date',
    //   description:'A single line of text for anything you want to keep track',
    //   type:'DATE'
    // }
  ]



  close(){
    this.dialogRef.close({data:null})
  }

  openDropdown : boolean = false;
  dropdownClick(){
    this.openDropdown = !this.openDropdown
  }

  increaseTextAreaHeight(ev){
    let code = ev.keyCode || ev.which;
    this.value = ev.target.value.replace(/\n/g, "<br>");
    this.data.value = this.value;
    this.value = this.data.value.replace(/<br>/g, "\r\n");
  }

  enterValues(ev){
    console.log(this.itemData.options)
    // if(ev.keyCode === 188){

    // }
  }

  selectedItem(item){
    this.selectedField = item
    this.openDropdown = false;
    this.itemData.details.inputType = item.inputType;
    this.itemData.details.type = item.type;
    if(this.selectedField.type === 'DROPDOWN'){
      if(this.itemData.hasOwnProperty('options')){}
      else{
        this.itemData.options = []
      }      
    }

    console.log(this.itemData);
  }

  updateList(){
    this.dialogRef.close({data:this.data});
  }
}
