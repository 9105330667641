<div class="main_section">
    <div class="image_section">
        <img class="card_image" [src]="data.image.attachment">
    </div>
    <div class="content_section">
        <div class="title_section">
            <app-text-element [data] = "data.title"></app-text-element>
        </div>
        <div class="description_section">
            <app-text-element [data] = "data.description"></app-text-element>
        </div>
    </div>
</div>