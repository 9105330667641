import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-text-area-element',
  templateUrl: './text-area-element.component.html',
  styleUrls: ['./text-area-element.component.scss']
})
export class TextAreaElementComponent implements OnInit{

  @Input() responseData : any;

  data : any;

  constructor(){}

  ngOnInit(): void {
    this.data = this.responseData
  }

}
