<section class="complete-section">
  <div class="header-section">
    <div class="header-text">
      Pages
    </div>
    <div (click)="closeTab()" class="close-btn">
      <mat-icon>close</mat-icon>
    </div>
  </div>

  <div cdkDropList  class="list-pages"  (cdkDropListDropped)="drop($event)" >
    <ng-container *ngFor="let page of navButton; let i = index">
      <div cdkDragLockAxis="y"  cdkDrag class="drag-navbar">
        <div class="middle-section">
          <div class="middle-text"
            (click)="!page.hasOwnProperty('folder') ? pageAction('changePage', page.pageId, page.label) : ''">
            <mat-icon class="drag-icon">drag_indicator</mat-icon> &nbsp;&nbsp;{{page.label}}
          </div>
          <div class="icon-section">
            <div class="setting-icon"
              (click)="openPageSetting(page.hasOwnProperty('folder') ? page.folder.folderId : page.pageId, page.hasOwnProperty('folder') ? 'FOLDER' : 'PAGE')">
              <mat-icon style="font-size: 20px;">settings</mat-icon>
            </div>
            <div class="more-icon" [matMenuTriggerFor]="menu">
              <mat-icon style="font-size: 20px;">more_vert</mat-icon>
            </div>
            <mat-menu #menu>
              <div class="more-options">
                <div class="duplicate-option" (click)="pageAction('addPage',page.pageId,'', 0)"
                  *ngIf="!page.hasOwnProperty('folder')">
                  <mat-icon class="icon-duplicate">file_copy</mat-icon> Duplicate
                </div>
                <div class="delete-option" *ngIf="!showDeleteOption(page.label)"
                  (click)="!page.hasOwnProperty('folder') ? pageAction('deletePage', page.pageId) : deleteFolder(page.folder.folderId)">
                  <mat-icon class="icon-delete">delete</mat-icon> Delete
                </div>
              </div>
            </mat-menu>
          </div>
        </div>
        <div class="middle-section" *ngFor="let folder of page?.folder?.pages">
          <div class="down-level">
            <span></span>
            <div class="middle-text" (click)="pageAction('changePage', folder.pageId, folder.label)">
              {{folder.label}}
            </div>
          </div>
          <div class="icon-section">
            <div class="setting-icon" (click)="openPageSetting(folder.pageId, 'PAGE')">
              <mat-icon style="font-size: 20px;">settings</mat-icon>
            </div>
            <div class="more-icon" [matMenuTriggerFor]="menu">
              <mat-icon style="font-size: 20px;">more_vert</mat-icon>
            </div>
            <mat-menu #menu>
              <div class="more-options">
                <div class="duplicate-option" (click)="pageAction('addPage',folder.pageId,'', 0)">
                  <mat-icon class="icon-duplicate">file_copy</mat-icon> Duplicate
                </div>
                <div class="delete-option" *ngIf="page.pageName != 'Home'"
                  (click)="deletePage(page.folder.folderId, folder.pageId)">
                  <mat-icon class="icon-delete">delete</mat-icon> Delete
                </div>
              </div>
            </mat-menu>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="bottom-section">
    <div class="create-opt1">
      <div class="create-options" (click)="pageAction('addPage')">
        <mat-icon class="icons">note_add</mat-icon>
        <p class="name">Create page</p>
      </div>
    </div>
    <div class="create-opt1">
      <div class="create-options" (click)="createFolder()">
        <mat-icon class="icons">note_add</mat-icon>
        <p class="name">Create Folder</p>
      </div>
    </div>
    <!-- <div class="create-options">
        <mat-icon class="icons">create_new_folder</mat-icon> Create folder
      </div>
      <div class="create-options">
        <mat-icon class="icons">description</mat-icon> Create blog post
      </div> -->
  </div>
</section>