import { Component, HostListener, Inject } from '@angular/core';
import { TemplateService } from 'src/app/services/template.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProcessInprogressComponent } from '../process-inprogress/process-inprogress.component';
import { WebisiteService } from '../../webisite.service';
import { ContactUsDomainComponent } from '../contact-us-domain/contact-us-domain.component';


const showEntri = new CustomEvent('showEntri', {
  detail: {
    applicationId: "12345",
    token: "12345",
    dnsRecords: [],
  },
});
@Component({
  selector: 'app-steps-to-follow',
  templateUrl: './steps-to-follow.component.html',
  styleUrls: ['./steps-to-follow.component.scss']
})
export class StepsToFollowComponent {

  constructor(
    private templateService : TemplateService,
    public dialogRef: MatDialogRef<StepsToFollowComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private _websiteService : WebisiteService,
  ){
    this.domainData = data;
    this.getScreenSize();
  }

  businessDetails : any ;
  domainData:any;
  linkingDomain :any = '';

  ngOnInit(): void {
    let bDetails = window.localStorage.getItem('bDetails');
    if(bDetails){
      this.businessDetails = JSON.parse(bDetails);
    }
    this.linkingDomain = this.domainData.dnsDomainUrl;
  }

  close(){
    this.dialogRef.close();
  }

  

  submitLoader : boolean = false;
  submitVerify(){
    // this.submitLoader = true;
    let data = {
      businessId : this.businessDetails.id,
      domainUrl : this.linkingDomain,
      force:false
    }

    this._websiteService.gettinEntriToken(data).subscribe(
      (res:any) => {
        console.log(res.data);
        if(res.data === null){
          console.log('sass')
        }
        else{
          // let a = JSON.parse(res.data);
          let checkDomain = new CustomEvent('checkDomain',{
            detail:{
              domain:this.linkingDomain,
              config:res.data
            }
          });

          let checkingDomain = document.dispatchEvent(checkDomain)
          if(checkingDomain){
            const showEntri = new CustomEvent('showEntri', {
              detail: {
                applicationId: res.data.applicationId,
                token: res.data.token,
                dnsRecords: res.data.dnsRecords
              },
            });
            let showEntriChecks = document.dispatchEvent(showEntri);
            console.log(showEntriChecks);

            function handleOnEntriClose(event) {
              console.log('onEntriClose', event.detail);
            }
            window.addEventListener('onEntriClose', handleOnEntriClose, false);
          }
        }
        // else{

        // }
      },
      (err) => {
        console.log(err.error.message)
      }
    )
    // let checkDomain = new CustomEvent('checkDomain',{
    //   detail:{
    //     domainUrl : this.linkingDomain,
    //     config:''
    //   }
    // })

    // document.dispatchEvent(checkDomain)

    // this._websiteService.linkExistingDomain(data).subscribe(
    //   (res:any) => {
    //     // console.log(res.data);
    //     this.dialogRef.close();
    //     let popup = this.templateService.openDialog(
    //       ProcessInprogressComponent,
    //       'auto',
    //       '540px',
    //       '80vw'
    //     );
    //     this.submitLoader = false;
    //   },
    //   (err) => {
    //     this.submitLoader = false;  
    //     //console.log(err.error);
    //   }
    // )

    // this.dialogRef.close();
    // let popup = this.templateService.openDialog(
    //   ProcessInprogressComponent,
    //   'auto',
    //   '540px',
    //   '80vw'
    // )
  }
  contactUs(){
    if(this.srcWidth < 475){
    let popup = this.templateService.openDialog(
      ContactUsDomainComponent,
      'auto',
      '92%',
      {}
    )
    }
    if(this.srcWidth > 475){
      let popup = this.templateService.openDialog(
        ContactUsDomainComponent,
        'auto',
        '26%',
        {}
      )
    }
  }
  srcHeight:any;
  srcWidth:any;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event? : any) {
    this.srcHeight = window.innerHeight;
    this.srcWidth = window.innerWidth;
    // console.log(this.scrHeight, this.scrWidth);
  }
}
