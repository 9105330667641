import { Component, ElementRef, HostListener, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, map, startWith } from 'rxjs';
import { ShareWebsiteComponent } from 'src/app/our-components/share-website/share-website.component';
import { DbService } from 'src/app/services/db-service/db.service';
import { DynamicpickcomponentsService } from 'src/app/services/dynamicpickcomponents.service';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';
import { MapService } from 'src/app/services/map.service';
import { TemplateService } from 'src/app/services/template.service';
import {
  buttonShape,
  buttonStyle
} from 'src/app/constants/common-constant';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ListCustomElementsComponent } from 'src/app/master-config-components/micro-apps/website/edit-website/v-2/list-custom-elements/list-custom-elements.component';
import { Industry, StorageKeys } from 'src/app/shared-module-files/simpo.constant';
import { SingupPopupComponent } from './../../our-components/signin-signup/singup-popup/singup-popup.component';
import { BusinessServiceService } from 'src/app/master-config-components/micro-apps/business/services/business-service.service';
import { WebisiteService } from 'src/app/master-config-components/micro-apps/website/webisite.service';
import { SocialLoginService } from 'src/app/master-config-components/micro-apps/website-marketing/services/social-login-service.service';

@Component({
  selector: 'app-v2-website-generation',
  templateUrl: './v2-website-generation.component.html',
  styleUrls: ['./v2-website-generation.component.scss']
})
export class V2WebsiteGenerationComponent implements OnInit {

  constructor(
    private _templateService: TemplateService,
    private _dbService: DbService,
    private router: Router,
    private pickupService: DynamicpickcomponentsService,
    private eventHelper: EventEmmiterService,
    private _mapservice: MapService,
    private _authService: AuthServiceService,
    private _snackBar: MatSnackBar,
    private _activeRoute : ActivatedRoute,
    private _businessService : BusinessServiceService,
    private _websiteService: WebisiteService,
    private socialLogin : SocialLoginService
  ) {
    this.getScreenSize();
    this.getAllTypes();
  }

  srcWidth: any;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.srcWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.business = JSON.parse(localStorage.getItem("business") || '[]');
    this.websiteId = localStorage.getItem(StorageKeys.WEBSITE_ID) || '';
    this.staffId = localStorage.getItem(StorageKeys.STAFF_ID) || '';
    this.googleTokenId = localStorage.getItem(StorageKeys.GOOGLETOKENID) || null;
    let industry: any = localStorage.getItem(StorageKeys.INDUSTRY_DETAILS);
    this.selectedIndustry = industry ? JSON.parse(industry) : this.selectedIndustry;

    if(this.staffId) {
      this.urlShared = false;
      this.generateWebsite();
    }
    else if(this.websiteId && this._activeRoute.snapshot.queryParams['tempId'] === undefined){
      this.urlShared = false;
      this.websiteId = localStorage.getItem(StorageKeys.WEBSITE_ID) || '';
      this.getWebsite();
      // console.log();
    }
    else {
      this._activeRoute.queryParams.subscribe(
        (param:any) => {
          if(Object.keys(param).length === 0){
            this.urlShared = false;
            this.generateWebsite()
          }
          else{
            this.urlShared = true;
            this.websiteId = param.tempId;
            this.getWebsiteWithoutHover();
          }
        }
      )
      // if (this.websiteId) {
      //   this.getWebsite()
      // } else {
      //   this.generateWebsite();
      // }
    }
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((item, j) => {
        item.unsubscribe();
      });
    }
  }

  @ViewChild('websiteStructure', { read: ViewContainerRef })
  websiteStructure: ViewContainerRef | any;
  templatePages: any[] = [];
  pageSeoslected: any;
  staffId: string = '';
  urlShared : boolean = false;

  websiteId: string = '';
  business: any[] = [];
  googleTokenId:any;
  googleEmail:any;
  selectedIndustry: Industry = {
    name: '',
    industryId: '',
    subIndustryId: '',
    industryName: '',
    subIndustryName: ''
  };

  textsForScreenLoader: string[] = [];

  // get website data based on website id
  getWebsite() {
    this._templateService.getWebsiteWithAllPages(this.websiteId).subscribe(
      (res: any) => {
        this.loadingText = ''
        this.templatePages = res.data;
        this.pageSeoslected = this.templatePages[0].id;

        this.websiteData = res.data[0];

        //attaching current button styles
        this.buttonStyles.forEach((btnStyles) => {
          if (btnStyles.value === res.data[0].btnStyle) {
            this.bStyle = btnStyles.value
          }
        });

        //attaching current button shapes
        this.buttonShape.forEach((btnShapes) => {
          if (btnShapes.value === res.data[0].btnShape) {
            this.bShape = btnShapes.value;
          }
        });

        //attaching button color and button text color
        this.btnColor = res.data[0].btnColor;
        this.btnTextColor = res.data[0].btnTextColor;
        this.screenLoader = false;

        // this.dyanmicComponentPickup(this.websiteStructure,res.data,true);
        const instance: any = this.pickupService.dyanmicComponentPickup(this.websiteStructure, res.data[0].components, true)
        this.initiateSubscription(instance);
      },
      (err) => {
        this.screenLoader = false;
      }
    )
  }

  //get website without hover effects for share url
  webData : any ;
  getWebsiteWithoutHover() {
    this._templateService.getWebsiteWithWebCode(this.websiteId).subscribe(
      (res: any) => {
        this.loadingText = ''
        this.templatePages = res.data;
        this.pageSeoslected = this.templatePages[0].id;
        this.path = res.data[0].path
        this.selectedPageData = res.data[0];
        this.webData = res.data;
        this.websiteData = res.data[0];

        //attaching current button styles
        this.buttonStyles.forEach((btnStyles) => {
          if (btnStyles.value === res.data[0].btnStyle) {
            this.bStyle = btnStyles.value
          }
        });

        //attaching current button shapes
        this.buttonShape.forEach((btnShapes) => {
          if (btnShapes.value === res.data[0].btnShape) {
            this.bShape = btnShapes.value;
          }
        });

        //attaching button color and button text color
        this.btnColor = res.data[0].btnColor;
        this.btnTextColor = res.data[0].btnTextColor;
        this.screenLoader = false;

        // this.dyanmicComponentPickup(this.websiteStructure,res.data,true);
        const instance: any = this.pickupService.dyanmicComponentPickup(this.websiteStructure, res.data[0].components, false)
        this.initiateSubscription(instance);
      },
      (err) => {
        this.screenLoader = false;
      }
    )
  }
  localNewPageArray: any[] = [];
  currentPageIndex: number = 0;
  addNewSectionCick(res: any) {
    let index;
    // this.addNewSectionClickSubscribe = this.eventHelper.addNewSectionCick.subscribe(
    //   (res) => {
    index = res.index;
    if (res.status) {
      let customElements = this._templateService.openDialog(
        ListCustomElementsComponent,
        '674px',
        '1017px',
        'auto'
      );
      customElements.afterClosed().subscribe(
        (res: any) => {
          if (res.responseData != '') {
            // this.localNewPageArray.forEach((la,i) => {
            if (this.localNewPageArray[1]?.webComponentName === 'new_page') {
              this.websiteStructure.clear();
              this.localNewPageArray.splice(1, 1);
              this.localNewPageArray.push(JSON.parse(JSON.stringify(res.responseData)));
              this.pickupService.dyanmicComponentPickup(this.websiteStructure, this.localNewPageArray, true);
            }
            else {
              // this.localNewPageArray.splice(index + 1,0,res.responseData);
              this.templatePages[this.currentPageIndex].components.splice(index + 1, 0, JSON.parse(JSON.stringify(res.responseData)));
              this.pickupService.addComponent(this.websiteStructure, JSON.parse(JSON.stringify(res.responseData)), true, index + 1);
            }
            // });
          }
          else {
            if (res.localElement != '') {
              // console.log(index);
              // console.log(res.localElement);
              this._templateService.getLocalComponentWithData(index + 1, this.templatePages[0].id, res.localElement.componentId, this.websiteData.subIndustryId,this.templatePages[this.currentPageIndex].id).subscribe(
                (res: any) => {
                  console.log(res.data);
                  if (this.localNewPageArray[1]?.webComponentName === 'new_page') {
                    this.websiteStructure.clear();
                    this.localNewPageArray.splice(1, 1);
                    this.localNewPageArray.push(res.data);
                    this.pickupService.dyanmicComponentPickup(this.websiteStructure, this.localNewPageArray, true);
                  }
                  else {
                    // this.localNewPageArray.splice(index+1,0,res.data);
                    this.templatePages[this.currentPageIndex].components.splice(index + 1, 0, res.data)
                    // this.totalWebsiteData[this.currentPageIndex].components.splice(index+1,0,res.data)
                    // console.log(this.localNewPageArray);
                    this.pickupService.addComponent(this.websiteStructure, res.data, true, index + 1)
                  }
                },
                (err) => {
                  this._templateService.openSnack(
                    `${err.error.message}`,
                    'Close'
                  )
                }
              )

            }
          }
        }
      )
    }
    //   }
    // )
  }

  //generate website tab
  websiteData: any;
  screenLoader: boolean = true;
  loadingText : any = '';
  poppperLoader : boolean = false;
  generateWebsite() {
    document.getElementById('closeRegenerateSheet')?.click()
    this.loadingText = 'Generating a Home page'
    let data = {
      businessName: localStorage.getItem(StorageKeys.BUSINESS_NAME),
      address: {
        address: window.localStorage.getItem('address'),
        city: localStorage.getItem(StorageKeys.LOCALITY),
        state:localStorage.getItem(StorageKeys.STATE),
        country:window.localStorage.getItem('count'),
        pincode : window.localStorage.getItem('postalCode'),
        geolocation: {
          latitude: localStorage.getItem(StorageKeys.LATITUDE),
          longitude: localStorage.getItem(StorageKeys.LONGITUDE)
        }
      },
      industryName: this.selectedIndustry?.industryName ?? '',
      industryId: this.selectedIndustry?.industryId ?? '',
      subIndustryId: this.selectedIndustry?.subIndustryId ?? '',
      subIndustryName: this.selectedIndustry?.subIndustryName
    }
    this._templateService.generateWebsite(data).subscribe({
      next: (res: any) => {
        console.log(res.data);
        localStorage.setItem(StorageKeys.WEB_CODE,res.data[0].websiteCode);
        this.templatePages = res.data;

        this.websiteId = res.data[0].id;
        localStorage.setItem(StorageKeys.WEBSITE_ID, this.websiteId);

        //attaching current button styles
        this.buttonStyles.forEach((btnStyles) => {
          if (btnStyles.value === res.data[0].btnStyle) {
            this.bStyle = btnStyles.value
          }
        });

        //attaching current button shapes
        this.buttonShape.forEach((btnShapes) => {
          if (btnShapes.value === res.data[0].btnShape) {
            this.bShape = btnShapes.value;
          }
        });

        //attaching button color and button text color
        this.btnColor = res.data[0].btnColor;
        this.btnTextColor = res.data[0].btnTextColor;

        this.pageSeoslected = this.templatePages[0].id;

        this.websiteId = res.data[0].id;
        localStorage.setItem(StorageKeys.WEBSITE_ID, res.data[0].id);

        this.websiteData = res.data[0];

        //this is for dynamic component pick this is created in global level in pickup service file
        const instance: any = this.pickupService.dyanmicComponentPickup(this.websiteStructure, res.data[0].components, true);
        this.initiateSubscription(instance);

        let timer = 1500;
        let loaderText = document.getElementById('addingText');
        if(!loaderText) return;
        loaderText.innerHTML = this.loadingText;
        for (let index = 0; index < res.data[0].components.length; index++) {
          setTimeout(() => {
            let a = document.getElementById(res.data[0].components[index].name);
            let loaderText = document.getElementById('addingText');
            if(!loaderText) return;
            loaderText.innerHTML = `Generating Photos & Content for ${res.data[0].components[index].category.name}`;
            a?.scrollIntoView({
              behavior: 'smooth',
              block:"center"
            });
            if((index+1) === res.data[0].components.length){
              this.poppperLoader = true;
              this.screenLoader = false;
              setTimeout(() => {
                this.poppperLoader = false;
                setTimeout(() => {
                  let a = document.getElementById(res.data[0].components[0].name);
                  a?.scrollIntoView({
                    behavior: 'smooth',
                    block:"center"
                  });

                }, 250);
              }, 1000);
              this.screenLoader = false;
            }
          }, timer * index);
        }
      },
      error: (err) => {
        this.screenLoader = false;
      }
    });
  }

  // signup tab which is appeace in bottom of screen
  signUp(type) {
    const websiteId = localStorage.getItem(StorageKeys.WEBSITE_ID);
    localStorage.setItem(StorageKeys.WEBSITE_STATUS,type)

    if (websiteId) {
      if(this.googleTokenId){
        this.login()
        return;
      }
      this.router.navigate(['/signup']);
      window.localStorage.setItem('EXISTING','YES')
    } else {
      this._templateService.openSnack(
        'Website not yet generated, Getting error',
        'Ok'
      );
    }
  }

  regenerateButtonCountClick = 0;
  // component regenerate
  regenerateComponent(res: any) {
    // this._eventHelper.regenerateComponent.subscribe(
    //   (res:any) => {
    // console.log(this.websiteData.components[res.indexValue])

    this.regenerateButtonCountClick = this.regenerateButtonCountClick + 1
    if(this.regenerateButtonCountClick > 5 && !this.staffId ){
      this.openingSignupPopup();
    }
    else{
      let data = {
        index: res.indexValue,
        templateId: this.websiteData.id,
        subIndustryId: this.selectedIndustry?.subIndustryId ?? ''
      }
      this._templateService.regenerateComponent(data).subscribe(
        (comingData: any) => {
          this.websiteData.components.splice(res.indexValue, 1, comingData.data);
          this.pickupService.dyanmicComponentPickup(this.websiteStructure, this.websiteData.components, true);
          // this.pickupService.replaceComponent(this.websiteStructure,this.websiteData.components[res.indexValue],comingData.data,true,false);
        },
        (err) => {
          this._templateService.openSnack(
            `${err.error.message}`,
            'Close'
          )
        }
      )
    }
  }

  subscriptions: any[] = [];
  needUserToLogin: boolean = false;
  private initiateSubscription(instance: any) {
    if (!instance) return;
    this.subscriptions.push(
      instance.changeContent.subscribe((response: any) => {
      this.changeComponentContent(response);
    }));
    this.subscriptions.push(
     instance.changeContent.subscribe((response: any) => {
      this.changeComponentContent(response);
    }));
    this.subscriptions.push(
    instance.componentPositionMoving.subscribe((response: any) => {
      this.componentPositionMove(response);
    }));
    this.subscriptions.push(
    instance.componentDelete.subscribe((response: any) => {
      if (this.staffId) {

          this.deleteComponent(response);
      }
      else {
        // this.needUserToLogin = true;
        // setTimeout(() => {
        //   this.needUserToLogin = false;
        // }, 1500);
        // this.router.navigate(['/signup'])
        this.openingSignupPopup();
      }
    }));
    this.subscriptions.push(
    instance.regenerateComponent.subscribe((response: any) => {
      this.regenerateComponent(response);
    }));
      this.subscriptions.push(
    instance.addNewSectionCick.subscribe((response: any) => {
      if (this.staffId)
        this.addNewSectionCick(response);
      else {
        // this.needUserToLogin = true;
        // setTimeout(() => {
        //   this.needUserToLogin = false;
        // }, 1500);
        // this.router.navigate(['/signup'])
        this.openingSignupPopup();
      }
    }));
    this.subscriptions.push(
    instance.editSection.subscribe((response: any) => {
      if (this.staffId)  {
        if (this.business)
          this.createBusiness('NOT_PUBLISHED');
        else
          this.eventHelper.editSection.emit(response);
      }
      else {
        // this.needUserToLogin = true;
        // setTimeout(() => {
        //   this.needUserToLogin = false;
        // }, 1500);
        // this.router.navigate(['/signup'])
       this.openingSignupPopup()
      }
    }));
    this.subscriptions.push(
      instance.mobileEditorOpen.subscribe((response : any) => {
        if(this.staffId){
          if (this.business)
          this.createBusiness('NOT_PUBLISHED');
          else
          this.eventHelper.editSection.emit(response);
        } else{
          this.openingSignupPopup()
        }
      })
    )
    this.subscriptions.push(
      instance.buttonCick.subscribe((response) => {
        this.buttonCickedChecks(response);
      })
    )
    // }
  }

  selectedPageData:any;
  path: any;
  buttonCickedChecks(res:any) {
        if (res.buttonData.redirectionType === 'SAME_PAGE') {
          this.pickupService.samePageRedirection(this.selectedPageData, res.buttonData.redirectTo);
        }
        else if (res.buttonData.redirectionType === 'INTERNAL') {
          this.websiteStructure.clear()
          this.webData.forEach(webData => {
            if (res.buttonData.redirectTo === webData.path) {
              if (webData.path != '' && webData.components[0].category.name != 'Top Nav Bar') {
                webData.components.splice(0, 0, this.websiteData[0].components[0])
                // console.log(webData.components.length)
                webData.components.splice(webData.components.length, 0, this.websiteData[0].components[this.websiteData[0].components.length - 1]);
              }
              else { }
              this.pickupService.dyanmicComponentPickup(this.websiteStructure, webData.components, false);
              this.path = webData.path;
              this.selectedPageData = webData;
            }
          });
        }
        else {
          this.pickupService.externelUrls(res.buttonData.redirectTo)
        }
  }

  openingSignupPopup(){
    let dialogRef;
    if(this.srcWidth > 475){
      dialogRef = this._templateService.openDialog(
        SingupPopupComponent,
        'auto',
        '900px',
        {data:null}
      )
    }
    else{
      dialogRef = this._templateService.openDialog(
        SingupPopupComponent,
        '100%',
        '900px',
        {data:null}
      )
    }
    dialogRef.afterClosed().subscribe((response: any)=> {
      this.pageSeoslected = this.templatePages[0].id;
    })
  }

  deleteComponent(res: any) {
    let index = res.index;
    this.templatePages[this.currentPageIndex].components.splice(index, 1);
    this.pickupService.dyanmicComponentPickup(this.websiteStructure, this.templatePages[this.currentPageIndex].components, true);
    if ((this.templatePages[this.currentPageIndex].components.length === 1 && this.templatePages[this.currentPageIndex].components[0].category.name === 'Top Nav Bar') ||
      (this.templatePages[this.currentPageIndex].components.length === 2 && this.templatePages[this.currentPageIndex].components[1].category.name === 'Footer"')) {
      let newPageObject = {
        webComponentName: 'new_page',
        category: {
          name: 'new_page'
        }
      };
      this.templatePages[this.currentPageIndex].components.push(JSON.parse(JSON.stringify(newPageObject)));
      this.pickupService.dyanmicComponentPickup(this.websiteStructure, this.templatePages[this.currentPageIndex].components, true);
    }
  }

  componentPositionMove(res: any) {
    // this.componentPositionMoveSubscribe = this.eventHelper.componentPositionMoving.subscribe(
    //   (res:any) => {
    let indexA;
    let indexB;
    if (res.movingDirection === 'DOWN') {
      indexA = res.index;
      indexB = res.index + 1;
    }
    else if (res.movingDirection === 'UP') {
      indexA = res.index;
      indexB = res.index - 1;
    }
    let arr = this.templatePages[this.currentPageIndex].components;
    arr[indexA] = arr.splice(indexB, 1, arr[indexA])[0];
    this.pickupService.dyanmicComponentPickup(this.websiteStructure, this.templatePages[this.currentPageIndex].components, true);
    setTimeout(() => {
      const element = document.getElementById(this.templatePages[this.currentPageIndex].components[indexB].name);
      element?.scrollIntoView({
        behavior: 'smooth'
      });
    }, 100);
    //   }
    // )
  }

  //change component content

  changeComponentContent(res: any) {
    // this._eventHelper.changeContent.subscribe(
    //   (res:any) => {
      this.regenerateButtonCountClick = this.regenerateButtonCountClick + 1
      if(this.regenerateButtonCountClick > 5){
        // this.router.navigate(['/signup']);
        this.openingSignupPopup();
      }
      else{
        if (res.changeContent) {
          let data = {
            index: res.indexValue,
            templateId: this.websiteData.id,
            subIndustryId: this.selectedIndustry?.subIndustryId ?? ''
          }
          this._templateService.changeComponentContent(data).subscribe(
            (comingData: any) => {
              this.websiteData.components.splice(res.indexValue, 1, comingData.data);
              this.pickupService.dyanmicComponentPickup(this.websiteStructure, this.websiteData.components, true);
            },
            (err) => {
              this._templateService.openSnack(
                `${err.error.message}`,
                'Close'
              )
            }
          )
        }
      }
    // console.log(res.indexValue);
    // console.log(this.websiteData.components[res.indexValue]
    //   }
    // )
  }

  // page change event
  pageSelectionChange(ev) {
    document.getElementById('closeaddPageBottomSheet')?.click()
    if(!this.staffId){
      // this.router.navigate(['/signup']);
      setTimeout(() => {
        this.pageSeoslected = this.templatePages[0].id;
        this.openingSignupPopup();
      }, 10);
    // }
    // this.websiteStructure.clear()
    // for (var i = 0; i < this.templatePages.length; i++) {
    //   if (this.templatePages[i].id === ev.value) {
    //     if (i != 0) {
    //       const temp = Array();
    //       temp.push(this.templatePages[0].components[0]);
    //       temp.push(...this.templatePages[i].components);
    //       temp.push(this.templatePages[0].components[this.templatePages[0].components.lenght - 1]);
    //       this.pickupService.dyanmicComponentPickup(this.websiteStructure, temp, true);
    //     } else {
    //       this.pickupService.dyanmicComponentPickup(this.websiteStructure, this.templatePages[0].components, true);
    //     }
    //   }
    } else {
      this.createBusiness('NOT_PUBLISHED');
    }
  }


  // global regenerate structure and website
  globalRegenerateComponent: boolean = false;

  searchLocation: any =
    `${localStorage.getItem(StorageKeys.LOCALITY)}` + ',' + `${localStorage.getItem(StorageKeys.STATE)}`;
  searchBox = new FormControl();
  locations: any = [];
  searchResults: Observable<string[]> | any;
  selectedLocation: any;
  myControl = new FormControl();
  filteredOptions: Observable<string[]> | any;
  allTypes: any = [];

  globalRegenrate() {
    this.globalRegenerateComponent = true;
  }

  //location change
  search() {
    this._mapservice.searchAddress(this.searchLocation).subscribe(
      (res: any) => {
        let locations: any = [];
        res.features.forEach((location: any) => {
          locations.push({
            displayName:
              location?.place_name.split(', ')[0] +
              ',' +
              location?.context[0]?.text +
              ',' +
              location?.context[2]?.text,
            latlng: location?.center,
          });
        });
        this.locations = locations;
        this.searchResults = this.searchBox.valueChanges.pipe(
          startWith(''),
          map((value) => this._searchFilter(value))
        );
      },
    );
  }
  private _searchFilter(value: any) {
    const filterValue = value.toLowerCase();

    return this.locations.filter((option: any) =>
      option.displayName.toLowerCase().includes(filterValue)
    );
  }

  selectedLocations(option: any) {
    this.selectedLocation = option;
    let splitedLocation = this.selectedLocation.displayName.split(',');
    localStorage.setItem(StorageKeys.LOCALITY, splitedLocation[0])
    localStorage.setItem(StorageKeys.STATE, splitedLocation[1]);
  }

  //industry type change

  getAllTypes() {
    this._templateService.mergedIndustrySubIndustry().subscribe(
      (res: any) => {
        // //console.log(res.data);
        this.filteredOptions = this.myControl;
        // this.allTypes = res.data;
        res.data.forEach((data: any) => {
          this.allTypes.push({
            name: data.parent.name + ' - ' + data.name,
            industryId: data.parent.id,
            subIndustryId: data.id,
            industryName: data.parent.name,
            subIndustryName: data.name,
          });
        });
        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(''),
          map((value) => this._filter(value))
        );
      },
      (err) => {
        // ////console.log(err)
        this._templateService.openSnack(`${err.error.message}`, 'Close');
      }
    );
  }
  private _filter(value: any) {
    const filterValue = value.toLowerCase();

    return this.allTypes.filter((option: any) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  selectBusinessType(ev: Industry) {
    this.selectedIndustry = ev;
    localStorage.setItem(StorageKeys.INDUSTRY_DETAILS, JSON.stringify(ev));
  }

  //businessName change
  bName: any = localStorage.getItem(StorageKeys.BUSINESS_NAME);

  cancelRegenerate() {
    this.globalRegenerateComponent = false;
  }

  doneGlobalRegenerate() {
    if (this.subscriptions) {
      this.subscriptions.forEach((item, j) => {
        item.unsubscribe();
      });
    }
    {
      this.screenLoader = true;
      localStorage.setItem(StorageKeys.BUSINESS_NAME, this.bName);
      localStorage.removeItem('templateId');
      this.globalRegenerateComponent = false;
      this.websiteStructure.clear();
      this.generateWebsite();
    }
    this.regenerateButtonCountClick = this.regenerateButtonCountClick + 1
    if(this.regenerateButtonCountClick > 5){
      this.openingSignupPopup();
      this.screenLoader = false;
      this.globalRegenerateComponent = true;
    }

  }

  shareWebsite() {
    if (this.websiteId) {
      this._templateService.openDialog(ShareWebsiteComponent, 'auto', '490px', {
        webId:
          window.location.href +
          `?tempId=${localStorage.getItem(StorageKeys.WEB_CODE)}`,
      });
    } else {
      this._templateService.openSnack('Website Not Yet Generated', 'Ok');
    }
  }


  //global restyle functionlities starts

  positionRestyleTop: any = '';
  positionRestyleRight: any = '';
  restyleComponent: boolean = false;
  bStyle: any;
  buttonStyles = buttonStyle;
  bShape: any;
  buttonShape = buttonShape;
  btnColor: any;
  btnTextColor: any;
  openRestyleComponent() {
    this.restyleComponent = true;
    let a = document.getElementById('restyle_component');
    let position = a?.getBoundingClientRect();
    this.positionRestyleRight = position?.top;
    this.positionRestyleTop = position?.right;
  }
  cancelRestyleComponent() {
    this.restyleComponent = false;
  }

  saveGlobalRestyle() {
    let data = {
      homePageId: this.websiteData.id,
      btnShape: this.bShape,
      btnStyle: this.bStyle,
      btnColor: this.btnColor,
      btnTextColor: this.btnTextColor
    }
    this._templateService.reStylePages(data).subscribe(
      (res: any) => {
        // console.log(res.data);
        this.websiteStructure.clear();
        this.templatePages = res.data;
        this.pageSeoslected = this.templatePages[0].id;

        this.websiteData = res.data[0];

        //attaching current button styles
        this.buttonStyles.forEach((btnStyles) => {
          if (btnStyles.value === res.data[0].btnStyle) {
            this.bStyle = btnStyles.value
          }
        });

        //attaching current button shapes
        this.buttonShape.forEach((btnShapes) => {
          if (btnShapes.value === res.data[0].btnShape) {
            this.bShape = btnShapes.value;
          }
        });

        //attaching button color and button text color
        this.btnColor = res.data[0].btnColor;
        this.btnTextColor = res.data[0].btnTextColor;


        // this.dyanmicComponentPickup(this.websiteStructure,res.data,true);
        this.pickupService.dyanmicComponentPickup(this.websiteStructure, res.data[0].components, true)
        this.restyleComponent = false;
      },
      (err) => {
        console.log(err.error.message);
      }
    )
  }
  goBack() {
    if (!this.staffId) {
      this.router.navigate(['/business-type']);
    }else
    this.router.navigate(['/list-business'])
}

  login(){
    this.screenLoader = true;

    this.textsForScreenLoader = [
      'Website is being published, please wait',
      'Website is ready to publish'
    ];

    var data = {
      businessName: localStorage.getItem(StorageKeys.BUSINESS_NAME),
      address: {
        city: localStorage.getItem(StorageKeys.LOCALITY),
        geolocation: {
          latitude: localStorage.getItem(StorageKeys.LATITUDE),
          longitude: localStorage.getItem(StorageKeys.LONGITUDE)
        }
      },
      industryName: this.selectedIndustry.industryName,
      industryId: this.selectedIndustry.industryId,
      subIndustryId: this.selectedIndustry.subIndustryId,
      subIndustryName: this.selectedIndustry.subIndustryName,
      templateId: this.websiteId,
      countryId: window.localStorage.getItem('countryId'),
      countryCode: window.localStorage.getItem('countryCode'),
      googleToken: this.googleTokenId
    }

    this._authService.login(data).subscribe(
      (res: any) => {
        localStorage.removeItem(StorageKeys.GOOGLETOKENID);

        localStorage.setItem(StorageKeys.STAFF_ID, res.data.id);
        localStorage.setItem(StorageKeys.STAFF_NAME, res.data.name);
        this.screenLoader = false;
        this._dbService.setFname(res.data.name)
        localStorage.setItem(StorageKeys.BUSINESS_NAME, res.data.business[0].name);
        window.localStorage.setItem('business', JSON.stringify(res.data.business));
        if (res.data.business.length === 1) {
          this._dbService.setBusinessId(res.data.business[0].id);
          this.getBusinessDetailsById(res.data.business[0].id);
        } else {
          let a = res.data.business.find(e => e.favourite);
          if (a) {
            this.getBusinessDetailsById(a.id);
            this._dbService.setBusinessId(a.id);
          } else {
            this.router.navigate(['/list-business']);
          }
        }
      },
      (err) => {
        this.screenLoader = false;
      }
    );
  }

  //create new business
  createBusiness(type: "NOT_PUBLISHED" | "PUBLISHED", action?:any) {
    this.screenLoader = true;
    if (type == "PUBLISHED") {
    this.textsForScreenLoader = [
      'Website is being published, please wait',
      'Website is ready to publish'
    ];
  } else {
    this.textsForScreenLoader = [
      'Website is being saved, please wait',
      'Website is ready you can edit in website section'
    ];
  }

  window.localStorage.setItem(StorageKeys.WEBSITE_STATUS,type)
    let data = {
      businessName: localStorage.getItem(StorageKeys.BUSINESS_NAME),
      address: {
        city: localStorage.getItem(StorageKeys.LOCALITY) ?? "Hyderabad",
        geolocation: {
          latitude: localStorage.getItem(StorageKeys.LATITUDE) ?? "17.360589",
          longitude: localStorage.getItem(StorageKeys.LONGITUDE) ?? "78.474061"
        },
        state:localStorage.getItem(StorageKeys.STATE) ?? "Telangana",
        country:window.localStorage.getItem('count') ?? 'INDIA',
        pincode : window.localStorage.getItem('postalCode') ?? "500032",
      },
      industryName: this.selectedIndustry?.industryName ?? '',
      industryId: this.selectedIndustry?.industryId ?? '',
      subIndustryId: this.selectedIndustry?.subIndustryId ?? '',
      subIndustryName: this.selectedIndustry?.subIndustryName ?? '',
      templateId: this.websiteId,
      countryId: window.localStorage.getItem('countryId'),
      countryCode: window.localStorage.getItem('countryCode'),
      staffId: this.staffId,
      websiteStatus: type
    };

    this._authService.createAccount(data).subscribe(
      (res: any) => {
        localStorage.removeItem('countryCode');
        localStorage.removeItem('countryId');
        localStorage.removeItem(StorageKeys.LOCALITY);
        localStorage.removeItem(StorageKeys.STATE);
        localStorage.removeItem(StorageKeys.BUSINESS_NAME);
        localStorage.removeItem(StorageKeys.BUSINESS_NAME);

        localStorage.setItem('business', JSON.stringify(res.data.business));

        this.getBusinessDetailsById(res.data.business[res.data.business.length - 1].id,action);

        this.screenLoader = false;
        this.socialLogin.logout();
      },
      (err) => {
        this.textsForScreenLoader = [];
        this.screenLoader = false;
        this._snackBar.open(
          `${err.error.message}`,
          `Close`,
          {
            duration: 1500
          }
        )
      }
    )
  }

  goToHome() {
    const favouriteBusiness = this.business.filter((d: any) => d.favourite);

    if (favouriteBusiness.length) {
      this.getBusinessDetailsById(favouriteBusiness[0].id);
    } else {
      this.getBusinessDetailsById(this.business[0].id);
    }
  }

  getBusinessDetailsById(bId: any,action?:any) {
    this._authService.getDetailsByBusinessId(bId).subscribe(
      (res: any) => {
        window.localStorage.setItem('bDetails',JSON.stringify(res.data.business));
        localStorage.setItem(StorageKeys.BUSINESS_NAME, res.data.business.name);
        this._dbService.setBusinessId(res.data.business.id);
        window.localStorage.setItem('type','admin');
        if(action){
          this.router.navigate(['/v2-editor'],{queryParams : {action : action}});
        } else {
          this.router.navigate(['/admin/home']);
        }
        // if (goToEdit)

        // else
          // this.router.navigate(['/website/preview'])
        this.getCurrencySymbol(res.data.business.id)
      },
      (err) => {
      }
    )
  }

  getCurrencySymbol(bId:any){
    this._businessService.getCurrencySymbol(bId).subscribe(
      (res:any) => {
        console.log(res.data);
        this._dbService.setBusinessPrefernce(JSON.stringify(res.data));
      },
      (err) => {
        console.log(err)
      }
    )
  }

  shareWebsiteMobile(){
    this._websiteService.openBottomSheet(ShareWebsiteComponent,  {
      webId:
        window.location.href +
        `?tempId=${localStorage.getItem(StorageKeys.WEB_CODE)}`,
    })
  }

  deletePage(){
    document.getElementById('closeaddPageBottomSheet')?.click()
    if(this.staffId){
      this.createBusiness('NOT_PUBLISHED');
    } else {
      this.openingSignupPopup();
    }
  }

  openEditor(){
    document.getElementById('closeaddPageBottomSheet')?.click()
    if(this.staffId){
      this.createBusiness('NOT_PUBLISHED');
    } else {
      this.openingSignupPopup();
    }
  }

  changeSiteStyle(){
    if(this.staffId || this.googleTokenId){
      this.createBusiness('NOT_PUBLISHED','openSiteStyle')
    } else {
      this.openingSignupPopup();
    }
  }

  redirectToPage(page:any,index:number){
    if(this.staffId || this.googleTokenId){
      this.currentPageIndex = index;
      if(!(page.components[0].category.name === 'Top Nav Bar')){
        page.components.splice(0,1,this.templatePages[0].components[0]);
      }
      if(!(page.components[page.components.length - 1].category.name === 'Footer')){
        page.components.push(this.templatePages[0].components[this.templatePages[0].components.length - 1]);
      }

      const instance = this.pickupService.dyanmicComponentPickup(this.websiteStructure,page.components,true);
      this.initiateSubscription(instance);

    } else{
      this.openingSignupPopup();
    }
  }
}
