import { Component, Input, OnInit } from '@angular/core';
import { ElementHelper } from '../../../../elements/elements_helper';

@Component({
  selector: 'app-contact-us-location',
  templateUrl: './contact-us-location.component.html',
  styleUrls: ['./contact-us-location.component.scss']
})
export class ContactUsLocationComponent implements OnInit{
  @Input() responseData : any;
  constructor(
    private _elementHelper : ElementHelper
  ){}
  webComponentName : any  = 'contact_us_location';
  ngOnInit(): void {
    this.data = this.responseData.styles;
    this.data = this.responseData.styles;
  }
  data : any = {
    "image":{
      "attachment":"https://images.unsplash.com/photo-1572756317709-fe9c15ced298?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1167&q=80",
      "altName":"banner image",
      "lm_type":"attachment",
      "lm_title":"Banner Image",
      "attachment_type":"IMAGE"
    }
  }
  itemData : any = 
  [
    {
      "image":{
        "attachment":"https://cdn4.vectorstock.com/i/1000x1000/23/78/mouse-cursor-arrow-line-style-vector-29932378.jpg",
        "altName":"banner image",
        "lm_type":"attachment",
        "lm_title":"Banner Image",
        "attachment_type":"IMAGE"
       },
       "description":this._elementHelper.getTextDescElement('455 West Orchard Street Kings Mountain, NC 28086','white',''),
    },
    {
      "image":{
        "attachment":"https://png.pngtree.com/png-clipart/20191120/original/pngtree-phone-icon-png-image_5065646.jpg",
        "altName":"banner image",
        "lm_type":"attachment",
        "lm_title":"Banner Image",
        "attachment_type":"IMAGE"
       },
       "description":this._elementHelper.getTextDescElement('(272) 211-7370','white',''),
    },
    {
      "image":{
        "attachment":"https://w7.pngwing.com/pngs/226/248/png-transparent-computer-icons-envelope-mail-envelope-miscellaneous-angle-triangle.png",
        "altName":"banner image",
        "lm_type":"attachment",
        "lm_title":"Banner Image",
        "attachment_type":"IMAGE"
       },
       "description":this._elementHelper.getTextDescElement('support@yourbrand.com','white',''),
    },
  ]
}
