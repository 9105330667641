import { Component, ElementRef, Inject, Input, OnChanges, Optional, SimpleChanges, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { StorageKeys } from 'src/app/shared-module-files/simpo.constant';

@Component({
  selector: 'app-global-invoice-download',
  templateUrl: './global-invoice-download.component.html',
  styleUrls: ['./global-invoice-download.component.scss']
})
export class GlobalInvoiceDownloadComponent implements OnChanges{

  constructor(
    @Optional() public dialogRef: MatDialogRef<GlobalInvoiceDownloadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ){
    
  }
  @Input('isDiliveryChalan') isDiliveryChalan: boolean = false
  @Input('isCustomer') isCustomer : boolean = false
  @Input('isTransport') isTransport :boolean = false
  @Input ('isSupplier') isSupplier :boolean = false
  businessDetails : any;
  businessName : any;
  ngOnInit(){
    this.businessDetails = window.localStorage.getItem('bDetails');
    if(this.businessDetails){
      this.businessDetails = JSON.parse(this.businessDetails);
    }
    console.log(this.businessDetails);
    this.businessName = localStorage.getItem(StorageKeys.BUSINESS_NAME);
   
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (this.canDownloadPDF)
      // this.downloadPdf();
  }

  timeline:any = []
 @Input() isprivew:boolean = false;
 @Input() canDownloadPDF: boolean = false;
  ngAfterViewInit(): void {
    if(this.isprivew){
      return
    }
    setTimeout(()=> {

      this.downloadPdf();

    }, 1000);
  }
  downloadPdf(){
    html2canvas(this.InvoiceDetails.nativeElement, {scale: 2}).then((canvas)=> {
      var imgData = canvas.toDataURL("image/jpeg");
      var imgWidth = 210;
      var pageHeight = 295;
      var position = 0;
      var imgHeight = (canvas.height * imgWidth ) / canvas.width;
      var heightLef = imgHeight;
      // var doc = new jsPDF("p", "mm", [pageWidth, pageHeight]);
      var doc = new jsPDF("p", "mm", "a4");
      var position = 0;
      doc.addImage(imgData, 'JPEG', 0, 5,imgWidth, imgHeight );
      heightLef -= pageHeight;
      while (heightLef >=0 ){
        position = heightLef - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'JPEG' , 0 , position, imgWidth , imgHeight);
        heightLef -= pageHeight
      }
      doc.save(`a.pdf`);
    })
  }
  @ViewChild("InvoiceDetails") InvoiceDetails!: ElementRef;
  // closeButton() {
  //   this.dialogRef.close();

  // }
  participantsDisplayedColumns: string[] =
  [
    'Product',
    'Rate',
    'Quantity',
    'Discount',
    'TaxRate',
    'TotalPrice',
    // 'DueDate',
    // 'Action',
  ];
  participantsDataSource: any;

  closeButton() {
    this.dialogRef.close();

  }
}

