import { Component, HostListener, Inject, Optional } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TemplateService } from 'src/app/services/template.service';

@Component({
  selector: 'app-create-folder',
  templateUrl: './create-folder.component.html',
  styleUrls: ['./create-folder.component.scss']
})
export class CreateFolderComponent {
  constructor(
    private dialogRef: MatDialogRef<CreateFolderComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private _bottomSheet: MatBottomSheetRef<CreateFolderComponent>,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData: any
  ) {
    this.getScreenSize();
   }

  scrWidth: any;
  folderName: any = ''
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.scrWidth = window.innerWidth;
  }

  folderData: any;
  ngOnInit() {
    if(this.data?.folder) {
      this.folderData = this.scrWidth > 475 ? this.data.folder : this.bottomSheetData.folder
    } else {
      let bussinessDetail = JSON.parse(String(localStorage.getItem('bDetails')));
      this.folderData = {
        businessId: bussinessDetail.id,
        templateId: bussinessDetail.templateId,
        folderName: ''
      }
    }
    this.folderName = this.folderData.folderName;
  }

  closeWindow(){
    this.scrWidth > 475 ? this.dialogRef.close() : this._bottomSheet.dismiss();
  }

  createFolder(){
    this.scrWidth > 475 ? this.dialogRef.close(this.folderData) : this._bottomSheet.dismiss(this.folderData);
  }
}
