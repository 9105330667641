import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DbService } from 'src/app/services/db-service/db.service';
import { BusinessServiceService } from '../../business/services/business-service.service';
import { StorageKeys } from 'src/app/shared-module-files/simpo.constant';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CreateNewBusinessComponent } from '../../business/create-new-business/create-new-business.component';
import { SocialLoginService } from '../../website-marketing/services/social-login-service.service';

@Component({
  selector: 'app-additional-components',
  templateUrl: './additional-components.component.html',
  styleUrls: ['./additional-components.component.scss']
})

export class AdditionalComponentsComponent {

  constructor(private router: Router, private _dbStorage: DbService,
    private _businessService: BusinessServiceService,
    private _eventService: EventEmmiterService,
    private _snackBar: MatSnackBar,
    private socialMedia : SocialLoginService) {
    this.gettingAllbusiness();
    this.checkingSwitchBusiness();
    this.updateBusinessDetails();
  }

  bDetails: any;
  businessName: any;
  ngOnInit() {
    let bDetails = window.localStorage.getItem('bDetails') || ''
    if (bDetails) {
      this.bDetails = JSON.parse(bDetails)
      this.businessName = this.bDetails.name;
    }
    else {
      this.businessName = 'Simpo'
    }
  }

  appsData = [
    {
      heading: 'Invoice',
      apps: [
        {
          iconName: 'credit_card',
          name: 'Overview',
          desc: 'Create business invoice',
          redirectingUrl: '/admin/invoice/invoice-dashboard'
        },
        {
          iconName: 'shopping_cart',
          name: 'Invoices',
          desc: 'Create business invoice',
          redirectingUrl: '/admin/invoice/list-invoice'
        },
        {
          iconName: 'credit_card',
          name: 'Invoice Items',
          desc: 'Manage and track expenses',
          redirectingUrl: '/admin/invoice/list-invoice-items'
        }
      ]
    },
    {
      heading: 'Staff',
      apps: [
        {
          iconName: 'credit_card',
          name: 'Staff',
          desc: 'Create schedule and get insights',
          redirectingUrl: '/admin/staff/list-staff'
        }
      ]
    },
    {
      heading: 'Marketing',
      apps: [
        {
          iconName: 'credit_card',
          name: 'Overview',
          desc: 'Create schedule and get insights',
          redirectingUrl: '/admin/marketing/list-overview'
        },
        {
          iconName: 'shopping_cart',
          name: 'Post',
          desc: 'Manage google my business',
          redirectingUrl: '/admin/marketing/list-posts'
        }
        // {
        //   iconName : 'shopping_cart',
        //   name : 'Reports',
        //   desc : 'Manage google my business',
        //   redirectingUrl : '/admin/website/reports'
        // }
      ]
    },
    {
      heading: 'Products',
      apps: [
        {
          iconName: 'credit_card',
          name: 'Products',
          desc: 'Create schedule and get insights',
          redirectingUrl: 'admin/products/list-products'
        }
      ]
    },
    {
      heading: 'Customers',
      apps: [
        {
          iconName: 'customers',
          name  : 'Customers',
          desc : 'Check your Customers',
          redirectingUrl : '/admin/ecommerce/list-customer',
        }
      ]
    },
    {
      heading: 'Orders',
      apps: [
        {
          iconName: 'orders',
          name  : 'Orders',
          desc : 'Check your orders',
          redirectingUrl : '/admin/ecommerce/list-orders',
        }
      ]
    },
    {
      heading: 'Apps',
      apps: [
        {
          iconName: 'apps',
          name  : 'Apps',
          desc : 'Check your list of apps',
          redirectingUrl : '/admin/app-registry/list-apps',
        }
      ]
    },


  ]

  apps = [
    {
      name : 'Invoice',
      desc : 'Create Business Invoice',
      icon : 'shopping_cart',
      image: 'https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/441381c1721907983711Group_1707481819-1-removebg-preview.png'
    },
    {
      name : 'Marketing',
      desc : 'Create New Promotion',
      icon : 'shopping_cart',
      image: 'https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/553239c1721908278247file.png'
    },
    {
      name : 'Staff',
      desc : 'Create New Staff',
      icon : 'shopping_cart',
      image: 'https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/592883c1721908752658Group_1707481817-1-removebg-preview.png'
    },
    {
      name: 'Products',
      desc : 'Create New Product',
      icon : 'shopping_cart',
      image: 'https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/576808c1721908693129819231c1721908574588Group_1707481818-1-removebg-preview.png'
    },
    {
      name : 'Orders',
      desc : 'Check your orders here',
      icon : 'orders',
      image: 'https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/576808c1721908693129819231c1721908574588Group_1707481818-1-removebg-preview.png'
    },
    {
      name: 'Customers',
      desc : 'See your customers',
      icon : 'customers',
      image: 'https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/576808c1721908693129819231c1721908574588Group_1707481818-1-removebg-preview.png'

    },
    {
      name: 'Apps',
      desc : 'Check your list of apps',
      icon : 'apps',
      image: 'https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/576808c1721908693129819231c1721908574588Group_1707481818-1-removebg-preview.png'

    }
  ]

  switchToApp(name: String){
    this._eventService.tabChange.emit({selectedPanel : name})
  }

  allBusiness: any = [];
  gettingAllbusiness() {
    this.allBusiness = window.localStorage.getItem('business');
    this.allBusiness = JSON.parse(this.allBusiness);
    this.allBusiness.forEach(element => {
      if (element.id === this._dbStorage.getBusinessId()) {
        element.status = true;
      }
      else {
        element.status = false;
      }
    });
  }

  updateBusinessDetails() {
    this._eventService.updateBusinessName.subscribe(
      (res: any) => {
        if (res.update) {
          let bDetails = window.localStorage.getItem('bDetails') || ''
          if (bDetails) {
            this.bDetails = JSON.parse(bDetails)
            this.businessName = this.bDetails.name;
          }
          else {
            this.businessName = 'Simpo'
          }
        }
      }
    )
  }

  checkingSwitchBusiness() {
    this._eventService.switchBusiness.subscribe(
      (res: any) => {
        if (res.switchBusiness) {
          this.gettingAllbusiness();
          let bDetails = window.localStorage.getItem('bDetails') || ''
          if (bDetails) {
            this.bDetails = JSON.parse(bDetails)
            this.businessName = this.bDetails.name;
          }
          else {
            this.businessName = 'Simpo'
          }
        }
      }
    )
  }

  switchBusiness(item: any) {
    this.allBusiness.forEach(element => {
      if (element.id === item.id) {
        element.status === true;
        console.log(element)
        this._businessService.getDetailsByBusinessId(item.id).subscribe(
          (res: any) => {
            window.localStorage.setItem('bDetails', JSON.stringify(res.data.business));
            localStorage.setItem(StorageKeys.BUSINESS_NAME, res.data.business.name);
            window.localStorage.setItem('type', 'admin');
            this._dbStorage.setBusinessId(res.data.business.id);
            this.getCurrencySymbol(res.data.business.id);
            document.getElementById('closeBottomSheet')?.click();
            window.localStorage.setItem('status',res.data.website.status);
            this._eventService.switchBusiness.emit({ switchBusiness: true })
            this.socialMedia.logout();
          },
          (err) => {
            //console.log(err.error.message);
            // this.loader = false;
            this._snackBar.open(
              `${err.error.message}`,
              'Close',
              {
                duration: 1500
              }
            )
          }
        )
      }
      else {
        element.status = false;
      }
    });
  }

  getCurrencySymbol(bId: any) {
    this._businessService.getCurrencySymbol(bId).subscribe(
      {
        next: (value: any) => {
          console.log(value.data);

          this._dbStorage.setBusinessPrefernce(JSON.stringify(value.data));
        },
        error: (err: any) => {
        },
      }
    )
  }

  goToListing() {
    this.router.navigate(['/list-business'])
  }

  createNewBusiness() {
    document.getElementById('closeBottomSheet')?.click();
    this._businessService.openDialog(
      CreateNewBusinessComponent,
      'auto',
      '100%',
      { data: null }
    )
  }


  redirectTo(url: String) {
    this.router.navigate([`${url}`])
  }

  businessDetails() {
    this.router.navigate([`/admin/account/business-details`]);
  }
}
