import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SeoImageUploadService {
  constructor() { }
  imageObject: unknown;

  async uploadSEOimages(element: any, folder = 'seo') {
    const AWSService = AWS;
    const imageEnvCognito =
      environment.componentImageUploading.CredentialsProvider.CognitoIdentity.Default;
    const imageEnvUtility = environment.componentImageUploading.S3TransferUtility.Default;
    const region = imageEnvUtility.Region;
    const bucketName = imageEnvUtility.Bucket;
    const IdentityPoolId = imageEnvCognito.PoolId;
    // ? Configures the AWS service and initial authorization
    AWSService.config.update({
      region: region,
      credentials: new AWSService.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId,
      }),
    });
    // ? adds the S3 service, make sure the api version and bucket are correct
    const s3 = new AWSService.S3({
      apiVersion: '2012-07-10',
      params: {
        Bucket: bucketName,
      },
    });

    // const element = e.srcElement.files[0];

    let imgPromise = new Promise((res, rej) => {
      if (element) {
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          s3.upload(
            {
              Key:
                `${folder}/` +
                Math.floor(100000 + Math.random() * 900000) +
                'c' +
                new Date().getTime() +
                element.name,
              Bucket: bucketName,
              Body: element,
              ACL: 'public-read',
            },
            function (err: any, data: any) {
              if (err) {
                rej(err);
              } else {
                res(data);
              }
            }
          );
        };
      }
    });

    return imgPromise
      .then((data) => {
        this.imageObject = data;
        // //console.log('awsResult', data);
        return data;
      })
      .catch((err) => {
        //console.log(err);
        return err;
      });
  }

  async deleteImages(imageList: any[], folder = 'seo') {
    const AWSService = AWS;
    const imageEnvCognito =
      environment.componentImageUploading.CredentialsProvider.CognitoIdentity.Default;
    const imageEnvUtility = environment.componentImageUploading.S3TransferUtility.Default;
    const region = imageEnvUtility.Region;
    const bucketName = imageEnvUtility.Bucket;
    const IdentityPoolId = imageEnvCognito.PoolId;
    // ? Configures the AWS service and initial authorization
    AWSService.config.update({
      region: region,
      credentials: new AWSService.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId,
      }),
    });
    // ? adds the S3 service, make sure the api version and bucket are correct
    let imgPromise = new Promise((res, rej) => {
      imageList.forEach((img) => {
        let imgname = img.split('/');
        const s3 = new AWSService.S3({
          apiVersion: '2020-01-18',
          params: { Bucket: bucketName },
        });
        let params = {
          Bucket: bucketName,
          Key: `${folder}/${imgname[imgname.length - 1]}`,
        };
        s3.deleteObject(params, (err, result) => {
          if (err) {
            rej('Unable to delete!');
            //  this.ErrorPopsService.open('Image Not Deleted', 'Close', {
            //    duration: 5000,
            //  });
          } else {
            res('Removed Selected Image!');
            //  this.ErrorPopsService.open(`Removed Selected Image!`, 'Close', {
            //    duration: 5000,
            //  });
          }
        });
      });
    });

    return imgPromise
      .then((data: any) => {
        return { data: data, res: true };
      })
      .catch((err) => {
        return { data: err, res: false };
      });
  }
}
