<div class="number_input" *ngIf="data.details.inputType === 'number'">
    <div class="label" [ngStyle]="data.title ? data.title : {}">
        {{data.title.value}} <sup *ngIf="data.details.mandatory">*</sup>
    </div>
    <mat-form-field class="sfull_width input_card" appearance="outline">
        <input matInput type="number" [placeholder]="data.details.hint"/>
    </mat-form-field>
</div>
<div class="email_input" *ngIf="data.details.inputType === 'email'">
    <div class="label" [ngStyle]="data.title ? data.title : {}">
        {{data.title.value}} <sup *ngIf="data.details.mandatory">*</sup>
    </div>
    <mat-form-field class="sfull_width input_card" appearance="outline">
        <input matInput [placeholder]="data.details.hint"/>
    </mat-form-field>
</div>
<data class="text_input" *ngIf="data.details.inputType === 'text'">
    <div class="label" [ngStyle]="data.title ? data.title : {}">
        {{data.title.value}} <sup *ngIf="data.details.mandatory">*</sup>
    </div>
    <mat-form-field class="sfull_width input_card" appearance="outline">
        <input matInput [placeholder]="data.details.hint"/>
    </mat-form-field>
</data>