<nav class="navbar navbar-expand-lg bg-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="">
        <img src="./../../../assets/images/common/home_page_logo.svg" alt="logo">
        </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <!-- <span class="navbar-toggler-icon"></span> -->
        <!-- <mat-icon>menu</mat-icon> -->
        <img src="../../../assets/images/common/menu.svg" alt="" class="menu-img">
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <!-- <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#">Home</a>
          </li> -->
          <!-- <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Dropdown
            </a>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="#">Action</a></li>
              <li><a class="dropdown-item" href="#">Another action</a></li>
              <li><hr class="dropdown-divider"></li>
              <li><a class="dropdown-item" href="#">Something else here</a></li>
            </ul>
          </li> -->
          <!-- <li class="nav-item">
            <a class="nav-link disabled">Disabled</a>
          </li> -->
        </ul>
        <!-- <form class="d-flex" role="search">
          <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
          <button class="btn btn-outline-success" type="submit">Search</button>
        </form> -->
        <div class="right_container">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <a class="nav-link pricing" (click)="redirectPricing()">Pricing</a>
              </li>
              <li class="nav-item">
                <a class="nav-link pricing" (click)="redirectContactUs()">Contact Us</a>
              </li>
              <li class="nav-item" *ngIf="srcWidth > 475">
                <div class="vr_line"></div>
              </li>
              <li class="nav-item">
                <a class="nav-link signin" (click)="loginPage()">Sign in</a>
              </li>
                <li class="nav-item">
                    <button class="signup_btn" (click)="redirectToBType()">
                        Generate Website
                    </button>
                </li>
                <!-- <li class="nav-item">
                    <button class="signup_btn" (click)="signUp()">
                        Sign up
                    </button>
                </li> -->
            </ul>
        </div>
      </div>
    </div>
  </nav>