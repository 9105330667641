<section class="main-feature-section">
    <div class="main_section">
    <div class="main-header-section">
        <div class="sub-toggle-heading">
            Meet Simpo.AI
        </div>
        <div class="main-feature-heading">
            Powerful simplest AI tool to build your business
        </div>
    </div>
    <div class="main-middle-section">
        <div class="main-features-section">
            <div class="left-side-feature">
                <div class="left-main-heading">
                    Three Clicks to Take Your Business Digital
                </div>
                <div class="left-sub-heading">
                    Generate a professional business website in seconds with content, images and lead form to capture business leads 
                </div>
                <div class="left-side-image">
                    <img src="./../../../../assets/images/features/Business1_image.svg" alt="first_image" />
                </div>
            </div>
            <div class="right-side-feature">
                <div class="right-main-heading">
                    Drive more Traffic and Visibility with SEO- Friendly website
                </div>
                <div class="right-sub-heading">
                    A High-Performance, SEO-Optimized Websites with Lightning-Fast Load Time and High Visibility in Search Engine Results
                </div>
                <div class="right-side-image">
                    <img src="./../../../../assets/images/features/Business2_image.svg" alt="first_image" />
                </div>
            </div>
        </div>

        <div class="main-features-section2">
            <div class="left-side-feature-1">
                <div class="left-main-heading">
                    Easily Customize your Site to Match your Brand Identity
                </div>
                <div class="left-sub-heading">
                    Effortlessly Customize Your Website's Look and Feel to Match Your Brand Identity with Our Easy Editing Tools. No Coding Required.
                </div>
                <div class="left-side-image">
                    <img src="./../../../../assets/images/features/second_business_2.svg" alt="first_image" />
                </div>
            </div>
            <div class="right-side-feature-1">
                <div>
                    <div class="right-main-heading">
                        Increase Your Sales with Our User-Friendly Lead management
                    </div>
                    <div class="right-sub-heading">
                        Accelerate your business growth by converting your business leads to sales with simple lead management tool 
                    </div>
                </div>
                <div class="right-side-image">
                    <img src="./../../../../assets/images/features/second_business_1.svg" alt="first_image" />
                </div>
            </div>
        </div>

        <div class="main-features-section">
            <div class="left-side-feature-2">
                <div class="left-main-heading">
                    Keep Engaging with Your Customers through Expertly Crafted Blog Posts
                </div>
                <div class="left-sub-heading">
                    With our easy-to-use blog creation tool, you can keep your customers engaged and drive more business to your website.
                </div>
                <div class="left-side-image">
                    <img src="./../../../../assets/images/features/third_business_1.svg" alt="first_image" />
                </div>
            </div>
            <div class="right-side-feature-2">
                <div class="right-main-heading">
                    Gain Valuable Insights into Customers Behaviour 
                </div>
                <div class="right-sub-heading">
                    Drive more business by tracking and analysing website traffic, user engagement with built -in web analytic
                </div>
                <div class="right-side-image">
                    <img src="./../../../../assets/images/features/third_business_2.svg" alt="first_image" />
                </div>
            </div>
        </div>
    </div>
    </div>
</section>
