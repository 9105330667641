import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { take } from 'rxjs';
import { ImageCompressionService } from './../../services/image-compression.service';
import { ComponentImageUploadService } from './../../services/component-image-upload.service'
import { TemplateService } from './../../services/template.service';

@Component({
  selector: 'app-template-images-upload',
  templateUrl: './template-images-upload.component.html',
  styleUrls: ['./template-images-upload.component.scss']
})
export class TemplateImagesUploadComponent implements OnInit{

  constructor(
    private ds : DomSanitizer,
    private templateService : TemplateService,
    private _compressingImage : ImageCompressionService,
    private _imageUpload : ComponentImageUploadService
  ){}

  imgUrl : any = '';
  pageLoader : boolean = false;

  ngOnInit(): void {
  }

  
  browseImage(){
    document.getElementById('browse_fc_image')?.click();
  }
  facebookImage :any ;
  async updateImage(ev:any){
    this.pageLoader = true;
    let img: File = ev.srcElement.files[0];
    const file = ev.srcElement.files[0];
    this.imgUrl = await this._imageUpload.uploadComponentsImages(file)
    this.imgUrl = this.imgUrl.Location;
    this.pageLoader = false;
  }

  clipBoardCopy(){
    let val=this.imgUrl;
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.templateService.openSnack("link copied successfully","Close");
  }
}
