import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TemplateService } from 'src/app/services/template.service';

@Component({
  selector: 'app-delete-pop',
  templateUrl: './delete-pop.component.html',
  styleUrls: ['./delete-pop.component.scss']
})
export class DeletePopComponent {

constructor(
  public dialogRef: MatDialogRef<DeletePopComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any,
  private templateService : TemplateService,
  private _snackBar : MatSnackBar

){
 console.log(this.data)
}




  discard(){
    this.dialogRef.close({data:'null'});
  }
  apply(){
    this.loader = true;
    this.templateService.deletePage(this.data.data).subscribe(
      (res:any) => {
        // console.log(res.data);
        this._snackBar.open(
          'Page deleted Succesfully',
          'Ok',
          {
            duration:1500
          }
        );
        this.dialogRef.close({data:'APPLY_CHANGES'})
      },
      (err) => {
        this.loader = false;
        this.dialogRef.close({data:'APPLY_CHANGES'})
      });
  }
  loader : boolean = false;
}
