<!-- <section class="main_section">
    <section class="top_navbar">
        <app-home-nav-bar></app-home-nav-bar>
    </section>
    <section class="body_section">
        <div class="field">
            <mat-form-field appearance="outline" class="sfull_width">
                <mat-select [(ngModel)]="selectingValue">
                    <mat-option value="">--Select--</mat-option>
                    <mat-option *ngFor="let item of testingArray" [value]="item.value">{{item.name | titlecase}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="field">
            <mat-form-field class="sfull_width" appearance="outline">
                <input placeholder="Enter name" matInput/>
            </mat-form-field>
        </div>
        <div class="submit_btn" (click)="submit()">
            <button>Submit</button>
        </div>
    </section>
</section> -->
  <!-- <section> -->
    <!-- <app-herobanner-stat-image [templateData]="data"></app-herobanner-stat-image> -->
    <!-- <app-process-spark-image-side></app-process-spark-image-side> -->
    <!-- <app-aboutus-with-background-image></app-aboutus-with-background-image> -->
    <!-- <app-preview-aboutus></app-preview-aboutus> -->
    <!-- <preview-app-aboutus-with-background-image></preview-app-aboutus-with-background-image> -->
    <!-- <app-map-left-address></app-map-left-address> -->
    <!-- <app-map-with-right-address></app-map-with-right-address> -->
    <!-- <app-preview-hover-horizontal-service-section></app-preview-hover-horizontal-service-section> -->
    <!-- <app-preview-servicesectioneditor></app-preview-servicesectioneditor> -->
    <!-- <app-preview-verticle-service-section></app-preview-verticle-service-section> -->
    <!-- <app-preview-aboutus></app-preview-aboutus>
    <preview-app-aboutus-with-background-image></preview-app-aboutus-with-background-image>
</section> -->

<!-- <section class="preview">
    <section *ngIf="data.category.name === 'Hero Banner'">
        <app-preview-herobanner-stat-image [templateData]="data"></app-preview-herobanner-stat-image>

 <section class="preview">
    <section *ngIf="data.category.name === 'Testimonial'">
        <app-preview-single-horizontal-testimonial [templateData]="data"></app-preview-single-horizontal-testimonial>
    </section>
</section> -->
<!-- <app-our-success-story></app-our-success-story> -->

 <!-- <section class="preview">
    <section>
       <app-preview-blog-cards-vertical-align [blogData]="blogData"></app-preview-blog-cards-vertical-align>
     </section> 
</section>   -->
<!-- <app-process-count-image></app-process-count-image>
<app-process-count-image-side></app-process-count-image-side> -->
<!-- <app-slider-faq-components></app-slider-faq-components> -->
<!-- <app-stats-text-partition></app-stats-text-partition> -->
<!-- <app-stats-with-text></app-stats-with-text>
<app-stats-with-side-image></app-stats-with-side-image>
<app-stats-text-wrap></app-stats-text-wrap>
<app-stats-text-side-partition></app-stats-text-side-partition> -->
<!-- <app-blog-cards-horizontal-align [blogData]="blogData"></app-blog-cards-horizontal-align> -->
<!-- <app-blog-cards-horizontal-align [blogData]="blogData"></app-blog-cards-horizontal-align> -->



<!-- strip checking purpose -->



<!-- <section>
    <div class="product">
    <img src="https://i.imgur.com/EHyR2nP.png" alt="The cover of Stubborn Attachments" />
    <div class="description">
    <h3>Stubborn Attachments</h3>
    <h5>$20.00</h5>
    </div>
    </div>
    <form action="/create-checkout-session" method="POST">
    <button type="submit" id="checkout-button" (click)="stripeChecking()">Checkout</button>
    </form>
</section> -->

<section *ngIf="!loader">
    <!-- <app-contact-us1></app-contact-us1> -->
    <!-- <app-v2-herobanner-feature-rightside-image></app-v2-herobanner-feature-rightside-image>
    
    <app-v2-herobanner-stat-image></app-v2-herobanner-stat-image>

    <app-v2-simple-hero-banner></app-v2-simple-hero-banner>

    <app-v2-herobanner-rating-review-bgimg-image></app-v2-herobanner-rating-review-bgimg-image>

    <app-v2-simple-herobanner-button-image></app-v2-simple-herobanner-button-image>

    <app-v2-herobanner-stat-image-reverse></app-v2-herobanner-stat-image-reverse>

    <app-v2-simple-herobaner-rightside-background></app-v2-simple-herobaner-rightside-background>

    <app-v2-herobanner-with-review></app-v2-herobanner-with-review>

    <app-v2-aboutus></app-v2-aboutus>

    <app-v2-aboutus-with-background-image></app-v2-aboutus-with-background-image>

    <app-v2-servicesectioneditor></app-v2-servicesectioneditor>

    <app-v2-verticle-service-section></app-v2-verticle-service-section>

    <app-v2-hover-horizontal-service-section></app-v2-hover-horizontal-service-section>

    <app-v2-process-spark-image-side></app-v2-process-spark-image-side>

    <app-v2-process-count-image></app-v2-process-count-image>

    <app-v2-process-count-image-side></app-v2-process-count-image-side>

    <app-v2-process-number-text></app-v2-process-number-text>

    <app-v2-single-page-double-testimonial></app-v2-single-page-double-testimonial>

    <app-v2-sinle-verticle-testimonial></app-v2-sinle-verticle-testimonial>

    <app-v2-single-horizontal-testimonial></app-v2-single-horizontal-testimonial>

    <app-v2-horizontal-faq></app-v2-horizontal-faq>

    <app-v2-slider-faq-components></app-v2-slider-faq-components>

    <app-v2-opened-faq></app-v2-opened-faq> -->

    <!-- <app-v2-simple-navbar-pagelinks></app-v2-simple-navbar-pagelinks> -->

    <!-- <app-v2-contactus-text-inputs></app-v2-contactus-text-inputs> -->

    <!-- <app-v2-simple-contact-form></app-v2-simple-contact-form> -->

    <!-- <app-v2-simple-footer></app-v2-simple-footer> -->
    <!-- <app-terms-privacy-card></app-terms-privacy-card> -->
    <!-- <app-custom-text-on-image-element></app-custom-text-on-image-element> -->
    <!-- <app-v2-aboutus-with-background-image></app-v2-aboutus-with-background-image> -->
    <!-- <app-about-us-herobanner></app-about-us-herobanner> -->
        <!-- <app-aboutus-business-description></app-aboutus-business-description> -->
        <!-- <app-aboutus-specialization-ourmission></app-aboutus-specialization-ourmission> -->
        <!-- <app-contact-us-input-text></app-contact-us-input-text> -->
        <!-- <app-v2-contactus-text-inputs></app-v2-contactus-text-inputs> -->
        <!-- <app-contact-us-location></app-contact-us-location> -->
        <!-- <app-service-section-cards></app-service-section-cards> -->
        <!-- <app-service-section-contact></app-service-section-contact> -->
        <app-service-section-process></app-service-section-process>

 </section>
 <section *ngIf="loader"></section>
<!-- <section class="preview">
    <section>
        <app-preview-contactus-text-inputs></app-preview-contactus-text-inputs>
    </section>
</section>  -->