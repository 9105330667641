<section class="main-section">
    <div class="top-layer">
        <div class="top-left-layer">
            Plugin
        </div>
        <div class="top-right-layer" (click)="close()">
            <mat-icon>close</mat-icon>
        </div>
    </div>
    <div class="bottom-layer">
        <div class="instruction">
            Follow the instruction to plugin
        </div>
        <div class="step-1">
            1. Copy below URL and paste in between body tags of blogs page
        </div>
        <div class="url-purpose">
            <div class="index-url">
                {{indexHtmlUrl}}
            </div>
            <div class="copy-img" (click)="copyIndexUrl()">
                <mat-icon>file_copy</mat-icon>
            </div>
        </div>
        <div class="step-1">
            2. This URL for List Blogs(Copy below URL and paste in blogs page)
        </div>
        <div class="url-purpose">
            <div class="index-url">
                {{allBlog}}
            </div>
            <div class="copy-img" (click)="copyAllBlogsUrl()">
                <mat-icon>file_copy</mat-icon>
            </div>
        </div>
        <div class="step-1">
            3. This URL for 2 blogs display in home page(Copy below URL and paste home page file)
        </div>
        <div class="url-purpose">
            <div class="index-url">
                {{homePageDisplay}}
            </div>
            <div class="copy-img" (click)="copyLimitedBlogsUrl()">
                <mat-icon>file_copy</mat-icon>
            </div>
        </div>
    </div>
</section>