import { Component, Inject, HostListener, Input } from '@angular/core';
import { ButtonStyleEditorComponent } from '../button-style-editor/button-style-editor.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EventsService } from 'simpo-component-library';
import { TemplateService } from 'src/app/services/template.service';
import { MediaSelectorComponent } from '../../popups/media-selector/media-selector.component';
import { ImageUploadService } from 'src/app/services/ImageUploadService/image-upload.service';

@Component({
  selector: 'app-logo-editor',
  templateUrl: './logo-editor.component.html',
  styleUrls: ['./logo-editor.component.scss']
})
export class LogoEditorComponent {

  constructor(public dialogRef: MatDialogRef<ButtonStyleEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private eventService: EventsService,
    private templateService: TemplateService,
    private imageUploadService: ImageUploadService) { }

  logoData: any
  siteName: any;
  bgColor: any;
  isLoading: boolean = false;
  ngOnInit() {
    this.logoData = this.data.data;
    this.siteName = this.data.siteName;
    this.bgColor = this.data.bgColor;
    this.screenSize()
  }
  screenWidth: any
  @HostListener ('window:resize', ['$event'])
  screenSize(event? : any){
    this.screenWidth= window.innerWidth
  }

  selectedTab: 'Text + Icon' | 'Image' = "Text + Icon";
  showImageEditor: boolean = false;
  selectedOption: string = 'upload-file';

  max = 100;
  min = 0;
  showTicks = false;
  step = 1;

  changeTabs(selectedTab: 'Text + Icon' | 'Image') {
    this.selectedTab = selectedTab;

  }

  closeDialog() {
    this.dialogRef.close();
  }

  async updateIcon(event: any){
    this.isLoading = true;
    let file = event.target.files[0];
    const maximumFileSize = 4 * 1024 * 1024; // Max is 4mb
    if(maximumFileSize < file.size){
      return;
    }

    const response: any = await this.imageUploadService.uploadPageScreenShots(
      file,
      'library-media'
    );
    this.logoData.text.url = response.Location;
    this.isLoading = false;
  }

  async updateImage() {
    let width = this.screenWidth > 475 ? '50%' : 'auto' ;
    let dialog = this.templateService.openDialogWithData(
      MediaSelectorComponent,
      '90vh',
      width,
      { multiple: false }
    )
    dialog.afterClosed().subscribe(
      (res: any) => {
        this.logoData.image.url = res[0].assets[0].url;
      }
    )
    this.isLoading = false;
  }

  deleteImage(){
    this.logoData.image.url = ''
  }

  deleteIcon(){
     this.logoData.text.url = ''
  }
}
