<div class="total_container">
    <!-- <div class="test" [ngStyle]="{'background-color': responseData?.bgColor}">
        <div [ngStyle]="data ? data : {}" [innerHTML]="data.value">
    </div> -->
    <div class="main_container" [ngStyle]="{'background-color': data.bgColor}">
        <div class="title_line">
            <app-text-element [data]="data.styles.title"></app-text-element>
        </div>
        <div class="description_line">
            <app-text-element [data]="data.styles.description"></app-text-element>
        </div>
        <div class="carousel_section">
            <!-- <div class="card md:flex md:justify-content-center">
                <p-galleria [value]="data.itemStyles" [responsiveOptions]="responsiveOptions" [containerStyle]="{ 'max-width': '640px' }" [numVisible]="5">
                    <ng-template pTemplate="item" let-item>
                        <img [src]="item.image.attachement" style="width: 100%;" />
                    </ng-template>
                    <ng-template pTemplate="thumbnail" let-item>
                        <div class="grid grid-nogutter justify-content-center">
                            <img [src]="item.image.attachment" />
                        </div>
                    </ng-template>
                </p-galleria>
            </div> -->
            <!-- <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img src="..." class="d-block w-100" alt="...">
                  </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>
            </div> -->
            <!-- <p-carousel  [value]="data.itemStyles" [numVisible]="3" [numScroll]="1" [responsiveOptions]="responsiveOptions" [autoplayInterval]="3000">
                <ng-template let-product pTemplate="item">
                    <div class="border-1 surface-border border-round m-2 text-center py-5 px-3">
                        <div class="mb-3">
                            <img [src]="product.image.attachment" [alt]="product.image.altName" class="w-6 shadow-2" />
                        </div>
                    </div>
                </ng-template>
            </p-carousel> -->
            <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <ng-container *ngFor="let item of data.itemStyles">
                        <div class="carousel-item active">
                            <img [src]="item.image.attachment" class="d-block w-100" alt="...">
                          </div>
                    </ng-container>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    </div>
    <div class="show_btns" *ngIf="all">
        <div class="full_btns">
            <div class="full-edit-section" (click)="editSection()">
                <div class="edit image">
                    <img src="./../../../assets/images/editor/edit_blue.svg" alt="buleditIcon">
                </div>
                <div class="editsection-text">
                    Edit Section
                </div>
            </div>
            <div class="full-edit-section">
                <div class="edit image">
                    <img src="./../../../assets/images/editor/loaderRegenrate.svg"
                        alt="regenrate">
                </div>
                <div class="regenerate-text" (click)="addNewSection()">
                    Add Section
                </div>
            </div>
            <div class="full-edit-section">
                <div class="all_icons">
                    <img src="./../../../../../../../../assets/images/editor/upArrowEnable.svg" alt="uparrow" *ngIf="index != 1" (click)="positionMoveUp()" class="upward_arrow">
                    <div class="downward_arrow">
                        <img src="./../../../../../../../../assets/images/editor/down_arrow.svg" alt="downarrow" (click)="positionMoveDown()">
                    </div>
                    <div class="delete_icon">
                        <img src="./../../../../../../../../assets/images/editor/delete_icon.svg" alt="delete_icon" (click)="deleteComponent()">
                    </div>
                </div>
            </div>
        </div>
        <button class="add_btn" (click)="addSection()">
            + Add Section
        </button>
    </div>
</div>