import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-spark-image-card',
  templateUrl: './spark-image-card.component.html',
  styleUrls: ['./spark-image-card.component.scss']
})
export class SparkImageCardComponent implements OnInit{
  
  @Input() dynamicData : any;
  @Input() indexValue : any;

  even : boolean = false;

  constructor(){}

  ngOnInit(): void {
    if((this.indexValue%2 === 0)){
      this.even = true;
    }
    else{
      this.even = false;
    }
  }

}
