<section class="complete-section">
  <ng-container [ngSwitch]="screen">
    <ng-container *ngSwitchCase="'SELECT_FIELDS'">
      <div class="header">
        <div class="header-text">Manage fields</div>
        <p class="close-btn" (click)="closeModal()"><mat-icon>close</mat-icon> </p>
      </div>

      <hr class="line">

      <div>
        <p class="sub-text">Fields from your CRM contacts</p>

        <div>
          <div class="form-check-options" *ngFor="let field of fieldType;let i = index">
            <div class="form-check" [ngStyle]="{'background-color' : field.required ? 'rgb(238, 234, 234)' : ''}">
              <input class="form-check-input" type="checkbox" value="" [id]="i" [(ngModel)]="field.status" [disabled]="field.required" (ngModelChange)="changeTextField(field, 'CHECK')">
              <label class="form-check-label" [for]="i">
                {{field.label}}
              </label>
              <div class="ml-auto">
                <input class="form-check-input mr-1" type="checkbox" placeholder="Required" [(ngModel)]="field.required" (ngModelChange)="changeTextField(field, 'REQUIRED')">
                <span>Required</span>
              </div>
            </div>
          </div>
        </div>
        <hr class="line" style="margin-top: 15px;" />
        <div class="form-check-options" *ngFor="let field of customAddedFields;let i = index">
          <div class="form-check" [ngStyle]="{'background-color' : field.required ? 'rgb(238, 234, 234)' : ''}">
            <input class="form-check-input" type="checkbox" value="" [id]="i + '_custom'" [(ngModel)]="field.status" [disabled]="field.required" (ngModelChange)="changeTextField(field, 'CHECK')">
            <label class="form-check-label" [for]="i + '_custom'">
              <span>{{field.label}}</span>
              <br>
              <span>{{field.type}}</span>
            </label>
            <div class="ml-auto" style="display: flex; gap: 10px">
              <mat-icon (click)="editInput(field)">edit</mat-icon>
              <mat-icon (click)="deleteInput(field)">delete_outline</mat-icon>
            </div>
          </div>
        </div>
      </div>

      <span class="addMoreField" (click)="screen = 'ADD_FIELDS'"> + Add field</span>
    </ng-container>
    <ng-container *ngSwitchCase="'ADD_FIELDS'">
      <div class="header">
        <div class="header-text">Add a custom field</div>
        <p class="close-btn" (click)="closeModal()"><mat-icon>close</mat-icon> </p>
      </div>

      <hr class="line">
      <div class="addFieldContainer">
        <div class="headerInfo">
          <mat-icon>info</mat-icon>
          <span>Fields added here are also added to contacts in your CRM</span>
        </div>
        <hr class="line">
        <p class="sub-text" style="padding-left: 0px;">Select the type of input</p>
        <div class="available-fields">
          <ng-container *ngFor="let field of availableFields">
            <div class="field-container" (click)="addNewInput(field)">
              <div class="overlay"></div>
              <div class="field-icon">
                <span *ngIf="field.customIcon">{{ field.iconTxt }}</span>
                <mat-icon *ngIf="!field.customIcon">{{ field.icon }}</mat-icon>
              </div>
              <div class="field-info">
                <span>{{ field.text | titlecase }}</span>
                <br>
                <span>{{ field.description }}</span>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'EDIT_FIELD'">
      <div class="header">
        <div class="header-text">Edit the {{ getInputType | lowercase }} field</div>
        <p class="close-btn" (click)="closeModal()"><mat-icon>close</mat-icon> </p>
      </div>

      <hr class="line">
      <div class="field-edit-container">
        <div class="headerInfo">
          <mat-icon>info</mat-icon>
          <span>Changes made here will be reflected on contacts in your CRM</span>
        </div>
          
        <hr class="line">
        <div class="custom-inputs">
          <label class="input-label">Label</label>
          <input class="input-text" type="text" [(ngModel)]="newFieldLabel" />
        </div>
        <ng-container *ngIf="selectedInputType == 'checkbox' || selectedInputType == 'dropdown'">
          <span>Options</span>
          <div class="optionsList" cdkDropList (cdkDropListDropped)="drop($event)">
            <div class="custom-inputs" *ngFor="let _ of [].constructor(optionsCount); let idx = index">
              <div class="d-flex align-items-center">
                <mat-icon style="font-size: 23px; color: #555555; cursor: grab; margin-right: 10px;">drag_indicator</mat-icon> 
                <input class="input-text" type="text" [(ngModel)]="fieldOptions[idx].value" (ngModelChange)="fieldOptions[idx].label = fieldOptions[idx].value" />
                <mat-icon [ngClass]="{'disabled': optionsCount == 1}" style="margin-left: 15px;cursor: pointer;" (click)="removeOption(idx)">delete_outline</mat-icon>
              </div>
              <div class="error" *ngIf="isValueConflict(idx)">Values cannot be same</div>
            </div>
          </div>
          <span class="addMoreField" (click)="addMoreOption()" [ngClass]="{'disabled': !canAddMoreOption}"> + Add option</span>
        </ng-container>
        <div class="action-btn-container">
          <div class="cancel-btn" (click)="cancelEdit()">Cancel</div>
          <div class="save-btn" (click)="saveEdit()">Save</div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</section>
