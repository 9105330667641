import { Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-text-element',
  templateUrl: './text-element.component.html',
  styleUrls: ['./text-element.component.scss']
})
export class TextElementComponent implements OnInit{
 
  constructor(){
    this.getScreenSize();
  }

  @Input() data:any;

  scrWidth : any;
  fontSize: any;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event? : any) {
    this.scrWidth = window.innerWidth;
    // if(this.data != null){
    //   if(this.fontSize == null) {
    //     let temp = this.data['font-size'].replace('px','');
    //     let size = parseInt(temp, 10);
    //     this.fontSize = size;
    //   }
    //   this.data['font-size'] = ((this.scrWidth * this.fontSize) / 1600) + 'px';
    // }
    // //console.log(this.scrHeight, this.scrWidth);
  }


  ngOnInit(){
    // let temp = this.data['font-size'].replace('px','');
    // let size = parseInt(temp, 10);
    // this.data['font-size'] = ((this.scrWidth * size) / 1600) + 'px';
    // if(this.scrWidth < 475){
    //   this.data['font-size'] = '10px'
    // }
  }
}
