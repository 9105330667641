<div class="mainSection">
    <div [ngStyle]="heading.style?heading.style:{}" class="heading">
        {{heading.value}}
    </div>
    <div class="blogsContainer">
        <div class="rowFlexContainer">
            <div class="container">
                <img [ngStyle]="image1.style?image1.style:{}"  class="imageSize" src="{{image1.imgUrl}}" alt="">
                <div [ngStyle]="title1.style?title1.style:{}"  class="titleStyle">{{title1.value}}</div>
                <div [ngStyle]="description1.style?description1.style:{}"  class="descriptionStyle">{{description1.value}}</div>
                <div [ngStyle]="lifeStyleText.style?lifeStyleText.style:{}"  class="bottomTextStyle">{{lifeStyleText.value}}</div>
            </div>
            <div class="middle-container">
                <img [ngStyle]="image2.style?image2.style:{}"  class="imageSize" src="{{image2.imgUrl}}" alt="">
                <div [ngStyle]="title2.style?title2.style:{}"  class="titleStyle">{{title2.value}}</div>
                <div [ngStyle]="description2.style?description2.style:{}"  class="descriptionStyle">{{description2.value}}</div>
                <div [ngStyle]="tutorialText.style?tutorialText.style:{}"  class="bottomTextStyle">{{tutorialText.value}}</div>
            </div>
            <div class="container">
                <img [ngStyle]="image3.style?image3.style:{}"  class="imageSize" src="{{image3.imgUrl}}" alt="">
                <div [ngStyle]="title3.style?title3.style:{}"  class="titleStyle">{{title3.value}}</div>
                <div [ngStyle]="description3.style?description3.style:{}"  class="descriptionStyle">{{description3.value}}</div>
                <div [ngStyle]="personalText.style?personalText.style:{}"  class="bottomTextStyle">{{personalText.value}}</div>
            </div>
        </div>
    </div>
    <div class="readAllBlog">
        <div [ngStyle]="readAllBlogText.style?readAllBlogText.style:{}" class="readallblogtext">{{readAllBlogText.value}}</div>
    </div>
</div>
