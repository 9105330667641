<section class="main-feature-section">
    <div class="main_section">
    <div class="main-header-section">
        <div class="sub-toggle-heading">
            Upcoming Features
        </div>
        <div class="main-feature-heading">
            Boost Your Business Efficiency with Our Upcoming Features
        </div>
    </div>
    <div class="main-middle-section">
        <div class="main-upcoming-section">
            <div class="left-side-upcoming-section">
                <div class="toggle-upcoming-text">
                    CRM
                </div>
                <div class="upcoming-main-heading">
                    Convert more leads, Engage with Customers, and Grow more revenue.
                </div>
                <div class="upcoming-sub-heading">
                    With our simple CRM you can connect with customers, easily organize your contact, and generate instant client communications
                </div>
            </div>
            <div class="right-side-upcoming-section">
                <img src="./../../../../assets/images/features/upcoming_feature 1.svg" alt="first-image" />
            </div>
        </div>

        <div class="main-upcoming-section">
            <div class="left-side-upcoming-section">
                <div class="toggle-upcoming-text">
                    Invoice
                </div>
                <div class="upcoming-main-heading">
                    Easy to use invoicing tool for your business
                </div>
                <div class="upcoming-sub-heading">
                    Create and manage your invoices more efficiently with our simple invoicing tool designed for small business
                </div>
            </div>
            <div class="right-side-upcoming-section">
                <img src="./../../../../assets/images/features/upcoming_feature 2.svg" alt="first-image" />
            </div>
        </div>

        <div class="main-upcoming-section">
            <div class="left-side-upcoming-section">
                <div class="toggle-upcoming-text">
                    Marketing
                </div>
                <div class="upcoming-main-heading">
                    An advanced solution for social media management
                </div>
                <div class="upcoming-sub-heading">
                    Plan, organise, schedule and publish content together with multi-social social media posts.
                </div>
            </div>
            <div class="right-side-upcoming-section">
                <img src="./../../../../assets/images/features/upcoming_feature 3.svg" alt="first-image" />
            </div>
        </div>
    </div>
    </div>
</section>