<!-- <div class="total_container">
    <div class="main-container">
      <div class="headPart">
        <div class="title">Rating Text</div>
      </div>
      <div class="descriptionPart">
        <textarea name="" type="text" id="multiliner" matInput class="textarea" [(ngModel)]="value"
          (keyup)="increaseTextAreaHeight($event)" [cdkTextareaAutosize]="true" style="max-height: 100px;"></textarea>
      </div>
    </div>
  </div> -->
  <div class="total_container">
    <div class="main-container half-width">
      <div class="headPart">
        <div class="title">Rating Range</div>
      </div>
      <div class="descriptionPart">
        <input name="" type="number" id="multiliner" matInput class="textarea" [(ngModel)]="ratingRange" style="max-height: 100px;" />
      </div>
    </div>
    <div class="main-container half-width">
      <div class="headPart">
        <div class="title">Rating Value</div>
      </div>
      <div class="descriptionPart">
        <input name="" id="multiliner" matInput class="textarea" [(ngModel)]="data.ratingValue"
          style="max-height: 100px;"/>
      </div>
    </div>
  </div>
  
  <div class="color_related">
    <input type="color" name="bgColor" placeholder="text" [(ngModel)]="data['color']" class="bgColor">
    <div class="color_title">Color</div>
  </div>