import { Component } from '@angular/core';

@Component({
  selector: 'app-screen-loader',
  templateUrl: './screen-loader.component.html',
  styleUrls: ['./screen-loader.component.scss'],
  standalone: true
})
export class ScreenLoaderComponent {

}
