import { Component, HostListener, Input } from '@angular/core';
import { ActionModel, ButtonModel, ChannelType, EventsService, RedirectionLinkType } from 'simpo-component-library';
import { TemplateService } from 'src/app/services/template.service';
import { ListItemComponentComponent } from '../list-item-component/list-item-component.component';
import { LogoEditorComponent } from '../logo-editor/logo-editor.component';
import { AddSocialChannelsComponent } from '../add-social-channels/add-social-channels.component';
import { FooterSocialChannelModel } from 'simpo-component-library/lib/sections/footer-section/footer-section.modal';
import { WebisiteService } from 'src/app/master-config-components/micro-apps/website/webisite.service';
import { WarningPopupComponent } from '../../popups/warning-popup/warning-popup.component';
import { FormControl } from '@angular/forms';
import { MapService } from 'src/app/services/map.service';
import { Observable, map, startWith } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ProductServiceService } from 'src/app/master-config-components/micro-apps/products/service/product-service.service';
import { DbService } from 'src/app/services/db-service/db.service';
import { WebsiteGenerationService } from 'src/app/website-generation/website-generation.service';
import { EditorEventService } from 'src/app/services/editor-event.service';

@Component({
  selector: 'app-content-editor',
  templateUrl: './content-editor.component.html',
  styleUrls: ['./content-editor.component.scss']
})
export class ContentEditorComponent {
  @Input() content: any;
  @Input() action?: ActionModel;
  @Input() sectionType!: string;
  @Input() color?: string;
  @Input() sectionId!: string;
  @Input() templatePage: any[] = [];
  @Input() currentPageIndex:number = 0;

  public loading: string | null = null;

  constructor(
    private dialogSercvice: TemplateService,
    private websiteService: WebisiteService,
    private _mapservice: MapService,
    private eventEmitter: EventsService,
    private productService: ProductServiceService,
    private _dbService: DbService,
    private _localEventEmitterService : EditorEventService
  ) {
    this.screenSize()
   }

  ngOnInit() {
    this.getCollectionList();
    this.searchLocation = this.content?.address?.name

    console.log(this.content)
  }

  screenWidth: any;
  @HostListener('window:resize', ['$event'])
  screenSize(event?: any) {
    this.screenWidth = window.innerWidth;
  }

  ChannelType = ChannelType
  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['code-block'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['link'],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['link', 'image', 'video']
      ],

    },
    keyboard: {
      bindings: {
        enter: {
          key: 13,
          handler: (range: any, context: any) => {
            return true;
          }
        }
      }
    }
  };
  searchBox = new FormControl();
  searchLocation: any;
  searchResults: Observable<string[]> | any;
  locations: any = [];
  collectionList: any[] = [];

  openListItem(data: any) {
    let height = window.innerWidth > 475 ? '85vh' : '100vh';
    if(this.sectionType.includes('carouselBanner')){
      this._localEventEmitterService.openMultiBannerCarousel.emit({open:true,data:data})
    }
    else{
      this.dialogSercvice.openDialog(ListItemComponentComponent, height,
        '460px', { data: data, newItem: false, sectionId: this.sectionId, templatePage: this.templatePage, currentPageIndex: this.currentPageIndex });
    }
  }

  deleteListItem(index: number) {
    this.content.listItem.data.splice(index, 1);
  }

  addNewListItem() {
    let height = window.innerWidth > 475 ? '85vh' : '100vh';

    let data = this.content.listItem.data?.length > 0 ? JSON.parse(JSON.stringify(this.content.listItem.data[0])) : {};
    if(this.sectionType === 'banner') {
      data = {
        "inputText": [
          {
            "label": "Text",
            "value": "",
            "isRTE": false
          }
        ]
      }
    }
    let dialog = this.dialogSercvice.openDialog(ListItemComponentComponent, height,
      '460px', { data: data, newItem: true, buttonData: false, templatePage: this.templatePage, currentPageIndex: this.currentPageIndex });

    dialog.afterClosed().subscribe((res: any) => {
      if (res.data) {
        if(res.data.hasOwnProperty('image')){
          res.data.image.id = this.content.listItem.data.length + 1;
        }
        this.content.listItem.data.push(res.data);
      }
    })
  }

  addNewButton() {
    let newButton:ButtonModel = {
      "content": {
        "linkType": RedirectionLinkType.External,
        "label": "Button",
        "redirectionUrl": "",
        "pageId": "",
        "newTab": false
      },
      "styles": {
        "type": this.action?.buttons.length == 0 || this.action?.buttons[0].styles.type === 'Solid' ? "Outline" : 'Solid',
        "shape": "Round"
      },
      "id": "btn" + (this.action?.buttons.length ? this.action?.buttons.length + 1 : 1)
    }

    this.action?.buttons.push(newButton)
    let height= window.innerWidth > 475 ? 'auto' : '100vh';

    let dialog = this.dialogSercvice.openDialog(ListItemComponentComponent, height,
    '460px', { data: this.action?.buttons[this.action.buttons.length - 1], newItem: false, buttonData: true, templatePage: this.templatePage, currentPageIndex: this.currentPageIndex });
  }

  editButton(button:ButtonModel){
    let height = window.innerWidth > 475 ? 'auto' : '100vh';
    let dialog = this.dialogSercvice.openDialog(ListItemComponentComponent, height,
    '400px', { data: button, newItem: false, buttonData: true, templatePage: this.templatePage, currentPageIndex: this.currentPageIndex });
  }

  deleteButton(index: number){
    this.action?.buttons.splice(index,1)
  }

  changeSiteNameAndLogo(){
    try {

      const components = this.templatePage[this.currentPageIndex].components;
      if (!components[0].sectionType.includes("header"))
        return;
      const idx = components.length -1;

      if (this.content.siteNameAndLogo == "same as header") {

        const socialChannels = components[0].content.socialLinks.channels;
        const logo = components[0].content.logo;
        const siteName = components[0].content.siteName;

        if (idx > -1) {
          components[idx].content.siteName = siteName;
          components[idx].content.logo = logo;
          components[idx].content.socialLinks.channels = socialChannels;
        }
      } else {
        components[idx].content.siteName = {...components[idx]?.content?.siteName};
        components[idx].content.logo = {...components[idx]?.content?.logo};
        components[idx].content.socialLinks.channels = [...components[idx]?.content?.socialLinks?.channels];

      }
    } catch(exception) {

    }

  }

  openLogoStyleEditor(){
    let height = window.innerWidth > 475 ? 'auto' : '100vh';
    this.dialogSercvice.openDialog(LogoEditorComponent, height, '430px', {data: this.content.logo, siteName: this.content.siteName.value, bgColor: this.color})
  }

  addNewChannel(){
    let newData: FooterSocialChannelModel = {
      type: ChannelType.FACEBOOK,
      url: '',
      link: ''
    };
    let dialog = this.dialogSercvice.openDialog(AddSocialChannelsComponent, 'auto', '430px', {socialLink: newData, newItem: true})
    dialog.afterClosed().subscribe((res: any) => {
      if(res.data){
        this.content.socialLinks.channels.push(res.data);
      }
    })
  }

  editChannel(channel: any){
    let dialog = this.dialogSercvice.openDialog(AddSocialChannelsComponent, 'auto', '430px', {socialLink: channel, newItem: false})

  }

  deleteChannel(index: number){
    this.content.socialLinks.channels.splice(index,1);
  }

  regerateContent(inputText) {
    if (this.loading == inputText.label)
      return;
    this.loading = inputText.label;
    const bDetails = JSON.parse(localStorage.getItem("bDetails") ?? "");
    if (!bDetails)
      return;
    const payload = {
      "dataId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      "subIndustryId": bDetails.subIndustryId,
      "businessId": bDetails.id,
      // "businessName": "string", industryId
      "customPrompt": "",
      "label": inputText.label,
      "value": inputText.value,
      "suggestedDataType": "COMPONENT",
      "tone": "PROFESSIONAL",
      // "wordLimit": 0
    }
    this.websiteService.regenerateIndividualV2(payload).subscribe((response: any)=> {
      inputText.value = response.data;
      this.loading = null;
    }, (error)=> {
      this.loading = null;
    })
  }

  getSanitizedData(value: string){
    const regex = /(<([^>]+)>)/ig;
    return value.replace(regex, '');
  }

  deleteData(type: any, index: number){
    this.warningPopup(type).subscribe((res: any) => {
      if(res.delete) {
        if(type === 'LIST_ITEM') {
          this.deleteListItem(index);
        } else if(type === 'BUTTON') {
          this.deleteButton(index);
        } else if(type === 'SOCIAL_LINK') {
          this.deleteChannel(index);
        }
      }
    })
  }

  warningPopup(type: any) {
    let width= window.innerWidth > 475 ? '400px' : '94%'
    let height= window.innerWidth > 475 ? '268px' : '200px'
    let warningPopupModel = this.dialogSercvice.openDialog(
      WarningPopupComponent,
      height,
      width,
      { type: type }
    );

    return warningPopupModel.afterClosed()
  }

  search() {
    this._mapservice.searchAddress(this.searchLocation).subscribe(
      (res: any) => {
        if (res.features.length > 0) {
          this.locations = res.features;
          this.searchResults = this.searchBox.valueChanges.pipe(
            startWith(''),
            map((value) => this._searchFilter(value))
          );
        }
      },
      (err) => {
      }
    );
  }

  private _searchFilter(value: any) {
    const filterValue = value.toLowerCase();

    return this.locations.filter((option: any) =>
      option.place_name.toLowerCase().includes(filterValue)
    );
  }

  selectedLocations(location: any){
    this.content.address.name = this.searchLocation;
    this.content.address.longitude = location.center[0]
    this.content.address.latitude = location.center[1];
    this.eventEmitter.locationChangeCheck.emit(this.content.address);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.content.listItem.data, event.previousIndex, event.currentIndex);
  }

  getCollectionList() {
    let data = {
      businessId: this._dbService.getBusinessId(),
      pageNo: 0,
      pageSize: 100
    }
    this.productService.getCollectionList(data).subscribe(
      {
        next: (value: any) => {
          this.collectionList = value.data.data;
        },
        error: (err: any) => {
        }
      }
    )
  }


  onSelectAllCollection(checkboxElement) {
    if (checkboxElement.checked) {
      this.content.collectionIds = this.collectionList.map((element) => element.collectionId);
    } else {
      this.content.collectionIds = [];
    }
  }

  selectCollection(checkBoxElement, collection: any, allCollectionCheckbox) {
    if (checkBoxElement.selected) {
      this.content.collectionIds.push(collection.collectionId);
    } else {
      this.content.collectionIds = this.content.collectionIds.filter((data: any) => data != collection.collectionid);
      allCollectionCheckbox = this.content.collectionIds.length === this.collectionList.length;
    }
  }


  changeCollection(collectionName) {
    if(this.sectionType === 'featuredProduct')
      this.eventEmitter.featureCollectionList.emit()
    else if(this.sectionType === 'categoryProduct')
      this.eventEmitter.categoryProductList.emit(this.content.collectionId)
    else if(this.sectionType === 'featuredCategory')
      this.eventEmitter.categoryListEvent.emit()

    this.content.inputText[0].value = collectionName;
  }

  changeCollectionComponent() {
    console.log(this.content.collectionIds)
    this.eventEmitter.collectionsListEvent.emit()
  }
}

