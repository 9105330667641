<section class="position-relative">
    <div class="header-section">
        <div class="left-header">
            <span><img src="./../../../../../../../../assets/images/blogs/left-arrow.svg" alt="left-arrow"
                    (click)="back()"></span><span class="back-text" (click)="back()">Blogs</span>
        </div>
        <div class="right-header">
            <div class="formfield">
                <mat-form-field class="sfull-width domain-input" appearance="outline">
                    <mat-icon matPrefix><mat-icon>search</mat-icon></mat-icon>
                    <input type="text" placeholder="Enter business type" matInput [formControl]="myControl" [matAutocomplete]="auto1" 
                    [(ngModel)]="bType"/>
                    <mat-autocomplete #auto1="matAutocomplete">
                      <mat-option *ngFor="
                          let option of filteredOptions | async;
                          index as i
                        " [value]="option.name"
                        (click)="selectBusinessType(option)"
                        (keyup.enter)="selectBusinessType(option)"
                        >
                        {{ option.name}}
                      </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <button class="outlined-blue-button" type="button" (click)="saveToDraft()">Save</button>
            <button class="flat-blue-button" type="button" (click)="publishBlog()">Publish</button>
        </div>
    </div>
    <div class="configuration-tab">
        <div class="setting-icon">
            <img src="./../../../../../assets/images/blogs/settings.svg" (click)="openBlogSettingsDialog($event)" />
        </div>
    </div>
    <!-- editorjs-block -->
    <div #editor class="editorjs-block" (keypress)="countwords()"></div>

    <ng-template [ngIf]="screenLoading">
        <app-screen-loader></app-screen-loader>
    </ng-template>
</section>