<section class="main-section">
    <div class="main-left-section">
        <!-- <div class="main-heading">
            10k+ websites
        </div> -->
        <div class="main-sub-heading">
            Made with Simpo.ai
        </div>
        <div class="main-sub-text">
            From freelancers to startups, Simpo.ai empowers your business with its easy to use tools.
        </div>
        <div class="main-sub-button" (click)="generateWebsite()">
            <!-- <img src="./../../../../assets/images/home_page/main_button.svg" alt="main_button" /> -->
            <button (click)="generateWebsite()">
                Generate Free Website <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
        </div>
    </div>
    <div class="main-right-section">
        <div class="animation-section">
            <div class="gellery-section">
                <div class="first-image">
                    <img src="./../../../../assets/images/previous-section/desktop_first.svg" alt="first-image" />
                </div>
                <div class="second-image">
                    <img src="./../../../../assets/images/previous-section/mobile_first.svg" alt="first-image" />
                </div>
                <div class="third-image">
                    <img src="./../../../../assets/images/previous-section/tablet-first.svg" alt="first-image" />
                </div>
            </div>
            <div class="gellery-section">
                <div class="first-image">
                    <img src="./../../../../assets/images/previous-section/tablet-second.svg" alt="first-image" />
                </div>
                <div class="second-image">
                    <img src="./../../../../assets/images/previous-section/desktop-second.svg" alt="first-image" />
                </div>
                <div class="third-image">
                    <img src="./../../../../assets/images/previous-section/mobile-second.svg" alt="first-image" />
                </div>
            </div>
            <div class="gellery-section">
                <div class="first-image">
                    <img src="./../../../../assets/images/previous-section/desktop-third.svg" alt="first-image" />
                </div>
                <div class="second-image">
                    <img src="./../../../../assets/images/previous-section/desktop-fourth.svg" alt="first-image" />
                </div>
            </div>
            <div class="gellery-section">
                <div class="first-image">
                    <img src="./../../../../assets/images/previous-section/desktop_first.svg" alt="first-image" />
                </div>
                <div class="second-image">
                    <img src="./../../../../assets/images/previous-section/mobile_first.svg" alt="first-image" />
                </div>
                <div class="third-image">
                    <img src="./../../../../assets/images/previous-section/tablet-first.svg" alt="first-image" />
                </div>
            </div>
            <div class="gellery-section">
                <div class="first-image">
                    <img src="./../../../../assets/images/previous-section/tablet-second.svg" alt="first-image" />
                </div>
                <div class="second-image">
                    <img src="./../../../../assets/images/previous-section/desktop-second.svg" alt="first-image" />
                </div>
                <div class="third-image">
                    <img src="./../../../../assets/images/previous-section/mobile-second.svg" alt="first-image" />
                </div>
            </div>
            <div class="gellery-section">
                <div class="first-image">
                    <img src="./../../../../assets/images/previous-section/desktop-third.svg" alt="first-image" />
                </div>
                <div class="second-image">
                    <img src="./../../../../assets/images/previous-section/desktop-fourth.svg" alt="first-image" />
                </div>
            </div>
        </div>
    </div>
</section>