export class Blog {
    readTime: string = '0';
    wordCount: number = 0;
    id: string = '';
    time: number = 0;
    version: string = '';
    createdBy: string = '';
    postSummary: string = '';
    postTitle: string = '';
    blocks: any[] = [];
    blogMeta: BlogMeta = {
        metaTitle: '',
        metaDescription: {
            name: 'description',
            content: ''
        }
    };
    blogScheduledDate: string | null = null;
    blogScheduleTime: string | null = null;
    keyWords: MetaDefinition = {
        name: 'keywords',
        content: ''
    };
    author: MetaDefinition = {
        name: 'author',
        content: ''
    };
    blogStatus: BlogStatus = BlogStatus.SAVE;
    businessId: string | null = '';
    blogType: BlogType = BlogType.BUSINESS;
    updatedTimeStamp: string = '';
    createdTimeStamp: string = '';
    subIndustryId: string = '';
    subIndustryName: string = ''

    constructor(blogData?: Blog) {
        if (blogData) {
            this.readTime = blogData.readTime || '0';
            this.author = blogData.author || {
                name: 'author',
                content: ''
            };
            this.blocks = blogData.blocks || [];
            this.blogScheduleTime = blogData.blogScheduleTime;
            this.blogScheduledDate = blogData.blogScheduledDate;
            this.blogStatus = blogData.blogStatus || BlogStatus.SAVE;
            this.blogType = blogData.blogType || BlogType.BUSINESS;
            this.businessId = blogData.businessId;
            this.createdBy = blogData.createdBy;
            this.createdTimeStamp = blogData.createdTimeStamp;
            this.id = blogData.id;
            this.keyWords = blogData.keyWords || {
                name: 'keywords',
                content: ''
            };
            this.postSummary = blogData.postSummary || '';
            this.postTitle = blogData.postTitle || '';
            this.time = blogData.time;
            this.updatedTimeStamp = blogData.updatedTimeStamp;
            this.version = blogData.version;
            this.wordCount = blogData.wordCount;
            this.blogMeta = blogData.blogMeta || {
                metaTitle: '',
                metaDescription: {
                    name: 'description',
                    content: ''
                }
            };
            this.subIndustryId = blogData.subIndustryId || '';
            this.subIndustryName = blogData.subIndustryName || '';
        }
    }

    addTag(tag: string): boolean {
        if (!tag) return false;

        const previousTags = this.keyWords.content.split(',');
        
        if(previousTags.length === 1 && !previousTags[0]) {
            previousTags.pop();
        };

        if(previousTags.includes(tag)) return false;

        previousTags.push(tag);
        this.keyWords.content = previousTags.join(',');

        return true;
    }

    removeTag(removeTagName: string): boolean {
        const previousTags = this.keyWords.content.split(',');
        const removingTagIndex = previousTags.findIndex((tag: string) => tag === removeTagName);

        if (removingTagIndex > -1) {
            previousTags.splice(removingTagIndex, 1);
            this.keyWords.content = previousTags.join(',');

            return true;
        }

        return false;
    }

    addAuthor(author: string): boolean {
        if (!author) return false;

        const previousAuthors = this.author.content.split(',');

        if(previousAuthors.length === 1 && !previousAuthors[0]) {
            previousAuthors.pop();
        };

        if(previousAuthors.includes(author)) return false;

        previousAuthors.push(author);
        this.author.content = previousAuthors.join(',');

        return true;
    }

    removeAuthor(removeAuthorName: string): boolean {
        const previousAuthors = this.author.content.split(',');
        const removingAuthorIndex = previousAuthors.findIndex((author: string) => author === removeAuthorName);

        if (removingAuthorIndex > -1) {
            previousAuthors.splice(removingAuthorIndex, 1);
            this.author.content = previousAuthors.join(',');

            return true;
        }

        return false;
    }
};

export interface MetaDefinition {
    name: string;
    content: string;
};

export interface BlogMeta {
    metaTitle: string;
    metaDescription: MetaDefinition
};

export enum BlogStatus {
    "SAVE"="SAVE",
    "PUBLISH"="PUBLISH",
    "SCHEDULED"="SCHEDULED",
    "ALL"="ALL"
}

export enum BlogType {
    "MASTER"="MASTER",
    "BUSINESS"="BUSINESS"
}

export interface Tag {
    blogCount: number;
    businessId: string;
    createdById: string;
    createdByName: string;
    description: string;
    id: string;
    tagName: string;
    createdTimeStamp: string;
}

export interface Author {
    authorId: string;
    authorName: string;
    blogCount: number;
    businessId: string;
    createdById: string;
    createdByName: string;
    createdTimeStamp: string;
    email: string;
    id: string;
    imageUrl: string;
}

export const Blog_Cachekeys = {
    "BLOG_SETTINGS": "BLOG_SETTINGS"
}

export interface BlogFilterRequest {
    businessId: string;
    status: BlogStatus | null;
    page: number;
    size: number,
    authors: string[];
    tags: string[];
    publishDate: string | null;
    searchText: string;
}

export interface SelectedOption {
    fieldValue: string,
    fieldText: string,
    fieldName: string
}