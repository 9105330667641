import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ComponentImageUploadService } from 'src/app/services/component-image-upload.service';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';
import { TemplateService } from 'src/app/services/template.service';
import { MediaSelectorComponent } from '../../popups/media-selector/media-selector.component';

@Component({
  selector: 'app-bg-image-editor',
  templateUrl: './bg-image-editor.component.html',
  styleUrls: ['./bg-image-editor.component.scss']
})
export class BgImageEditorComponent {
  @Input() imageData : any;
  @Input() componentIndex : any;
  @Input() componentData:any;
  @Input() data : any;

  constructor(
    private imageUploadService:ComponentImageUploadService,
    private eventService : EventEmmiterService,
    private templateService: TemplateService,
  ){
    this.getScreenSize();
  }

  disabled = false;
  max = 100;
  min = 0;
  showTicks = false;
  step = 1;
  thumbLabel = false;
  value = 0;

  ngOnChanges(){
    if(this.imageData?.backgroundColor){
      var rgbaValue = this.imageData?.backgroundColor;
      var colorStr = rgbaValue.slice(rgbaValue.indexOf('(') + 1, rgbaValue.indexOf(')'));
      var colorArr = colorStr.split(',')
      this.value = parseFloat(colorArr[3]) * 100;
    }
  }
  pageLoader:boolean=false;
  addBGImage(item){
    // this.pageLoader = true;
    // let elementId = document.getElementById('browse_bg_img');
    // if(!elementId) return;
    // elementId.click();
    // this.pageLoader = false;
    if(this.srcWidth > 475){
    let dialog = this.templateService.openDialogWithData(
      MediaSelectorComponent,
      '90vh',
      '50%',
      {multiple:false}
  );
  dialog.afterClosed().subscribe(
    (res:any)=>{
      console.log(res)
      // this.profileImg = res[0].assets[0].url;
      this.imageData.attachment = res[0].assets[0].url;
      // console.log(res);
  
    
  }
  )
}
if(this.srcWidth < 475){
  let dialog = this.templateService.openDialogWithData(
    MediaSelectorComponent,
    '100vh',
    '100%',
    {multiple:false}
);
dialog.afterClosed().subscribe(
  (res:any)=>{
    console.log(res)
    // this.profileImg = res[0].assets[0].url;
    this.imageData.attachment = res[0].assets[0].url;
    // console.log(res);

  
}
)
}

  }
  srcHeight: any;
  srcWidth: any;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.srcHeight = window.innerHeight;
    this.srcWidth = window.innerWidth;
    ////console.log(this.scrHeight, this.scrWidth);
  }

  file : any ;
  profileImg : any = '';
  imageUploadingLoader : boolean = false;

  async updateBGImage(ev:any){
    this.pageLoader = true;
    const file = ev.srcElement.files[0];
    this.profileImg = await this.imageUploadService.uploadComponentsImages(file);
    this.profileImg = this.profileImg.Location;
    this.imageData.attachment = this.profileImg;
    this.pageLoader = false;

    //this.eventService.changeAnimation.emit({index:this.componentIndex,componentData:this.componentData})
  }

  changeOpacity(){
    let rgba = this.getBackgroundOpacity(this.componentData.bgColor,(this.value / 100) + "")
    this.imageData.backgroundColor = rgba;
  }

  public getBackgroundOpacity(hexCode:any,opacity:any){
    const r = parseInt(hexCode.slice(1, 3), 16);
    const g = parseInt(hexCode.slice(3, 5), 16);
    const b = parseInt(hexCode.slice(5, 7), 16);
    return 'rgb' + '(' + r + "," + g + "," + b + "," + opacity +')';
  }
}
