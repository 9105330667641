import { Component, Input } from '@angular/core';
import { ElementHelper } from '../../../elements/elements_helper';
import {MatSliderModule} from '@angular/material/slider';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';
import { fontSizes } from './../../../../../../../../constants/common-constant'
import { WebisiteService } from 'src/app/master-config-components/micro-apps/website/webisite.service';
import { MatSnackBar } from '@angular/material/snack-bar';


enum WebsiteContentType {
  HEADING = 'HEADING',
  DESCRIPTION = 'DESCRIPTION',
}
@Component({
  selector: 'app-text-editor-card',
  templateUrl: './text-editor-card.component.html',
  styleUrls: ['./text-editor-card.component.scss']
})
export class TextEditorCardComponent {

  formatLabel(value: number): string {
    if (value >= 100) {
      return Math.round(value / 100) + 'px';
    }

    return `${value}`;
  }

  fontSizes : any = fontSizes

  @Input() data : any;
  @Input() dynamicItemHrbar : any;
  @Input() selectedComponent : any;
  // @Input() removeTopNavbar :boolean = false;

  constructor(
    private _elementHelper : ElementHelper,
    private _eventHelper : EventEmmiterService,
    private webisiteService : WebisiteService,
    private _snackBar: MatSnackBar,
  ){
  }
  businessDetails: any ;
  value : any ;

  ngOnInit(){
    console.log(this.selectedComponent)
    if(!this.data){
      return
    }
    let bDetails = window.localStorage.getItem('bDetails');
    if (bDetails) {
      this.businessDetails = JSON.parse(bDetails);
    }
    // console.log(this.businessDetails , 'nimisha')

    this.value = String(this.data?.value)
    if(this.data['font-size'] != undefined){
      this.fontSizes.forEach(fz => {
        if(fz === Number(this.data['font-size']?.split('px')[0])){
          this.fontSize = fz
        }
      });
    }
    if(this.value.includes('<br>')){
      this.value = this.data.value.replace(/<br><br>/g, "\r\n\n");
      this.value = this.data.value.replace(/<br>/g, "\r\n");
    }
    document.getElementById('newLine')?.addEventListener('keydown', (e:any) => {
      if(e.key === 'Enter'){
        let new_line = document.createElement("br");
        new_line.innerText = e.target.value;
        // this.data.value = this.data.value+'<br>'+e.target.value
      }
    })
    if(this.data['font-weight'] == '900'||this.data['font-weight'] == '800' ||  this.data['font-weight'] == '700'){
      this.boldWeight.status = true;
    }
    if(this.data['font-style'] == 'italic')
    {
      this.style.status = true ;
    }
  }
  fontSize: number | any ;

  onChange(ev){
    this.data['font-size'] = ev.value+'px';
    let marginBottom = this.data['font-size'].split('px')[0]  - ev.value
    this.data['line-height'] = Number(this.data['font-size'].split('px')[0]) + 8 + 'px';
    this.data['margin-bottom'] = marginBottom + "px";
    this._eventHelper.showSaveOption.emit({data:true});
  }


  boldWeight : any = {
    name:'B',
    status : false
  }
  style : any = {
    name:'I',
    status : false
  }
  fontWeight(){
    this.boldWeight.status = !this.boldWeight.status;
    if(this.boldWeight.status){
      this.data['font-weight'] = '900';
    }
    else{
      this.data['font-weight'] = '400';
    }
    this._eventHelper.showSaveOption.emit({data:true});
  }
  fontStyle(){
    this.style.status = !this.style.status;
    if(this.style.status){
      this.data['font-style'] = 'italic';
    }
    else{
      this.data['font-style'] = 'normal'
    }
    this._eventHelper.showSaveOption.emit({data:true});
  }

  // colorChange(){
  //   let elementId = document.getElementById('browse_color');
  //   if(!elementId) return;
  //   elementId.click()
  // }

  increaseTextAreaHeight(ev){
    ev.target.value = ev.target.value.trimStart();
    let code = ev.keyCode || ev.which;
    this.value = ev.target.value.replace(/\n/g, "<br>");
    this.data.value = this.value;
    this.value = this.data.value.replace(/<br>/g, "\r\n");
    this._eventHelper.showSaveOption.emit({data:true});
  }





  websiteContentType :any;
  regenerate(){
    if(this.data['lm_title'] === 'Heading'){
      this.websiteContentType = 'HEADING'
    }else if(this.data['lm_title'] === 'Description'){
      this.websiteContentType = ' DESCRIPTION'
    }
   let  data= {
      websiteContentType: this.websiteContentType,
      businessName: this.businessDetails.name,
      subIndustryName: this.businessDetails.subIndustryName,
      existingText:this.value,
    }
    this.webisiteService.regenerate(data).subscribe((
      {
        next: (res:any)=>{
          this.value = res.data.suggestedText.replaceAll('\n\\', '');
          // this.value = this.value.replace(" ", "/n/n")
        },
        error: (err: any) => {
          this._snackBar.open(`${err.error.message}`, 'Close', { duration: 1500 });
        }
      }
    ))
  }
  submitLoader:boolean = false;
  regenerateV2(){
    this.submitLoader = true;
    let data = {
      dataId:this.selectedComponent.id ,
      suggestedDataType: "COMPONENT",
      subIndustryId: this.businessDetails.subIndustryId,
      businessId: this.businessDetails.id,
      businessName: this.businessDetails.name,
      individualDataParam: this.data.param,
      individualDataContext: this.data.value,
      tone: null,
      wordLimit: null,
      customPrompt: null
    }
    this.webisiteService.regenerateIndividual(data).subscribe((
      {
        next:(res:any)=>{
          this.submitLoader = false;
          this.value = res.data
          this.data.value = this.value;
          this._eventHelper.showSaveOption.emit({data:true});
        },
        error:(err:any)=>{

        }
      }
    ))

  }

  changeColor(ev){
    this._eventHelper.showSaveOption.emit({data:true});
  }
}
