<section class="main_section">

    <!-- top navbar  -->
    <section class="top_navbar">
        <app-home-nav-bar></app-home-nav-bar>
    </section>

    <!-- hero banner  -->
    <section class="hero_banner">
        <app-our-hero-banner></app-our-hero-banner>
    </section>

    <!-- feature section -->
    <section class="feature_section">
        <app-features></app-features>
    </section>

    <!-- testimonial section  -->
    <!-- <section class="testimonial_section">
        <app-our-testimonial></app-our-testimonial>
    </section> -->

    <!-- previous work  -->
    <!-- <section class="previous_work">
        <app-previous-work></app-previous-work>
    </section> -->

    <!-- upcoming features section  -->
    <section class="upcoming_feature">
        <app-upcoming-features></app-upcoming-features>
    </section>

    <!-- responsiveness section   -->
    <!-- <section class="app-responsiveness">
        <app-responsiveness></app-responsiveness>
    </section> -->

    <!-- multi testimonial  -->
    <section class="multi_testimonial">
        <app-multi-testimonial></app-multi-testimonial>
    </section>

    <!-- cta-action -->
    <section class="cta-action" *ngIf="scrWidth > 475">
        <app-cta-action></app-cta-action>
    </section>

    <!-- blogs section  -->
    <!-- <section class="blogs-section">
        <app-our-blogs></app-our-blogs>
    </section> -->

    <!-- footer section  -->
    <section class="footer-section">
        <app-our-footer></app-our-footer>
    </section>
    
</section>