import { Component, Inject, Optional, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { BlogServiceService } from '../../blog-service.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SeoImageUploadService } from 'src/app/services/ImageUploadService/seo-image-upload.service';
import moment from 'moment';
import { BlogPageComponent } from '../../../blogs-components/blog-page/blog-page.component';


@Component({
  selector: 'app-add-author',
  templateUrl: './add-author.component.html',
  styleUrls: ['./add-author.component.scss', './mq-add-author.component.scss']
})
export class AddAuthorComponent {

  constructor(
    private dialogRef: MatDialogRef<AddAuthorComponent>,
    private _bottomSheetRef: MatBottomSheetRef<AddAuthorComponent>,
    @Optional()   @Inject(MAT_DIALOG_DATA) public data,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData: any,
    public blogService: BlogServiceService,
    public ds: DomSanitizer,
    private _seoImageUploadService: SeoImageUploadService,
  ) {
    this.getScreenSize()
  }

  screenWidth: any;

  @HostListener("window:resize",["$event"])
  getScreenSize(){
    this.screenWidth = window.innerWidth;
  }

  // emailRegex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
  emailRegex = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);

  enterAuthor: string = '';
  enterSlug: string = '';
  enterEmail: string = '';
  imgFile: string = '';
  edit: boolean = this.data.edit;
  ngOnInit() {
    // this.edit = this.screenWidth > 475 ? this.data?.edit || false: this.bottomSheetData?.edit || false
    // if (this.screenWidth > 475 ? this.data.edit : this.bottomSheetData.edit) {
    //   this.enterAuthor = this.screenWidth > 475 ? this.data.data.authorName : this.bottomSheetData.data.authorName;
    //   this.enterEmail = this.screenWidth > 475 ? this.data.data.email: this.bottomSheetData.data.email;
    //   this.jeweller.jewellerLogo = this.screenWidth > 475 ? this.data.data.imageUrl: this.bottomSheetData.data.imageUrl;

    // }
    // if (this.screenWidth > 475 ? (this.data.type == 'EDITTAG' && this.data.edit): (this.bottomSheetData.type == 'EDITTAG' && this.bottomSheetData.edit)) {
    //   this.enterAuthor = this.screenWidth> 475 ? this.data.data.blogAuthor.authorName : this.bottomSheetData.data.blogAuthor.authorName;
    //   this.enterEmail = this.screenWidth> 475 ? this.data.data.blogAuthor.email: this.bottomSheetData.data.blogAuthor.email;
    //   this.jeweller.jewellerLogo = this.screenWidth> 475 ? this.data.data.blogAuthor.imageUrl: this.bottomSheetData.data.blogAuthor.imageUrl;
    // }

    if(this.data.edit){
      this.enterAuthor=this.data.data.authorName;
      this.enterEmail=this.data.data.email;
      this.jeweller.jewellerLogo = this.data.data.imageUrl;
    }

    if(this.data.type == 'EDITTAG' && this.data.edit){
      this.enterAuthor = this.data.data.blogAuthor.authorName;
      this.enterEmail = this.data.data.blogAuthor.email;
      this.jeweller.jewellerLogo = this.data.data.blogAuthor.imageUrl;
    }

  }

  browseFcImage() {
    document.getElementById('browse_fc_image')?.click();
  }

  file: any = '';
  async updatePostImage(ev: any) {
    const file = ev.srcElement.files[0];
    this.file = file;
    // this.noImg = window.URL.createObjectURL(file);
    // this.jeweller.jewellerLogo  = window.URL.createObjectURL(file);
    this.jeweller.jewellerLogo = await this._seoImageUploadService.uploadSEOimages(file, 'Blogs')
    this.jeweller.jewellerLogo = this.jeweller.jewellerLogo.Location;

  }

  jeweller: any = {
    businessName: "",
    legalEntityName: "",
    jewellerWebsite: "",
    address: {
      address: "",
      city: "",
      state: "",
      pinCode: ""
    },
    contactPerson: "",
    contactPhoneNo: "",
    jewellerLogo: "",
    jewellerGenId: "",
    active: true,
    createdTimeStamp: new Date(),
    updatedTimeStamp: new Date(),
    accountDetails: {
      accountName: "",
      accountNumber: 0,
      ifsc_code: "",
      account_holder_name: "",
      tan_number: 0
    }
  }

  delete() {
    this.jeweller.jewellerLogo = "";
  }
  addAuthor() {

      if (this.emailRegex.test(this.enterEmail)) {
        let data = {
          authorName: this.enterAuthor,
          email: this.enterEmail,
          imageUrl: this.jeweller.jewellerLogo,
          createdTimeStamp: moment(new Date()).format(),
          blogCount: 0,
          createdById: "",
          createdByName: "",
          businessId: JSON.parse(window.localStorage.getItem("bDetails") || "").id
        }

        data.createdById = localStorage.getItem('staffId') || '';
        data.createdByName = localStorage.getItem('staffName') || '';

        this.blogService.createBlogAuthor(data).subscribe({
          next: (res: any) => {
            this.blogService.openSnack("Author created sucessfully", "Close");
            this.dialogRef.close();
          },
          error: (err: any) => {
            this.blogService.openSnack(err.error.message, "close");
          }
        })
      }
      else {
        this.blogService.openSnack("Invalid Email", "Close")
      }
  }

  editAuthor() {
      if (this.emailRegex.test(this.enterEmail)) {
        let data = {
          authorId: this.data.data.authorId,
          id: this.data.data.id,
          authorName: this.enterAuthor,
          email: this.enterEmail,
          imageUrl: this.jeweller.jewellerLogo,
          createdTimeStamp: moment(new Date()).format(),
          blogCount: this.data.data.blogCount,
          createdById: this.data.data.createdById,
          createdByName: this.data.data.createdByName,
          businessId: JSON.parse(window.localStorage.getItem("bDetails") || "").id
        }

        data.createdById = localStorage.getItem('staffId') || '';
        data.createdByName = localStorage.getItem('staffName') || '';

        this.blogService.updateBlogAuthor(data).subscribe({
          next: (value: any) => {
            this.blogService.openSnack("Author updated sucessfully", "Close");
            this.dialogRef.close();
          },
          error: (err: any) => {
            this.dialogRef.close();
          },
        })
      }
      else {
        this.blogService.openSnack("Invalid Email", "Close")
      }
  }
  closeBtn() {
      this.dialogRef.close();
  }
}

