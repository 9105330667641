import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-warning-popup',
  templateUrl: './warning-popup.component.html',
  styleUrls: ['./warning-popup.component.scss']
})
export class WarningPopupComponent implements OnInit{

  constructor(
    public dialogRef: MatDialogRef<WarningPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ){}

  type: any;
  ngOnInit(): void {
    this.type = this.data.type
  }

  sanitizeType(type: string): string {
    if (!type) {
      return 'page';
    }
    return type.replace(/[^a-zA-Z0-9 ]/g, ' ').trim() || 'page';
  }

  closeDialog(deleteData: boolean){
    this.dialogRef.close({delete: deleteData});
  }
}
