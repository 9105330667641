<div class="nav d-flex justify-content-between px-4 py-3" *ngIf="scrWidth > 475" (click)="closeEditor()">
  <div class="d-flex gap-3" style="width: 20%;">
    <button class="border" data-bs-target="#staticBackdrop" data-bs-toggle="modal"
      style="width: 25%; border-radius: 5px;font-size: 14px; font-weight: 600;background-color: white;height: 40px;">Exit</button>
    <button (click)="savePage()" class="border"
      style="width: 25%; border-radius: 5px;font-size: 14px; font-weight: 600;background-color: white;height: 40px;">Save</button>
  </div>
  <div class="add_business d-flex" style="width: 30%; gap: 7px;">
    <div class="add_page" (click)="pageListComponent()">
      <!-- <mat-form-field class=" custom-picker" appearance="outline">
        <mat-select [(ngModel)]="selectedOption" (ngModelChange)="changePage($event)"
          style="height: 36px;display: flex;align-items: center;font-size: 14px;">
          <ng-container *ngFor="let page of templatePage">
            <mat-option [value]="page.id">
              {{ page.pageName}}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field> -->
      <div class="home-text">
        {{selectedPage}}
      </div>
      <div>
        <mat-icon>keyboard_arrow_down</mat-icon>
      </div>
    </div>
    <div class="multi_page d-flex border"
      style="width: fit-content; border-radius: 5px;font-size: 14px; font-weight: 600;background-color: white; height: 40px;"
      (click)="showPageList()">
      <button class="button-style d-flex justify-content-center align-items-center"
        style="border: none; border-radius: 5px;">
        <mat-icon>add</mat-icon></button>
    </div>
  </div>
  <div style="width: 40%;">
    <div class="d-flex gap-3" style="display: flex; justify-content: flex-end;">
      <button class="button-style d-flex justify-content-center align-items-center border" mat-button
        [matMenuTriggerFor]="menu"
        style="width: fit-content; border: 1px; border-radius: 5px;font-size: 14px; font-weight: 600;background-color: white; height: 40px; display: flex; flex-direction: row-reverse; gap: 10px;">
        <!-- <mat-icon class="icons">palette</mat-icon> -->
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16" width="22" height="23"
          class="icon w-5 h-5" aria-hidden="true">
          <path
            d="M12.4632 14.7277c.3088.3149.6854.4723 1.13.4723.4446 0 .8236-.1574 1.1369-.4723.3133-.3148.47-.7006.47-1.1574 0-.3568-.07-.6939-.21-1.0111-.14-.3173-.31-.6117-.51-.8833l-.52-.6926c-.0933-.1222-.2133-.1833-.36-.1833s-.2667.0611-.36.1833l-.52.6926c-.2133.2716-.3867.566-.52.8833-.1333.3172-.2.6543-.2 1.0111 0 .4568.1544.8426.4631 1.1574Z">
          </path>
          <path fill-rule="evenodd"
            d="M11.0506 1.01682c.1198-.359279-.0744-.747612-.4336-.867371-.3593-.1197581-.74766.074409-.86741.433684L9.058 2.65788l-.88815-.88816c-.53558-.53557-1.40392-.53557-1.9395 0l-4.4605 4.46051c-.53558.53558-.53558 1.40391 0 1.93949l4.4605 4.46048c.15077.1508.3279.2591.51656.325.31398.1097.65988.1017.96926-.0237.1651-.067.31981-.1674.45371-.3013l4.46052-4.46048c.5355-.53557.5355-1.40391 0-1.93949l-2.4882-2.48814.9084-2.72527ZM8.57313 4.1125l-.42354 1.27063c-.11976.35928.0744.74761.43368.86737.35927.11976.74761-.07441.86737-.43368l.2067-.62011 2.00326 2.00327H2.73959L7.2001 2.73947 8.57313 4.1125Z"
            clip-rule="evenodd"></path>
        </svg>
        Styles <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="open('color')" type="button" data-bs-toggle="offcanvas" data-bs-target="#sideBar"
          aria-controls="offcanvasRight">
          <!-- <mat-icon>palette</mat-icon> -->
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 20 20" width="24" height="24" class="icon w-5 h-5 text-gray-700" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M10 17.5c-1.98912 0-3.89678-.7902-5.3033-2.1967C3.29018 13.8968 2.5 11.9891 2.5 10c0-1.98912.79018-3.89678 2.1967-5.3033S8.01088 2.5 10 2.5c4.1417 0 7.5 2.985 7.5 6.66667 0 .88333-.395 1.73163-1.0983 2.35663-.7034.625-1.6575.9767-2.6517.9767h-2.0833c-.3718-.006-.7349.1125-1.0316.3367-.2967.2241-.5099.541-.6057.9003-.09578.3593-.0687.7403.077 1.0824.1456.3421.4015.6257.7269.8056.1664.1535.2805.3553.3263.5771.0458.2217.021.4522-.071.6591-.0919.2068-.2464.3797-.4416.4943-.1953.1146-.4215.1651-.647.1445Z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M6.25 8.75c0 .22101.0878.43298.24408.58926.15628.15628.36824.24407.58925.24407.22102 0 .43298-.08779.58926-.24407.15628-.15628.24408-.36825.24408-.58926 0-.22101-.0878-.43298-.24408-.58926-.15628-.15628-.36824-.24407-.58926-.24407-.22101 0-.43297.08779-.58925.24407C6.3378 8.31702 6.25 8.52899 6.25 8.75Zm3.33333-2.5c0 .22101.0878.43298.24408.58926.15628.15628.36829.24407.58929.24407.221 0 .4329-.08779.5892-.24407.1563-.15628.2441-.36825.2441-.58926 0-.22101-.0878-.43298-.2441-.58926-.1563-.15628-.3682-.24407-.5892-.24407-.221 0-.43301.08779-.58929.24407-.15628.15628-.24408.36825-.24408.58926Zm3.33337 2.5c0 .22101.0878.43298.244.58926.1563.15628.3683.24407.5893.24407.221 0 .433-.08779.5893-.24407.1562-.15628.244-.36825.244-.58926 0-.22101-.0878-.43298-.244-.58926-.1563-.15628-.3683-.24407-.5893-.24407-.221 0-.433.08779-.5893.24407-.1562.15628-.244.36825-.244.58926Z"></path></svg>
          <span class="window-titles">Colors</span></button>
        <button mat-menu-item (click)="open('font')" type="button" data-bs-toggle="offcanvas" data-bs-target="#sideBar"
          aria-controls="offcanvasRight">
          <!-- <mat-icon>font_download</mat-icon> -->
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 20 20" width="24" height="24" class="icon w-5 h-5 text-gray-700" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.16667 5.83333c-.44203 0-.86595-.17559-1.17851-.48815C2.67559 5.03262 2.5 4.60869 2.5 4.16667c0-.44203.17559-.86595.48816-1.17851C3.30072 2.67559 3.72464 2.5 4.16667 2.5c.44202 0 .86595.17559 1.17851.48816.31256.31256.48815.73648.48815 1.17851M4.16667 5.83333c.44202 0 .86595-.17559 1.17851-.48815.31256-.31256.48815-.73649.48815-1.17851M4.16667 5.83333v8.33337M5.83333 4.16667h8.33337m0 0c0 .44202.1756.86595.4881 1.17851.3126.31256.7365.48815 1.1785.48815m-1.6666-1.66666c0-.44203.1756-.86595.4881-1.17851.3126-.31257.7365-.48816 1.1785-.48816.4421 0 .866.17559 1.1785.48816.3126.31256.4882.73648.4882 1.17851 0 .44202-.1756.86595-.4882 1.17851-.3125.31256-.7364.48815-1.1785.48815m0 0v8.33337m-9.99997 1.6666c0 .4421-.17559.866-.48815 1.1785-.31256.3126-.73649.4882-1.17851.4882-.44203 0-.86595-.1756-1.17851-.4882-.31257-.3125-.48816-.7364-.48816-1.1785 0-.442.17559-.8659.48816-1.1785.31256-.3125.73648-.4881 1.17851-.4881m1.66666 1.6666c0-.442-.17559-.8659-.48815-1.1785-.31256-.3125-.73649-.4881-1.17851-.4881m1.66666 1.6666h8.33337m0 0c0 .4421.1756.866.4881 1.1785.3126.3126.7365.4882 1.1785.4882.4421 0 .866-.1756 1.1785-.4882.3126-.3125.4882-.7364.4882-1.1785 0-.442-.1756-.8659-.4882-1.1785-.3125-.3125-.7364-.4881-1.1785-.4881m-1.6666 1.6666c0-.442.1756-.8659.4881-1.1785.3126-.3125.7365-.4881 1.1785-.4881M8.33333 8.33333h3.33337M10 11.6667V8.33333"></path></svg>
          <span class="window-titles">Fonts</span>
          </button>
        <button mat-menu-item (click)="open('buttons')" type="button" data-bs-toggle="offcanvas"
          data-bs-target="#sideBar" aria-controls="offcanvasRight">
          <!-- <mat-icon>smart_button</mat-icon> -->
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" width="24" height="24" class="icon w-5 h-5 text-gray-700" aria-hidden="true"><rect width="14.5" height="5.5" x="2.75" y="2.75" fill="currentColor" stroke="currentColor" stroke-width="1.5" rx="2.25"></rect><rect width="14.5" height="5.5" x="2.75" y="11.75" stroke="currentColor" stroke-width="1.5" rx="2.25"></rect></svg>
          <span class="window-titles">Buttons</span>
          </button>
        <button mat-menu-item (click)="open('others')" type="button" data-bs-toggle="offcanvas"
          data-bs-target="#sideBar" aria-controls="offcanvasRight">
          <!-- <mat-icon>format_shapes</mat-icon> -->
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16" width="24" height="24" class="icon w-5 h-5 text-gray-700" aria-hidden="true"><path d="M12.4632 14.7277c.3088.3149.6854.4723 1.13.4723.4446 0 .8236-.1574 1.1369-.4723.3133-.3148.47-.7006.47-1.1574 0-.3568-.07-.6939-.21-1.0111-.14-.3173-.31-.6117-.51-.8833l-.52-.6926c-.0933-.1222-.2133-.1833-.36-.1833s-.2667.0611-.36.1833l-.52.6926c-.2133.2716-.3867.566-.52.8833-.1333.3172-.2.6543-.2 1.0111 0 .4568.1544.8426.4631 1.1574Z"></path><path fill-rule="evenodd" d="M11.0506 1.01682c.1198-.359279-.0744-.747612-.4336-.867371-.3593-.1197581-.74766.074409-.86741.433684L9.058 2.65788l-.88815-.88816c-.53558-.53557-1.40392-.53557-1.9395 0l-4.4605 4.46051c-.53558.53558-.53558 1.40391 0 1.93949l4.4605 4.46048c.15077.1508.3279.2591.51656.325.31398.1097.65988.1017.96926-.0237.1651-.067.31981-.1674.45371-.3013l4.46052-4.46048c.5355-.53557.5355-1.40391 0-1.93949l-2.4882-2.48814.9084-2.72527ZM8.57313 4.1125l-.42354 1.27063c-.11976.35928.0744.74761.43368.86737.35927.11976.74761-.07441.86737-.43368l.2067-.62011 2.00326 2.00327H2.73959L7.2001 2.73947 8.57313 4.1125Z" clip-rule="evenodd"></path></svg>
          <span class="window-titles">Other styles</span>
          </button>
      </mat-menu>
      <!-- <button (click)="openWidgets()" class="widgets d-flex justify-content-center"  style="width: 20%; border: 1px; border-radius: 5px;font-size: 14px; font-weight: 600;background-color: white; height: 35px;">
      <mat-icon>widgets</mat-icon>
          Widgets
    </button> -->
      <!-- Started code for selecting screens -->
      <!-- <button class="button-style d-flex justify-content-center align-items-center border" mat-button
        [matMenuTriggerFor]="screens"
        style="width: 10%; border: 1px; border-radius: 5px;font-size: 14px; font-weight: 600;background-color: white; height: 40px; display: flex; flex-direction: row-reverse; gap: 4px;">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="22" height="23" fill="currentColor"
          aria-hidden="true" class="icon h-5 w-5">
          <path fill-rule="evenodd"
            d="M2 4.25A2.25 2.25 0 014.25 2h11.5A2.25 2.25 0 0118 4.25v8.5A2.25 2.25 0 0115.75 15h-3.105a3.501 3.501 0 001.1 1.677A.75.75 0 0113.26 18H6.74a.75.75 0 01-.484-1.323A3.501 3.501 0 007.355 15H4.25A2.25 2.25 0 012 12.75v-8.5zm1.5 0a.75.75 0 01.75-.75h11.5a.75.75 0 01.75.75v7.5a.75.75 0 01-.75.75H4.25a.75.75 0 01-.75-.75v-7.5z"
            clip-rule="evenodd"></path>
        </svg> <mat-icon>keyboard_arrow_down</mat-icon>
      </button> -->
      <mat-menu #screens="matMenu">
        <button mat-menu-item type="button">
          <mat-icon>desktop_windows</mat-icon>Desktop</button>
        <button mat-menu-item type="button">
          <mat-icon>tablet</mat-icon>Tablet</button>
        <button mat-menu-item type="button">
          <mat-icon>smartphone</mat-icon>Mobile</button>
      </mat-menu>
      <!-- Ended code of selecting screens -->
      <button *ngIf="status === 'PUBLISHED'" class="view d-flex justify-content-center alig-items-center border" (click)="viewPopup()"
        style="width: 15%; border: 1px; border-radius: 5px;font-size: 14px; font-weight: 600;background-color: white; height: 40px;" >
        <!-- <mat-icon>streetview</mat-icon> -->
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="22" height="23" fill="currentColor"
          aria-hidden="true" class="icon w-5 h-5">
          <path fill-rule="evenodd"
            d="M4.25 5.5a.75.75 0 00-.75.75v8.5c0 .414.336.75.75.75h8.5a.75.75 0 00.75-.75v-4a.75.75 0 011.5 0v4A2.25 2.25 0 0112.75 17h-8.5A2.25 2.25 0 012 14.75v-8.5A2.25 2.25 0 014.25 4h5a.75.75 0 010 1.5h-5z"
            clip-rule="evenodd"></path>
          <path fill-rule="evenodd"
            d="M6.194 12.753a.75.75 0 001.06.053L16.5 4.44v2.81a.75.75 0 001.5 0v-4.5a.75.75 0 00-.75-.75h-4.5a.75.75 0 000 1.5h2.553l-9.056 8.194a.75.75 0 00-.053 1.06z"
            clip-rule="evenodd"></path>
        </svg>
        View
      </button>
      <button *ngIf="status === 'NOT_PUBLISHED'" class="view d-flex justify-content-center alig-items-center border" (click)="changeStatus()"
        style="width: 15%; border: 1px; border-radius: 5px;font-size: 14px; font-weight: 600;background-color: white; height: 40px;" >
        <!-- <mat-icon>streetview</mat-icon> -->
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="icon w-5 h-5"><path fill-rule="evenodd" d="M4.606 12.97a.75.75 0 01-.134 1.051 2.494 2.494 0 00-.93 2.437 2.494 2.494 0 002.437-.93.75.75 0 111.186.918 3.995 3.995 0 01-4.482 1.332.75.75 0 01-.461-.461 3.994 3.994 0 011.332-4.482.75.75 0 011.052.134z" clip-rule="evenodd"></path><path fill-rule="evenodd" d="M5.752 12A13.07 13.07 0 008 14.248v4.002c0 .414.336.75.75.75a5 5 0 004.797-6.414 12.984 12.984 0 005.45-10.848.75.75 0 00-.735-.735 12.984 12.984 0 00-10.849 5.45A5 5 0 001 11.25c.001.414.337.75.751.75h4.002zM13 9a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd"></path></svg>
        PUBLISH
      </button>
      <!-- <button class="button-style d-flex justify-content-center align-items-center border" mat-button
        [matMenuTriggerFor]="publish"
        style="width: 24%; border: 1px; border-radius: 5px;font-size: 14px; font-weight: 600;background-color: white; height: 40px; display: flex; flex-direction: row-reverse; gap: 10px;">
        <img
          src="https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/753077c1712744464360rocket_launch_FILL1_wght400_GRAD0_opsz24.png"
          alt="">
        Publish <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
      <mat-menu #publish="matMenu">
        <button mat-menu-item>
          <img
            src="https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/318622c1712745848693Screenshot%20%286%29.png"
            alt="" width="14%">
          Preview</button>
        <button mat-menu-item>
          <img
            src="https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/753077c1712744464360rocket_launch_FILL1_wght400_GRAD0_opsz24.png"
            alt="">Publish</button>
      </mat-menu> -->
    </div>
  </div>
  <!-- </div> -->

  <div class="offcanvas offcanvas-end custom-offcanvas" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1"
    id="sideBar" aria-labelledby="offcanvasRightLabel" *ngIf="scrWidth > 475">
    <div class="offcanvas-header">
      <p id="offcanvasRightLabel" *ngIf="selectedGlobal === 'color'" class="header-text mb-0">Color Palette</p>
      <p id="offcanvasRightLabel" *ngIf="selectedGlobal === 'buttons'" class="header-text mb-0">Button Styles</p>
      <p id="offcanvasRightLabel" *ngIf="selectedGlobal === 'font'" class="header-text mb-0">Fonts</p>
      <p id="offcanvasRightLabel" *ngIf="selectedGlobal === 'others'" class="header-text mb-0">Other Styles</p>
      <button type="button" data-bs-dismiss="offcanvas" aria-label="Close" class="doneBtn">Done</button>
    </div>
    <div class="offcanvas-body">
      <!-- Pallete -->
      <div *ngIf="selectedGlobal === 'color' ">
        <app-bg-color-palette [templatePage]="templatePage"  [themes]="themes"></app-bg-color-palette>
      </div>
      <!-- Buttons -->
      <div class="buttons" *ngIf="selectedGlobal === 'buttons'">
        <app-global-button-styles [templatePage]="templatePage"></app-global-button-styles>
      </div>
      <!-- Fonts -->
      <div class="fonts" *ngIf="selectedGlobal === 'font' ">
        <div class="font-families">
          <ng-container *ngFor="let item of fontFamilies">
            <div class="card-section border rounded bg-white px-4 py-2 cursor-pointer"[ngClass]="{'card-selected': item.displayHeading == selectedFont}" (click)="selectedFontFamily(item)">
                <div class="font-title" [style.fontFamily]="item?.headingFontValues?.fontFamily">
                    {{item.displayHeading}}
                </div>
                <div class="font-desc" [style.fontWeight]="item.bodyFontValues.fontWeight">
                    {{item.displayBody}}
                </div>
            </div>
        </ng-container>
        </div>
      </div>

      <!-- Other Styles -->
      <div class="other_styles" *ngIf="selectedGlobal === 'others'">
        <app-other-styles [templatePage]="templatePage"></app-other-styles>
      </div>
    </div>

  </div>
</div>
<div class="editor-part">
  <ng-container *ngIf="templatePage.length > 0 && themes.length > 0">
    <app-v3-editor [templatePage]="templatePage" [currentPageId]="currentPageId" [themes]="themes" (websiteLoaderfun)="getWebsiteLoader=$event"></app-v3-editor>
  </ng-container>
  <section class="loader_section" *ngIf="getWebsiteLoader">
    <div class="api_loader">
        <app-anime-loader></app-anime-loader>
    </div>
  </section>

</div>

<div *ngIf="scrWidth < 475" class="footer-section">
  <div class="footer-buttons">

    <button class="button-style" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom"
      aria-controls="offcanvasBottom">
      <!-- <mat-icon class="icons">palette</mat-icon> -->
      <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16" style="width: 19%;"
        class="icon w-5 h-5" aria-hidden="true">
        <path
          d="M12.4632 14.7277c.3088.3149.6854.4723 1.13.4723.4446 0 .8236-.1574 1.1369-.4723.3133-.3148.47-.7006.47-1.1574 0-.3568-.07-.6939-.21-1.0111-.14-.3173-.31-.6117-.51-.8833l-.52-.6926c-.0933-.1222-.2133-.1833-.36-.1833s-.2667.0611-.36.1833l-.52.6926c-.2133.2716-.3867.566-.52.8833-.1333.3172-.2.6543-.2 1.0111 0 .4568.1544.8426.4631 1.1574Z">
        </path>
        <path fill-rule="evenodd"
          d="M11.0506 1.01682c.1198-.359279-.0744-.747612-.4336-.867371-.3593-.1197581-.74766.074409-.86741.433684L9.058 2.65788l-.88815-.88816c-.53558-.53557-1.40392-.53557-1.9395 0l-4.4605 4.46051c-.53558.53558-.53558 1.40391 0 1.93949l4.4605 4.46048c.15077.1508.3279.2591.51656.325.31398.1097.65988.1017.96926-.0237.1651-.067.31981-.1674.45371-.3013l4.46052-4.46048c.5355-.53557.5355-1.40391 0-1.93949l-2.4882-2.48814.9084-2.72527ZM8.57313 4.1125l-.42354 1.27063c-.11976.35928.0744.74761.43368.86737.35927.11976.74761-.07441.86737-.43368l.2067-.62011 2.00326 2.00327H2.73959L7.2001 2.73947 8.57313 4.1125Z"
          clip-rule="evenodd"></path>
      </svg>
      <div style="font-size: 14px;">
        Styles
      </div>
    </button>

    <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
      <div class="offcanvas-body">
        <div class="option" (click)="open('color')" type="button" data-bs-toggle="offcanvas" data-bs-target="#sideBar"
          aria-controls="offcanvasRight">
          <!-- <mat-icon>palette</mat-icon>Colors -->
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 20 20" width="24" height="24" class="icon w-5 h-5 text-gray-700" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M10 17.5c-1.98912 0-3.89678-.7902-5.3033-2.1967C3.29018 13.8968 2.5 11.9891 2.5 10c0-1.98912.79018-3.89678 2.1967-5.3033S8.01088 2.5 10 2.5c4.1417 0 7.5 2.985 7.5 6.66667 0 .88333-.395 1.73163-1.0983 2.35663-.7034.625-1.6575.9767-2.6517.9767h-2.0833c-.3718-.006-.7349.1125-1.0316.3367-.2967.2241-.5099.541-.6057.9003-.09578.3593-.0687.7403.077 1.0824.1456.3421.4015.6257.7269.8056.1664.1535.2805.3553.3263.5771.0458.2217.021.4522-.071.6591-.0919.2068-.2464.3797-.4416.4943-.1953.1146-.4215.1651-.647.1445Z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M6.25 8.75c0 .22101.0878.43298.24408.58926.15628.15628.36824.24407.58925.24407.22102 0 .43298-.08779.58926-.24407.15628-.15628.24408-.36825.24408-.58926 0-.22101-.0878-.43298-.24408-.58926-.15628-.15628-.36824-.24407-.58926-.24407-.22101 0-.43297.08779-.58925.24407C6.3378 8.31702 6.25 8.52899 6.25 8.75Zm3.33333-2.5c0 .22101.0878.43298.24408.58926.15628.15628.36829.24407.58929.24407.221 0 .4329-.08779.5892-.24407.1563-.15628.2441-.36825.2441-.58926 0-.22101-.0878-.43298-.2441-.58926-.1563-.15628-.3682-.24407-.5892-.24407-.221 0-.43301.08779-.58929.24407-.15628.15628-.24408.36825-.24408.58926Zm3.33337 2.5c0 .22101.0878.43298.244.58926.1563.15628.3683.24407.5893.24407.221 0 .433-.08779.5893-.24407.1562-.15628.244-.36825.244-.58926 0-.22101-.0878-.43298-.244-.58926-.1563-.15628-.3683-.24407-.5893-.24407-.221 0-.433.08779-.5893.24407-.1562.15628-.244.36825-.244.58926Z"></path></svg>
          <span class="window-titles">Colors</span>
        </div>
        <div class="option" (click)="open('font')" type="button" data-bs-toggle="offcanvas" data-bs-target="#sideBar"
          aria-controls="offcanvasRight">
          <!-- <mat-icon>font_download</mat-icon>Fonts -->
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 20 20" width="24" height="24" class="icon w-5 h-5 text-gray-700" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.16667 5.83333c-.44203 0-.86595-.17559-1.17851-.48815C2.67559 5.03262 2.5 4.60869 2.5 4.16667c0-.44203.17559-.86595.48816-1.17851C3.30072 2.67559 3.72464 2.5 4.16667 2.5c.44202 0 .86595.17559 1.17851.48816.31256.31256.48815.73648.48815 1.17851M4.16667 5.83333c.44202 0 .86595-.17559 1.17851-.48815.31256-.31256.48815-.73649.48815-1.17851M4.16667 5.83333v8.33337M5.83333 4.16667h8.33337m0 0c0 .44202.1756.86595.4881 1.17851.3126.31256.7365.48815 1.1785.48815m-1.6666-1.66666c0-.44203.1756-.86595.4881-1.17851.3126-.31257.7365-.48816 1.1785-.48816.4421 0 .866.17559 1.1785.48816.3126.31256.4882.73648.4882 1.17851 0 .44202-.1756.86595-.4882 1.17851-.3125.31256-.7364.48815-1.1785.48815m0 0v8.33337m-9.99997 1.6666c0 .4421-.17559.866-.48815 1.1785-.31256.3126-.73649.4882-1.17851.4882-.44203 0-.86595-.1756-1.17851-.4882-.31257-.3125-.48816-.7364-.48816-1.1785 0-.442.17559-.8659.48816-1.1785.31256-.3125.73648-.4881 1.17851-.4881m1.66666 1.6666c0-.442-.17559-.8659-.48815-1.1785-.31256-.3125-.73649-.4881-1.17851-.4881m1.66666 1.6666h8.33337m0 0c0 .4421.1756.866.4881 1.1785.3126.3126.7365.4882 1.1785.4882.4421 0 .866-.1756 1.1785-.4882.3126-.3125.4882-.7364.4882-1.1785 0-.442-.1756-.8659-.4882-1.1785-.3125-.3125-.7364-.4881-1.1785-.4881m-1.6666 1.6666c0-.442.1756-.8659.4881-1.1785.3126-.3125.7365-.4881 1.1785-.4881M8.33333 8.33333h3.33337M10 11.6667V8.33333"></path></svg>
          <span class="window-titles">Fonts</span>
        </div>
        <div class="option" (click)="open('buttons')" type="button" data-bs-toggle="offcanvas"
          data-bs-target="#sideBar" aria-controls="offcanvasRight">
          <!-- <mat-icon>smart_button</mat-icon>Buttons -->
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" width="24" height="24" class="icon w-5 h-5 text-gray-700" aria-hidden="true"><rect width="14.5" height="5.5" x="2.75" y="2.75" fill="currentColor" stroke="currentColor" stroke-width="1.5" rx="2.25"></rect><rect width="14.5" height="5.5" x="2.75" y="11.75" stroke="currentColor" stroke-width="1.5" rx="2.25"></rect></svg>
          <span class="window-titles">Buttons</span>
        </div>
        <div class="option" (click)="open('others')" type="button" data-bs-toggle="offcanvas"
          data-bs-target="#sideBar" aria-controls="offcanvasRight">
          <!-- <mat-icon>format_shapes</mat-icon>Other styles -->
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16" width="24" height="24" class="icon w-5 h-5 text-gray-700" aria-hidden="true"><path d="M12.4632 14.7277c.3088.3149.6854.4723 1.13.4723.4446 0 .8236-.1574 1.1369-.4723.3133-.3148.47-.7006.47-1.1574 0-.3568-.07-.6939-.21-1.0111-.14-.3173-.31-.6117-.51-.8833l-.52-.6926c-.0933-.1222-.2133-.1833-.36-.1833s-.2667.0611-.36.1833l-.52.6926c-.2133.2716-.3867.566-.52.8833-.1333.3172-.2.6543-.2 1.0111 0 .4568.1544.8426.4631 1.1574Z"></path><path fill-rule="evenodd" d="M11.0506 1.01682c.1198-.359279-.0744-.747612-.4336-.867371-.3593-.1197581-.74766.074409-.86741.433684L9.058 2.65788l-.88815-.88816c-.53558-.53557-1.40392-.53557-1.9395 0l-4.4605 4.46051c-.53558.53558-.53558 1.40391 0 1.93949l4.4605 4.46048c.15077.1508.3279.2591.51656.325.31398.1097.65988.1017.96926-.0237.1651-.067.31981-.1674.45371-.3013l4.46052-4.46048c.5355-.53557.5355-1.40391 0-1.93949l-2.4882-2.48814.9084-2.72527ZM8.57313 4.1125l-.42354 1.27063c-.11976.35928.0744.74761.43368.86737.35927.11976.74761-.07441.86737-.43368l.2067-.62011 2.00326 2.00327H2.73959L7.2001 2.73947 8.57313 4.1125Z" clip-rule="evenodd"></path></svg>
          <span class="window-titles">Other styles</span>
        </div>
      </div>
      <div class="close-button" data-bs-dismiss="offcanvas">
        Close
      </div>
    </div>

    <button *ngIf="mobileStatus === 'PUBLISHED'" class="view" (click)="viewPopup()">
      <!-- <mat-icon>streetview</mat-icon> -->
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" style="width: 19%;" fill="currentColor"
        aria-hidden="true" class="icon w-5 h-5">
        <path fill-rule="evenodd"
          d="M4.25 5.5a.75.75 0 00-.75.75v8.5c0 .414.336.75.75.75h8.5a.75.75 0 00.75-.75v-4a.75.75 0 011.5 0v4A2.25 2.25 0 0112.75 17h-8.5A2.25 2.25 0 012 14.75v-8.5A2.25 2.25 0 014.25 4h5a.75.75 0 010 1.5h-5z"
          clip-rule="evenodd"></path>
        <path fill-rule="evenodd"
          d="M6.194 12.753a.75.75 0 001.06.053L16.5 4.44v2.81a.75.75 0 001.5 0v-4.5a.75.75 0 00-.75-.75h-4.5a.75.75 0 000 1.5h2.553l-9.056 8.194a.75.75 0 00-.053 1.06z"
          clip-rule="evenodd"></path>
      </svg>
      <div style="font-size: 14px;">
        View
      </div>
    </button>

    <button *ngIf="mobileStatus === 'NOT_PUBLISHED'" class="view d-flex justify-content-center alig-items-center" (click)="changeStatus()">
        <svg style="width: 17%" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" class="icon w-5 h-5"><path fill-rule="evenodd" d="M4.606 12.97a.75.75 0 01-.134 1.051 2.494 2.494 0 00-.93 2.437 2.494 2.494 0 002.437-.93.75.75 0 111.186.918 3.995 3.995 0 01-4.482 1.332.75.75 0 01-.461-.461 3.994 3.994 0 011.332-4.482.75.75 0 011.052.134z" clip-rule="evenodd"></path><path fill-rule="evenodd" d="M5.752 12A13.07 13.07 0 008 14.248v4.002c0 .414.336.75.75.75a5 5 0 004.797-6.414 12.984 12.984 0 005.45-10.848.75.75 0 00-.735-.735 12.984 12.984 0 00-10.849 5.45A5 5 0 001 11.25c.001.414.337.75.751.75h4.002zM13 9a2 2 0 100-4 2 2 0 000 4z" clip-rule="evenodd"></path></svg>
        <div style="font-size: 14px;">Publish</div>
    </button>

    <!-- <button class="button-style" data-bs-toggle="offcanvas" data-bs-target="#offcanvasPublish"
      aria-controls="offcanvasPublish">
      <img
        src="https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/753077c1712744464360rocket_launch_FILL1_wght400_GRAD0_opsz24.png"
        alt="" style="width: 19%;">
      <div style="font-size: 14px;">
        Publish
      </div>
    </button>
    <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasPublish" aria-labelledby="offcanvasPublishLabel">
      <div class="offcanvas-body-publish">
        <div class="option">
          <img src="https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/318622c1712745848693Screenshot%20%286%29.png"
            alt="" width="7%">
          Preview
        </div>
        <div class="option">
          <img src="https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/753077c1712744464360rocket_launch_FILL1_wght400_GRAD0_opsz24.png"
            alt="">Publish
        </div>
      </div>
      <div class="close-button" data-bs-dismiss="offcanvas">
        Close
      </div>
    </div> -->

  </div>

  <div class="offcanvas offcanvas-style offcanvas-end custom-offcanvas" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1"
    id="sideBar" aria-labelledby="offcanvasRightLabel">
    <div class="offcanvas-header">
      <p id="offcanvasRightLabel" *ngIf="selectedGlobal === 'color'" class="header-text mb-0">Color Palette</p>
      <p id="offcanvasRightLabel" *ngIf="selectedGlobal === 'buttons'" class="header-text mb-0">Button Styles</p>
      <p id="offcanvasRightLabel" *ngIf="selectedGlobal === 'font'" class="header-text mb-0">Fonts</p>
      <p id="offcanvasRightLabel" *ngIf="selectedGlobal === 'others'" class="header-text mb-0">Other Styles</p>
      <button type="button" data-bs-dismiss="offcanvas" aria-label="Close" class="doneBtn">Done</button>
    </div>
    <div class="offcanvas-body">
      <!-- Pallete -->
      <div *ngIf="selectedGlobal === 'color' ">
        <app-bg-color-palette [templatePage]="templatePage" [themes]="themes"></app-bg-color-palette>
      </div>
      <!-- Buttons -->
      <div class="buttons" *ngIf="selectedGlobal === 'buttons'">
        <app-global-button-styles [templatePage]="templatePage"></app-global-button-styles>
      </div>
      <!-- Fonts -->
      <div class="fonts" *ngIf="selectedGlobal === 'font' ">
        <div class="font-families">
          <ng-container *ngFor="let item of fontFamilies">
            <div class="card-section border rounded bg-white px-4 py-2 cursor-pointer" [ngClass]="{'card-selected': item.displayHeading == selectedFont}" (click)="selectedFontFamily(item)">
                <div class="font-title" [style.fontFamily]="item?.headingFontValues?.fontFamily">
                    {{item.displayHeading}}
                </div>
                <div class="font-desc" [style.fontWeight]="item?.bodyFontValues?.fontWeight">
                    {{item.displayBody}}
                </div>
            </div>
        </ng-container>
        </div>
      </div>
      <!-- Other Styles -->
      <div class="other_styles" *ngIf="selectedGlobal === 'others'">
        <app-other-styles [templatePage]="templatePage"></app-other-styles>
      </div>
    </div>

  </div>

</div>

<div class="navbar-component" *ngIf="scrWidth < 475">
  <div class="d-flex gap-2" style="width: 20%;">
    <button data-bs-target="#staticBackdrop" data-bs-toggle="modal" class="border">Exit</button>
    <button (click)="savePage()" class="border">Save</button>
  </div>
  <div class="add_business d-flex" style="width: 100%; gap: 7px;">
    <div class="add_page" (click)="pageListComponent()">
      <div class="home-text">
        {{selectedPage}}
      </div>
      <div>
        <mat-icon>keyboard_arrow_down</mat-icon>
      </div>
    </div>
    <div class="multi_page d-flex border" style="padding: 0 0;" (click)="showPageList()">
      <button class="button-style d-flex justify-content-center align-items-center"
        style="border: none; border-radius: 5px;">
        <mat-icon>add</mat-icon></button>
    </div>
  </div>
</div>

<div class="modal fade" id="staticBackdrop" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div>
        <img src="../../../../../../assets/images/invoice/delete-invoice.svg" alt class="delete-img">
      </div>
      <div class="exit-header">
        Are you sure to exit?
      </div>
      <div class="exit-text">
        Are you sure you want to close without saving? Any unsaved changes will be discarded.
      </div>
      <div class="btn-section">
        <div>
          <button class="delete-btn" data-bs-dismiss="modal" (click)="goBack()">Exit</button>
        </div>
        <div>
          <button type="button" class="cancel-btn" data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>
