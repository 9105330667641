import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { EditWebsiteComponent } from './master-config-components/micro-apps/website/edit-website/edit-website/edit-website.component';
import { CreateBlogsComponent } from './master-config-components/micro-apps/website/edit-website/website-generationcomponent/blogs/create-blogs/create-blogs.component';
import { BusinessNameComponent } from './our-components/business-name/business-name.component';
import { BusinessTypeComponent } from './our-components/business-type/business-type.component';
import { GeneratingWebsiteComponent } from './our-components/generating-website/generating-website.component';
import { SigninSignupComponent } from './our-components/signin-signup/signin-signup/signin-signup.component';
import { TemplateImagesUploadComponent } from './our-components/template-images-upload/template-images-upload.component';
import { PreviewComponent } from './preview/preview.component';
import { AuthGuard } from './services/auth.guard';
import { WebsiteGeneratorComponent } from './website-generation/website-generator/website-generator.component';
import { SuccessComponent } from './our-components/strip/success/success.component';
import { CancelComponent } from './our-components/strip/cancel/cancel.component';
import { PricingComponent } from './our-components/pricing/pricing.component';
import { WithoutSignupEditComponent } from './our-components/without-signup-edit/without-signup-edit.component';
import { RestPasswordComponent } from './our-components/signin-signup/rest-password/rest-password.component';
import { CreateTaskComponent } from './master-config-components/micro-apps/crm/view-lead-details/lead-detail-component/create-components/create-task/create-task.component';
import { CreateMeetingComponent } from './master-config-components/micro-apps/crm/view-lead-details/lead-detail-component/create-components/create-meeting/create-meeting.component';
import { CreateCallComponent } from './master-config-components/micro-apps/crm/view-lead-details/lead-detail-component/create-components/create-call/create-call.component';
import { ListBusinessComponent } from './master-config-components/micro-apps/business/list-business/list-business.component';
import { OurContactUsPageComponent } from './our-components/components/our-contact-us-page/our-contact-us-page.component';
import { Title } from '@angular/platform-browser';
import { AddCustomizationsComponent } from './master-config-components/micro-apps/crm/view-lead-details/lead-detail-component/create-components/add-customizations/add-customizations.component';
import { V2WebsiteGenerationComponent } from './website-generation/v2-website-generation/v2-website-generation.component';
import { V2EditorComponent } from './master-config-components/micro-apps/website/edit-website/v-2/editor/v2-editor/v2-editor.component';
import { ServicesComponent } from './website-generation/services/services.component';
import { LoaderComponent } from './our-components/loader/loader.component';
import { HomeGuard } from './services/home.guard';
import { WebsitePreviewComponent } from './master-config-components/micro-apps/website/edit-website/v-2/editor/website-preview/website-preview.component';
import { ViewInvoiceReceiptComponent } from './website-generation/view-invoice-receipt/view-invoice-receipt.component';
import { V3WebsiteGenerationComponent } from './website-generation/v3-website-generation/v3-website-generation.component';
import { V3EditorComponent } from './master-config-components/micro-apps/website/edit-website/v-2/editor/v3-editor/v3-editor.component';
import { V3GlobalStylesComponent } from './master-config-components/micro-apps/website/edit-website/v-2/editor/editor-components/v3-global-styles/v3-global-styles.component';
import { WebsiteTypeComponent } from './our-components/website-type/website-type.component';

const routes: Routes = [
  // {
  //   path: '',
  //   component: HomeComponent,
  //   canActivate:[HomeGuard]
  // },
  {
    path: 'generating-website',
    component: WebsiteGeneratorComponent,
  },
  {
    path: 'business-type',
    component: BusinessTypeComponent,
    // canActivate:[HomeGuard]
  },
  {
    path: 'website-type',
    component: WebsiteTypeComponent,
    // canActivate:[HomeGuard]
  },
  {
    path: 'business-name',
    component: BusinessNameComponent,
  },
  {
    path:'test',
    component:GeneratingWebsiteComponent
  },
  {
    path:'preview',
    component:PreviewComponent
  },
  {
    path:'create-task',
    component:CreateTaskComponent
  },
  {
    path:'create-meeting',
    component:CreateMeetingComponent
  },
  {
    path:'create-call',
    component:CreateCallComponent
  },
  {
    path:'create-customization',
    component: AddCustomizationsComponent
  },
  {
    path:'list-business',
    component:ListBusinessComponent
  },
  {
    path: 'admin',
    loadChildren: () =>
      import(
        './master-config-components/master-config-mod/master-config-mod.module'
      ).then((module) => module.MasterConfigModModule),
      canActivate:[AuthGuard]
  },
  {
    path:'signup',
    component:SigninSignupComponent,
    canActivate:[HomeGuard]
  },
  {
    path:'reset-password',
    component:RestPasswordComponent
  },
  {
    path:'',
    component:SigninSignupComponent,
    canActivate:[HomeGuard]
  },
  {
    path:'upload',
    component:TemplateImagesUploadComponent
  },
  {
    path:'testing',
    component:GeneratingWebsiteComponent
  },
  {
    path:'edit-website',
    component:EditWebsiteComponent
  },
  {
    path:'local-edit',
    component:WithoutSignupEditComponent
  },
  {
    path:'create-blog',
    component:CreateBlogsComponent
  },
  // {
  //   path:'privacy-policy',
  //   component:PrivacyPolicyComponent
  // },
  // {
  //   path:'terms-conditions',
  //   component:TermsConditionsComponent
  // },
  {
    path:"stripe-success",
    component:SuccessComponent
  },
  {
    path:'stripe-cancel',
    component:CancelComponent
  },
  {
    path:'pricing',
    component:PricingComponent
  },
  {
    path:'contact-us',
    component:OurContactUsPageComponent
  },
  {
    path:'v2-generator',
    component:V2WebsiteGenerationComponent
  },
  {
    path:'v3-generator',
    component:V3WebsiteGenerationComponent
  },
  // {
  //   path:'gen-v1',
  //   component:WebsiteGeneratorComponent
  // },
  {
    path:'v2-editor',
    component:V2EditorComponent
  },
  {
    path:'website-preview',
    component: WebsitePreviewComponent
  },
  {
    path: 'v3-editor',
    component: V3EditorComponent
  },
  {
    path: 'website-editor',
    component: V3GlobalStylesComponent
  },
  {
    path:'services',
    component:ServicesComponent
  },
  {
    path:'loader',
    component:LoaderComponent
  },
  {
    path:'invoice-view-receipt',
    component:ViewInvoiceReceiptComponent
  }

];

@NgModule({
  // imports: [RouterModule.forRoot(routes,{
  //   preloadingStrategy: PreloadAllModules
  // })],
  imports:[RouterModule.forRoot(routes,{preloadingStrategy: PreloadAllModules })],
  providers:[Title],
  exports: [RouterModule],
})
export class AppRoutingModule {}
