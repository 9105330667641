import { Component, Host, HostListener, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TemplateService } from 'src/app/services/template.service';
import { PageEditComponent } from '../page-edit/page-edit.component';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CreateFolderComponent } from '../editor-compnents-v3/create-folder/create-folder.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { WarningPopupComponent } from '../popups/warning-popup/warning-popup.component';

@Component({
  selector: 'app-page-list',
  templateUrl: './page-list.component.html',
  styleUrls: ['./page-list.component.scss']
})
export class PageListComponent {

  constructor(
    private dialogRef: MatDialogRef<PageListComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private templateService: TemplateService,
    private _bottomSheet: MatBottomSheetRef<PageListComponent>,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData: any,
    private snackBar: MatSnackBar
  ) { }

  templatePage: any[] = []
  navButton: any[] = [];
  bussinessDetail: any;
  getWebsiteLoader!: boolean;
  ngOnInit() {

    this.getScreenSize();
    this.bussinessDetail = JSON.parse(String(localStorage.getItem('bDetails')));
    // this.templatePage = this.data.data
    if(window.innerWidth > 475){
      this.templatePage = this.data.data
      this.getWebsiteLoader = this.data.loader
    }
    else{
      this.templatePage = this.bottomSheetData.data
      this.getWebsiteLoader = this.bottomSheetData.loader
    }
    this.navButton = this.templatePage[0].components[0].content.navbarButtons
    this.getAllFolder();
  }

  scrWidth: any;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.scrWidth = window.innerWidth;
  }

  closeTab() {
    if(this.scrWidth > 475){
      this.dialogRef.close();
    }

    if (this.scrWidth < 475){
      this._bottomSheet.dismiss();
    }
  }

  pageAction(actionData: string, pageId?: string, pageName?: string, index?: number, page?: any, folderData?: any, folderId?: string) {
    let pageData = this.getPageById(pageId);
    page = pageData?.page;
    index = pageData?.index;

    const actionObject = {
      action: actionData,
      id: pageId,
      pageName: pageName,
      index: index,
      page: page,
      duplicate: !!page,
      folder: folderData,
      folderId: folderId
    };
    if (actionData === 'addPage' && !!page) {
      this.snackBar.open('Duplicate page created successfully!', 'Close', {
        duration: 3000,
      });
    }
    if(this.scrWidth > 475){
      this.dialogRef.close(actionObject);
    }
    if(this.scrWidth < 475){
      this._bottomSheet.dismiss(actionObject);
    }
  }

  openPageSetting(id: any, action : 'PAGE' | 'FOLDER'){
    if(action === 'PAGE') {
      let page = this.getPageById(id)?.page;
      if(this.scrWidth > 475){
        let dialog = this.templateService.openDialog(PageEditComponent,'', '', {pageData: page, navbarButton: this.templatePage[0].components[0].content.navbarButtons, folder: this.folderList})
        dialog.afterClosed().subscribe((res: any) => {
          if(res?.action === 'add data'){
            this.removePageFromNavbar(page.id);
            this.addNewPageToFolder(page.folderId, page)
          }
          else if(res?.action === 'Remove data'){
            this.removePageFromFolder(res?.folderId, page.id, true);
          }
          else if(res?.action === 'Update data') {
            this.removePageFromFolder(res?.folderId, page.id)
            this.addNewPageToFolder(page.folderId, page)
          }
          if(res?.action) {
            this.savePage();
          }
        })
      }

      if (this.scrWidth < 475){
        let bottomSheet = this.templateService.openBottomSheet(PageEditComponent, {pageData: page, navbarButton: this.templatePage[0].components[0].content.navbarButtons, folder: this.folderList},'bottom-sheet-height')
        bottomSheet.afterDismissed().subscribe((res: any) => {
          if(res?.action === 'add data'){
            this.removePageFromNavbar(page.id);
            this.addNewPageToFolder(page.folderId, page)
          }
          else if(res?.action === 'Remove data'){
            this.removePageFromFolder(res?.folderId, page.id, true)
          }
          else if(res?.action === 'Update data') {
            this.removePageFromFolder(res?.folderId, page.id)
            this.addNewPageToFolder(page.folderId, page)
          }
          if(res?.action) {
            this.savePage();
          }
        })
      }
    } else {
      let folder = this.getFolderById(id)
      this.openFolderPanel('updateFolder', folder);
    }
  }

  getPageById(pageId: any){
    for(let i = 0;i<this.templatePage.length;i++) {
      let page = this.templatePage[i];
      if(page.id === pageId) {
        return {page : page, index: i};
      }
    }
    return null;
  }

  folderList: any[] = []
  getAllFolder(){
    let payload = {
      templateId: this.bussinessDetail.templateId
    }
    this.templateService.getAllFolder(payload).subscribe((res: any) => {
      this.folderList = res.data;
    })
  }

  getFolderById(folderId: any){
    for(let i = 0;i<this.folderList.length;i++) {
      let folder = this.folderList[i];
      if(folder.folderId === folderId) {
        return {folder : folder, index: i};
      }
    }
    return null;
  }

  createFolder(){
    this.openFolderPanel('addFolder');
  }

  openFolderPanel(action: 'addFolder' | 'updateFolder', folder?: any){
    if(this.scrWidth > 475){
      let dialog = this.templateService.openDialog(CreateFolderComponent,'', '', folder ? folder : {})
      dialog.afterClosed().subscribe((res: any) => {
        if(res) {
          this.pageAction(action, '', '', 0, null, res)
        }
      })
    }

    if (this.scrWidth < 475){
      let bottomSheet = this.templateService.openBottomSheet(CreateFolderComponent, folder ? folder : {},'bottom-sheet-height')
      bottomSheet.afterDismissed().subscribe((res: any) => {
        if(res) {
          this.pageAction(action, '', '', 0, null, res);
        }
      })
    }

  }

  deletePage(folderId: any, pageId: any){
    // this.removePageFromFolder(folderId, pageId)
    this.pageAction('deletePage', pageId, '', 0, null, null, folderId);
  }

  deleteFolder(folderId: any){
    this.warningPopup().subscribe((res: any) => {
      if(res.delete) {
        this.templateService.deleteFolder(folderId).subscribe((res: any) => {
          let deleteIndex = -1;
          let deletedFolderPage: any = [];

          for(let i = 0;i < this.templatePage[0].components[0].content.navbarButtons.length;i++ ) {
            let navbar = this.templatePage[0].components[0].content.navbarButtons[i]
            if(navbar?.folder && navbar.folder.folderId === folderId) {
              deleteIndex = i;
              deletedFolderPage = navbar.folder.pages;
            }
          }
          if(deleteIndex > -1) {
            this.templatePage[0].components[0].content.navbarButtons.splice(deleteIndex, 1);
            this.removeFolderIdFromPages(deletedFolderPage);
            this.addPageToNavbar(deletedFolderPage);
            this.savePage();
          }

        })
      }
    })
  }

  removeFolderIdFromPages(pages: any){
    for(let navbar of pages) {
      for(let page of this.templatePage) {
        if(navbar.pageId === page.id) {
          page.addToFolder = false;
          page.folderId = null;
        }
      }
    }
  }

  warningPopup() {
    let width= window.innerWidth > 475 ? '400px' : '94%'
    let height= window.innerWidth > 475 ? '268px' : '200px'
    let warningPopupModel = this.templateService.openDialog(
      WarningPopupComponent,
      height,
      width,
      { data: null }
    );

    return warningPopupModel.afterClosed()
  }


  addNewPageToFolder(folderId: any, page: any){
    for(let button of this.templatePage[0].components[0].content.navbarButtons) {
      if(button?.folder && button.folder.folderId === folderId) {
        let newPage = {
          label: page.pageName,
          pageId: page.id,
          redirectionUrl: page.path
        }
        button.folder.pages.push(newPage);
        break;
      }
    }
  }

  removePageFromFolder(folderId: any, pageId: any, addToNavbar?: boolean){
    let removedPage;

    for(let button of this.templatePage[0].components[0].content.navbarButtons) {
      if(button?.folder && button.folder.folderId === folderId) {
        let deleteIndex = -1;
        for(let i = 0;i < button.folder.pages.length;i++) {
          let page = button.folder.pages[i];
          if(page.pageId === pageId) {
            deleteIndex = i;
            removedPage = page;
          }
        }
        if(deleteIndex > -1) {
          button.folder.pages.splice(deleteIndex, 1);
          break;
        }
      }
    }

    if(removedPage && addToNavbar) {
      this.addPageToNavbar([removedPage])
    }
  }

  addPageToNavbar(pageList : any[]){
    for(let page of pageList) {
      let pageNavbar = {
        label : page.label,
        pageId: page.pageId,
        status: false,
        redirectionUrl: page.redirectionUrl,
        showHeader: true
      }
      this.templatePage[0].components[0].content.navbarButtons.push(pageNavbar);
    }
  }

  removePageFromNavbar(pageId: any){
    let deleteIndex = -1;
    for(let i = 0;i < this.templatePage[0].components[0].content.navbarButtons.length;i++) {
      let button = this.templatePage[0].components[0].content.navbarButtons[i];
      if(button.pageId === pageId) {
        deleteIndex = i;
        break;
      }
    }

    if(deleteIndex > -1) {
      this.templatePage[0].components[0].content.navbarButtons.splice(deleteIndex, 1);
    }
  }

  savePage() {
    this.getWebsiteLoader = true;
    this.templateService.saveTemplatePage(this.templatePage).subscribe((res: any) => {
      this.snackBar.open('Folder Updated successfully', 'close', {duration: 1500});
      this.getWebsiteLoader = false;

    }, () => {
      this.getWebsiteLoader = false;
    })
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.navButton, event.previousIndex, event.currentIndex);
  }

  showDeleteOption(pageName: string) {
    return pageName == 'Home' || pageName === 'Product List' || pageName === 'Product Description' || pageName === 'Cart' || pageName === 'Checkout'
      || pageName === 'Wishlist' || pageName === 'User Profile' || pageName === 'Auth' || pageName === 'Payment Verification' || pageName === 'List Category'
  }
}
