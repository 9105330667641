<section class="main-section">
    <div class="header">
        <div class="header-text">{{data.data.edit ? "Edit Call" : "Create Call"}}</div>
        <div class="close-image" (click)="cancel()">
            <img src="../../../../../../../../assets/images/close.svg" alt="" class="close">
        </div>
    </div>
    <hr class="section-divider">
    <div class="input-field-section">
        <div class="input-box">
            <div class="input-label">Type of Call</div>
            <div class="email-remainder">
                <mat-radio-group aria-label="" [(ngModel)]="callDetails.call_variant"
                    (ngModelChange)="changingStatus()">
                    <div class="invoice-info full">
                        <div class="content-box-small">
                            <mat-radio-button color="primary" value="SCHEDULE"></mat-radio-button>
                            <span>Schedule a Call</span>
                        </div>
                        <div class="content-box-small">
                            <mat-radio-button color="primary" value="LOG"></mat-radio-button>
                            <span>Log a Call</span>
                        </div>
                    </div>
                </mat-radio-group>
            </div>

        </div>

        <div class="input-box">
            <div class="input-label">Title <span class="required">*</span></div>
            <mat-form-field class="full-width custom-mat-form-field" appearance="outline">
                <input matInput placeholder="Enter subject" value="" [(ngModel)]="callDetails.title">
            </mat-form-field>
        </div>


        <div class="input-box">
            <div class="input-label">Call Start time <span class="required">*</span></div>
            <div class="invoice-info full">
                <mat-form-field class="email-date custom-mat-form-field custom-mat-form-field-size" appearance="outline">
                    <input matInput [matDatepicker]="picker" placeholder="DD/MM/YYYY" [(ngModel)]="startDate"
                           (ngModelChange)="setScheduleDateTime()" [min]="callDetails.call_variant === 'SCHEDULE' ? minDate : ''"
                           readonly>
                    <mat-datepicker-toggle class="custom-mat-datepicker-field" matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker class="custom-mat-datepicker-field" #picker></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="email-time custom-mat-form-field custom-mat-form-field-size"
                    appearance="outline">
                    <mat-select id="input" placeholder="HH : MM" [(ngModel)]="callStartTime"
                        (ngModelChange)="setScheduleDateTime()">
                        <ng-container *ngFor="let item of timeArray">

                            <mat-option [value]="item.viewValue"

                            (click)="selectTime(item)">

                                {{item.viewValue}}</mat-option>


                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>

        </div>

        <div class="input-box">
            <div class="input-label">Call Type <span class="required">*</span></div>
            <mat-form-field class="full-width custom-mat-form-field" appearance="outline">
                <mat-select [ngClass]="callDetails.call_variant === 'SCHEDULE' ? 'disabled' : ''" id="input"
                    placeholder="" [(ngModel)]="callDetails.callType"
                    [disabled]="callDetails.call_variant === 'SCHEDULE'">
                    <ng-container *ngFor="let item of callTypes">
                        <mat-option [value]="item.value">{{item.viewValue}}</mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>

        <!-- <div class="input-box" *ngIf="callDetails.call_variant == 'LOG'">
            <div class="input-label">Call Duration</div>
            <mat-form-field class="full-width custom-mat-form-field" appearance="outline">
                <input id="input" matInput placeholder="00 min 00 sec" value="" [(ngModel)]="callDetails.callDuration">
            </mat-form-field>

        </div> -->
        <div class="input-box" *ngIf="callDetails.call_variant == 'LOG'">
            <div class="input-label">Call Duration</div>
            <mat-form-field class="full-width custom-mat-form-field" appearance="outline">
                <input id="input" matInput placeholder="00:00" name="callDuration" #callDuration="ngModel"
                       [(ngModel)]="callDetails.callDuration" pattern="^\d{2}:\d{2}$" required>
               
            </mat-form-field>
        </div>


        <div class="input-box">
            <div class="input-label">Call Status</div>
            <mat-form-field class="full-width custom-mat-form-field" appearance="outline">
                <mat-select id="input" placeholder="" [(ngModel)]="  callDetails.callStatus">
                    <ng-container *ngFor="let item of status">
                        <mat-option [value]="item.value">{{item.viewValue}}</mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="input-box" *ngIf="callDetails.call_variant =='SCHEDULE'">
            <div class="input-label">Reminder</div>
            <mat-form-field class="full-width custom-mat-form-field" appearance="outline">
                <mat-select id="input" placeholder="" [(ngModel)]="  callDetails.remainder">
                    <ng-container *ngFor="let item of hourReminder">
                        <mat-option [value]="item.value">{{item.viewValue}}</mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>


        <div class="input-box">
            <div class="input-label">Call Purpose</div>
            <mat-form-field class="full-width custom-mat-form-field" appearance="outline">
                <mat-select id="input" placeholder="Pick one" [(ngModel)]="callDetails.callPurpose">
                    <ng-container *ngFor="let item of callPurpose">
                        <mat-option [value]="item.value">{{item.viewValue}}</mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="input-box" *ngIf="callDetails.callPurpose === 'OTHERS'">
            <div class="input-label">Other Reason<span class="required">*</span></div>
            <mat-form-field class="full-width custom-mat-form-field" appearance="outline">
                <input matInput placeholder="Enter Reason" value="" [(ngModel)]="callDetails.otherReason">
            </mat-form-field>
        </div>



        <div class="input-box" *ngIf="data.data.type == 'ALL_CALLS'">
            <div class="input-label">select <span class="required">*</span></div>
            <div class="lead-section">
                <mat-form-field class="select form-field-h-40px" appearance="outline" class="lead-dropdown">
                    <mat-select id="input" placeholder="" [(ngModel)]="participantType" (ngModelChange)="getContacts()">
                        <ng-container *ngFor="let item of type">
                            <mat-option [value]="item.value">{{item.viewValue}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="add-email form-field-h-40px" appearance="outline" class="searchsection">
                    <input type="text" placeholder="Search by name" matInput [(ngModel)]="selectedContact"
                        [formControl]="myControl" [matAutocomplete]="auto" (ngModelChange)="getContacts()">
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option.personalInfo.name"
                            (click)="contactSelected(option)">
                            {{option.personalInfo.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>

        <div class="description">
            <div class="input-label">Description</div>
            <textarea name="" placeholder="Enter description here" cols="30" rows="5" [(ngModel)]="callDetails.description"></textarea>
        </div>

        <div class="button-container">
            <div class="cancel-btn" (click)="cancel()">
                Cancel
            </div>
            <ng-container *ngIf="!addLoader; else addLoading">
                <button class="create-button" (click)="createCall()" *ngIf="!data.data.edit"
                    [disabled]="!callDetails.startDateTime || !callDetails.title || !callStartTime || (data.data.type == 'ALL_CALLS'? !participantType : false ) || callDetails.callPurpose === 'OTHERS' ? !callDetails.otherReason : false ">
                    <span *ngIf="createLoader"><i class="fas fa-spinner fa-spin"></i>&nbsp;Loading</span>
                    <span *ngIf="!createLoader">Create Call</span>
                </button>
            </ng-container>
            <button class="create-button" (click)="editCall()" *ngIf="data.data.edit"
                [disabled]="!callDetails.startDateTime">
                <!-- Update Call -->
                <span *ngIf="updateLoader"><i class="fas fa-spinner fa-spin"></i>&nbsp;Loading</span>
                <span *ngIf="!updateLoader">Update Call</span>
            </button>
        </div>

    </div>
</section>

<ng-template #addLoading>
    <button class="continue">
        <i class="fa fa-spinner fa-spin"></i> &nbsp;Loading
    </button>
</ng-template>