export interface Task {
    name: string,
    priority: PRIORITY,
    dueDate: string,
    reminderDate: string,
    tasksRepeat: TaskRepeat | null,
    notify: NOTIFY,
    taskStatus: TASK_STATUS,
    description: string,
    parentId: string,
    parentName: string,
    createdById: string,
    createdByName: string,
    assignedTo: string,
    assignToMailId: string,
    assignedName: string,
    businessId: string
}

export interface TaskRepeat {
    startRemainder: string,
    endRemainder: string,
    taskRepeat: TASK_REPEAT,
    taskEndOn: TASK_END_ON,
    repeatCount: number,
    endsOn: string,
    taskStatus: TASK_STATUS | null
  }

export enum PRIORITY {
    "HIGH"="HIGH",
    "MEDIUM"="MEDIUM",
    "LOW"="LOW"
};

export enum TASK_REPEAT {
    "DAILY"="DAILY",
    "WEEKLY"="WEEKLY",
    "MONTHLY"="MONTHLY",
    "YEARLY"="YEARLY"
}

export enum TASK_END_ON {
    "NEVER"="NEVER",
    "AFTER_N_TIME"="AFTER_N_TIME",
    "ON_DATE"="ON_DATE"
}

export enum TASK_STATUS {
    "NEW"="NEW",
    "NOT_STARTED"="NOT_STARTED",
    "IN_PROGRESS"="IN_PROGRESS",
    "COMPLETED"="COMPLETED",
    "WAITING_FOR_INPUT"="WAITING_FOR_INPUT",
    "CANCELLED"="CANCELLED"
}

export enum NOTIFY {
    "EMAIL"="EMAIL",
    "POPUP"="POPUP",
    "BOTH"="BOTH"
}