<section class="main-section">
  <div class="header">
    <div class="header-text">
      Social Link
    </div>
    <mat-icon class="cursor-pointer" (click)="closeDialog()">close</mat-icon>
  </div>

  <hr class="divider">

  <div class="body-section">
    <label class="input-label">Type</label>
    <mat-form-field appearance="outline" style="width: 100%;">
      <mat-select [(ngModel)]="socialLink.type">
        <ng-container *ngFor="let item of channels">
          <mat-option [value]="item.type">{{item.type}}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="input-field">
    <label for="">Link</label>
    <div class="input-container">
      <!-- <div class="link">
        {{ socialLink.type === 'Facebook' ? "facebook.com/" : '' }}
      </div> -->
      <input type="text" [(ngModel)]="socialLink.link">
    </div>
  </div>

  <hr class="divider">

  <div class="button-parent" *ngIf="data.newItem">
    <button (click)="closeDialog()" class="discard-button">Discard</button>
    <button (click)="addNewChannel()" [disabled]="!socialLink.link || !socialLink.type" class="save-button">Save</button>
  </div>
</section>
