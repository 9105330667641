<div class="mainSection">
    <div class="headContainer">
        <div class="heading" [ngStyle]="heading.style?heading.style:{}">{{heading.value}}</div>
        <div [ngStyle]="description.style?description.style:{}">{{description.value}}</div>
    </div>
    <div class="imageAndContentContainer">
        <div class="row">
            <ng-container *ngFor="let blog of blogData;let i=index">
                <ng-container *ngIf="i<=2">
                    <div class="image1Contianer imageContentProperties" (click)="viewBlogPage(blog)">
                        <img class="imageAdjustment" [ngStyle]="image1.style?image1.style:{}" src="{{blog.blocks[0].type=='image'?blog.blocks[0].data.file.url:image1.imgUrl}}" alt="">
                        <div class="dateAndGrowth">
                            <!-- <div [ngStyle]="growth.style?growth.style:{}">{{growth.value}}</div> -->
                            <div [ngStyle]="dateOfImage.style?dateOfImage.style:{}">{{blog?.createdTimeStamp|date:'mediumDate'}}</div>
                        </div>
                        <div class="imageContent" [ngStyle]="image1Content.style?image1Content.style:{}">{{blog?.postTitle}}</div>
                    </div>
                </ng-container>
            </ng-container>
            
          
            <!-- <div class="image1Contianer imageContentProperties">
                <img class="imageAdjustment" [ngStyle]="image2.style?image2.style:{}" src="{{image2.imgUrl}}" alt="">
                <div class="dateAndGrowth">
                    <div [ngStyle]="growth.style?growth.style:{}">{{growth.value}}</div>
                    <div [ngStyle]="dateOfImage.style?dateOfImage.style:{}">{{dateOfImage.value}}</div>
                </div>
                <div class="imageContent" [ngStyle]="image2Content.style?image2Content.style:{}">{{image2Content.value}}</div>
            </div>
            <div class="image1Contianer imageContentProperties">
                <img class="imageAdjustment" [ngStyle]="image3.style?image3.style:{}" src="{{image3.imgUrl}}" alt="">
                <div class="dateAndGrowth">
                    <div [ngStyle]="growth.style?growth.style:{}">{{growth.value}}</div>
                    <div [ngStyle]="dateOfImage.style?dateOfImage.style:{}">{{dateOfImage.value}}</div>
                </div>
                <div class="imageContent" [ngStyle]="image3Content.style?image3Content.style:{}">{{image3Content.value}}</div>
            </div> -->
            <!-- <div class="image1Contianer imageContentProperties">
                <img class="imageAdjustment" [ngStyle]="image4.style?image4.style:{}" src="{{image4.imgUrl}}" alt="">
                <div class="dateAndGrowth">
                    <div [ngStyle]="growth.style?growth.style:{}">{{growth.value}}</div>
                    <div [ngStyle]="dateOfImage.style?dateOfImage.style:{}">{{dateOfImage.value}}</div>
                </div>
                <div class="imageContent" [ngStyle]="image4Content.style?image4Content.style:{}">{{image4Content.value}}</div>
            </div>
            <div class="image1Contianer imageContentProperties">
                <img class="imageAdjustment" [ngStyle]="image5.style?image5.style:{}" src="{{image5.imgUrl}}" alt="">
                <div class="dateAndGrowth">
                    <div [ngStyle]="growth.style?growth.style:{}">{{growth.value}}</div>
                    <div [ngStyle]="dateOfImage.style?dateOfImage.style:{}">{{dateOfImage.value}}</div>
                </div>
                <div class="imageContent" [ngStyle]="image5Content.style?image5Content.style:{}">{{image5Content.value}}</div>
            </div>
            <div class="image1Contianer imageContentProperties">
                <img class="imageAdjustment" [ngStyle]="image6.style?image6.style:{}" src="{{image6.imgUrl}}" alt="">
                <div class="dateAndGrowth">
                    <div [ngStyle]="growth.style?growth.style:{}">{{growth.value}}</div>
                    <div [ngStyle]="dateOfImage.style?dateOfImage.style:{}">{{dateOfImage.value}}</div>
                </div>
                <div class="imageContent" [ngStyle]="image6Content.style?image6Content.style:{}">{{image6Content.value}}</div>
            </div> -->
        </div>
    </div>
</div>