import { ChangeDetectorRef, Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ComponentImageUploadService } from 'src/app/services/component-image-upload.service';
import { MediaSelectorComponent } from '../../popups/media-selector/media-selector.component';
import { TemplateService } from 'src/app/services/template.service';

@Component({
  selector: 'app-dynamic-item-popup',
  templateUrl: './dynamic-item-popup.component.html',
  styleUrls: ['./dynamic-item-popup.component.scss']
})
export class DynamicItemPopupComponent implements OnInit{

  constructor(
    public dialogRef: MatDialogRef<DynamicItemPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private imageUploadService:ComponentImageUploadService,
    private cdref: ChangeDetectorRef,
    private templateService: TemplateService,
  ){
    this.getScreenSize();
  }

  edit : boolean = false;
  samepageComops : any;
  public componentData: any;


  ngOnInit(): void {
    if(this.data.edit){
      this.edit = this.data.edit
      this.samepageComops = this.data.samePageComponents;
      this.componentData = this.data.componentData;
      this.data = this.data.item;
    }
    else{
      this.edit = this.data.edit;
      this.data = this.data.newItem;
      if(this.data.hasOwnProperty('image')){
        this.data['image'].attachment = '';
        this.data['image'].lm_title = 'Item Image';
      }
      if(this.data.hasOwnProperty('title')){
        this.data['title'].value = '';
        this.data['title'].lm_title = 'Item Title';
      }
      if(this.data.hasOwnProperty('description')){
        this.data['description'].value = '';
        this.data['description'].lm_title = 'Item Description';

      }
      if(this.data.hasOwnProperty('number')){
        this.data['number'].value = '';
        this.data['number'].lm_title = 'Item Number';
      }
    }
    this.checkingBackgroundColor();

  }


  checkingBackgroundColor(){
    if(this.data.hasOwnProperty('bgColor')){
    }
    else{
        this.data.bgColor = ''
    }
      }


  close(){
    this.dialogRef.close({data:null});
  }
  pageLoader:boolean=false;
  addImage(){
    if(this.srcWidth > 475){
    this.imageUploadingLoader = true;
    // let elementId = document.getElementById('browse_img');
    // if(!elementId) return;
    // elementId.click();
    // this.imageUploadingLoader = false;
    let dialog = this.templateService.openDialogWithData(
      MediaSelectorComponent,
      '90vh',
      '50%',
      {multiple:false}
  );
  dialog.afterClosed().subscribe(
    (res:any)=>{
      // this.profileImg = res[0].assets[0].url;
      // this.data.attachment = this.profileImg;
      console.log(res);
      this.imageUploadingLoader = false;
      this.data['image'].attachment=res[0].assets[0].url;


  }
  )
}
if(this.srcWidth < 475){
  this.imageUploadingLoader = true;
  // let elementId = document.getElementById('browse_img');
  // if(!elementId) return;
  // elementId.click();
  // this.imageUploadingLoader = false;
  let dialog = this.templateService.openDialogWithData(
    MediaSelectorComponent,
    '100vh',
    '100%',
    {multiple:false}
);
dialog.afterClosed().subscribe(
  (res:any)=>{
    // this.profileImg = res[0].assets[0].url;
    // this.data.attachment = this.profileImg;
    console.log(res);
    this.imageUploadingLoader = false;
    this.data['image'].attachment=res[0].assets[0].url;


}
)
}
}
  file : any ;
  profileImg : any = '';
  imageUploadingLoader : boolean = false;

  async updateImage(ev:any){
    this.imageUploadingLoader = true;
    const file = ev.srcElement.files[0];
    this.profileImg = await this.imageUploadService.uploadComponentsImages(file);
    this.profileImg = this.profileImg.Location;
    this.data['image'].attachment = this.profileImg;
    this.imageUploadingLoader = false
  }

  updateList(){
    this.dialogRef.close({data:this.data});
  }

  getWebsiteLoader: boolean = true;
  srcHeight: any;
  srcWidth: any;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.srcHeight = window.innerHeight;
    this.srcWidth = window.innerWidth;
    ////console.log(this.scrHeight, this.scrWidth);
  }

  changeTextColor(){
    let textColor = this.getTextColor(this.data.bgColor)

    if(this.data.bgTransparent){
      return;
    }

    for (const key in this.data) {
      if (this.data[key].color && !key.includes("button")) {
        this.data[key].color = textColor;
      }
    }
  }

  getTextColor(bgColor:any){
    const threshold = 130; // Adjust this threshold as needed
    const r = parseInt(bgColor.slice(1, 3), 16);
    const g = parseInt(bgColor.slice(3, 5), 16);
    const b = parseInt(bgColor.slice(5, 7), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    return brightness > threshold ? '#000000' : '#ffffff';
  }

}
