export const customElements = [
    {
        "webComponentName":"lm_text",
        "styles":{
            "title":{
                "color":"#000000",
                "font-size":"18px",
                "font-weight":"500",
                "line-height":"40px",
                "value":"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever.",
                "lm_title":"Heading",
                "lm_type":"TEXT_TITLE"
            },
            "option":{
                "bgColor":true,
                "bgImage":false
            },
            "animation" : {
                "type":"Zoom",
                "speed":"Fast",
                "lm_type":"animation"
            }
        },
        "image":{
            "imageUrl":"./../../../../../../../assets/images/custom-elements/only_text.PNG",
            "altName":"Only Text"
        },
        "category":{
            "name":"text element"
        },
        "bgColor":null,
        "name":"text element"
    },
    {
        "webComponentName":"lm_single_image",
        "styles":{
            "image":{
                "attachment":'https://dev-beeos.s3.amazonaws.com/prod-images/802820c1677675132169E3.jpg',
                "altName":"banner image",
                "lm_type":"attachment",
                "lm_title":"Banner Image",
                "attachment_type":"IMAGE"
              },
              "option":{
                "bgColor":true,
                "bgImage":false
            },
            "animation" : {
                "type":"Zoom",
                "speed":"Fast",
                "lm_type":"animation"
            }

        },
        "image":{
            "imageUrl":"./../../../../../../../assets/images/custom-elements/only_text.PNG",
            "altName":"Only Text"
        },
        "category":{
            "name":"image element"
        },
        "bgColor":null,
        "name":"image element"
    },
    {
        "webComponentName":"lm_multi_image_gallery",
        "styles":{
            "title":{
                "color":"#000000",
                "font-size":"18px",
                "font-weight":"bold",
                "line-height":"40px",
                "value":"My Image Gallery",
                "lm_title":"Heading",
                "lm_type":"TEXT_TITLE"
            },
            "description":{
                "color":"#b6b6b6",
                "font-size":"16px",
                "font-weight":"500",
                "line-height":"26px",
                "value":"All images Covered",
                "lm_title":"Description",
                "lm_type":"TEXT_DESC"
              },
              "option":{
                "bgColor":true,
                "bgImage":false
            },
            "animation" : {
                "type":"Zoom",
                "speed":"Fast",
                "lm_type":"animation"
              }
        },
        "itemStyles":[
            {
                "image":{
                    "attachment":'https://dev-beeos.s3.amazonaws.com/prod-images/802820c1677675132169E3.jpg',
                    "altName":"First Gallery image",
                    "lm_type":"attachment",
                    "lm_title":"First Gallery Image",
                    "attachment_type":"IMAGE"
                  }
            },
            {
                "image":{
                    "altName":"Second Gallery image",
                    "attachment":"https://dev-beeos.s3.amazonaws.com/prod-images/201691c1683640760717car-mechanic-sanding-car-door-in-repair-garage-2022-03-04-01-55-05-utc-min.jpg",
                    "attachment_type":"IMAGE",
                    "lm_title":"Second Gallery Image",
                    "lm_type": "attachment"
                }
            },
            {
                "image":{
                    "altName":"Third Gallery image",
                    "attachment":"https://dev-beeos.s3.amazonaws.com/prod-images/355690c1680948563755a%20%287%29.jpg",
                    "attachment_type":"IMAGE",
                    "lm_title":"Third Gallery Image",
                    "lm_type": "attachment"
                }
            },
            {
                "image":{
                    "altName":"Second Gallery image",
                    "attachment":"https://dev-beeos.s3.amazonaws.com/prod-images/504100c1680948452183a%20%283%29.jpg",
                    "attachment_type":"IMAGE",
                    "lm_title":"Fourth Gallery Image",
                    "lm_type": "attachment"
                }
            }
        ],
        "image":{
            "imageUrl":"./../../../../../../../assets/images/custom-elements/only_text.PNG",
            "altName":"Only Text"
        },
        "category":{
            "name":"multi image gallery element"
        },
        "bgColor":null,
        "name":"multi image gallery element"
    },
]