import { ANIMATION_SPEED, BUTTON_SHAPE, BUTTON_STYLE, STYLE_THEME } from "../enums/website.enum";

export interface COMPONENT_STYLE {
    readonly bgColor: string;
    readonly txtSectionTitleColor: string;
    readonly txtHeadingColor: string;
    readonly txtDescColor: string;
    readonly txtNormalColor: string;
    readonly accentColor: string;
}

export class PageTheme {
    private themeId: string | undefined;
    readonly themeName: string | undefined;
    readonly styleTheme: STYLE_THEME | undefined;
    readonly buttonStyle: BUTTON_STYLE | undefined;
    readonly buttonShape: BUTTON_SHAPE | undefined;
    readonly animationSpeed: ANIMATION_SPEED | undefined;
    readonly componentStyle: COMPONENT_STYLE[] | undefined;
    headingFontFamily: string[] | undefined;
    bodyFontFamily: string[] | undefined;

    constructor(json) {
        this.themeId = json["id"];
        this.themeName = json["name"];
        this.styleTheme = json["styleTheme"];
        this.buttonShape = json["btnShape"];
        this.buttonStyle = json["btnStyle"];
        this.animationSpeed = json["animationSpeed"];
        this.headingFontFamily = json["headingFonts"][0];
        this.bodyFontFamily = json["bodyFonts"][0];
        this.componentStyle = json["componentStyles"];
    }

    
    public set websiteThemeId(themeId : string) {
        this.themeId = themeId;
    }
    public get getWebsiteThemeId(): string | undefined {
        return this.themeId;
    }
}