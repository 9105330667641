import { Component, HostListener, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DbService } from 'src/app/services/db-service/db.service';
import { PaymentsService } from 'src/app/services/payment_apis/payments.service';
import { OurContactUsComponent } from '../components/our-contact-us/our-contact-us.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss' , './mq-pricing.component.scss']
})
export class PricingComponent implements OnInit{

  constructor(
    private _paymentService : PaymentsService,
    private _dbService : DbService,
    private _router : Router,
    private snackBar : MatSnackBar,
    private title:Title,
    private metaTagService: Meta
  ){
    this.getScreenSize();
  }

  allPlans : any = [];

  ngOnInit(): void {
    // this.getAllPlans();
    this.title.setTitle('Pricing');
    this.metaTagService.updateTag({name:'description',content:'Our pricing is very affordable to all the businesses'})
    this.getAllCountries()
  }
  isAnually : boolean = true;
  twoButtons = [
    {
      name:'monthly',
      status:false,
      value:'MONTHLY'
    },
    {
      name:'yearly',
      status: true,
      value:'ANNUALLY'
    }
  ];

  testimonials = [
    {
      testimonialImage:"./../../../assets/images/pricing/testimonial_1.svg",
      review:"I'm amazed at how easy it was to create a professional website with Simpo.ai. It has truly transformed my online presence.",
      reviewerImage:"./../../../assets/images/pricing/reviewer_1.svg",
      reviwerName:"Aravind Kumar"
    },
    {
      testimonialImage:"./../../../assets/images/pricing/testimonial_2.svg",
      review:"Simpo.ai transformed our construction website. It's easy to use, and the results are impressive. We've received positive feedback and increased client inquiries.",
      reviewerImage:"./../../../assets/images/pricing/reviewer_2.svg",
      reviwerName:"Vishnu"
    },
    {
      testimonialImage:"./../../../assets/images/pricing/testimonial_3.svg",
      review:"Simpo.ai exceeded my expectations. I was able to customize my website exactly the way I wanted, and it has had a positive impact on my business growth.",
      reviewerImage:"./../../../assets/images/pricing/reviewer_3.svg",
      reviwerName:"Simran Pandey"
    }
  ];
  faqQuestions = [
    {
      question:"Can I upgrade from the Starter plan to the Business Pro plan?",
      answer:"Yes, you can easily upgrade from the Starter plan to the Business Pro plan within your account settings."
    },
    {
      question:"Are there any additional fees for domain registration or hosting?",
      answer:"The subscription cost includes domain registration and hosting with no additional fees."
    },
    {
      question:"Is there a dedicated customer support team available?",
      answer:"Yes, we have a dedicated customer support team available to assist you with any questions or issues you may have."
    },
    {
      question:"Can I switch between the Starter plan and the Business Pro plan?",
      answer:"Yes, you can switch between plans at any time to accommodate your evolving needs."
    },
    {
      question:"Do you offer a custom plan tailored to specific business requirements?",
      answer:"Currently, we offer the predefined Starter and Business Pro plans. However, we are open to discussing custom solutions for unique business needs. Please reach out to our sales team for further assistance."
    },
    {
      question:"Can I use my own custom domain name with both the Starter and Business Pro plans?",
      answer:"You can use your own custom domain name with the Business Pro plan. This feature is specifically available in the Business Pro plan to provide a professional and branded online presence."
    },
    {
      question:"Are there any setup or activation fees associated with subscribing to Simpo.ai?",
      answer:"No, there are no setup or activation fees. The subscription cost is based on the chosen plan and billing cycle."
    }
  ];
  expandedIndex = 0;
  
  createLoader : boolean = false;
  allCountries : any = [];
  selectedCountry : any;
  getAllCountries(){
    this.createLoader = true;
    this._paymentService.getAllCountries().subscribe(
      (res:any) => {
        res.data.data.forEach(country => {
          if(country.currency === 'INR'){
            this.selectedCountry = country
          }
        });
        this.allCountries = res.data.data;
        this.getAllPlans(this.selectedCountry.id)
      },
      (err) => {
        this.snackBar.open(
          `${err.error.message}`,
          'Close',
          {
            duration:1500
          }
        )
      }
    )
  }
  getAllPlans(countryId){
    this.createLoader = true;
    this._paymentService.getAllPlans(countryId).subscribe(
      (res:any) => {
        console.log(res.data);
        this.allPlans = res.data
        this.createLoader = false;
      },
      (err) => {
        this.snackBar.open(
          `${err.error.message}`,
          'Close',
          {
            duration:1500
          }
        )
      }
    )
    // this._paymentService.getAllPlans().subscribe(
    //   (res:any) => {
    //     console.log(res.data);
        
    //     this.allPlans = res.data;
    //   }
    // )
  }

  planChange(a:any){
    this.twoButtons.forEach(tb => {
      if(a === tb.value){
        tb.status = true;
      }
      else{
        tb.status = false;
      }
    });
    if(a === 'ANNUALLY'){
      this.isAnually = true;
    }
    else{
      this.isAnually = false;
    }
  }

  selectedPlan(id,price){
    console.log(id,this.isAnually);

    // let data ={
    //   bId:'1eddd077-efb5-6b04-9532-83e6506e4b48',
    //   subscriptionId:id,
    //   isMonthly:!this.isAnually
    // }
    // this._paymentService.subscribePlan(data).subscribe(
    //   (res:any) => {
    //     window.location.href = `${res.data.url}`
    //     this._dbService.setPaymentId(res.data.paymentId);
    //   },
    //   (err) => {
    //     console.log(err.error)
    //   }
    // )
    if(this._dbService.getBusinessId() === null){
      this._router.navigate(['/business-type']);
      this._dbService.setOrderId(`${id}`);
      this._dbService.setOrderPrice(`${price}`);
      this._dbService.setAnuallyOrNot(!this.isAnually);
    }
    else{
    }
  }

  generateWebsite(){
    this._router.navigate(['/business-type'])    
  }
  changeCountry(ev){
    this.getAllPlans(ev.value.id);    
  }

  srcHeight:any;
  srcWidth:any;
  
  @HostListener('window:resize', ['$event'])
    getScreenSize(event? : any) {
      this.srcHeight = window.innerHeight;
      this.srcWidth = window.innerWidth;
      // console.log(this.scrHeight, this.scrWidth);
    }


  contactUs(){
    if(this.srcWidth > 475){
      this._paymentService.openDialog(
            OurContactUsComponent,
            'auto',
            '25%',
            {}
      )
    }
    else{
      this._paymentService.openDialog(
        OurContactUsComponent,
        'auto',
        '100%',
        {}
      )
    }
  }
}
