<div class="main_section">
    <div class="sticky-md-top bg-white ">
        <div class="d-flex justify-content-between">
            <div class="fs-6 fw-bold">Add Section</div>
            <div class="cursor-pointer" (click)="closePopup()">
                <!-- <img src="../../../../../../assets/images/common/close-img.svg" alt=""> -->
                <mat-icon>close</mat-icon>
            </div>
        </div>
        <hr class="section-divider">
        <div class="d-flex justify-content-between">
                <mat-form-field class="custom-picker domain-input" appearance="outline">
                    <input type="text" class="inputStyle" matInput placeholder="Search" [(ngModel)]="searchComponents" (keyup)="filterComponents()"/>
                    <mat-icon matPrefix>search</mat-icon>
                </mat-form-field>
            <!-- <mat-form-field class="custom-picker domain-input"  appearance="outline">
                <mat-select [(ngModel)]="selectedOption" style="height: 36px;display: flex;align-items: center;font-size: 14px;">
                    <ng-container *ngFor="let option of options">
                        <mat-option [value]="option.value">
                            {{ option.viewValue}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field> -->
        </div>
    </div>
    <!-- Card section -->
    <div *ngIf="!noData" class="bottom_section d-flex justify-content-between flex-wrap gap-3">
      <ng-container *ngFor="let item of filteredComponents">
          <div *ngIf="!componentName(item.sectionName, item.type)" class="card-section d-flex justify-content-center card border cursor-pointer" (click)="selectComponent(item.componentId, item.sectionType)">
              <div class="row w-100" *ngIf="!pageLoader">
                  <div class="col-md-8">
                      <div class="card-body">
                          <div class="card-title fs-6 fw-bold">{{item.sectionName}}</div>
                          <div class="description card-text fs 5 fw-lighter">{{item.desc}}</div>
                          <div class="tag card-text fs-6 fw-light" style="margin-top: 10px;">By Simpo</div>
                      </div>
                  </div>
                  <div class="col-md-4 d-flex justify-content-center align-items-center"  >
                      <img src="{{item.imgUrl}}" class="card-img " style="width: 76px" alt="Banner Image">
                  </div>
              </div>
          </div>
      </ng-container>
  </div>
  <ng-container *ngIf="noData">
    <div class="no-data">
      <img src="https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/246964c1721892428006No%20data.jpg" alt="No data found">
      <div class="no-data-text">
        Oops..No data found!
      </div>
    </div>
  </ng-container>
    <section class="loader_section" *ngIf="pageLoader">
      <div class="api_loader">
          <app-anime-loader></app-anime-loader>
      </div>
    </section>
</div>
<section class="loader_section" *ngIf="loader">
  <div class="api_loader">
      <app-anime-loader></app-anime-loader>
  </div>
</section>
