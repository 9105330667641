import { FacebookAuthProvider } from 'firebase/auth';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgmCoreModule } from '@agm/core';
import { LottieModule } from "ngx-lottie";
import player from "lottie-web";

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConstantMaterialModule } from './material_module/constant-material.module';
import { BusinessNameComponent } from './our-components/business-name/business-name.component';
import { BusinessTypeComponent } from './our-components/business-type/business-type.component';
import { GeneratingWebsiteComponent } from './our-components/generating-website/generating-website.component';
import { HomeComponent } from './our-components/home/home.component';
import { ShareWebsiteComponent } from './our-components/share-website/share-website.component';
import { SigninSignupComponent } from './our-components/signin-signup/signin-signup/signin-signup.component';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from 'src/environments/environment';
import { HomeNavBarComponent } from './our-components/home-nav-bar/home-nav-bar.component';
import { PreviewComponent } from './preview/preview.component';
import { TemplateImagesUploadComponent } from './our-components/template-images-upload/template-images-upload.component';
import { WebsiteGeneratorComponent } from './website-generation/website-generator/website-generator.component';
import { WebsiteModule } from './master-config-components/micro-apps/website/website.module';
import { PrivacyPolicyComponent } from './our-components/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './our-components/terms-conditions/terms-conditions.component';
import { SuccessComponent } from './our-components/strip/success/success.component';
import { CancelComponent } from './our-components/strip/cancel/cancel.component';
import { PricingComponent } from './our-components/pricing/pricing.component';
import { WithoutSignupEditComponent } from './our-components/without-signup-edit/without-signup-edit.component';
import { ForgotPasswordComponent } from './our-components/signin-signup/forgot-password/forgot-password.component';
import { RestPasswordComponent } from './our-components/signin-signup/rest-password/rest-password.component';
import { FeaturesComponent } from './our-components/components/features/features.component';
import { PreviousWorkComponent } from './our-components/components/previous-work/previous-work.component';
import { UpcomingFeaturesComponent } from './our-components/components/upcoming-features/upcoming-features.component';
import { ResponsivenessComponent } from './our-components/components/responsiveness/responsiveness.component';
import { MultiTestimonialComponent } from './our-components/components/multi-testimonial/multi-testimonial.component';
import { CtaActionComponent } from './our-components/components/cta-action/cta-action.component';
import { OurHeroBannerComponent } from './our-components/components/our-hero-banner/our-hero-banner.component';
import { OurTestimonialComponent } from './our-components/components/our-testimonial/our-testimonial.component';
import { OurFooterComponent } from './our-components/components/our-footer/our-footer.component';
import { ListBusinessComponent } from './master-config-components/micro-apps/business/list-business/list-business.component';
import { OurBlogsComponent } from './our-components/components/our-blogs/our-blogs.component';
import { OurContactUsComponent } from './our-components/components/our-contact-us/our-contact-us.component';
import { OurContactUsPageComponent } from './our-components/components/our-contact-us-page/our-contact-us-page.component';
import { V2WebsiteGenerationComponent } from './website-generation/v2-website-generation/v2-website-generation.component';
import { ServicesComponent } from './website-generation/services/services.component';
import { NgSwitcheryModule } from 'angular-switchery-ios';
import { LoaderComponent } from './our-components/loader/loader.component';
import { ChangePasswordComponent } from './our-components/signin-signup/change-password/change-password.component';
import { LazyImgDirective } from './lazy-img-directive.directive';
import { CustomSnackbarComponent } from './helper-components/custom-snackbar/custom-snackbar.component';
import { SocialLoginModule, SocialAuthServiceConfig, GoogleSigninButtonModule } from '@abacritt/angularx-social-login';
import {
  GoogleLoginProvider
} from '@abacritt/angularx-social-login';

import { LottieLoaderComponent } from './shared-module-files/components/lottie-loader/lottie-loader.component';
import { LottiePoppperComponent } from './shared-module-files/components/lottie-poppper/lottie-poppper.component';
import { SingupPopupComponent } from './our-components/signin-signup/singup-popup/singup-popup.component';
import { FormsModule } from '@angular/forms';
import { V2ListInvoiceComponent } from './master-config-components/micro-apps/invoice/v2-list-invoice/v2-list-invoice.component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { ViewInvoiceReceiptComponent } from './website-generation/view-invoice-receipt/view-invoice-receipt.component';
import { GlobalInvoiceDownloadComponent } from './website-generation/global-invoice-download/global-invoice-download.component';
import { GenerateWebsitePopupComponent } from './our-components/generate-website-popup/generate-website-popup.component';
import { V3WebsiteGenerationComponent } from './website-generation/v3-website-generation/v3-website-generation.component';
import { WebsiteTypeComponent } from './our-components/website-type/website-type.component';
import { ListAppsComponent } from './master-config-components/micro-apps/app-registry/list-apps/list-apps.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    GeneratingWebsiteComponent,
    BusinessTypeComponent,
    BusinessNameComponent,
    ShareWebsiteComponent,
    SigninSignupComponent,
    HomeNavBarComponent,
    PreviewComponent,
    TemplateImagesUploadComponent,
    WebsiteGeneratorComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    SuccessComponent,
    CancelComponent,
    PricingComponent,
    WithoutSignupEditComponent,
    ForgotPasswordComponent,
    RestPasswordComponent,
    FeaturesComponent,
    PreviousWorkComponent,
    UpcomingFeaturesComponent,
    ResponsivenessComponent,
    MultiTestimonialComponent,
    CtaActionComponent,
    OurHeroBannerComponent,
    OurTestimonialComponent,
    OurFooterComponent,
    OurBlogsComponent,
    OurContactUsComponent,
    ListBusinessComponent,
    OurContactUsPageComponent,
    V2WebsiteGenerationComponent,
    ServicesComponent,
    LoaderComponent,
    ChangePasswordComponent,
    LazyImgDirective,
    CustomSnackbarComponent,
    LottieLoaderComponent,
    LottiePoppperComponent,
    SingupPopupComponent,
    ViewInvoiceReceiptComponent,
    GlobalInvoiceDownloadComponent,
    GenerateWebsitePopupComponent,
    V3WebsiteGenerationComponent,
    WebsiteTypeComponent,
    // V2ListInvoiceComponent,
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    BrowserModule,
    AppRoutingModule,
    ConstantMaterialModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    NgxBarcodeModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDjOcdDo78v7TuPUrmdX-UxAUjOx5J7KQU',
      libraries: ['places', 'geometry'],
    }),
    WebsiteModule,
    NgSwitcheryModule,
    SocialLoginModule,
    GoogleSigninButtonModule,
    LottieModule.forRoot({ player: playerFactory })
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '675780867172-62em2u33p7vmv616ofqmej9n52tged8v.apps.googleusercontent.com', {oneTapEnabled:false, prompt:'consent'}
            )
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    }

  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
