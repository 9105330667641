import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-nav-bar',
  templateUrl: './home-nav-bar.component.html',
  styleUrls: ['./home-nav-bar.component.scss']
})
export class HomeNavBarComponent implements OnInit{

  constructor(
    private router : Router
  ){
    this.getScreenSize();
  }

  ngOnInit(): void {
  }

  srcHeight:any;
  srcWidth:any;
    
  @HostListener('window:resize', ['$event'])
  getScreenSize(event? : any) {
    this.srcHeight = window.innerHeight;
    this.srcWidth = window.innerWidth;
    ////console.log(this.scrHeight, this.scrWidth);
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    let element = document.querySelector('.navbar') as HTMLElement;
    if (window.pageYOffset > element.clientHeight) {
      element.classList.add('navbar-inverse');
    } else {
      element.classList.remove('navbar-inverse');
    }
  }

  loginPage(){
    this.router.navigate([''])    
  }
  signUp(){
    this.router.navigate(['/signup'])
  }
  redirectPricing(){
    this.router.navigate(['/pricing'])
  }
  redirectContactUs(){
    this.router.navigate(['/contact-us'])
  }
  redirectToBType(){
    this.router.navigate(['/business-type'])
  }
}
