import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { InvoiceServiceService } from 'src/app/master-config-components/micro-apps/invoice/service/invoice-service.service';
import { DbService } from 'src/app/services/db-service/db.service';
import { StorageKeys } from 'src/app/shared-module-files/simpo.constant';
import { GlobalInvoiceDownloadComponent } from '../global-invoice-download/global-invoice-download.component';

@Component({
  selector: 'app-view-invoice-receipt',
  templateUrl: './view-invoice-receipt.component.html',
  styleUrls: ['./view-invoice-receipt.component.scss']
})
export class ViewInvoiceReceiptComponent {

  constructor(
    private _route : ActivatedRoute,
    private snackbar: MatSnackBar,
    private invoiceService: InvoiceServiceService,
    private _dbService : DbService,
  ){

  }
  businessDetails : any;
  businessName : any;
  invoiceData:any;
  invoiceId:any
  ngOnInit(){
    
    this.businessDetails = window.localStorage.getItem('bDetails');
    if(this.businessDetails){
      this.businessDetails = JSON.parse(this.businessDetails);
    }
    console.log(this.businessDetails);
    this.businessName = localStorage.getItem(StorageKeys.BUSINESS_NAME);
    // 
    this._route.queryParams.subscribe(
      (params:any)=>{
        // this.invoiceId = params.invoiceId
        // setTimeout(() => {
          this.getInvoiceList(params.invoiceid);
        // }, 500);
        console.log(params.invoiceid)
      }
    )
  }

getInvoiceList(id){
  this.invoiceService.v2getInvoiceById(id , 'INVOICE').subscribe(
    {
      next:(value:any)=>{
        this.invoiceData = value.data;
      }
    }
  )
}
downloadPDF() {
  let dialog = this.invoiceService.openSocialBrand(
    GlobalInvoiceDownloadComponent,
    '100%',
    '100%',
    {data:this.invoiceData}
  )
  }

}
