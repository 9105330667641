<section class="main_section">
    <div class="top_layer">
        <div class="layer_title">Create Business</div>
        <div class="close_btn" (click)="close()">
            <mat-icon>close</mat-icon>
        </div>
    </div>
    <div class="website-type">
      <div>
        <input type="radio" [value]="'STATIC'" [(ngModel)]="websiteType"> Informational
      </div>
      <div>
        <input type="radio" [value]="'E_COMMERCE'" [(ngModel)]="websiteType"> E-Commerce
      </div>
    </div>
    <div class="bottom_layer">
        <div class="location_change">
            <div class="regenerate_location">
                <div class="label_heading">Location <sup>*</sup></div>
                <mat-form-field class="sfull-width input_card" appearance="outline">
                    <input type="text" placeholder="Search for location" matInput [formControl]="searchBox" [matAutocomplete]="auto2"
                    [(ngModel)]="searchLocation" (ngModelChange)="search()"/>
                    <mat-autocomplete #auto2="matAutocomplete">
                        <mat-option *ngFor="
                            let option of searchResults | async;
                            index as i
                        " [value]="option.displayName" (click)="selectedLocations(option)">
                            {{ option.displayName | titlecase }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>

        <div class="location_change">
            <div class="regenerate_location">
                <div class="label_heading">Industry Type <sup>*</sup></div>
                <mat-form-field class="sfull-width domain-input input_card" appearance="outline" *ngIf="websiteType === 'STATIC'"
                    [ngClass]="{'error-border': !isIndustryTypeSelected && (isBusinessNameClicked || isSuggestedNamesClicked)}">

                    <input type="text" placeholder="Enter Industry type" matInput [formControl]="industrySelectControl"
                        [matAutocomplete]="auto1" [(ngModel)]="bType" />
                    <mat-autocomplete #auto1="matAutocomplete">
                        <mat-option *ngFor="let option of filteredIndustries | async; index as i" [value]="option.name"
                            (click)="selectBusinessType(option)" (keyup.enter)="selectBusinessType(option)">
                            {{ option.name }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field class="sfull-width domain-input input_card" appearance="outline" *ngIf="websiteType === 'E_COMMERCE'">

                  <input type="text" placeholder="Enter Industry type"
                      matInput [(ngModel)]="bType"
                      [formControl]="ecommerceIndustrySelectControl"
                      [matAutocomplete]="auto1"
                  />
                  <mat-autocomplete #auto1="matAutocomplete">
                      <mat-option *ngFor="let option of filteredEcommerceIndustries | async; index as i" [value]="option.name"
                          (click)="selectBusinessType(option)"
                          (keyup.enter)="selectBusinessType(option)"
                          id="searchValue"
                      >
                          {{ option.name | titlecase }}
                      </mat-option>
                  </mat-autocomplete>
              </mat-form-field>
                <!-- <div *ngIf="!isIndustryTypeSelected && (isBusinessNameClicked || isSuggestedNamesClicked)" class="error-text">
                    Please select the industry type
                </div> -->
            </div>
        </div>

        <div class="bottom_section" style="margin-top: 10px; text-align: left; display: flex; flex-wrap: wrap;">
            <div class="taking_input">
                <div class="label_heading">Business Name <sup>*</sup></div>
                <mat-form-field class="sfull-width domain-input input_card" appearance="outline">
                    <input type="text" placeholder="Enter business name" matInput [formControl]="businessNameControl"
                        [(ngModel)]="bName" (focus)="onBusinessNameFocus()" />
                </mat-form-field>
            </div>
            <div class="suggest_businessType">
                Not Sure?
                <span style="color: #0496ff; cursor: pointer; font-weight: 500;"
                    [ngClass]="{'disabled-link': !isIndustryTypeSelected}"
                    (click)="isIndustryTypeSelected ? seeSuggestedNames() : null">
                    See some suggestions
                    <img src="./../../../../../assets/images/common/suggestions_bulb.svg" alt="bulb" />
                </span>
            </div>
        </div>

    </div>
    <div class="bottom_section" *ngIf="suggestedNamesScreen">
        <div class="some_suggesions">Here are some suggestions</div>
        <div class="suggestions"
            style="border-radius: 10px; background-color: white; margin-top: 10px;text-align: left;display: flex;flex-wrap: wrap;">
            <ng-container *ngFor="let item of suggestNames">
                <button class="chips" (click)="clickedSuggestions(item)">{{ item.bName | titlecase}}</button>
            </ng-container>
        </div>
        <div class="enter_own_section">
            <div class="enter_own" (click)="enterOwnBName()">
                <mat-icon>keyboard_backspace</mat-icon>&nbsp;Enter my own
            </div>
            <div class="see_more" (click)="seeMore()">
                <mat-icon>refresh</mat-icon> &nbsp;See more
            </div>
        </div>
    </div>

    <div class="footer_layer">
        <button (click)="generateWebsite()" [ngClass]="{'disabled-btn': !bName || !bType || !searchLocation}">
            <span *ngIf="createBusinessLoader"><i class="fas fa-spinner fa-spin"></i>&nbsp;Loading</span>
            <span *ngIf="!createBusinessLoader">Generate Website</span>
        </button>
    </div>
</section>
