import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PaymentsService } from 'src/app/services/payment_apis/payments.service';

@Component({
  selector: 'app-our-contact-us',
  templateUrl: './our-contact-us.component.html',
  styleUrls: ['./our-contact-us.component.scss']
})
export class OurContactUsComponent implements OnInit{

  constructor(
    public dialogRef: MatDialogRef<OurContactUsComponent>,
    private _paymentService : PaymentsService,
    private snackbar: MatSnackBar,
  ){}

  ngOnInit(){
  }

  fullName : any = '';
  mobileNumber :any = '';
  mailId : any = '';
  message: any = '';
  emailRegex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
  contactBtnLoader : boolean = false;

  close(){
    this.dialogRef.close();
  }

  submit(){
    this.contactBtnLoader = true;
    if(this.emailRegex.test((this.mailId).toLowerCase())){
      let data = {
        businessId:null,
        name:this.fullName,
        email:this.mailId,
        mobileNo:this.mobileNumber,
        message:this.message
      }
      this._paymentService.contactUs(data).subscribe(
        (res:any) => {
          this.snackbar.open(
            'Thank you, our executive will contact you',
            'Ok',
            {
              duration:1500
            }
          );
          this.contactBtnLoader = false;
          this.close();
        },
        (err) => {
          this.contactBtnLoader = false;
          this.snackbar.open(
            `${err.error.message}`,
            'Close',
            {
              duration:1500
            }
          );
        }
      )
    }
    else{
      this.snackbar.open(
        'Please enter valid mail id',
        'Ok',
        {
          duration:1500
        }
      );
      this.contactBtnLoader = false;
    }
  }
}
