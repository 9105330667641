<section class="main_section" *ngIf="screenWidth > 475">
    <div class="header-sectiontop">
        <div class="left-headertop">
            <div class="done-editortext" (click)="backToEditor()">
                <img src="./../../../../../../assets/images/editor/backeditorChnages.svg" alt="backeditorchnages">
            </div>
            <!-- <div class="discard-save" *ngIf="showSave">
                <div class="discard" (click)="initiateDiscardChanges()">
                    <button>Discard</button>
                </div>
                <div class="save-btn" (click)="updatePageData()">
                    <button>Save</button>
                </div>
            </div> -->
        </div>
        <div class="middle-header">
            <div class="page-dropdown whitebackgroundInput">
                <mat-form-field appearance="outline" class="days_dropdown">
                    <mat-select [(ngModel)]="pageSeoslected" [disabled]="showSave">
                        <mat-select-trigger>
                            {{selectedPage}}
                        </mat-select-trigger>
                        <mat-option [ngStyle]="{color: showSave ? 'gray' : 'black'}" [value]="item.id" *ngFor="let item of templatePages">
                            <div class="options" style="width: 100%;display:flex;justify-content: space-between;height: 47px;align-items: center;">
                                <div class="add_page_text" style="width: 70%;" (click)="pageSelectionChange({value:item.id})" (keyup.enter)="pageSelectionChange({value:item.id})">{{item.pageName}}</div>
                                <div style="display: flex;width: 30%;">
                                    <div *ngIf="item.pageName != 'Home'"><mat-icon
                                        (click)="pageDelete(item)">delete</mat-icon></div>
                                    <div (click)="openPageLevelEditor(item.id,item.pageName)"><mat-icon>settings</mat-icon></div>
                                </div>
                            </div>
                        </mat-option>
                        <mat-option value="addPage" (click)="pageSelectionChange({value:'addPage'})" (keyup.enter)="pageSelectionChange({value:'addPage'})">
                            <mat-icon>add</mat-icon>
                            <span>Add Page</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="right-headertop">
            <div class="style-sheet">
                <button id="openWebSiteStyle" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight" style="background-color: transparent; border: none">
                    <img src="./../../../../../../assets/images/editor/theme.svg" alt="style-sheet"
                    mat-raised-button #tooltip="matTooltip" matTooltip="{{'Theme'}}"
                                                matTooltipPosition="above" matTooltipClass="infotooltip"
                                                (click)="tooltip.toggle()">
                </button>
            </div>
            <div class="share-icon">
                <img src="./../../../../../../assets/images/editor/shareicon.svg" alt="shareIcon"
                    (click)="shareWebsite()">
            </div>
            <div class="preview-icon">
                <img src="./../../../../../../assets/images/editor/previewicon.svg" alt="preview-icon"
                    (click)="previewCurrentWebsite()" />
            </div>
            <!-- <div class="save-chnages-btn">
                <button (click)="saveChanges()">
                    <span *ngIf="!savechangesLoader">
                        Save Changes
                    </span>
                    <span *ngIf="savechangesLoader">
                        <i class="fas fa-spinner fa-spin"></i>&nbsp;Loading
                    </span>
                </button>
            </div> -->
        </div>
    </div>
    <div class="bottom_section">
        <div class="bottom_left_section">
            <div class="bottom_website_display_section">
                <ng-template #displayWebsite></ng-template>
            </div>
        </div>
        <div [hidden]="!showEditor" class="bottom_right_section" [style.height]="!collpaseEditor ? '50px' : ''" [ngClass]="{'rightDirection': editorPanelDirection === 'RIGHT', 'leftDirection': editorPanelDirection === 'LEFT'}">
            <div class="headerContainer d-flex justify-content-between w-100">
                <!-- editorPanelDirection = (editorPanelDirection === 'LEFT' ? 'RIGHT' : 'LEFT') -->
                <div class="directionContainer">
                    <!-- <img src="../../../../../../../../assets/images/right-drag.svg" alt="" [style.transform]="editorPanelDirection == 'LEFT' ? 'rotate(180deg)' : ''"> -->
                    <!-- <mat-icon>{{ editorPanelDirection == 'RIGHT' ? 'keyboard_arrow_left' : 'keyboard_arrow_right'}}</mat-icon> -->
                    <span>{{isPageLevelEdit ? templatePages[currentPageIndex].pageName : categoryName}}</span>
                </div>
                <div class="close-moving-icon">
                    <div class="directionContainer-icon" (click)="changePanelDirection(editorPanelDirection)">
                        <img src="../../../../../../../../assets/images/right-drag.svg" alt="" [style.transform]="editorPanelDirection == 'LEFT' ? 'rotate(180deg)' : ''">
                    </div>
                    <div class="collapseContainer cursor-pointer"  (click)="showEditor = false">
                        <!-- <img src="../../../../../../../../assets/images/left_editor.svg" alt="" [style.transform]="collpaseEditor ? 'rotate(90deg)' : 'rotate(270deg)'"> -->
                        <!-- <mat-icon>{{!collpaseEditor ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}}</mat-icon> -->
                        <button>Close</button>
                    </div>
                </div>
            </div>
            <div class="editorContainer" [ngClass]="{'editorContainerCollapse': collpaseEditor}">
                <app-editor-right-side-panel [totalWebsiteData]="templatePages" [allPages]="templatePages"
                    [samePageComponents]="templatePages[currentPageIndex]"></app-editor-right-side-panel>
            </div>
        </div>
    </div>
</section>
<ng-container *ngIf="screenWidth < 475">
    <div class="header-sectiontop">
        <div class="left-headertop">
            <div class="done-editortext" (click)="backToEditor()">
                <img src="./../../../../../../assets/images/common/left_arrow.svg" alt="">
            </div>
        </div>
        <div class="right-headertop">
            <div class="page-dropdown whitebackgroundInput mobile-header" style="width: 37%;border-radius: 5px;"
                (click)="openMobileDropdown()">
                <span style="position: relative;left: 13px;">{{selectedPage?.substring(0, 7)}}</span> <mat-icon
                    class="maticon-dropdown">keyboard_arrow_down</mat-icon>
            </div>
            <div class="style-sheet">
                <!-- <img src="../../../../../../../../assets/images/editor/site-style-mobile.svg"  alt="style-sheet" (click)="openRestyleComponent()"> -->
            </div>
            <div class="share-icon">
                <img src="../../../../../../../../assets/images/editor/site-style-mobile.svg" alt="shareIcon" id="openMobileSiteStyle"
                data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
                aria-controls="offcanvasRight">
            </div>
            <div class="preview-icon">
                <img src="./../../../../../../assets/images/common/eye_icon.svg" alt="preview-icon"
                    (click)="previewCurrentWebsite()" />
            </div>
        </div>
    </div>
    <div class="bottom_section">
        <div class="bottom_left_section">
            <div class="bottom_website_display_section">
                <ng-template #displayWebsite></ng-template>
            </div>
        </div>
    </div>
</ng-container>

<section class="loader_section" *ngIf="getWebsiteLoader">
    <div class="api_loader">
        <!-- <div class="dots-container">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </div> -->
        <app-anime-loader></app-anime-loader>
    </div>
</section>


<!-- Global Restyle -->

<section *ngIf="restyleComponent" [ngClass]="screenWidth > 475 ? 'restyle_component' : 'mobile_restyle_component'">
    <div class="center_component">
        <div class="heading_style">
            <div class="heading_text">Restyle Website</div>
            <div class="cross_icon" (click)="cancelRestyleComponent()"><mat-icon>close</mat-icon></div>
        </div>
        <div class="hr-line"></div>
        <div class="button_styles">
            <div class="heading_text">Button Style</div>
            <div class="different_styles">
                <div class="style">
                    <div class="label_heading">Style</div>
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-select [(ngModel)]="bStyle">
                            <!-- <mat-option [value]="bStyle">--Select--</mat-option> -->
                            <mat-option *ngFor="let item of buttonStyles" [value]="item?.value">
                                {{item?.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="shape">
                    <div class="label_heading">Shape</div>
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-select [(ngModel)]="bShape">
                            <mat-option *ngFor="let item of buttonShape" [value]="item.value">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="button_styles">
            <div class="heading_text">Colors</div>
            <div class="different_styles">
                <div class="style">
                    <div class="label_heading">Brand</div>
                    <div class="color_picking">
                        <input type="color" [value]="btnColor" [(ngModel)]="btnColor" />
                        <span class="color_value">{{btnColor}}</span>
                    </div>
                </div>
                <div class="shape">
                    <div class="label_heading">Text Color</div>
                    <div class="color_picking">
                        <input type="color" [value]="btnTextColor" [(ngModel)]="btnTextColor" />
                        <span class="color_value">{{btnTextColor}}</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="button_styles">
            <div class="heading_text">Fonts</div>
            <div class="text_looks_like">
                <div class="text_showing" [ngStyle]="{'font-family':globalStyles.headingFontFamily['font-family']}">Heading</div>
                <div class="hint" [ngStyle]="{'font-family': globalStyles.bodyFontFamily['font-family']}">Body text will look like this.</div>
            </div>
            <div class="font_family">
                <div class="label_heading">Select a font pair</div>
                <mat-form-field appearance="outline" class="font-sfull-width">
                    <mat-select [(ngModel)]="selectingFontFamily" (ngModelChange)="changeFontFamily()">
                        <mat-option *ngFor="let item of fontFamilies" [value]="item?.value">
                            {{item?.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div> -->
        <div class="btns">
            <button class="cancel_btn" (click)="cancelRestyleComponent()">Cancel</button>
            <button class="restyle_website" (click)="saveGlobalRestyle()">
                <span *ngIf="restyleWebsite"><i class="fas fa-spinner fa-spin"></i>&nbsp;Loading</span>
                <span *ngIf="!restyleWebsite">Restyle website</span>
            </button>
        </div>
    </div>
</section>

<div class="offcanvas" [ngClass]="screenWidth > 475 ? 'offcanvas-end' : 'offcanvas-bottom'" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" style="overflow: scroll;height: auto;">
    <div class="offcanvas-header">
        <h5 id="offcanvasRightLabel" class="primaryText">
            <mat-icon (click)="screenType = 'BASIC'" *ngIf="screenType === 'ADVANCE'" class="cursor-pointer">keyboard_backspace</mat-icon>
            <span style="margin-left:4%">Style</span>
        </h5>
        <!-- <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button> -->
    </div>
    <section *ngIf="screenType === 'BASIC'">
        <div class="offcanvas-body" *ngIf="websiteTheme">
            <!-- <div class="stylePrimary mb-2">{{ websiteTheme.themeName?.replaceAll("_", " ") | titlecase }}</div> -->
            <div class="styleContainer">
                <div class="styleHeading">
                    <div class="stylePrimary">Colors</div>
                    <div class="styleSecondary cursor-pointer" (click)="screenType = 'ADVANCE'">Change</div>
                </div>
                <div class="styleContent palletContainer">
                    <ng-container *ngFor="let componentStyle of websiteTheme.componentStyle; let idx = index">
                        <div class="pallet" [style.backgroundColor]="componentStyle.bgColor">
                            <div class="palletContent">
                                <div class="textColor" [style.backgroundColor]="componentStyle.accentColor"></div>
                                <div class="palletText">{{ textPalletToShow[idx] }}</div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="bottomDivider"></div>
            <div class="styleContainer">
                <div class="styleHeading">
                    <div class="stylePrimary">Button Style</div>
                </div>
                <div class="styleContent">
                    <div class="styleContent d-flex justify-content-between">
                        <div class="subHeadingText">1st Button <span *ngIf="selectedButtonStyle === 'SOLID'" class="selectedTag">(Currently Selected)</span></div>
                        <!-- <div class="styleSecondary">Change</div> -->
                    </div>
                    <button class="fillBtn" [style.backgroundColor]="websiteTheme.componentStyle ? websiteTheme.componentStyle[0].bgColor : ''" (click)="changeWebsiteButton('SOLID')">Style 1</button>
                    <div class="styleContent d-flex justify-content-between">
                        <div class="subHeadingText">2nd Button <span *ngIf="selectedButtonStyle === 'OUTLINE'" class="selectedTag">(Currently Selected)</span></div>
                        <!-- <div class="styleSecondary">Change</div> -->
                    </div>
                    <button class="outlineBtn" [style.borderColor]="websiteTheme.componentStyle ? websiteTheme.componentStyle[0].bgColor : ''"
                        [style.color]="websiteTheme.componentStyle ? websiteTheme.componentStyle[0].bgColor : ''" (click)="changeWebsiteButton('OUTLINE')">Style 2</button>
                </div>
                <div class="bottomDivider"></div>
            </div>
            <div class="styleContainer">
                <div class="styleHeading">
                    <div class="stylePrimary">Rounded Cornor</div>
                </div>
                <div class="styleContent">
                    <div class="descText">Choose the size of rounded cornor for your website {{ buttonShapeList.length }}</div>
                    <mat-form-field appearance="outline" class="input_card">
                        <mat-select [(ngModel)]="selectedButtonShape" (ngModelChange)="changeButtonStyle()">
                            <ng-container *ngFor="let button of buttonShapeList">
                                <mat-option [value]="button">{{button}}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="bottomDivider"></div>
            <div class="styleContainer">
                <div class="styleHeading">
                    <div class="stylePrimary">Heading Font</div>
                </div>
                <div class="styleContent">
                    <div class="descText">Choose Heading Font</div>
                    <mat-form-field appearance="outline" class="input_card">
                        <mat-select [(ngModel)]="websiteTheme.headingFontFamily" (ngModelChange)="selectTheme(websiteTheme)">
                            <ng-container *ngFor="let fontFamily of FontFamilyList">
                                <mat-option [value]="fontFamily.name">
                                    <span [ngStyle]="{'font-family': fontFamily.name}">{{fontFamily.name}}</span>
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="styleContainer">
                <div class="styleHeading">
                    <div class="stylePrimary">Body Font</div>
                </div>
                <div class="styleContent">
                    <div class="descText">Choose Body Font</div>
                    <mat-form-field appearance="outline">
                        <mat-select [(ngModel)]="websiteTheme.bodyFontFamily" (ngModelChange)="selectTheme(websiteTheme)">
                            <ng-container *ngFor="let fontFamily of FontFamilyList">
                                <mat-option [value]="fontFamily.name">
                                    <span [ngStyle]="{'font-family': fontFamily.name}">{{fontFamily.name}}</span>
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="bottomDivider"></div>
            <div class="animation-relateed">
                <div class="styleContainer">
                    <div class="styleHeading">
                        <div class="stylePrimary">Animation</div>
                    </div>
                    <div class="styleContent">
                        <div class="descText">Change an animation style for how website elements appearance</div>
                        <mat-form-field appearance="outline">
                            <mat-select [(ngModel)]="selectedAnimationType">
                                <ng-container *ngFor="let type of animationType">
                                    <mat-option [value]="type.value" (click)="globalAnimationChange('type')">
                                        <span>{{type.value | titlecase}}</span>
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="styleContent">
                        <div class="sub-title">Speed</div>
                        <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedAnimatinoSpeed">
                            <mat-radio-button value="Slow" (change)="globalAnimationChange('speed','Slow')">Slow</mat-radio-button>
                            <mat-radio-button value="Medium" (change)="globalAnimationChange('speed','Medium')">Medium</mat-radio-button>
                            <mat-radio-button value="Fast" (change)="globalAnimationChange('speed','Fast')">Fast</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </div>
            <div class="bottomDivider"></div>
        </div>
    </section>
    <section *ngIf="screenType === 'ADVANCE'" class="advanceColorPallet">
        <ng-container *ngFor="let theme of allThemes">
            <div class="styleContent palletContainer" [ngClass]="{'selectedPalletContainer': theme.getWebsiteThemeId == websiteTheme?.getWebsiteThemeId}" (click)="selectTheme(theme)">
                <ng-container *ngFor="let componentStyle of theme.componentStyle">
                    <div class="pallet" [style.backgroundColor]="componentStyle.bgColor">
                        <div class="palletContent">
                            <div class="textColor" [style.backgroundColor]="componentStyle.txtDescColor"></div>
                            <div class="palletText">Aa</div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </section>
    <div class="offcanvas-footer" style="padding-bottom: 10px;">
        <button class="discardStyling cursor-pointer" data-bs-dismiss="offcanvas" (click)="discardThemeChanges()">Discard</button>
        <button class="applyStyling cursor-pointer" data-bs-dismiss="offcanvas" (click)="applyThemeChangesToWebsite()">Apply Changes</button>
    </div>
</div>
