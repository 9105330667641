<section class="main_section">
    <div class="top_section">
        <div class="top_title">
            Contact Us
        </div>
        <div class="cross_btn" (click)="close()">
            <mat-icon>close</mat-icon>
        </div>
    </div>
    <div class="hr_line"></div>

    <div class="fields business-field-section">
        <div class="single_field">Name<sup>*</sup></div>
        <mat-form-field class="sfull-width" appearance="outline">
            <input placeholder="Ex. Joseph Nair" matInput [(ngModel)]="fullName"/>
        </mat-form-field>
    </div>
    <div class="fields business-field-section">
        <div class="single_field">Mobile Number<sup>*</sup></div>
        <mat-form-field class="sfull-width" appearance="outline">
            <input placeholder="Ex. 98xxxxx12" matInput [(ngModel)]="mobileNumber" type="number"  maxlength="2"
            pattern="^[6-9]\d{9}$|^[1-9]\d{9}$" onKeyPress="if(this.value.length==15) return false;">
        </mat-form-field>
    </div>
    <div class="fields business-field-section">
        <div class="single_field">Email<sup>*</sup></div>
        <mat-form-field class="sfull-width" appearance="outline">
            <input placeholder="Ex. abc@gmail.com" matInput [(ngModel)]="mailId"/>
        </mat-form-field>
    </div>
    <div class="fields business-field-section">
        <div class="single_field">Message</div>
        <mat-form-field class="sfull-width_1" appearance="outline">
            <input placeholder="Comment" matInput [(ngModel)]="message"/>
        </mat-form-field>
    </div>
    <div class="submit_btn">
        <button (click)="submit()" [disabled]="fullName === '' || mailId === '' ||  (mobileNumber.toString().length < 8)">
            <span *ngIf="contactBtnLoader"><i class="fas fa-spinner fa-spin"></i>&nbsp;Loading</span>
            <span *ngIf="!contactBtnLoader">Submit</span>
        </button>
    </div>
</section>