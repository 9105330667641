<section class="container">
  <div class="row">
    <div class="col-lg-6 col-12">
      <img src="../../../assets/images/websiteType.svg" alt="" class="left-img">
    </div>
    <div class="col-lg-6 col-12">
      <img src="../../../assets/images/named-logo.svg" alt="" class="logo">

      <div class="heading">Let’s start creating your website with AI</div>
      <div class="desc">Ready to get personalized webiste? Just answer a few quick questions, and our AI will work its magic!</div>

      <div class="website-type-parent">
        <div (click)="websiteType = 'STATIC'" [ngClass]="{'selected-website': websiteType === 'STATIC'}">
          <!-- <input type="radio" name="website-type"> -->
          <!-- <br> -->
          <span class="type-desc">
            I Want to create a informational business website
          </span>
        </div>
        <div (click)="websiteType = 'E_COMMERCE'" [ngClass]="{'selected-website': websiteType === 'E_COMMERCE'}">
          <!-- <input type="radio" name="website-type" > -->
          <!-- <br> -->
          <span class="type-desc">
            I  Want to create a website with online store
          </span>
        </div>
      </div>

     <button class="next-btn" (click)="next()" [disabled]="!websiteType">
        Next
     </button>

    </div>
  </div>
</section>
