<section class="main_section">
    <div class="image_section">
        <img src="./../../../../../../../../../assets/images/editor/featured_icon.svg" alt="warning_featured_image">
    </div>
    <div class="text_section">
        <div class="title_text">
            Delete {{ sanitizeType(type) || 'page' | lowercase }}
        </div>
        <div class="description_text">
            Are you sure you want to delete <span style="font-weight: 700;">{{ sanitizeType(type) || 'page' | lowercase }}</span>?
        </div>
    </div>
    <div class="btn_section">
        <div class="discard_btn">
            <button (click)="closeDialog(false)">Cancel</button>
        </div>
        <div class="save_changes_btn">
            <button (click)="closeDialog(true)">Delete</button>
        </div>
    </div>
</section>
