<div class="blogs-totalsection" *ngIf="screenWidth > 475">
    <div class="white-backgroud-text">
        <!-- <div class="header">
            <span><img src="./../../../../../../../../assets/images/blogs/left-arrow.svg" alt="left-arrow" (click)="back()" class="left-arrowimage"></span> <span (click)="back()" class="back-text">Back</span>
          </div> -->
        <div class="top-blogview-section">
        <div class="heading-section">
            <div class="right-blogsection">
                {{blog.postTitle}}
            </div>
            <div class="left-blogsection">
                <div class="action-view">
                    <div class="delete-icon">
                        <img src="./../../../../../../../../assets/images/blogs/delete.svg" alt="delete-icon" (click)="deleteBlog()"/>
                    </div>
                    <div class="actionline"></div>
                    <div class="edit-icon">
                        <img src="./../../../../../../../../assets/images/blogs/edit.svg" (click)="editBlog()">
                    </div>
                </div>
                <div class="close-icon">
                    <img src="./../../../../../../../../assets/images/blogs/close-icon.svg" alt="close-icon" (click)="back()"   />
                </div>
            </div>
            </div>
            <div class="blog-info-section">
            <div class="published-section" *ngIf="blog.blogStatus === 'PUBLISH' || blog.blogStatus === 'SCHEDULED'">
                <div class="info-heading">
                    {{ blog.blogStatus === 'PUBLISH' ? 'Published' : 'Scheduled' }} on :
                </div>
                <div class="info-description">
                    <!-- {{ (blog.blogScheduledDate | date:'mediumDate') || 'Not Yet Published' }} -->
                    {{blog.blogScheduledDate | date : 'mediumDate'}}
                    {{ ( blog.blogScheduleTime)  || 'Not Yet Published' }}

                </div>
            </div>
            <div class="published-section" *ngIf="blog.blogStatus === 'SAVE' || !blog.blogStatus">
                <div class="info-heading-draft">
                    Created on :
                </div>
                <div class="info-description-draft">
                    {{ (blog.createdTimeStamp | date:'medium') }} (In Draft)
                </div>
            </div>
            <div class="last-edited-info">
                <div class="info_heading">
                    Last Edit on :
                </div>
                <div class="info_description">
                    {{ (blog.blogScheduledDate | date:'mediumDate') || '' }}
                    {{ ( blog.blogScheduleTime)  || 'Not Yet Published' }}
                </div>
            </div>
            </div>
            <!-- <div class="analytics-section">
            <div class="analytics-headings">
                Analytics
            </div>
            <div class="analytics-blocks">
                <div class="blocks-data">
                <div class="bocks-heading">
                    Visitors
                </div>
                <div class="analytics-count">
                    9300
                </div>
            </div>
                <div class="blocks-data">
                <div class="bocks-heading">
                    Visitors
                </div>
                <div class="analytics-count">
                    9300
                </div>
            </div>
            </div>

            </div> -->
        </div>

        <div class="blog-preview">
            <div class="blog-preview-heading">
                Blog Preview <span></span>
            </div>
            <div class="author-section">
                <div class="post-contentcontainer">
                    <div class="blog_subheading">{{blog.postTitle}}</div>
                    <div class="blog-info">
                            <div class="right-author">
                                <div class="right-author-text">Author : </div>
                                <!-- <div class="author-name-heading">{{blog.author.content ? blog.author.content : 'N/A'}}</div> -->
                                 <div class="author-name-heading"> {{blog.author.content ? blog.author.content : 'N/A'}} </div>
                            </div>
                            <div class="published-on">
                                <div class="right-author-text">Tags :</div>
                                <div class="author-name">{{ blog.keyWords.content || 'N/A' }}</div>
                            </div>
                            <div class="read-time">
                                {{ blog.readTime ? blog.readTime : '0'}} mins read
                        </div>
                    </div>
                </div>
            </div>
            <div class="editorjs-section">
                <div class="container-section">
                    <div *ngFor="let item of blog.blocks" >
                        <div *ngIf="item.type=='header'" >
                        <h1 *ngIf="item.data.level==1">{{item.data.text}}</h1>
                        <h2 *ngIf="item.data.level==2">{{item.data.text}}</h2>
                        <h3 *ngIf="item.data.level==3">{{item.data.text}}</h3>
                        <h4 *ngIf="item.data.level==4">{{item.data.text}}</h4>
                        <h5 *ngIf="item.data.level==5">{{item.data.text}}</h5>
                        <h6 *ngIf="item.data.level==6">{{item.data.text}}</h6>
                        </div>
                        <div *ngIf="item.type=='paragraph'" >
                            <div class="inner-text" [innerHTML]="item.data.text"></div>
                        </div>
                        <div *ngIf="item.type=='list'" >
                            <!-- <div *ngIf="item.data.style=='unordered'">
                                <ng-container *ngFor="let list of item.data.items">
                                    <ul>
                                        <li>{{ list }}</li>
                                    </ul>
                                </ng-container>
                            </div> -->
                            <div *ngIf="item.type=='list'">
                                <div *ngIf="item.data.style=='ordered'">
                                  <ol>
                                    <ng-container *ngFor="let list of item.data.items">
                                      <li>{{ list }}</li>
                                    </ng-container>
                                  </ol>
                                </div>
                              </div>
                        </div>
                        <div *ngIf="item.type=='delimiter'" class="ce-delimiter">
                        </div>
                        <div *ngIf="item.type=='image'" class="image-container">
                                <img [src]="item.data.file.url" alt="">
                        </div>
                        <div *ngIf="item.type=='table'">

                            <table>
                                <ng-container *ngFor="let data of item.data.content">
                                    <tr>
                                        <ng-container *ngFor="let item of data">
                                        <td>{{ item }}</td>
                                        </ng-container>
                                    </tr>
                                </ng-container>
                            </table>

                        </div>
                    </div>
                </div>
                <!-- <div class="configuration-tab">
                <div class="setting-icon">
                    <img src="./../../../../../assets/images/blogs/settings.svg" (click)="filterBtn()" />
                </div>
                </div>  -->
            </div>
        </div>
    </div>

</div>

<div class="blogs-totalsection" *ngIf="screenWidth < 475">
  <div class="white-backgroud-text">
        <div class="top_header">
            <div class="header">
                <span><img src="./../../../../../../../../assets/images/blogs/left-arrow.svg" alt="left-arrow" (click)="back()" class="left-arrowimage"></span> <span (click)="back()" class="back-text">Post Details</span>
              </div>
            <div class="edit-delete">
                <div class="action-view">
                    <div class="edit-icon">
                        <img src="https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/635499c1711739244856Vector.png" (click)="editBlog()">
                    </div>
                </div>
                <div class="action-view">
                  <div class="delete-icon">
                      <img src="https://prod-simpo.s3.ap-south-1.amazonaws.com/prod-images/631744c1711739284642Vector.png" alt="delete-icon" (click)="deleteBlog()"/>
                  </div>
              </div>
            </div>
        </div>
      <div class="top-blogview-section">
        <div class="heading-section">
          <div class="right-blogsection">
            {{blog.postTitle}}
          </div>
          <div class="left-blogsection">
              <!-- <div class="close-icon">
                  <img src="./../../../../../../../../assets/images/blogs/close-icon.svg" alt="close-icon" (click)="back()"   />
              </div> -->
          </div>
        </div>
          <div class="blog-info-section">
          <div class="published-section" *ngIf="blog.blogStatus === 'PUBLISH' || blog.blogStatus === 'SCHEDULED'">
              <div class="info-heading">
                  {{ blog.blogStatus === 'PUBLISH' ? 'Published' : 'Scheduled' }} on :
              </div>
              <div class="info-description">
                  <!-- {{ (blog.blogScheduledDate | date:'mediumDate') || 'Not Yet Published' }} -->
                  {{blog.blogScheduledDate | date : 'mediumDate'}}
                  {{ ( blog.blogScheduleTime)  || 'Not Yet Published' }}

              </div>
          </div>
          <div class="published-section" *ngIf="blog.blogStatus === 'SAVE' || !blog.blogStatus">
              <div class="info-heading-draft">
                  Created on :
              </div>
              <div class="info-description-draft">
                  {{ (blog.createdTimeStamp | date:'medium') }} (In Draft)
              </div>
          </div>
          <div class="last-edited-info">
              <div class="info_heading">
                  Last Edit on :
              </div>
              <div class="info_description">
                  {{ (blog.blogScheduledDate | date:'mediumDate') || '' }}
                  {{ ( blog.blogScheduleTime)  || 'Not Yet Published' }}
              </div>
          </div>
          </div>
          <!-- <div class="analytics-section">
          <div class="analytics-headings">
              Analytics
          </div>
          <div class="analytics-blocks">
              <div class="blocks-data">
              <div class="bocks-heading">
                  Visitors
              </div>
              <div class="analytics-count">
                  9300
              </div>
          </div>
              <div class="blocks-data">
              <div class="bocks-heading">
                  Visitors
              </div>
              <div class="analytics-count">
                  9300
              </div>
          </div>
          </div>

          </div> -->
      </div>

      <div class="blog-preview">
          <div class="blog-preview-heading">
              Blog Preview
              <!-- <span></span> -->
          </div>
          <div class="author-section">
              <div class="post-contentcontainer">
                  <div class="blog_subheading">{{blog.postTitle}}</div>
                  <div class="blog-info">
                          <div class="right-author">
                              <div class="right-author-text">Author : </div>
                              <div class="author-name-heading">{{blog.author.content ? blog.author.content : 'N/A'}}</div>
                          </div>
                          <div class="published-on">
                              <div class="right-author-text">Tags :</div>
                              <div class="author-name">{{ blog.keyWords.content || 'N/A' }}</div>
                          </div>
                          <div class="read-time">
                              {{ blog.readTime ? blog.readTime : '0'}} mins read
                      </div>
                  </div>
              </div>
          </div>
          <div class="editorjs-section">
              <div class="container-section">
                  <div *ngFor="let item of blog.blocks" >
                      <div *ngIf="item.type=='header'" >
                      <h1 *ngIf="item.data.level==1">{{item.data.text}}</h1>
                      <h2 *ngIf="item.data.level==2">{{item.data.text}}</h2>
                      <h3 *ngIf="item.data.level==3">{{item.data.text}}</h3>
                      <h4 *ngIf="item.data.level==4">{{item.data.text}}</h4>
                      <h5 *ngIf="item.data.level==5">{{item.data.text}}</h5>
                      <h6 *ngIf="item.data.level==6">{{item.data.text}}</h6>
                      </div>
                      <div *ngIf="item.type=='paragraph'" >
                          <div class="inner-text" [innerHTML]="item.data.text"></div>
                      </div>
                      <div *ngIf="item.type=='list'" >
                          <!-- <div *ngIf="item.data.style=='unordered'">
                              <ng-container *ngFor="let list of item.data.items">
                                  <ul>
                                      <li>{{ list }}</li>
                                  </ul>
                              </ng-container>
                          </div> -->
                          <div *ngIf="item.type=='list'">
                              <div *ngIf="item.data.style=='ordered'">
                                <ol>
                                  <ng-container *ngFor="let list of item.data.items">
                                    <li>{{ list }}</li>
                                  </ng-container>
                                </ol>
                              </div>
                            </div>
                      </div>
                      <div *ngIf="item.type=='delimiter'" class="ce-delimiter">
                      </div>
                      <div *ngIf="item.type=='image'" class="image-container">
                              <img [src]="item.data.file.url" alt="">
                      </div>
                      <div *ngIf="item.type=='table'">

                          <table>
                              <ng-container *ngFor="let data of item.data.content">
                                  <tr>
                                      <ng-container *ngFor="let item of data">
                                      <td>{{ item }}</td>
                                      </ng-container>
                                  </tr>
                              </ng-container>
                          </table>

                      </div>
                  </div>
              </div>
              <!-- <div class="configuration-tab">
              <div class="setting-icon">
                  <img src="./../../../../../assets/images/blogs/settings.svg" (click)="filterBtn()" />
              </div>
              </div>  -->
          </div>
      </div>
  </div>

</div>
