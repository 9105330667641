import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BannerSectionModel } from 'simpo-component-library/lib/sections/banner-section/banner-section.model';
import { ServiceSectionModal } from 'simpo-component-library/lib/sections/service-section/service-section.model';
import { TextSectionModel } from 'simpo-component-library/lib/sections/text-section/text-section.model';
import { alignmentTabs, animationSpeed, animationTypes, height, spacing, tabs } from './floating-editor.modal';
import { DomSanitizer } from '@angular/platform-browser';
import { EventsService } from 'simpo-component-library';
import { Subscription } from 'rxjs';
import { EventEmmiterService } from 'simpo-components';
@Component({
  selector: 'app-floating-editor',
  templateUrl: './floating-editor.component.html',
  styleUrls: ['./floating-editor.component.scss']
})
export class FloatingEditorComponent implements OnInit, OnChanges {
  @Input() data!: TextSectionModel | BannerSectionModel | ServiceSectionModal;
  @Input() templatePage: any[] = [];
  @Input() currentPageIndex: number = 0;

  // tabs = tabs;
  tabs = [
    { name: 'CONTENT', status: true },
    { name: 'STYLES', status: false }
  ];

  alignmentTabs = alignmentTabs;
  animationTypes = animationTypes;
  animationSpeed = animationSpeed;
  spacing = spacing;
  height = height;
  selecteAnimation: string = animationTypes[0].type;
  selectedTab: string = 'CONTENT';
  Object =  Object;
  carouselBanner : boolean = false;


  constructor(
    public ds: DomSanitizer,
    public eventEmitter: EventsService,
    private eventHelper: EventEmmiterService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.templatePage)
  }

  contentData : any;
  private emiterSubscriber: Subscription | undefined;
  ngOnInit(): void {
    this.emiterSubscriber = this.eventEmitter.editSection.subscribe((res:any) => {
      this.data = res.data;
      this.contentData = this.data.content;
      this.carouselBanner = false;
    });

    this.emiterSubscriber = this.eventHelper.editSection.subscribe((res:any)=>{
      console.log(res.data);
      this.data = res.data;
      this.contentData = this.data.content
      this.selectedTab = 'CONTENT';
      this.carouselBanner = true
    })
  }

  ngOnDestroy(): void {
    this.emiterSubscriber?.unsubscribe();
  }

  tabShift(tab: string) {
    this.tabs.forEach(element => {
      if (element.name === tab) {
        element.status = true;
        this.selectedTab = tab;
      } else {
        element.status = false;
      }
    });
  }

  //background image toggle
  toggleChanged(ev: any) {
    this.data.styles.background.showImage = ev.checked;
    this.eventEmitter.backgroundImageChangeCheck.emit({
      data: this.data.styles.background,
      type:this.data.sectionType
    });
  }

  //alignment tabs
  changeAlignmentTab(item: string) {
    // this.alignmentTabs.forEach(element => {
    //   if (element.name === item) {
    //     element.status = true;
    //     this.data.styles.layout.layoutAlignment?.value = item;
    //     this.eventEmitter.alignmentChangeChecks.emit({
    //       data: this.data.styles.layout,
    //       type:this.data.sectionType
    //     });
    //   } else {
    //     element.status = false;
    //   }
    // });
  }

  changeAnimationType() {
    this.eventEmitter.animationChangeChecks.emit({
      data:this.data.styles.animation,
      type:this.data.sectionType
    });
  }

  changeAnimationSpeed(item: any) {
    this.animationSpeed.forEach(element => {
      if (element.name === item) {
        element.status = true;
        this.data.styles.animation.speed = item;
        this.eventEmitter.animationChangeChecks.emit({
          data:this.data.styles.animation,
          type:this.data.sectionType
        });
      } else {
        element.status = false;
      }
    });
  }

  //spacing Releate functions

  spacingTop() {
    this.eventEmitter.spacingChangeChecks.emit({
      data: this.data.styles.layout,
      type:this.data.sectionType
    });
  }

  changesingleHeightTab(item: any) {
    this.height.forEach(element => {
      if (element.name === item) {
        element.status = true;
        this.data.styles.layout.fit = item;
        this.eventEmitter.spacingChangeChecks.emit({
          data: this.data?.styles.layout,
          type:this.data.sectionType
        });
      } else {
        element.status = false;
      }
    });
  }

  toggleBorder() {
    this.eventEmitter.borderChangeChecks.emit({
      data: this.data?.styles.border,
      type:this.data.sectionType
    });
  }

  atValues = [
    { id: 1, value: 'Fredrik Sundqvist', link: 'https://google.com' },
    { id: 2, value: 'Patrik Sjölin' }
  ];
  hashValues = [
    { id: 3, value: 'Fredrik Sundqvist 2' },
    { id: 4, value: 'Patrik Sjölin 2' }
  ]


  quillConfig={
    //toolbar: '.toolbar',
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['code-block'],
        // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        //[{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        //[{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        //[{ 'direction': 'rtl' }],                         // text direction
        ['link'],
        // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        //[{ 'font': [] }],
        //[{ 'align': [] }],

        // ['clean'],                                         // remove formatting button

        ['link', 'image', 'video']
      ],

    },
    keyboard: {
      bindings: {
        // shiftEnter: {
        //   key: 13,
        //   shiftKey: true,
        //   handler: (range, context) => {
        //     // Handle shift+enter
        //     console.log("shift+enter")
        //   }
        // },
        enter:{
          key:13,
          handler: (range:any, context:any)=>{
            // console.log("enter");
            return true;
          }
        }
      }
    }
  };

    getOriginalObjectStructure(){
    return 0;
  }
}
