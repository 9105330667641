import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BusinessServiceService } from '../services/business-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StorageKeys } from 'src/app/shared-module-files/simpo.constant';

@Component({
  selector: 'app-delete-business',
  templateUrl: './delete-business.component.html',
  styleUrls: ['./delete-business.component.scss']
})
export class DeleteBusinessComponent {
constructor(
  public dialogRef: MatDialogRef<DeleteBusinessComponent>,
  @Inject(MAT_DIALOG_DATA) public data:any,
  private _businessService : BusinessServiceService,
  private _snackBar : MatSnackBar,
){

}
id:any
ngOnInit() {

  this.id = this.data.data.id;
}

deleteLoader : boolean = false;
deleteBusiness(){
  this.deleteLoader = true;
  let payload = {
    businessId: this.id,
    staffId: localStorage.getItem(StorageKeys.STAFF_ID),
    transferAdmin: false
  }
  this._businessService.deleteBusiness(payload).subscribe(
    {
      next:(value:any)=>{
        this._snackBar.open(
          `Business Deleted successfully`, "Close",
          {
            duration :1500
          }
          )
        this.dialogRef.close();
        this.deleteLoader = false;
      },
      error:(err)=>{
        this.deleteLoader = false;
        this._snackBar.open(err.error.message,
          'Close',
          {
            duration:1500
          }
        )
      }
    }
  )
}

closeBtn(){
  this.dialogRef.close();
}

}
