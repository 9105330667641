<section>
    <section class="main_section">
        <div class="left_part">
            <div class="map map_section" id="map"></div>
        </div>
        <div class="right_part">
            <div class="logo" *ngIf="scrWidth > 475px">
                <img src="./../../../assets/images/named-logo.svg" alt="logo">
            </div>
            <div class="bottom_section" *ngIf="!seeSuggestionsScreen">
                <div *ngIf="showAddress != ''" class="location_rel">It looks like you’re in <span class="location" (click)="changeLocation()">&nbsp;{{showAddress}}</span>
                <span class="pin"><img src="./../../../assets/images/map/black_map_pin.svg" alt="map_pin"></span></div>
                <div *ngIf="showAddress === ''" class="location_rel">Where is your business located
                    <span class="pin"
                    (click)="changeLocation()">Select location</span>
                    <!-- &nbsp;or<span class="located_text" (click)="locateMe()">Locate me</span> -->
                </div>
                <div class="title" *ngIf="websiteType === 'STATIC'">What type of business are you building?</div>
                <div class="title" *ngIf="websiteType === 'E_COMMERCE'">What type of store are you building?</div>
                <div class="taking_input">
                    <mat-form-field class="sfull-width domain-input" appearance="outline"  *ngIf="websiteType === 'STATIC'">
                        <mat-icon matPrefix><mat-icon>search</mat-icon></mat-icon>
                        <input type="text" placeholder="Enter business type"
                            matInput
                            [formControl]="industrySelectControl"
                            [matAutocomplete]="auto1"
                        />
                        <mat-autocomplete #auto1="matAutocomplete">
                            <mat-option *ngFor="let option of filteredIndustries | async; index as i" [value]="option.name"
                                (click)="selectBusinessType(option)"
                                (keyup.enter)="selectBusinessType(option)"
                                id="searchValue"
                            >
                                {{ option.name | titlecase }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field class="sfull-width domain-input" appearance="outline" *ngIf="websiteType === 'E_COMMERCE'">
                      <mat-icon matPrefix><mat-icon>search</mat-icon></mat-icon>
                      <input type="text" placeholder="Enter store type"
                          matInput
                          [formControl]="ecommerceIndustrySelectControl"
                          [matAutocomplete]="auto1"
                      />
                      <mat-autocomplete #auto1="matAutocomplete">
                          <mat-option *ngFor="let option of filteredEcommerceIndustries | async; index as i" [value]="option.name"
                              (click)="selectEcommerceBusiness(option)"
                              (keyup.enter)="selectEcommerceBusiness(option)"
                              id="searchValue"
                          >
                              {{ option.name | titlecase }}
                          </mat-option>
                      </mat-autocomplete>
                  </mat-form-field>
                    <div class="next_btn" >
                      <button [disabled]="!industrySelectControl.value" *ngIf="websiteType === 'STATIC'" (click)="goTobName()">Next</button>
                      <button [disabled]="!selectedIndustry.industryId" *ngIf="websiteType === 'E_COMMERCE'" (click)="goTobName()">Next</button>
                    </div>
                </div>
            </div>
            <div class="bottom_section" *ngIf="seeSuggestionsScreen">
                <div class="title">What type of business are you building?</div>
                <div style="margin-top: 8px;">Here are some suggestions</div>
                <!-- <div class="suggestions" style="margin-top: 10px;text-align: left;display: flex;justify-content: space-between;flex-wrap: nowrap;">
                    <ng-container *ngFor="let item of suggestions">
                        <button >{{item.businessCategory}}</button>
                    </ng-container>
                </div> -->
                <!-- <div style="margin-top: 10px;text-align: left;width: 30%;">
                    <span style="color: blue;cursor: pointer;font-size: 15px" (click)="goBack()">Pick my own</span>
                    <span style="color: blue;cursor: pointer;margin-left: 10px;font-size: 15px;" (click)="seeMore(3)">See more</span>
                </div> -->
            </div>
        </div>
    </section>
    <section class="location_box" *ngIf="changeLocationbox">
        <div class="change_location">
            <div class="location_title_cross">
                <div class="location_title">Where is your business located?</div>
                <!-- <div class="cross" (click)="close()"><mat-icon>close</mat-icon></div> -->
            </div>
            <div class="input_icon">
                <div class="location_input">
                    <mat-form-field class="sfull_width" appearance="outline">
                        <mat-icon matPrefix>
                            <mat-icon> place</mat-icon>
                        </mat-icon>
                        <input type="text" placeholder="Search location" matInput [formControl]="searchBox" [matAutocomplete]="auto2"
                        [(ngModel)]="searchLocation" (keyup)="search()"/>
                        <mat-autocomplete #auto2="matAutocomplete">
                            <mat-option *ngFor="
                                let option of searchResults | async;
                                index as i
                            " [value]="option.displayName"
                            (click)="selectedLocations(option)"
                            >
                            {{ option.displayName | titlecase }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="location_icon">
                    <img src="./../../../assets/animations/location_input.svg" alt="location icon">
                </div>
            </div>
            <div class="later_options">
                <div class="info_icon">
                    <img src="./../../../assets/animations/info_icon.svg" alt="info_icon">
                </div>
                <div class="info_text">
                    You can change business location later
                </div>
            </div>
            <div class="save_btn">
                <button (click)="saveLocation()">Next</button>
            </div>
            <div class="later" (click)="close()">
                I’ll add later
            </div>
        </div>
    </section>
</section>
<section *ngIf="loaderSection" class="loader">
    <!-- <img src="./../../../assets/images/common/beeos_loader.gif" alt=""> -->
    <app-lottie-loader></app-lottie-loader>
</section>
