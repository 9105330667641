import { Corners } from "simpo-component-library";

interface type {
  type: string;
}
export const animationType: Array<type> = [
  { type: 'none' },
  { type: 'left' },
  { type: 'top' },
  { type: 'right' },
  { type: 'bottom' },
  { type: 'fadeIn' },
  { type: 'zoom' },
]

export const animationSpeed: Array<type> = [
  { type: 'slow'},
  { type: 'medium'},
  { type: 'fast'},
];

export const corners: Array<type> = [
  {
    type: Corners.Small
  },
  {
    type: Corners.Medium
  },
  {
    type: Corners.Large
  },
  {
    type: Corners.None
  }
]
