// ordinal.utils.ts

export function numberToWords(num: number): string {
    const ones = [
      '', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'
    ];
    const teens = [
      'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 
      'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'
    ];
    const tens = [
      '', 'Ten', 'Twenty', 'Thirty', 'Forty', 'Fifty', 
      'Sixty', 'Seventy', 'Eighty', 'Ninety'
    ];
    const thousands = ['', 'Thousand', 'Million', 'Billion', 'Trillion'];
  
    if (num === 0) return 'Zero';
  
    let words = '';
  
    function getBelowHundred(n: number) {
      if (n < 10) return ones[n];
      if (n < 20) return teens[n - 11];
      const ten = Math.floor(n / 10);
      const one = n % 10;
      return tens[ten] + (one ? ' ' + ones[one] : '');
    }
  
    function convert(num: number, idx: number): string {
      if (num === 0) return '';
      let str = '';
      const hundred = Math.floor(num / 100);
      const rest = num % 100;
      if (hundred) {
        str += ones[hundred] + ' Hundred ';
      }
      if (rest) {
        str += getBelowHundred(rest);
      }
      return str + (str ? ' ' + thousands[idx] : '');
    }
  
    let idx = 0;
    while (num > 0) {
      const chunk = num % 1000;
      if (chunk) {
        words = convert(chunk, idx) + (words ? ' ' + words : '');
      }
      num = Math.floor(num / 1000);
      idx++;
    }
  
    return words.trim();
  }
  
  export function toOrdinalWord(num: number): string {
    const ordinals = ['Zeroth', 'First', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth', 'Seventh', 'Eighth', 'Ninth'];
    const ordinalsTeens = ['Eleventh', 'Twelfth', 'Thirteenth', 'Fourteenth', 'Fifteenth', 'Sixteenth', 'Seventeenth', 'Eighteenth', 'Nineteenth'];
    const ordinalsTens = ['', 'Tenth', 'Twentieth', 'Thirtieth', 'Fortieth', 'Fiftieth', 'Sixtieth', 'Seventieth', 'Eightieth', 'Ninetieth'];
  
    if (num < 10) return ordinals[num];
    if (num < 20) return ordinalsTeens[num - 11];
    if (num < 100) {
      const ten = Math.floor(num / 10);
      const one = num % 10;
      return ordinalsTens[ten] + (one ? '-' + ordinals[one] : '');
    }
  
    const word = numberToWords(num);
    const lastTwoDigits = num % 100;
    const lastDigit = num % 10;
  
    if (lastTwoDigits > 10 && lastTwoDigits < 20) {
      return word + 'th';
    }
    
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const suffix = suffixes[lastDigit] || suffixes[0];
    
    return word + suffix;
  }
  