<div class="main_section">
    <div class="container_section">
        <div class="title_section">
    `       <app-text-element [data] = "data.title"></app-text-element>
        </div>
        <div class="item_description">
            <ng-container *ngFor = "let item of itemData">
                <div class="container">
                    <app-text-element [data] = "item.description"></app-text-element>
                </div>
            </ng-container>
        </div>  
    </div>

</div>