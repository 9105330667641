import { Component, Input, OnInit } from '@angular/core';
import { ElementHelper } from '../../../../elements/elements_helper';

@Component({
  selector: 'app-aboutus-specialization-ourmission',
  templateUrl: './aboutus-specialization-ourmission.component.html',
  styleUrls: ['./aboutus-specialization-ourmission.component.scss']
})
export class AboutusSpecializationOurmissionComponent implements OnInit{
  @Input() responseData : any;
  @Input() edit : boolean | any;
  constructor(
    private _elementHelper : ElementHelper
  ){}
  webComponentName : any  = 'aboutus_specialization_ourmission';
  ngOnInit(): void {
    this.data = this.responseData.styles;
  }

  data : any = {
    "title":this._elementHelper.getTextTitleElement('Specialization','',''),
    "description":this._elementHelper.getTextDescElement('Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi','',''),
      "image":{
      "attachment":"http://paul-themes.com/html/mario/demo/img/6-540x670.jpg",
      "altName":"banner image",
      "lm_type":"attachment",
      "lm_title":"Banner Image",
      "attachment_type":"IMAGE"
    }
  }
}
