import { Component } from '@angular/core';

@Component({
  selector: 'app-custom-button-element',
  templateUrl: './custom-button-element.component.html',
  styleUrls: ['./custom-button-element.component.scss']
})
export class CustomButtonElementComponent {

}
