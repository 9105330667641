import { Component, Input } from '@angular/core';
import { fontSizes } from 'src/app/constants/common-constant';

@Component({
  selector: 'app-ratin-card',
  templateUrl: './ratin-card.component.html',
  styleUrls: ['./ratin-card.component.scss']
})
export class RatinCardComponent {
  
  formatLabel(value: number): string {
    if (value >= 100) {
      return Math.round(value / 100) + 'px';
    }

    return `${value}`;
  }

  fontSizes: any = fontSizes;

  @Input() data: any;
  @Input() dynamicItemHrbar: any;
  @Input() showColor: boolean = true;

  constructor(
  ) {
  }

  ratingRange: any;
  ratingValue: any;
  value: any;
  kk : any;

  ngOnInit() {
    console.log(this.data);
    // this.formatLabel(24)

    this.value = String(this.data?.value);
    this.ratingRange = this.data?.ratingRange;
    this.ratingValue = this.data?.ratingValue;

    if (this.data['font-size'] != undefined) {
      this.fontSizes.forEach(fz => {
        if (fz === Number(this.data['font-size']?.split('px')[0])) {
          this.fontSize = fz
        }
      });
    }
    if (this.value.includes('<br>')) {
      this.value = this.data.value.replace(/<br><br>/g, "\r\n\n");
      this.value = this.data.value.replace(/<br>/g, "\r\n");
    }
    document.getElementById('newLine')?.addEventListener('keydown', (e: any) => {
      if (e.key === 'Enter') {
        let new_line = document.createElement("br");
        new_line.innerText = e.target.value;
        // this.data.value = this.data.value+'<br>'+e.target.value
      }
    })
    if (this.data['font-weight'] == '900') {
      this.boldWeight.status = true;
    }
    if (this.data['font-style'] == 'italic') {
      this.style.status = true;
    }
  }
  fontSize: number | any;

  onChange(ev) {
    // console.log(ev)
    this.data['font-size'] = ev.value + 'px';
  }


  boldWeight: any = {
    name: 'B',
    status: false
  }
  style: any = {
    name: 'I',
    status: false
  }
  fontWeight() {
    this.boldWeight.status = !this.boldWeight.status;
    if (this.boldWeight.status) {
      this.data['font-weight'] = '900';
    }
    else {
      this.data['font-weight'] = '400';
    }
  }
  fontStyle() {
    this.style.status = !this.style.status;
    if (this.style.status) {
      this.data['font-style'] = 'italic';
    }
    else {
      this.data['font-style'] = 'normal'
    }
  }

  // colorChange(){
  //   let elementId = document.getElementById('browse_color');
  //   if(!elementId) return;
  //   elementId.click()
  // }

  increaseTextAreaHeight(ev) {
    let code = ev.keyCode || ev.which;
    this.value = ev.target.value.replace(/\n/g, "<br>");
    this.data.value = this.value;
    this.value = this.data.value.replace(/<br>/g, "\r\n");
  }
}
