<div class="number_input" *ngIf="data.details.inputType === 'number'">
    <div class="label" [ngStyle]="data.title ? data.title : {}">
        {{data.title.value}} <sup *ngIf="data.details.mandatory">*</sup>
    </div>
    <input type="number" [placeholder]="data.details.hint" class="input-field">
</div>
<div class="email_input" *ngIf="data.details.inputType === 'email'">
    <div class="label" [ngStyle]="data.title ? data.title : {}">
        {{data.title.value}} <sup *ngIf="data.details.mandatory">*</sup>
    </div>
    <input type="email" [placeholder]="data.details.hint" class="input-field">
</div>
<data class="text_input" *ngIf="data.details.inputType === 'text'">
    <div class="label" [ngStyle]="data.title ? data.title : {}">
        {{data.title.value}} <sup *ngIf="data.details.mandatory">*</sup>
    </div>
    <input type="text" [placeholder]="data.details.hint" class="input-field">
</data>
<data class="text_input" *ngIf="data.details.inputType === 'textArea'">
    <div class="label" [ngStyle]="data.title ? data.title : {}">
        {{data.title.value}} <sup *ngIf="data.details.mandatory">*</sup>
    </div>
    <textarea type="" rows="6" cols="20" [placeholder]="data.details.hint" class="input-field"></textarea>
</data>