import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, from, of, EMPTY } from 'rxjs';
import { map, concatMap, finalize } from 'rxjs/operators';

import { environment } from './../../../../../environments/environment';
// import { Account } from '@app/_models';
declare const window: any;

@Injectable({ providedIn: 'root' })
export class SocialLoginService {
  private accountSubject: BehaviorSubject<any>;
  public account: Observable<any>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {
    this.accountSubject = new BehaviorSubject<any>(null);
    this.account = this.accountSubject.asObservable();
  }

  public get accountValue(): any {
    return this.accountSubject.value;
  }

  login() {
    // login with facebook then authenticate with the API to get a JWT auth token
    this.facebookLogin()
      .pipe(concatMap(accessToken => this.apiAuthenticate(accessToken)))
      .subscribe(() => {
        // get return url from query parameters or default to home page
        const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
        this.router.navigateByUrl(returnUrl);
      });
  }

  facebookLogin() {
    // login with facebook and return observable with fb access token on success
    return from(new Promise<any>(resolve => window.FB.login(resolve)))
      .pipe(concatMap(({ authResponse }) => {
        if (!authResponse) return EMPTY;
        return of(authResponse);
      }));
  }

  apiAuthenticate(accessToken: string | null) {
    // authenticate with the api using a facebook access token,
    // on success the api returns an account object with a JWT auth token
    return this.http.post<any>(`${environment.baseUrl}/authenticate`, { accessToken })
      .pipe(map(account => {
        this.accountSubject.next(account);
        // this.startAuthenticateTimer();
        return account;
      }));
  }

  checkUserAlreadyLoggedIn() {
    let loggedIn = false;
    window.FB.getLoginStatus(function (response) {
      if (response.status === 'connected') {
        loggedIn = true;
      } else if (response.status === 'not_authorized') {
        loggedIn = true
      } else {
        loggedIn = false;
      }
    });
    return loggedIn;
  }

  logout() {
    // revoke app permissions to logout completely because window.FB.logout() doesn't remove FB cookie
    // window.FB.api('/me/permissions', 'delete', {}, () => window.FB.logout());
    // this.stopAuthenticateTimer();
    // this.accountSubject.next(null);
    // this.router.navigate(['/login']);
    if (!this.checkUserAlreadyLoggedIn()) {
      return;
    }
    window.FB.logout(function (res) {
      console.log(res);
    })
  }

  getAll() {
    return this.http.get<any[]>(environment.baseUrl);
  }

  getById(id: string) {
    return this.http.get<any>(`${environment.baseUrl}/${id}`);
  }

  update(id: string, params: any) {
    return this.http.put(`${environment.baseUrl}/${id}`, params)
      .pipe(map((account: any) => {
        // update the current account if it was updated
        if (account.id === this.accountValue.id) {
          // publish updated account to subscribers
          account = { ...this.accountValue, ...account };
          this.accountSubject.next(account);
        }
        return account;
      }));
  }

  delete(id: string) {
    return this.http.delete(`${environment.baseUrl}/${id}`)
      .pipe(finalize(() => {
        // auto logout if the logged in account was deleted
        if (id === this.accountValue.id)
          this.logout();
      }));
  }

  // helper methods

  private authenticateTimeout: any;

  // private startAuthenticateTimer() {
  //   // parse json object from base64 encoded jwt token
  //   const jwtToken = JSON.parse(atob(this.accountValue.token.split('.')[1]));

  //   // set a timeout to re-authenticate with the api one minute before the token expires
  //   const expires = new Date(jwtToken.exp * 1000);
  //   const timeout = expires.getTime() - Date.now() - (60 * 1000);
  //   const accessToken = window.window.FB.getAuthResponse();
  //   this.authenticateTimeout = setTimeout(() => {
  //     if (!accessToken)
  //       return;
  //     this.apiAuthenticate(accessToken).subscribe();
  //   }, timeout);
  // }

  private stopAuthenticateTimer() {
    // cancel timer for re-authenticating with the api
    clearTimeout(this.authenticateTimeout);
  }
}
