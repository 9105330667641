import { Component, HostListener, Input, OnInit, Inject, Optional, DoCheck } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';
import { TemplateService } from 'src/app/services/template.service';
import { NavbarEditorCardComponent } from '../navbar-editor-card/navbar-editor-card.component';
import { ContactusDetailsEditCardComponent } from '../contactus-details-edit-card/contactus-details-edit-card.component';
import * as _ from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DynamicItemPopupComponent } from '../dynamic-item-popup/dynamic-item-popup.component';

@Component({
  selector: 'app-mobile-editor-right-side-panel',
  templateUrl: './editor-right-side-panel.component.html',
  styleUrls: ['./editor-right-side-panel.component.scss', './mq-editor-right-side-panel.component.scss']
})
export class MobileEditorRightSidePanelComponent implements OnInit {

  @Input() totalWebsiteData: any;
  @Input() allPages: any;
  @Input() samePageComponents: any;
  // @Input() removeTopNavbar: boolean = true;

  pageLevelEditor: boolean = true;
  pageLevelData: any = {};

  constructor(
    private _eventHelper: EventEmmiterService,
    private _tempalteService: TemplateService,
    public dialogRef: MatBottomSheetRef<MobileEditorRightSidePanelComponent>,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private _snackbar: MatSnackBar
  ) {
    this.getScreenSize();
  }

  screenWidth: any;
  @HostListener("window: resize", ["$event"])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
    //console.log(this.screenWidth);
  }

  componentData: any;
  componentIndex: any;
  businessDetails: any;
  dynamicItemSelectedIndex = 0;
  isContactUsSection = false;
  isNavbarSection: boolean = false;
  metaTitle: any = '';
  metaDescription: any = '';
  replicaComponentData: any;
  keywords: any = '';

  // gettingLoader : boolean = true;
  ngOnInit(): void {
    // console.log(this.data);

    this.checkingLinks(this.dynamicItemSelectedIndex);
    if (this.data.pageLevelEdit) {
      this.getPageLevelEdit();
      this.allPages = this.data.allPages;
      this.samePageComponents = this.data.samePageComponents
      window.localStorage.setItem('allPages', JSON.stringify(this.data.allPages));
      window.localStorage.setItem('samePageComponents', JSON.stringify(this.data.samePageComponents));
    }
    else {
      this.getComponentData()
      let allPages = window.localStorage.getItem('allPages')
      if (allPages)
        this.allPages = JSON.parse(allPages)

      let samePageComponents = window.localStorage.getItem('samePageComponents');
      if (samePageComponents)
        this.samePageComponents = JSON.parse(samePageComponents)

      console.log(this.allPages)
      console.log(this.samePageComponents);
    }
    let bDetails = window.localStorage.getItem('bDetails');
    if (bDetails) {
      this.businessDetails = JSON.parse(bDetails);
    }

    console.log("Mobie view " + this.data + " " + this.pageLevelEditor)
  }
  animationType = [
    {
      key: 'Top',
      value: 'Top'
    },
    {
      key: 'Bottom',
      value: 'Bottom'
    },
    {
      key: 'Left',
      value: 'Left'
    },
    {
      key: 'Right',
      value: 'Right'
    },
    {
      key: 'FadeIn',
      value: 'FadeIn'
    },
    {
      key: 'Zoom',
      value: 'Zoom'
    }
  ]
  animationSpeed = [
    {
      key: 'Slow',
      value: 'Slow'
    },
    {
      key: 'Medium',
      value: 'Medium'
    },
    {
      key: 'Fast',
      value: 'Fast'
    }
  ]
  //checking for page level edit
  getPageLevelEdit() {
    if (this.data.pageLevelEdit) {
      this.pageLevelData = this.data.samePageComponents;
      if (this.pageLevelData && this.pageLevelData.seoData != null) {
        this.metaTitle = this.pageLevelData.seoData != null ? this.pageLevelData.seoData.metaTitle : '';
        this.metaDescription = this.pageLevelData.seoData != null ? this.pageLevelData.seoData.metaDescription : '';
        this.keywords = this.pageLevelData.seoData.keywords != null ? this.pageLevelData.seoData.keywords : '';
      }
    }
  }

  //getComopnent data for editor in mobile responsiveness
  getComponentData() {
    this.componentIndex = this.data.index;
    this.componentData = this.data.data;
    this.replicaComponentData = JSON.parse(JSON.stringify(this.componentData));
    this.pageLevelEditor = this.data.pageLevelEdit
    this.samePageComponents = this.data.samePageComponents
    // console.log(res);
    if (this.componentData.category.name.toUpperCase().indexOf("CONTACT") !== -1) {
      this.isContactUsSection = true;
      this.isNavbarSection = false;
    }
    else if (this.componentData.category.name.toUpperCase().indexOf("TOP NAV BAR") !== -1) {
      // console.log('section');
      this.isNavbarSection = true;
      this.isContactUsSection = false;
    }
    else {
      this.isNavbarSection = false;
      this.isContactUsSection = false;
      this.dynamicItemSelectedIndex = 0;
    }
  }

  //checking text type

  returnValue: any;
  getValue(item) {
    if ((item['lm_type']?.split('_')[0]) === 'TEXT') {
      return this.returnValue = 'text';
    }
    else if ((item['lm_type']?.split('_')[0]) === 'BUTTON') {
      return this.returnValue = 'button';
    }
    else if ((item['lm_type']?.split('_')[0]) === 'attachment') {
      return this.returnValue = 'attachment';
    }
    else if (item['lm_type'] === 'animation') {
      return this.returnValue = 'animation'
    } else if (item['lm_type'] === 'background_attachment') {
      return this.returnValue = 'background_attachment';
    }
    else {
      return this.returnValue = '';
    }
    return this.returnValue;
  }


  //get item styles types

  // returnItemStylesValue : any ;
  // imgUrl : any;
  // getItemStylesValues(key){
  //   if((key['lm_type']?.split('_')[0]) === 'attachment'){
  //     this.returnItemStylesValue = 'image';
  //     // this.imgUrl = value.attachment;
  //   }
  // }

  //this for orginal object structure from json(presnt coming like ascending order)
  getOriginalObjectStructure() {
    return 0;
  }
  pageLoader: boolean = false;

  savePageData() {
    let data = {
      id: this.businessDetails.templateId,
      components: this.totalWebsiteData.components
    }
    this._tempalteService.updateWebsiteData(data).subscribe(
      (res: any) => {
        console.log(res.data);
      },
      (err) => {
        console.log(err.error.message)
      }
    )
    // console.log(this.totalWebsiteData);
  }


  // dynamic values related

  //checking links for dynamic items
  checkingLinks(index) {
    if (this.componentData?.itemStyles != null || this.componentData != undefined) {
      let dynamicItems = this.componentData.itemStyles[index];
      Object.keys(dynamicItems).forEach(key => {
        if (this.componentData.itemStyles[this.dynamicItemSelectedIndex][key]['type']) {
          this.buttonLinks.forEach(btl => {
            if (btl.value === this.componentData.itemStyles[this.dynamicItemSelectedIndex][key]['type']) {
              this.buttonRedirectionValue = btl.value
            }
          });
          if (this.buttonRedirectionValue === 'INTERNAL') {
            this.internalPageRedirectionValue = this.componentData.itemStyles[index].links.url;
          }
          else if (this.buttonRedirectionValue === 'EXTERNAL') {
            this.externalPageRedirectionValue = this.componentData.itemStyles[index].links.url;
          }
          else {
            this.samePageRedirectionValue = this.componentData.itemStyles[this.dynamicItemSelectedIndex].links.url
          }
        }
        else {
          this.buttonRedirectionValue = '';
          this.internalPageRedirectionValue = '';
          this.samePageRedirectionValue = '';
          this.externalPageRedirectionValue = '';
        }
      })
    }
  }
  dynamicSelectionChange(ev) {
    this.dynamicItemSelectedIndex = ev.value;
    this.checkingLinks(this.dynamicItemSelectedIndex);
  }

  addNewItem() {
    // let newItem = Object.assign({}, this.componentData.itemStyles[0]);
    // this.componentData.itemStyles.push(newItem);
    let newItem = JSON.parse(JSON.stringify(this.componentData.itemStyles[0]));
    this.componentData.itemStyles.push(newItem)
    this.dynamicItemSelectedIndex = this.componentData.itemStyles.length - 1;
  }

  buttonLinks: any = [
    {
      name: 'external link',
      value: 'EXTERNAL'
    },
    {
      name: 'internal link',
      value: 'INTERNAL'
    },
    {
      name: 'same page',
      value: 'SAME_PAGE'
    }
  ]

  buttonRedirectionValue: any = '';
  internalPageRedirectionValue: any = '';
  samePageRedirectionValue: any = '';
  externalPageRedirectionValue: any = '';

  links: any = {
    type: '',
    url: ''
  }

  buttonRedirections(ev) {
    this.componentData.itemStyles[this.dynamicItemSelectedIndex].links = this.links
    this.componentData.itemStyles[this.dynamicItemSelectedIndex].links.type = ev;
    if (ev === 'INTERNAL') {
      this.componentData.itemStyles[this.dynamicItemSelectedIndex].links.url = this.internalPageRedirectionValue;
    }
    else if (ev === 'EXTERNAL') {
      this.componentData.itemStyles[this.dynamicItemSelectedIndex].links.url = this.externalPageRedirectionValue;
    }
    else {
      this.componentData.itemStyles[this.dynamicItemSelectedIndex].links.url = this.samePageRedirectionValue;
    }
  }

  deleteItem(i) {
    // this.componentData.itemStyles.splice(this.dynamicItemSelectedIndex,1);
    // this.dynamicItemSelectedIndex = this.componentData.itemStyles.length-1
    // this.componentData.itemStyles.splice(i,1)
    if (this.componentData.itemStyles.length === 1) {
      this._snackbar.open(
        "You can't able to delete all items",
        "Ok",
        {
          duration: 1500
        }
      )
    }
    else {
      this.componentData.itemStyles.splice(i, 1)
    }
  }


  //contactus
  deleteContactField(ev, i) {
    // console.log(this.componentData.itemStyles)
    // console.log(i);
    // this.componentData.itemStyles.splice(i,1)
    if (this.componentData.itemStyles.length === 1) {
      this._snackbar.open(
        "You can't able to delete all items",
        "Ok",
        {
          duration: 1500
        }
      )
    }
    else {
      this.componentData.itemStyles.splice(i, 1)
    }
  }

  addContactField() {
    // let newItem = Object.assign({}, this.componentData.itemStyles[0]);
    // this.componentData.itemStyles.push(newItem);
    // console.log(this.componentData.itemStyles)
    let a = JSON.parse(JSON.stringify(this.componentData.itemStyles[0]));
    this.componentData.itemStyles.push(a);
  }

  updateMetaTitle() {
    this.pageLevelData.seoData.metaTitle = this.metaTitle
  }
  updateMetaDescription() {
    this.pageLevelData.seoData.metaDescription = this.metaDescription;
  }
  updateKeywords() {
    this.pageLevelData.seoData.keywords = this.keywords;
  }

  editDynamicItem(item) {
    // console.log(item)
    let editPopupModel = this._tempalteService.openDialog(
      DynamicItemPopupComponent,
      '85vh',
      '460px',
      { item, edit: true, samePageComponents: this.samePageComponents }
    )
  }
  editNavbarDynamicItem(item) {
    let navbarEditModel = this._tempalteService.openDialog(
      NavbarEditorCardComponent,
      '100vh',
      '460px',
      { data: item, allPages: this.allPages, samePageComponents: this.samePageComponents, edit: true }
    )
  }

  addNewNavbarItem() {
    console.log(this.componentData.itemStyles);
    let a = JSON.parse(JSON.stringify(this.componentData.itemStyles[0].item))
    let navbarEditModel = this._tempalteService.openDialog(
      NavbarEditorCardComponent,
      'auto',
      '348px',
      { data: a, allPages: this.allPages, samePageComponents: this.samePageComponents, edit: false }
    )

    navbarEditModel.afterClosed().subscribe(
      (res: any) => {
        if (res.item != null) {
          this.componentData.itemStyles.push(res);
        }
      }
    )
  }

  editLocationDetails(title, value, longitude, latitude) {
    let contactUsDetailsModel = this._tempalteService.openDialog(
      ContactusDetailsEditCardComponent,
      '85vh',
      '460px',
      { data: [title, value, longitude, latitude] }
    )
  }

  editContactDetails(title, value) {
    let contactUsDetailsModel = this._tempalteService.openDialog(
      ContactusDetailsEditCardComponent,
      'auto',
      '460px',
      { data: [title, value] }
    )
  }

  editContactField(item) {

  }

  addContactItem() {

  }

  // save Changes
  applyChanges() {
    this._eventHelper.saveChanges.emit({ saveChanges: true,data:this.componentData,index:this.componentIndex });
    this.dialogRef.dismiss();
  }

  discardChanges() {
    this._eventHelper.localDiscardChanges.emit({data:this.replicaComponentData,discard:true})
    this.dialogRef.dismiss();
    // if (_.isEqual(this.componentData, this.replicaComponentData)) {
    // }
    // else {
    //   this.componentData = this.replicaComponentData;
    //   setTimeout(() => {
    //     this._eventHelper.saveChanges.emit({ saveChanges: true });
    //   }, 500);
    // }
  }
  close(ev) {
    this.dialogRef.dismiss(ev);
  }

  closeDialog(pageName: any, action: String) {
    this.dialogRef.dismiss({ pageName: pageName, action: action });
  }

  pageNameChange() {
    this._eventHelper.pageNameChange.emit({ pageName: this.pageLevelData.pageName });
    this.pageLevelData.path = this.pageLevelData.pageName.split(' ').join('_')
  }

  changeColor() {
    var bgColor = this.componentData.bgColor
    const threshold = 130; // Adjust this threshold as needed
    const r = parseInt(bgColor.slice(1, 3), 16);
    const g = parseInt(bgColor.slice(3, 5), 16);
    const b = parseInt(bgColor.slice(5, 7), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    var color = brightness > threshold ? '#000000' : '#ffffff';
    for (const key in this.componentData.styles) {
      if (this.componentData.styles[key].color) {
        this.componentData.styles[key].color = color
      }
    }

    if (this.componentData?.styles['bgImage']?.backgroundColor) {
      var rgbaValue = this.componentData?.styles['bgImage']?.backgroundColor;
      var colorStr = rgbaValue.slice(rgbaValue.indexOf('(') + 1, rgbaValue.indexOf(')'));
      var colorArr = colorStr.split(',')
      let opacity = colorArr[3];
      let newrgba = 'rgb' + '(' + r + "," + g + "," + b + "," + opacity + ')';
      this.componentData.styles['bgImage'].backgroundColor = newrgba
    }

  }

  openEditor() {
    if (this.data) {
      this.data.isEditor = true;
    }
  }
  changeAnimation() {
    this._eventHelper.changeAnimation.emit({ index: this.componentIndex, newData: this.componentData })
  }
  toggleBackgrondImage(event) {
    this.componentData.styles['option'].bgImage = event
    this.componentData.styles['option'].bgColor = !event;
  }

  // showSaveTab: boolean = false;
  // ngDoCheck() {
  //   // console.log(this.cdr)
  //   if (JSON.stringify(this.componentData) == JSON.stringify(this.replicaComponentData)) {
  //     // console.log('no changes detected');
  //     this.showSaveTab = false;
  //   }
  //   else {
  //     // console.log('changes detected');
  //     this.showSaveTab = true;
  //     return;
  //   }
  // }
}


