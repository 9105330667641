<section class="main_section">
    <section class="options_section">
        <div class="edit-icon">
            <mat-icon>edit</mat-icon> &nbsp;Edit
        </div>
        <div class="domain_publish">
            <div class="domain_url">
                <div class="domain">https://harishvithan.beeos.com</div>
                <div class="custom_domain">Connect custom domain</div>
            </div>
            <div class="published_not">
                &#x2022; Published
            </div>
        </div>
        <div class="preview_monitors">
            <div class="mobile_desktop">
                <div class="monitor" (click)="changeScreen('monitor')">
                    <img src="./../../assets/images/preview_site/monitor.svg" alt="monitor_screen" *ngIf="!monitorScreen">
                    <img src="./../../assets/images/preview_site/monitor_active.svg" alt="active_monitor_screen" *ngIf="monitorScreen">
                </div>
                <div class="hr_line"></div>
                <div class="mobile" (click)="changeScreen('mobile')">
                    <img src="./../../assets/images/preview_site/mobile.svg" alt="mobile_screen" *ngIf="!mobileScreen">
                    <img src="./../../assets/images/preview_site/mobile_active.svg" alt="active_mobile_screen" *ngIf="mobileScreen">
                </div>
            </div>
            <div class="preview_icon">
                <mat-icon>remove_red_eye</mat-icon>
            </div>
        </div>
    </section>
    <section class="mobile_preview_section" *ngIf="mobileScreen" id="mobileScreen">
        <!-- <div>
            <app-topnavbar2></app-topnavbar2>
        </div>
        <div *ngIf="heroBannerComponent">
            <app-hero-banner [data]="heroBanner_ComponentData" id="heroBanner"></app-hero-banner>
        </div> -->
        <!-- <iframe id="iframe_view" class="embed-responsive-item container well well-small span6" style="height: 720px; width: 470px; background-color: #2e2e2e;" src="http://localhost:4201/generating-website?webId=1edb0f14-5c74-6c4b-be4f-a76cac9f6441" frameborder="0" scrolling="no" allowfullscreen></iframe> -->
    </section>
</section>