<div class="total_container">
    <div class="card-section" [ngStyle]="{'background-color': dynamicData?.bgColor}">
        <div class="image-service">
            <img [src]="dynamicData.image.attachment" [alt]="dynamicData.image.altName">
        </div>
        <div class="card-contentsection">
            <div class="heading">
                <app-text-element id="title" [data]="dynamicData.title"></app-text-element>
            </div>
            <div class="description">
                <app-text-element id="description" [data]="dynamicData.description" ></app-text-element>
            </div>
        </div>
    </div>
    <div class="show_btns" *ngIf="all">
        <div class="full_btns">
            <div class="full-edit-section" (click)="editSection()">
                <div class="edit image">
                    <img src="./../../../assets/images/editor/edit_blue.svg" alt="buleditIcon">
                </div>
                <div class="editsection-text">
                    Edit Section
                </div>
            </div>
            <div class="full-edit-section" (click)="addSection()">
                <div class="edit image">
                    <img src="./../../../../../../../../assets/images/editor/plus-icon.svg"
                        alt="regenrate">
                </div>
                <div class="regenerate-text">
                    Add Section
                </div>
            </div>
            <div class="full-edit-sections">
                <div class="all_icons">
                    <div>
                        <img src="./../../../../../../../../../assets/images/editor/arrow-right.svg" alt="uparrow" *ngIf="index === 1">
                        <img src="./../../../../../../../../../assets/images/editor/upArrowEnable.svg" alt="uparrow" *ngIf="index != 1" (click)="positionMoveUp()" class="upward_arrow">
                    </div>
                    <div class="downward_arrow">
                        <img src="./../../../../../../../../../assets/images/editor/down_arrow.svg" alt="downarrow" (click)="positionMoveDown()">
                    </div>
                    <div class="delete_icon">
                        <img src="./../../../../../../../../../assets/images/editor/delete_icon.svg" alt="delete_icon" (click)="deleteComponent()">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>