import { Component,Inject, Optional, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { BlogServiceService } from '../../blog-service.service';

@Component({
  selector: 'app-delete-tags',
  templateUrl: './delete-tags.component.html',
  styleUrls: ['./delete-tags.component.scss']
})
export class DeleteTagsComponent {

  constructor(
    private dialogRef: MatDialogRef<DeleteTagsComponent>,
    private _bottomSheetRef: MatBottomSheetRef<DeleteTagsComponent>,
    @Optional()   @Inject(MAT_DIALOG_DATA) public data,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData: any,
    private blogService : BlogServiceService
  ){
    this.getScreenSize()
  }

  screenWidth: any;

  @HostListener("window:resize",["$event"])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  tagId : any ;
  ngOnInit(){
    if (this.screenWidth > 475){
      this.tagId = this.data.data;
    }
    else {
      this.tagId = this.bottomSheetData.data;
    }
  }

  closeBtn(){
    if (this.screenWidth > 475){
      this.dialogRef.close();
    }

    if (this.screenWidth < 475){
      this._bottomSheetRef.dismiss();
    }
  }

  deleteTag(){
    this.blogService.deleteBlogTag(this.tagId).subscribe({
      next:(res:any)=>{
          this.blogService.openSnack("Tag Deleted sucessfully","Close");
          if (this.screenWidth > 475){
            this.dialogRef.close('success');
          }
          if (this.screenWidth < 475){
            this._bottomSheetRef.dismiss('success');
          }
      },
      error:(err)=>{
        this.blogService.openSnack(err.error.message,"close");
      }
    })
  }
}
