import { AfterViewInit, Directive, ElementRef, OnDestroy } from '@angular/core';

@Directive({
  selector: '[appAppear]'
})
export class AppearDirective implements AfterViewInit, OnDestroy {

  // Directive used to check if element is on screen, if it is, then add class 'visible'

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    const ioCallbackFn: IntersectionObserverCallback = (
      entries: IntersectionObserverEntry[]
    ) => {
      entries.forEach((entry: IntersectionObserverEntry, index: number) => {
        if (
          entry.isIntersecting &&
          !this.elementRef.nativeElement.className.includes('visible')
        ) {
          this.elementRef.nativeElement.classList.add('visible');
          this.observer?.unobserve(this.elementRef.nativeElement);
        }
      });
    };

    const ioOptions: IntersectionObserverInit = {
      root: null,
      rootMargin: '0px',
      threshold: [0.1, 0.2],
    };

    this.observer = new IntersectionObserver(ioCallbackFn, ioOptions);

    this.observer?.observe(this.elementRef.nativeElement);
  }

  observer: IntersectionObserver | undefined;

  ngOnDestroy(): void {
    this.observer?.disconnect();
  }
}