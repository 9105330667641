<div class="header-section">
<div class="headear-text">
    <span class="header-image" (click)="returnHome()"><img src="./.././../../../../assets/images/common/rightArrow.svg" alt="rightArrow"></span>   {{businessName}}
</div>
</div>

<div class="editorjs-section">
    <div class="container-section">
        <div class="post-contentcontainer">
            <h2>{{blogData.postTitle}}</h2>
            <div class="blog-info">
                    <div class="right-author">
                        <div class="right-author-text">Author : </div>
                        <div class="author-name-heading">&nbsp;{{blogData?.author?.content?blogData?.author?.content:'N/A'}}</div>
                    </div>
                    <div class="published-on">
                        <div class="right-author-text">Pubished on :  </div>
                        <div class="author-name">&nbsp;{{blogData.createdTimeStamp|date:'mediumDate'}}</div>
                    </div>
                    <div class="read-time">
                        {{blogData.readTime}}
                </div>
            </div>
        </div>
        
        <div *ngFor="let item of blogData.blocks" >
            <div *ngIf="item.type=='header'" >
            <h1 *ngIf="item.data.level==1">{{item.data.text}}</h1>
            <h2 *ngIf="item.data.level==2">{{item.data.text}}</h2>
            <h3 *ngIf="item.data.level==3">{{item.data.text}}</h3>
            <h4 *ngIf="item.data.level==4">{{item.data.text}}</h4>
            <h5 *ngIf="item.data.level==5">{{item.data.text}}</h5>
            <h6 *ngIf="item.data.level==6">{{item.data.text}}</h6>
            </div>
            <div *ngIf="item.type=='paragraph'" >
                <p [innerHTML]="item.data.text"></p>
            </div>
            <div *ngIf="item.type=='list'" >
                <div *ngIf="item.data.style=='unordered'">
                    <ng-container *ngFor="let list of item.data.items">
                        <ul>
                            <li>{{list}}</li>
                          </ul> 
                    </ng-container>   
                </div>
                <div *ngIf="item.data.style=='ordered'">
                    <ng-container *ngFor="let list of item.data.items">
                        <ol>
                            <li>{{list}}</li>
                          </ol>
                    </ng-container>   
                </div>
            </div>
            <div *ngIf="item.type=='delimiter'" class="ce-delimiter">
            </div>
            <div *ngIf="item.type=='image'" class="image-container">
                    <img [src]="item.data.file.url" alt="">
            </div>
            <div *ngIf="item.type=='table'">
                
                <table>
                    <ng-container *ngFor="let data of item.data.content">
                        <tr>
                            <ng-container *ngFor="let item of data">
                            <td>{{item}}</td>
                        </ng-container> 
                          </tr>
                    </ng-container>       
                  </table>
               
            </div>
            </div>
    </div>
    <div class="left-container-blog" >
        <div class="right-contain-heading">
            More blogs for you
        </div>
        <ng-container *ngFor="let blogSec of blogRightSide">
            <div class="cards-section" (click)="blogRedirection(blogSec)">
                <div class="image-section">
                    <img [src]="blogSec.blocks[0].type=='image'?blogSec.blocks[0].data.file.url:'./../../../../../../../../assets/images/aboutUs_Image.svg'" alt="dummyimage" />
                </div>
                <div class="author-info">
                    <div class="right-author">
                        <div class="right-author-text">Author : </div>
                        <div class="author-name">&nbsp;{{blogSec?.author?.content?blogSec?.author?.content:'N/A'}}</div>
                    </div>
                    <div class="read-time">
                        5 Mins read
                    </div>
                </div>
                <div class="cards-section-post-title">
                    {{blogSec?.postTitle}}
                </div>
            </div>
        </ng-container>
        
        
    </div> 
    <!-- <div class="configuration-tab">
      <div class="setting-icon">
          <img src="./../../../../../assets/images/blogs/settings.svg" (click)="filterBtn()" />
      </div>
    </div>  -->
    </div>