import { Component, HostListener, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ComponentImageUploadService } from 'src/app/services/component-image-upload.service';
import { TemplateService } from 'src/app/services/template.service';
import { MediaSelectorComponent } from '../../popups/media-selector/media-selector.component';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';

@Component({
  selector: 'app-navbar-image-editor-card',
  templateUrl: './navbar-image-editor-card.component.html',
  styleUrls: ['./navbar-image-editor-card.component.scss']
})
export class NavbarImageEditorCardComponent implements OnInit{

  @Input() data : any;
  imageData : any;
  constructor(
    private imageUploadService:ComponentImageUploadService,
    private _formBuilder: FormBuilder,
    private templateService: TemplateService,
    private _eventHelper : EventEmmiterService,
  ){
    this.getScreenSize();
  }

  sliderValue = 50

  selectedLogoStatus : any = 'TEXT_LOGO';

  logoStatus : any = [
    {
      name:'logo',
      value:'LOGO',
      status:false
    },
    {
      name:'text logo',
      value:'TEXT_LOGO',
      status:false
    },
    {
      name:'both',
      value:'BOTH',
      status : false
    }
  ]

  ngOnInit(): void {
    setTimeout(() => {
      this.checkingTextLogo();
    }, 500);
    this.checkinImageLogoWidth();
  }
  checkingTextLogo(){
    // if(this.data.hasOwnProperty('textLogo')){
    // }
    // else{
    //   this.data.textLogo = true;
    // }
    if(this.data.textLogo === true){
      // this.logoStatus[1].value = 'TEXT_LOGO';
      this.selectedLogoStatus = 'TEXT_LOGO';
    }
    else if (this.data.status === true){
      // this.logoStatus[0].value = 'LOGO';
      this.selectedLogoStatus = 'LOGO'
    }
    else{
      // this.logoStatus[2].value = 'BOTH'
      this.selectedLogoStatus = 'BOTH'
    }
  }
  checkinImageLogoWidth(){
    if(this.data.hasOwnProperty('imageLogoWidth')){
    }
    else{
      this.data.imageLogoWidth = '100%';
    }
  }

  changeButtonStatus(ev){
    // if(ev){
    //   this.data.status = ev;
    //   this.data.textLogo = false;
    // }
    // else{
    //   this.data.status = ev;
    //   this.data.textLogo = true;
    // }
    if(ev === 'LOGO'){
      this.data.status = true;

      this.data.textLogo = false
    }
    else if(ev === 'TEXT_LOGO'){
      this.data.status = false;
      this.data.textLogo = true
    }
    else{
      this.data.status = true;
      this.data.textLogo = true
    }
    this._eventHelper.showSaveOption.emit({data:true});
  }
  pageLoader:boolean=false;
  addImage(item){
    // this.pageLoader = true;
    // this.imageData = item;
    // let elementId = document.getElementById('browse_img');
    // if(!elementId) return;
    // elementId.click();
    // this.pageLoader = false;
    if(this.srcWidth > 475){
    let dialog = this.templateService.openDialogWithData(
      MediaSelectorComponent,
      '90vh',
      '50%',
      {multiple:false}
  );
  dialog.afterClosed().subscribe(
    (res:any)=>{
      this.profileImg = res[0].assets[0].url;
      this.data.attachment = this.profileImg;
      this._eventHelper.showSaveOption.emit({data:true});
  }
  )
}
if(this.srcWidth < 475){
  let dialog = this.templateService.openDialogWithData(
    MediaSelectorComponent,
    '90vh',
    '50%',
    {multiple:false}
);
dialog.afterClosed().subscribe(
  (res:any)=>{
    this.profileImg = res[0].assets[0].url;
    this.data.attachment = this.profileImg;
    this._eventHelper.showSaveOption.emit({data:true});
}
)
}
  }

  file : any ;
  profileImg : any = '';
  imageUploadingLoader : boolean = false;
  async updateImage(ev:any){
    this.pageLoader = true;
    console.log(this.data);
    const file = ev.srcElement.files[0];
    this.profileImg = await this.imageUploadService.uploadComponentsImages(file);
    this.profileImg = this.profileImg.Location;
    this.imageData.attachment = this.profileImg;
    this._eventHelper.showSaveOption.emit({data:true});
    this.pageLoader = false;
  }

  toppings = this._formBuilder.group({
    Logo: false,
    heading: false,
    mushroom: false,
  });

  test(ev){
    this.data.imageLogoWidth = this.sliderValue+'%';
    this._eventHelper.showSaveOption.emit({data:true});
  }

  srcHeight: any;
  srcWidth: any;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.srcHeight = window.innerHeight;
    this.srcWidth = window.innerWidth;
    ////console.log(this.scrHeight, this.scrWidth);
  }


}
