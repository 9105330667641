<section class="main-section">
    <div class="header">
        <div class="header-text">{{data.data.edit ? "Edit Task" : "Create Task"}}</div>
        <div class="close-image" (click)="cancel()">
            <img src="../../../../../../../../assets/images/close.svg" alt="">
        </div>
    </div>
    <div class="input-field-section">
        <div class="input-box-full">
            <div class="input-label">Subject <span class="required">*</span></div>
            <mat-form-field class="custom-mat-form-field full-width" appearance="outline">
                <input id="input" matInput placeholder="Enter subject" [(ngModel)]="taskDetails.name">
            </mat-form-field>
        </div>
        <div class="input-box">
            <div class="input-label">Priority</div>
            <mat-form-field class="custom-mat-form-field full-width" appearance="outline">
                <mat-select id="input" placeholder="" [(ngModel)]="taskDetails.priority">
                    <ng-container *ngFor="let item of priorityList">
                        <mat-option [value]="item.value">{{item.viewValue}}</mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="input-box">
            <div class="input-label">Due Date <span class="required">*</span></div>
            <mat-form-field class="custom-mat-form-field full-width" appearance="outline">
                <input matInput [matDatepicker]="picker1" [(ngModel)]="taskDetails.dueDate"
                    placeholder="Select Due Date" [min]="minDate">
                <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="input-box">
            <div class="input-label">Email Reminder</div>
            <mat-form-field style="width:63%;"class="custom-mat-form-field full-width" appearance="outline">
                <input matInput [matDatepicker]="picker2" placeholder="DD/MM/YYYY" [min]="minDate"
                    [max]="taskDetails.dueDate" [(ngModel)]="taskDetails.reminderDate" (dateChange)="onReminderDateChange()">
                <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="custom-mat-form-field email-time emailreminder" appearance="outline">
                <mat-select placeholder="HH : MM" [disabled]="!taskDetails.reminderDate" [(ngModel)]="selectedTime">
                    <ng-container *ngFor="let item of timeArray">

                        <mat-option [value]="item.value" 
                        [disabled]="item.disabled"
                        
                        (click)="selectTime(item)">
                        
                        {{item.viewValue}}</mat-option>

                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>

        <!-- <div class="input-box">
            <div class="task-repeat">
                <div class="input-label">Reminder</div>
                <mat-slide-toggle color="primary" class="slide-toggle" [(ngModel)]="setReminder"></mat-slide-toggle>
            </div>
            <mat-form-field class="full-width" appearance="outline">
                <input matInput placeholder="" value="" [disabled]="!setReminder" (click)="taskReminderPopUp(0,$event)"
                    class="task-reminder-showpopup">
            </mat-form-field>
        </div> -->

        <!-- reminder pop up -->
        <!-- <div class="task-reminder-popup" *ngIf="showReminder">
            <div class="header-text">Task Reminder</div>
            <div class="input-box">
                <div class="repeat-options">
                    <mat-radio-group aria-label="">
                        <div class="task-reminder-item">
                            <mat-radio-button color="primary"></mat-radio-button>
                            <span>On </span>
                            <mat-form-field class="small-width" appearance="outline">
                                <input matInput [matDatepicker]="reminderpicker" placeholder="Select"
                                    [(ngModel)]="taskDetails.tasksRepeat.startRemainder" [min]="minDate">
                                <mat-datepicker-toggle matIconSuffix [for]="reminderpicker"></mat-datepicker-toggle>
                                <mat-datepicker #reminderpicker></mat-datepicker>
                            </mat-form-field>
                            <span> at </span>
                            <mat-form-field class="small-width" appearance="outline">
                                <mat-select placeholder="12:00 AM">
                                    <ng-container *ngFor="let item of timeArray ">
                                        <mat-option [value]="item.value">{{item.viewValue}}</mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="task-reminder-item">
                            <mat-radio-button color="primary"></mat-radio-button>
                            <span>On </span>
                            <mat-form-field class="task-reminder-set" appearance="outline">
                                <mat-select placeholder="1 day">
                                    <ng-container *ngFor="let item of reminderDay ">
                                        <mat-option [value]="item.value">{{item.viewValue}}</mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field><br>
                            <span> Before the due date at </span><br>
                            <mat-form-field class="task-reminder-set" appearance="outline">
                                <mat-select placeholder="12:00 AM">
                                    <ng-container *ngFor="let item of timeArray ">
                                        <mat-option [value]="item.value">{{item.viewValue}}</mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </mat-radio-group>
                </div>
            </div>
            <hr class="section-divider">
            <div class="alert">
                <div class="input-label">Reminder Alert</div>
                <div class="task-reminder-set">
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-select placeholder="" [(ngModel)]="taskDetails.tasksRepeat.notify">
                            <ng-container *ngFor="let item of reminderAlert ">
                                <mat-option [value]="item.value">{{item.viewValue}}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="button-container">
                <div class="cancel-btn" (click)="showReminder = !showReminder">
                    Cancel
                </div>
                <button class="create-btn" (click)="showReminder = !showReminder">
                    Create Task
                </button>
            </div>
        </div> -->


        <div class="input-box">
            <div class="task-repeat">
                <div class="input-label">Task Repeat</div>
                <mat-slide-toggle color="primary" class="slide-toggle" [(ngModel)]="isTaskRepeated"
                    (ngModelChange)="toggleTaskRepeat()"></mat-slide-toggle>
            </div>
            <mat-form-field id="TaskrepeatMenuTrigger" class="custom-mat-form-field full-width"
                appearance="outline" [cdkMenuTriggerFor]="TaskRepeatMenu">
                <input matInput placeholder="None" value="" [disabled]="!isTaskRepeated" readonly
                    class="task-repeat-showpopup" [(ngModel)]="taskRepeatText" style="cursor:pointer;">
                <button matSuffix mat-icon-button aria-label="Clear">
                    <mat-icon>keyboard_arrow_down</mat-icon>
                </button>
            </mat-form-field>
        </div>

        <div class="input-box" *ngIf="data.data.type == 'ALL_TASKS'">
            <div class="input-label">Select <span class="required">*</span></div>
            <div class="lead-section">
                <mat-form-field class="custom-mat-form-field select" appearance="outline">
                    <mat-select placeholder="" [(ngModel)]="participantType" (ngModelChange)="getContacts()">
                        <ng-container *ngFor="let item of type">
                            <mat-option [value]="item.value">{{item.viewValue}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="custom-mat-form-field add-email" appearance="outline">
                    <input type="text" placeholder="Search by name" matInput [(ngModel)]="selectedContact"
                        [formControl]="myControl" [matAutocomplete]="auto" (keyup)="getContacts()">
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option.personalInfo.name"
                            (click)="contactSelected(option)">
                            {{option.personalInfo.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </div>

        <div class="input-box">
            <div class="input-label">Status</div>
            <mat-form-field class="custom-mat-form-field full-width" appearance="outline">
                <mat-select id="input" placeholder="" [(ngModel)]="taskDetails.taskStatus">
                    <ng-container *ngFor="let item of status">
                        <mat-option [value]="item.value">{{item.viewValue}}</mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="input-box">
            <div class="input-label">Assign To <span class="required">*</span></div>
            <mat-form-field class="custom-mat-form-field full-width" appearance="outline">
                <mat-select id="input" placeholder="Select" [(ngModel)]="taskDetails.assignedTo"
                    (ngModelChange)="changeStaff()">
                    <ng-container *ngFor="let staff of staffList">
                        <mat-option [value]="staff.id">{{staff.name}}</mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="description">
            <div class="input-label">Description <span class="required">*</span></div>
            <textarea class="text-box" id="input" placeholder="Add description" cols="100" rows="4"
                [(ngModel)]="taskDetails.description"></textarea>
        </div>

        <div class="button-container">
            <div class="cancel-btn" (click)="cancel()">
                Cancel
            </div>
            <ng-container *ngIf="!addLoader; else addLoading">
                <button class="create-button" *ngIf="!data.data.edit" (click)="createTask()"
                    [disabled]="MandatoryFields()">
                    <span *ngIf="createLoader"><i class="fas fa-spinner fa-spin"></i>&nbsp;Loading</span>
                    <span *ngIf="!createLoader">Create Task</span>
                </button>
    
                <button class="create-button" *ngIf="data.data.edit" (click)="editTask()"
                    [disabled] ="!(taskDetails.name && taskDetails.dueDate && taskDetails.assignedTo)">
                    <span *ngIf="createLoader"><i class="fas fa-spinner fa-spin"></i>&nbsp;Loading</span>
                    <span *ngIf="!createLoader">Update Task</span>
                </button>
            </ng-container>
        </div>
    </div>


</section>

<ng-template #addLoading>
    <button class="continue">
        <i class="fa fa-spinner fa-spin"></i> &nbsp;Loading
    </button>
</ng-template>

<ng-template #TaskRepeatMenu>
    <!-- task repeat pop up -->
    <div class="task-repeat-popup" *ngIf="isTaskRepeated">
        <div class="heading__task-repeat">
            <h4>Task Repeat</h4>
            <img src="../../../../../../../../assets/images/common/close-img.svg" alt="Close"
                (click)="closeTaskRepeatPopup()">
        </div>

        <div>
            <h6>Repeat Type</h6>
            <mat-radio-group class="type__task-repeat" [(ngModel)]="taskRepeatDetails.taskRepeat">
                <ng-container *ngFor="let type of repeatType">
                    <mat-radio-button color="primary" class="label-font-bold-14px" [value]=type.value>{{ type.viewValue
                        }}</mat-radio-button>
                </ng-container>
            </mat-radio-group>
        </div>

        <hr>

        <div>
            <h6>End On</h6>

            <mat-radio-group class="end__task-repeat" [(ngModel)]="taskRepeatDetails.taskEndOn">
                <mat-radio-button color="primary" class="label-font-bold-14px" value="NEVER">Never</mat-radio-button>
                <mat-radio-button color="primary" class="label-font-bold-14px" value="AFTER_N_TIME">After
                    <mat-form-field class="end-on__input" appearance="outline">
                        <input matInput type="number" placeholder="1" [(ngModel)]="taskRepeatDetails.repeatCount">
                    </mat-form-field> Time(s)
                </mat-radio-button>
                <mat-radio-button color="primary" class="label-font-bold-14px" value="ON_DATE">On
                    <mat-form-field class="form-field-h-40px custom-mat-form-field" appearance="outline">
                        <input matInput [matDatepicker]="picker3" [(ngModel)]="taskRepeatDetails.endsOn"
                            placeholder="Select Date" [min]="minDate">
                        <mat-datepicker-toggle matIconSuffix [for]="picker3"></mat-datepicker-toggle>
                        <mat-datepicker #picker3></mat-datepicker>
                    </mat-form-field>
                </mat-radio-button>
            </mat-radio-group>
        </div>

        <div class="btn__task-repeat">
            <button class="outlined-btn" (click)="saveTaskRepeatDetails()">Done</button>
            <button class="create-btn" (click)="closeTaskRepeatPopup()">Cancel</button>
        </div>
    </div>
</ng-template>