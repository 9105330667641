<div class="mainSection">
    <div class="title">
        Connect existing domain
        <div><mat-icon (click)="close()">close</mat-icon></div>
    </div>
    <div class="hr-line"></div>
    <div class="registerDomain">
        Registered Domain
        <!-- <input class="inputBox" type="text"> -->
    </div>
    <div class="form_field business-field-section">
        <mat-form-field class="sfull-width" appearance="outline">
            <input placeholder="abc.com" matInput [(ngModel)]="linkingDomain"/>
        </mat-form-field>
    </div>
    <div class="belowStepsText">
        Follow below steps to map your existing domain
    </div>
    <div class="steps">
        <span class="fontBold">1. Access domain settings:</span>
        <span class="fontBold-text">Log in to your domain registrar's account where you purchased the domain. Locate the domain management or settings section.
        </span>
    </div>
    <div class="steps">
        <span class="fontBold">2. DNS Settings: </span>
        <span>Look for the DNS settings or DNS management section. You'll be modifying DNS records to point to Simpo.ai's servers.</span>
    </div>
    <!-- <div class="cname">
        <span>CNAME: </span>
        <span>dfgh4567hj.business.beeos.com</span>
        <span class="copyStyle">Copy</span>
    </div> -->
    <div class="steps">
        <div >
            <div class="_fontBold">3. Create A Record:</div>
            <div class="host-container">
            <ul>
                <li>
                    <div class="host-name">Hostname:
                        <span class="host">@</span>
                    </div>
                    <div class="host-text">(This represents the root domain, e.g., "yourcompany.com" without "www")</div>
                </li>
            </ul>
             </div>
             <div class="type-conatiner">
                <ul>
                    <li>
                        <div class="type">Type:
                            <span class="host">A</span>
                        </div>
      
                    </li>
                </ul>
             </div>

             <div class="type-container">
                <ul>
                    <li>
                        <div class="type">Value:
                            <span class="host">3.89.84.48</span>
                        </div>
      
                    </li>
                </ul>
             </div>
        </div>
      
    </div>
    <div class="steps">
        <span class="fontBold">3.Save Changes:</span>
        <span>Once you’ve create a A record, click on submit and verify button below.</span>
    </div>
    <div class="contact-us-container">
        <div class="contact-us-section">
            <div class="contact-text">Need any help to connect domain</div>
            <div class="contactUs-button" (click)="contactUs()">Contact US</div>

        </div>
    </div>
    <div class="btnRightAlign">
        <button class="buttonStyle" (click)="submitVerify()" *ngIf="!submitLoader">Submit & Verify</button>
        <button class="buttonStyle" *ngIf="submitLoader">
            <i class="fa fa-spinner fa-spin"></i> &nbsp;Loading..
        </button>
    </div>
</div>