import { Component } from '@angular/core';

@Component({
  selector: 'app-upcoming-features',
  templateUrl: './upcoming-features.component.html',
  styleUrls: ['./upcoming-features.component.scss','./mq-upcoming-features.component.scss']
})
export class UpcomingFeaturesComponent {

}
