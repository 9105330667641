import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { WebisiteService } from '../webisite.service';
import { DbService } from 'src/app/services/db-service/db.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StaffServiceService } from '../../staff/service/staff-service.service';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss', './mq-analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {

  selectedDaysCount :any;
  data: any = "";
  constructor(
    private datePipe: DatePipe,
    private webisiteService: WebisiteService,
    private _dbService: DbService,
    private snackbar: MatSnackBar,
    private staffService: StaffServiceService
  ) {
    let bDetails = window.localStorage.getItem('bDetails');
    if (bDetails) {
      this.businessDetails = JSON.parse(bDetails);
      this.businessId = this.businessDetails.id;
    }
    this.getDashboardDetails();
    // this.getOauth2Data();
    this._dbService.setAnalyticsToken(
      JSON.stringify({
        "access_token": "ya29.a0AWY7Ckk0V9b-UgiYnnFD96IJCXDOjsnmp9wenmkQAjCvJABJmpXubScelcebk6U4DPJRrCVRkb8SeIbGeERPD4U6R032kRIHD-Igd0pMcXwL1sFE0rdnZ-pMtcGHwJ9bCtVePz4Y4DY2gTq_8XLWUAPgerZGaCgYKAaoSARMSFQG1tDrpZJBnydCT5R1xcaicL7oZGw0163",
        "expires_in": 3599,
        "refresh_token": "1//0df_2Wl69mxqYCgYIARAAGA0SNwF-L9Ir6eyjTK8SggJ7XkYQjmWdhvZcaYjZxksVN8z8iLcwkLAD5LzrwEu7MdGDwGZLQIExGgU",
        "scope": "https://www.googleapis.com/auth/analytics.readonly https://www.googleapis.com/auth/analytics https://www.googleapis.com/auth/analytics.edit",
        "token_type": "Bearer"
      })

    )
    // this.getTopData();
  }

  dropdownValues = [
    {
      displayValue: 'Last 15 days',
      value: 15,
      daysCount: ''
    },
    {
      displayValue: 'Last 30 days',
      value: 30,
      daysCount: ''
    }
  ]

  businessDetails : any;
  businessId : any;
  viewAccess: boolean = true;
  ngOnInit(): void {
    this.viewAccess = this.staffService.getStaffPermission('WEBSITE_ANALYTICS', 'VIEW');
  }

  loader : boolean = false;
  displayEndDate : any ;
  getDashboardDetails (){
    this.loader = true;
    let currentDate = new Date();
    this.displayEndDate = new Date(currentDate.setDate(currentDate.getDate()));
    this.displayEndDate = this.datePipe.transform(this.displayEndDate,'dd-MM-yyyy');
    this.endDate = new Date(currentDate.setDate(currentDate.getDate()+1));
    this.endDate = this.datePipe.transform(this.endDate,'dd-MM-yyyy');

    // let startDate = new Date();
    this.startDate = new Date(currentDate.setDate(currentDate.getDate() - 15))
    this.startDate = this.datePipe.transform(this.startDate,'dd-MM-yyyy');

    this.selectedDaysCount = this.dropdownValues[0].value;
    this.dropdownValues[0].daysCount = this.startDate + '-' + this.endDate


    this.webisiteService.getAnalyticsDashboard(this.businessId,this.startDate,this.endDate).subscribe(
      (res:any) => {
        // console.log(res.data);
        // chart related;
        this.isThereData = 'YES';
        this.linData.labels = [];
        this.linData.datasets[0].data = [];
        this.linData.datasets[1].data = [];
        Object.keys(res.data.dayWiseRegularAndUniqueVisit).forEach((day)=>{
          let a = String(this.datePipe.transform(day,'MMM dd'));
          this.linData.labels.push(a);
          this.linData.datasets.forEach(element => {
            if((element.label.split(' ').join('_')).toLowerCase() === 'regular_visits'){
              element.data.push(res.data.dayWiseRegularAndUniqueVisit[day].regularVisit);
            }
            else{
              element.data.push(res.data.dayWiseRegularAndUniqueVisit[day].uniqueVisit);
            }
          });
        });


        //bar chart related
        this.barBasicData.labels = [];
        this.barBasicData.datasets[0].data = [];
        Object.keys(res.data.hourlyVisit).forEach((hour)=>{
          this.convert24hrsTo12hrs(hour);
          // this.barBasicData.labels.push(hour);
          this.barBasicData.datasets.forEach((ds) => {
            ds.data.push(res.data.hourlyVisit[hour])
          });
        });

        // total pages counts
        this.pagesViews = [];
        res.data.pageWiseVisit.forEach((pvv) => {
          this.pagesViews.push({
            pageTitle:pvv.pageName,
            views : pvv.totalViews,
            scrolls:pvv.totalScrolls,
            uniqueVisitors:pvv.totalUniqueVisits,
            sessions:pvv.totalSessions
          })
        });

        //total devices connected
        this.deviceAnalytics = [];
        Object.keys(res.data.deviceWiseCount).forEach((deviceL)=>{
          this.deviceAnalytics.push({
            device : deviceL,
            views:res.data.deviceWiseCount[deviceL]
          })
        })

        this.loader = false
      },
      (err) => {
        this.loader = false;
        // console.log(err)
        // if(err.error.errorCode === '1004'){
        //   // this.isThereData = 'NO'

        // }
      }
    )
  }
  dataConvertions() {

  }
  visitors: any = [

  ];

  pagesViews : any = [
    {
      pageTitle: 'home',
      views: '0',
      scrolls: '0',
      uniqueVisitors: '0',
      sessions: '0'
    },
    {
      pageTitle: 'about us',
      views: '0',
      scrolls: '0',
      uniqueVisitors: '0',
      sessions: '0'
    }
  ]
  deviceAnalytics = [
    {
      device: 'Desktop',
      views: '0'
    },
    {
      device: 'mobile',
      views: '0'
    }
  ]

  labels :any = []
  //graph chart
  linData : any = {
    labels: [
      "Jan 1",
      "Jan 2",
      "Jan 3",
      "Jan 4",
      "Jan 5",
      "Jan 6",
      "Jan 7",
      "Jan 9",
      "Jan 10",
      "Jan 11",
      "Jan 12",
      "Jan 13",
      "Jan 14",
      "Jan 15"
    ],
    datasets: [
      {
        label: "Regular Visits",
        data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
        fill: false,
        backgroundColor: "#0496ff",
        borderColor: '#0496ff'
      },
      {
        label: "Unique Visitors",
        data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
        fill: false,
        backgroundColor: "#F79824",
        borderColor: '#F79824'
      }
    ]
  };

  LineOptions = {
    maintainAspectRatio: false,
    scales: {
      y:{
        ticks:{
          callback: function(value, index, values) {
            // Display integer values without decimal points
            return value >= 0 ? (Math.floor(value) === value ? value : '') : null;
          },
        },
        beginAtZero: true, // Start y-axis from 0
        suggestedMin: 0 // Ensure that y-axis doesn't go below 0
      }
      // yAxes: [
      //   {
      //     display: true,
      //     position: 'left',
      //     type: "linear",
      //       scaleLabel: {
      //           display: true,
      //           labelString: 'USD',
      //           beginAtZero: true,
      //       },
      //     //yAxisID: "id1"
      //     id: "id1" // incorrect property name.
      //   },
      //   {
      //     scaleLabel: {
      //               display: true,
      //               labelString: 'Commissions',
      //               beginAtZero: true,
      //           },
      //          //display: false,
      //          display: true, // Hopefully don't have to explain this one.
      //         //  type: "linear",
      //          position:"right",
      //          gridLines: {
      //              display: false
      //          },
      //          //yAxisID: "id2"
      //          id: "id2" // incorrect property name.
      //   }
      // ]
    }
  };


  barBasicData :any = {
    labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21'],
    datasets: [
      {
        backgroundColor: '#0496ff',
        label: 'Hourly Reports',
        data: ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0', '0'],
      },

    ]
  };

  barBasicOptions = {
    plugins: {
      legend: {
        display:false,
        labels: {
          color: '#black'
        }
      }
    },
    scales: {
      x: {
        ticks: {
          color: '#black'
        },
        grid: {
          color: 'rgba(255,255,255,0.2)'
        }
      },
      y: {
        ticks: {
          color: '#black',
          callback: function(value, index, values) {
            // Display integer values without decimal points
            return Math.floor(value) === value ? value : '';
          }
        },
        grid: {
          color: 'rgba(255,255,255,0.2)'
        }
      }
    }
  };

  startDate: any = "";
  endDate: any = "";
  isThereData : any;
  dateEvents(item: any) {
    let tokenData = JSON.parse(this._dbService.getAnalyticsToken() || "");
    // console.log(item);
    if(item.value === 15){
      let currentDate = new Date();
      this.endDate = new Date(currentDate.setDate(currentDate.getDate()));
      this.endDate = this.datePipe.transform(this.endDate,'dd-MM-yyyy');

      // let startDate = new Date();
      this.startDate = new Date(currentDate.setDate(currentDate.getDate() - 15))
      this.startDate = this.datePipe.transform(this.startDate,'dd-MM-yyyy');

      this.selectedDaysCount = this.dropdownValues[0].value;
      this.dropdownValues[0].daysCount = this.startDate + '-' + this.endDate
    }
    else{
      let currentDate = new Date();
      this.displayEndDate = new Date(currentDate.setDate(currentDate.getDate()));
      this.displayEndDate = this.datePipe.transform(this.displayEndDate,'dd-MM-yyyy')
      this.endDate = new Date(currentDate.setDate(currentDate.getDate()+1));
      this.endDate = this.datePipe.transform(this.endDate,'dd-MM-yyyy');

      // let startDate = new Date();
      this.startDate = new Date(currentDate.setDate(currentDate.getDate() - 30))
      this.startDate = this.datePipe.transform(this.startDate,'dd-MM-yyyy');

      this.selectedDaysCount = this.dropdownValues[1].value;
      // this.dropdownValues[1].daysCount = this.startDate + '-' + this.endDate
    }

    this.loader = true;


    this.webisiteService.getAnalyticsDashboard(this.businessId,this.startDate,this.endDate).subscribe(
      (res:any) => {
        // console.log(res.data);
        // chart related
        this.isThereData = 'YES';
        this.linData.labels = []
        this.linData.datasets[0].data = [];
        this.linData.datasets[1].data = [];
        Object.keys(res.data.dayWiseRegularAndUniqueVisit).forEach((day)=>{
          let a = String(this.datePipe.transform(day,'MMM dd'));
          this.linData.labels.push(a);
          this.linData.datasets.forEach(element => {
            if((element.label.split(' ').join('_')).toLowerCase() === 'regular_visits'){
              element.data.push(res.data.dayWiseRegularAndUniqueVisit[day].regularVisit);
            }
            else{
              element.data.push(res.data.dayWiseRegularAndUniqueVisit[day].uniqueVisit);
            }
          });
        });


        //bar chart related
        this.barBasicData.labels = [];
        this.barBasicData.datasets[0].data = [];
        Object.keys(res.data.hourlyVisit).forEach((hour)=>{
          this.convert24hrsTo12hrs(hour);
          // this.barBasicData.labels.push(hour);
          this.barBasicData.datasets.forEach((ds) => {
            ds.data.push(res.data.hourlyVisit[hour])
          });
        });

        // total pages counts
        this.pagesViews = [];
        res.data.pageWiseVisit.forEach((pvv) => {
          this.pagesViews.push({
            pageTitle:pvv.pageName,
            views : pvv.totalViews,
            scrolls:pvv.totalScrolls,
            uniqueVisitors:pvv.totalUniqueVisits,
            sessions:pvv.totalSessions
          })
        });

        //total devices connected
        this.deviceAnalytics = [];
        Object.keys(res.data.deviceWiseCount).forEach((deviceL)=>{
          this.deviceAnalytics.push({
            device : deviceL,
            views:res.data.deviceWiseCount[deviceL]
          })
        })

        this.loader = false
      },
      (err) => {
        this.loader = false;
        // console.log(err)
        // if(err.error.errorCode === 1004){
        //   this.isThereData = 'NO';
        // }
      }
    )
  }

  convert24hrsTo12hrs(time){

    if(time == 24){
      time = 0;
    }

    let hours = parseInt(time);

    //lets check AM/PM
    let period = hours >=12 ? 'PM' : 'AM';

    //Adjust hours for 12-hours format
    hours = hours % 12;

    // Handle midnight (00:00) and noon (12:00)
    if(hours == 0){
      hours = 12;
    }

    let hourPeriod = hours + ' ' + period

    // return hours
    this.barBasicData.labels.push(hourPeriod);
  }



  getOauth2Data() {
    this.webisiteService.auth2CallBack().subscribe({
      next: (value: any) => {
        console.log("auth2value", value);

      },
      error: (err: any) => {
        console.log("error", err);

      },
    })
  }

  getdataBuild() {
    this.visitors = [];
    this.data.data.reports.forEach((element: any) => {
      console.log("elements", element);
      if (element?.metricHeaders) {
        this.visitors.push({
          boxTitle: element?.metricHeaders[0]?.name,
          count: parseFloat(element.rows[0]?.metricValues[0]?.value).toFixed(2),
          increased: true,
          increasedBy: '9.4'
        })
      }
    });
  }

  getTopData() {
    let tokenData = JSON.parse(this._dbService.getAnalyticsToken() || "");
    let startDate: any = new Date();
    let endDate: any = new Date();
    let year = startDate.getFullYear();
    let month = startDate.getMonth();
    let date = startDate.getDate() - 15;
    startDate = new Date(year, month, date);
    this.startDate = startDate;
    this.endDate = endDate;
    let details = {
      startDate: this.datePipe.transform(startDate, 'yyyy-MM-dd'),
      endDate: this.datePipe.transform(endDate, 'yyyy-MM-dd'),
      token: tokenData ? tokenData.access_token : "",
      measurmentId: 365964460
    }
    this.webisiteService.reportBatch(details).subscribe({
      next: (value: any) => {
        console.log("data", value);
        this.data = value;
        this.getdataBuild();
      },
      error: (err) => {
        console.log('err', err);
        this.getRefreshToken(tokenData.refresh_token);
      },
    })
  }

  pageInfoData(details: any, tokenData: any) {
    this.webisiteService.reportBatch(details).subscribe({
      next: (value: any) => {
        console.log("data", value);
        this.data = value;
        this.getdataBuild();
      },
      error: (err) => {
        console.log('err', err);
        this.getRefreshToken(tokenData.refresh_token);
      },
    })
  }


  getRefreshToken(refresh_token: any) {
    let tokenData: any = JSON.parse(this._dbService.getAnalyticsToken() || "");
    this.webisiteService.refreshToken(refresh_token).subscribe({
      next: (value: any) => {
        tokenData.access_token = value.access_token;
        this._dbService.setAnalyticsToken(JSON.stringify(tokenData));
        this.getTopData();
      },
      error: (err: any) => {
        console.log("data", err);
        this.webisiteService.openSnack("Something Went Wrong Please Try After Sometime", "Close");
      },
    })
  }

}
