import { Component, Input, OnInit } from '@angular/core';
import { EventsService } from 'simpo-component-library';

@Component({
  selector: 'app-border-editor',
  templateUrl: './border-editor.component.html',
  styleUrls: ['./border-editor.component.scss']
})
export class BorderEditorComponent implements OnInit{

  @Input() data:any;

  min = 0;
  max = 20;

  constructor(
    public eventEmitter : EventsService
  ){}

  borderType : Array<any> = [
    {
      name:'solid',
      status : false
    },
    {
      name:'dashed',
      status:false
    }
  ];


  ngOnInit(): void {
    this.borderType.forEach(element => {
      if(element.name === (this.data.styles.border.type).toLowerCase()){
        element.status = true;
      }
      else{
        element.status = false;
      }
    });
  }

  enterColorCode(){
    let colorPicker = document.getElementById('colorPicker');
    colorPicker?.click();
  }

  changeColor(){
    this.eventEmitter.borderChangeChecks.emit({data:this.data.styles.border});
  }
  changeThickness(){
    this.eventEmitter.borderChangeChecks.emit({data:this.data.styles.border});
  }
  changeBorderType(item:any){
    this.borderType.forEach(element => {
      if(element.name === item){
        element.status = true;
        this.data.styles.border.type = item;
        this.eventEmitter.borderChangeChecks.emit({data:this.data.styles.border});
      }
      else{
        element.status = false;
      }
    });
  }
}
