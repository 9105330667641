import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DbService } from 'src/app/services/db-service/db.service';
import { TemplateService } from 'src/app/services/template.service';
import { WebisiteService } from '../../../../webisite.service';
import { StorageKeys } from 'src/app/shared-module-files/simpo.constant';

@Component({
  selector: 'app-preview-contactus-text-inputs',
  templateUrl: './contactus-text-inputs.component.html',
  styleUrls: ['./preview-contactus-text-inputs.component.scss']
})
export class PreviewContactusTextInputsComponent {

  @Input() templateData:any;
  valuesData:any;
  
  constructor(
    private templatesService:TemplateService,
    private _dbService : DbService,
    private _matPopup : MatSnackBar,
    private webisiteService:WebisiteService
  ){}

  dataSource: any = {
    "componentName": "SimpleFooterGetInTouchComponent",
    "headerData" : [
      {
        "value": "Contact Us",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 500,"font-size": "36px", "color":"#000000"}
      },
      {
        "value": "We're glad you're interested in reaching out to us.Whether you have a question about our products, want to give us feedback, or just want to say hi,we're here to help.You can get in touch with us filing out this form",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 500,"font-size": "18px", "color":"rgb(149, 147, 147)","padding-top": "2%"}
      },
      // {
      //   "value": "Location :",
      //   "type":"Text",
      //   "field":"Text",
      //   "style":{"font-weight": 700,"font-size": "24px", "color":"#000000"}
      // },
      // {
      //   "value": "Hyderabad,TG 500008",
      //   "type":"Text",
      //   "field":"Text",
      //   "style":{"font-weight": 500,"font-size": "16px", "color":"#000000"}
      // },
      // {
      //   "value": "Email :",
      //   "type":"Text",
      //   "field":"Text",
      //   "style":{"font-weight": 700,"font-size": "24px", "color":"#000000"}
      // },
      // {
      //   "value": "contactus@gmail.com",
      //   "type":"Text",
      //   "field":"Text",
      //   "style":{"font-weight": 500,"font-size": "16px", "color":"#000000"}
      // },
      // {
      //   "value": "Call :",
      //   "type":"Text",
      //   "field":"Text",
      //   "style":{"font-weight": 700,"font-size": "24px", "color":"#000000"}
      // },
      // {
      //   "value": "+1 (551)318-7369",
      //   "type":"Text",
      //   "field":"Text",
      //   "style":{"font-weight": 500,"font-size": "16px", "color":"#000000"}
      // },
    ]
  }

  headerData = this.dataSource.headerData;
  
  // templateData ={
  //   "data" :[
  //     {
  //       "value":"[Company Name] Getting in touch with you",
  //       "type":"text",
  //       "field":"Text",
  //       "style":{"font-weight": 900},
  //       "textType":"heading"
  //     },
  //     {
  //       "value":"Use this form to get in touch with [Company Name]. Whether you have a question or need a quote, we'll be happy to help.",
  //       "type":"text",
  //       "field":"Text",
  //       "style":{"font-weight": 100},
  //       "textType":"description"
  //     },
  //     {
  //       "value":'Send',
  //       "type":"button",
  //       "field":"button",
  //       "textType":"button",
  //       "style":{"font-weight":300}
  //     }
  //   ]
  // }

  ngOnInit(): void {
    this.templatesService.updateElements.subscribe(
      (value:any) => {
        //console.log(value)
        value.componentContentList.forEach((conCompList:any) => {
          if(conCompList.webComponentName === 'simple_contactus_right_heading'){
            this.templateData = conCompList;
            this.dataAssign();
          }
          else{}
        });
      })
    
    this.dataAssign();
  }

  data : any;
  bgColor : any;
  redirectionUrl : any;
  dataAssign(){
    //console.log(this.templateData);
    this.data = this.templateData.data;
    this.bgColor = this.templateData.bgColor;
    // this.redirectionUrl = `http://maps.google.com/maps?z=11&t=k&q=${this.templateData.data[4].value[5].value}N ${this.templateData.data[4].value[4].value}E`
    var bName = localStorage.getItem(StorageKeys.BUSINESS_NAME);
    this.data[0].value = this.data[0].value.replaceAll("{Company Name}", bName);
    this.checkingStyles();
  }
  checkingStyles(){
    this.data.forEach((elems:any) => {
      //text 
      if(elems.type === 'text'){
        // this.typeText(elems)
        elems= this.webisiteService.typeText(elems,this.templateData)
      }
      else if(elems.type === 'button'){
        elems= this.webisiteService.typeButton(elems,this.templateData)
      }
      else if(elems.type === 'array'){
        this.typeArray(elems)
      }
    });
  }
  typeArray(data:any){
    data.value.forEach((arrValue:any) => {
      if(arrValue.type === 'text'){
        arrValue= this.webisiteService.typeText(arrValue,this.templateData)
      }
      else if(arrValue.type === 'button'){
        arrValue= this.webisiteService.typeButton(arrValue,this.templateData)
      }
    });
  }

  // typeText(data:any){
  //   // data.style = {
  //   //   "color":this.templateData.textColor,
  //   //   "font-family":this.templateData.fontFamily,
  //   // }
  //   // //console.log(this.templateData);
  //   if(data.textType === 'heading'){
  //     data.style = {
  //       "color":this.templateData.colors.txtHeadingColor,
  //       "font-family":this.templateData.headingFontFamily,
  //     }
  //   }
  //   else if(data.textType === 'description'){
  //     data.style = {
  //       "color":this.templateData.colors.txtDescColor,
  //       "font-family":this.templateData.bodyFontFamily,
  //     }
  //   }
  //   else if(data.textType === 'text'){
  //     data.style = {
  //       "color":this.templateData.colors.txtNormalColor,
  //       "font-family":this.templateData.bodyFontFamily,
  //     }
  //   }
  // }
  // typeButton(data:any){
  //   if(this.templateData.buttonStyle === "SOLID"){
  //     if(this.templateData.buttonShape === "SHARP"){
  //       data.style = {
  //         "background-color":this.templateData.colors.btnColor,
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":"none",
  //         "padding":"8px 15px",
  //         "border":"none",
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //     else{
  //       data.style = {
  //         "background-color":this.templateData.colors.btnColor,
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":`10px`,
  //         "padding":"8px 15px",
  //         "border":"none",
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //   }
  //   else{
  //     if(this.templateData.buttonShape === "SHARP"){
  //       data.style = {
  //         "background-color":"transparent",
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border":`2px solid ${this.templateData.colors.btnColor}`,
  //         "padding":"8px 15px",
  //         "height":"44px",
  //         "font-size":"16px"
  //         // "border-radius":"none"
  //       }
  //     }
  //     else{
  //       data.style = {
  //         "background-color":"transparent",
  //         "color":this.templateData.colors.btnTextColor,
  //         "font-family":this.templateData.bodyFontFamily,
  //         "border-radius":`10px`,
  //         "padding":"8px 15px",
  //         "border":`2px solid ${this.templateData.colors.btnColor}`,
  //         "height":"44px",
  //         "font-size":"16px"
  //       }
  //     }
  //   }
  // }

  showMessage(){
    this._matPopup.open(
      'You need to signup to access this feature',
      'Ok'
    )
  }
  redirectEmail(){
    window.location.href = `mailto:${this.data[8].value}`
  }
  redirectMobile(){
    window.location.href = `tel:${this.data[6].value}`
  }
}
