<div>
    <div class="business-heading">
        <div class="business_tag" style="font-size: 25px;">
            {{businessName.split('')[0] | uppercase}}
        </div>
        <div class="header">
            <div>
                <div class="business-name">{{businessName}}</div>
                <div class="change-business" data-bs-toggle="offcanvas" data-bs-target="#addPageBottomSheet"
                    aria-controls="addPageBottomSheet">Switch Business &nbsp;&nbsp;
                    <mat-icon>keyboard_arrow_down</mat-icon></div>
            </div>
            <img src="../../../../../assets/images/sidepanel/account.svg" alt="accounts image"
                (click)="businessDetails()">
        </div>
    </div>
    <div class="explore-apps">Explore Apps</div>
    <ng-container *ngFor="let data of apps">
        <div class="other-apps" (click)="switchToApp(data.name)">
            <div class="apps">
                <div *ngIf="data.image === ''" class="business_tag">
                    <mat-icon>{{data.icon}}</mat-icon>
                </div>
                <div *ngIf="data.image != ''" >
                  <img style="width: 50px" src="{{data.image}}">
              </div>
                <div>
                    <div class="business-name">{{data.name}}</div>
                    <div class="change-business" style="color: rgba(20, 20, 20, 0.3);">{{data.desc}}</div>
                </div>
            </div>
        </div>
    </ng-container>
</div>

<div class="offcanvas offcanvas-bottom page-bottom-sheet" style="width: 100%;height: auto!important;overflow: scroll;"
    tabindex="-1" id="addPageBottomSheet" aria-labelledby="addPageBottomSheetLabel">
    <div class="heading_section" style="border-bottom: none;">
        <div class="heading">
            Business
        </div>
        <div class="closing_bar" id="closeBottomSheet" data-bs-dismiss="offcanvas" data-bs-target="#addPageBottomSheet"
            aria-label="Close"><mat-icon>close</mat-icon></div>
    </div>
    <div class="hr_line"></div>
    <div *ngFor="let item of allBusiness">
        <div class="heading_section" (click)="switchBusiness(item)">
            <div class="heading" [style.color]="item.status ? '#0496FF' : ''">
                {{item.name}}
            </div>
            <div class="done_btn">
                <button class="home-icon"><mat-icon
                        [style.color]="item.status ? '#0496FF' : ''">keyboard_arrow_right</mat-icon></button>
            </div>
        </div>
    </div>
    <div class="button-parent">
        <button class="manage-business" (click)="goToListing()">Manage</button>
        <button class="create-business" (click)="createNewBusiness()">Create New</button>
    </div>
</div>
