<section class="main_section">
    <div class="image_section">
        <img src="./../../../../assets/images/common/96237-success.gif" alt="loader">
    </div>
    <div class="success_heading">
        Payment Successfull
    </div>
    <div class="payment_sub_text">
        Your payment for the Starter subscription has been successfully processed. You will now have access to all of the features and benefits of the subscription
    </div>
    <div class="plan_description">
        <div class="title_value">
            <div class="plan_title">
                Subscribed Plan
            </div>
            <div class="plan_value">
                {{planName | titlecase}}
            </div>
        </div>
        <div class="title_value">
            <div class="plan_title">
                Amount Paid
            </div>
            <div class="plan_value">
                <span>₹</span><span>{{planAmount}}</span>
            </div>
        </div>
        <div class="title_value">
            <div class="plan_title">
                Valid till
            </div>
            <div class="plan_value">
                {{validity | date}}
            </div>
        </div>
    </div>
</section>