<section>
  <div class="content-section" *ngIf="colorPallet">
    <div class="tabs">
      <div [ngClass]="{'selected-tab' : selectedTab === 'Theme', 'left-tab-border' : selectedTab === 'Theme'}" (click)="changeTabs('Theme')">From theme</div>
      <div [ngClass]="{'selected-tab' : selectedTab === 'Custom', 'right-tab-border' : selectedTab === 'Custom'}" (click)="changeTabs('Custom')">Custom</div>
    </div>

    <ng-container *ngIf="selectedTab === 'Theme'">
      <div *ngFor="let theme of themes" class="mt-10" (click)="selectTheme(theme)" [ngClass]="{'selectedTheme' : templatePage[0].websiteThemeId === theme.id}">
        <div class="pallet-parent">
          <div *ngFor="let style of theme.componentStyles;let i = index" class="w-25">
            <div class="color-pallet" [ngStyle]="{'background-color' : style.bgColor}" [ngClass]="{ 'left-pallet-border': i === 0, 'right-pallet-border': i === 3 }">
              <div class="accent-color">
                <div [ngStyle]="{'background-color' : style.accentColor}"></div>
                <p>Dd</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="selectedTab === 'Custom'">
      <div class="custom-pallet mt-20">
        <div *ngFor="let style of selectedTheme;let i = index" (click)="editColorPallet(style, i)" class="selected-pallet" [ngStyle]="{'background-color' : style.bgColor}">
          <div class="accent-color">
            <div [ngStyle]="{'background-color' : style.accentColor}"></div>
            <p>Dd</p>
          </div>
          <mat-icon class="edit-icon">edit</mat-icon>
        </div>
      </div>
    </ng-container>

  </div>
  <div *ngIf="!colorPallet">
    <div class="custom-header">
      <div (click)="colorPallet=true"><mat-icon>keyboard_arrow_left</mat-icon></div>
      <p class="header-text">Color Pallet</p>
    </div>
    <div class="mt-20">
      <p class="heading">Background</p>
      <div class="text-background">
        <input type="color" class="color" [(ngModel)]="selectedBgColor"
          (ngModelChange)="changeBackground()">
        <input type="text" class="color-code" [(ngModel)]="selectedBgColor" disabled>
      </div>
    </div>
    <hr class="line">
    <div class="mt-20">
      <p class="heading">Accent</p>
      <div class="text-background">
        <input type="color" class="color" [(ngModel)]="selectedAccentColor"
          (ngModelChange)="changeAccentColor()">
        <input type="text" class="color-code" [(ngModel)]="selectedAccentColor" disabled>
      </div>
    </div>
    <hr class="line">
    <div>
      <p class="heading">Preview</p>
      <div class="color-pallet" [ngStyle]="{'background-color' : selectedBgColor}">
        <div class="accent-color">
          <div [ngStyle]="{'background-color' : selectedAccentColor}"></div>
          <p>Custom</p>
        </div>
      </div>
    </div>
  </div>
</section>
