import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-hover-cards',
  templateUrl: './hover-cards.component.html',
  styleUrls: ['./hover-cards.component.scss']
})
export class HoverCardsComponent implements OnInit{

  @Input() dynamicData : any;

  constructor(

  ){}

  ngOnInit(): void {
  }

}
