import { Component, Optional, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { TemplateService } from 'src/app/services/template.service';

@Component({
  selector: 'app-create-new-page',
  templateUrl: './create-new-page.component.html',
  styleUrls: ['./create-new-page.component.scss']
})
export class CreateNewPageComponent {

  constructor(
    private dialogRef: MatDialogRef<CreateNewPageComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private bottomSheetRef: MatBottomSheetRef<CreateNewPageComponent>,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public bottomData: any,
    private templateService : TemplateService
  ){
    this.screenSize()
  }

  screenWidth: any;
  subId: any;
  pageList: any[] = [];
  @HostListener('window: resize', ['$event'])
  screenSize(event?: any){
    this.screenWidth = window.innerWidth;
  }

  ngOnInit(){
    this.subId = this.data?.subId;
    this.getAllPage()
  }

  closeBtn(){
    if(this.screenWidth > 475){
      this.dialogRef.close();
    }
    if(this.screenWidth < 475){
      this.dialogRef.close();
    }
  }

  getAllPage(){
    this.templateService.pageNameCategories(this.subId).subscribe((res: any) => {
      this.pageList = res.data.filter(page => page.name !== 'Home');
    })
  }

  addPage(page: any){
    this.dialogRef.close({page: page});
  }
}
