import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import Typewriter from 'typewriter-effect/dist/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit{

  constructor(){}

  @Input() textLoop: string[] = [
    'Welcome to <strong style="color:blue">SIMPO AI</strong>',
    'Sit back and relax, it will take a few seconds',
    'Generating best website for your category'
  ];

  ngOnChanges(changes: SimpleChanges) {
    if (!changes['textLoop'].currentValue || !changes['textLoop'].currentValue.length) {
      this.textLoop = [
        'Welcome to <strong style="color:blue">SIMPO AI</strong>',
        'Sit back and relax, it will take a few seconds',
        'Generating best website for your category'
      ]
    }
  }

  ngOnInit(): void {
    var app = document.getElementById('app');

    var typewriter = new Typewriter(app, {
      loop: true,
      delay: 30,
    });

    this.textLoop.forEach((elem) => {
      typewriter
      .typeString(`${elem}`)
      .pauseFor(1000)
      .deleteAll()
      .start()
    });
  }
  
}
