<section class="body_section">
    <div class="big_title">
        Build & Launch your fully functional website in 3 click with AI
    </div>
    <div class="page_small_title">
        Simpo website builder powered by ai, Creates SEO friendly and fully responsive website with high quality content
        and fully functional in seconds
    </div>
    <div class="button-section">
        <div class="left-side">
            <!-- <img src="./../../../assets/images/home_page/main_button.svg" alt="main_button" (click)="go()" /> -->
            <button (click)="go()">
                Generate Website <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
        </div>
        <div class="right-side">
            <button class="demo-button"  (click)="redirectToCalendly()">
                Book Product Demo
            </button>
        </div>
    </div>
    <div class="banner-image">
        <img src="./../../../../assets/images/home_page/hero_banner_banner.svg" alt="hero_banner" />
    </div>
</section>