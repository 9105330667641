<section class="main-section">
    <!-- header-section  -->
    <div class="header-main-section">
        <div class="header-left-side">
            Tag Details
        </div>
        <div class="header-right-side">
            <div class="left-side-btn">
                <div class="delete-btn"><img src="../../../../../../../assets/images/master/delete_icon.svg"
                        alt="delete_icon" (click)="deleteTag(dataSource)" /></div>
                <div class="vertical-line"></div>
                <div class="edit-btn"><img src="../../../../../../../assets/images/master/edit_icon.svg" alt="edit_icon"
                        (click)="editTag(dataSource)" /></div>

            </div>
            <div class="close-btn">
                <img src="../../../../../../../assets/images/close.svg" alt="close_svg" (click)="closeBtn()" />
            </div>
        </div>
    </div>

    <!-- header middle heading  -->
    <div class="header-middle-section">
        <div class="header-sub-heading">
            {{dataSource?.blogTag?.tagName?dataSource?.blogTag?.tagName:'N/A' | titlecase}}
        </div>
        <div class="header-sub-text">
           {{dataSource?.blogTag?.description?dataSource?.blogTag?.description:'N/A'}}
        </div>
    </div>

    <!-- blog section  -->
    <div class="blog-main-section">
        <div class="blog-section">
            <div class="blog-left-section">
                Blogs Created :
            </div>
            <div class="blog-right-section">
                {{dataSource?.blogTag?.blogsCreatedCount ?  dataSource?.blogTag?.blogsCreatedCount : 'N/A'}}
            </div>
        </div>
        <div class="blog-section">
            <div class="blog-left-section">
                Created On :
            </div>
            <div class="blog-right-section">
                {{dataSource?.blogTag?.createdTimeStamp | date : 'mediumDate'}}
            </div>
        </div>
        <!-- <div class="blog-section">
            <div class="blog-left-section">
                Slug :
            </div>
            <div class="blog-right-section">
                {{dataSource.description}}
            </div>
        </div> -->
    </div>

    <hr>

    <!-- blog-bottom-section  -->
    <ng-container *ngIf="dataSource?.blogList?.length > 0 ; else emptyScreen">
    <div class="blog-bottom-section">
        <div class="blog-bottom-heading">
            Blogs
        </div>
        <div class="blog-bottom-text">
            All blogs created by {{dataSource?.blogTag?.tagName }}
        </div>
        <div class="blog-bottom-form" *ngFor="let blogs of dataSource.blogList">
            <!-- <img src="../../../../../../../../../assets/images/blogs/blog_image.svg" alt="blog_svg" /> -->
            <div class="blog-form-heading">
                {{blogs?.postTitle  ? blogs?.postTitle : 'N/A'| titlecase}}
            </div>
            <div class="form-author-section">
                <div class="form-left">
                    <div class="form-author-left">
                        Tag :
                    </div>
                    <div class="form-author-right">
                        {{ dataSource?.blogTag?.tagName }}
                    </div>
                </div>
                <div class="form-right">
                    <div class="form-right-left">
                        <img src="../../../../../../../../../assets/images/blogs/Vector.svg" alt="vector_svg" />
                    </div>
                    <div class="form-right-right">
                        {{blogs.blogStatus ? blogs.blogStatus : 'N/A'}}
                    </div>
                </div>
            </div>
            <div class="blog-position-section">
                <div class="position-section">
                   {{blogs.createdTimeStamp | date : 'mediumDate'}}
                </div>
                <div class="words-section">
                 {{blogs.wordCount ? blogs.wordCount : 'N/A'}} Words
                </div>
                <div class="read-section">
                {{blogs.readTime ? blogs.readTime : 'N/A'}}  mins read
                </div>
                <div class="edited-section">
                    Edited  on {{blogs.updatedTimeStamp | date : 'mediumDate'}}
                </div>
            </div>
        </div>
    </div>
    </ng-container>
    <ng-template #emptyScreen>
        <div class="empty-screen-section">
            No Any Blog Yet !!
        </div>
    </ng-template>
</section>
