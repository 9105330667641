import { Component, HostListener, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BlogServiceService } from '../blog-service.service';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';

@Component({
  selector: 'app-delete-blog',
  templateUrl: './delete-blog.component.html',
  styleUrls: ['./delete-blog.component.scss']
})
export class DeleteBlogComponent {

  constructor(
    private dialogRef: MatDialogRef<DeleteBlogComponent>,
    private _bottomSheetRef: MatBottomSheetRef<DeleteBlogComponent>,
    @Optional()   @Inject(MAT_DIALOG_DATA) public data,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData: any,
    private blogService:BlogServiceService,
    private router: Router
  ){
    this.getScreenSize();
  }

  isLoading: boolean = false;

  screenWidth: any;

  @HostListener("window:resize",["$event"])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  deleteBlog() {
    this.isLoading = true;
      this.blogService.deleteImage(this.screenWidth > 475 ? this.data.blogId : this.bottomSheetData.blogId).subscribe(
        (res:any)=>{
          this.blogService.openSnack("Blog deleted successfully","Close");
          if (this.screenWidth > 475){
            this.dialogRef.close({ended:"success"});
          }
          if (this.screenWidth < 475){
            this._bottomSheetRef.dismiss({ended:"success"});
          }
          this.router.navigate(['admin/website/blogs/listing']);
          this.isLoading = false;
        },
        (err)=>{
          this.isLoading = false;
          //console.log("error orccured");
        }
      )
  }

  cancelBlog(){
    if(this.screenWidth > 475){
      this.dialogRef.close(this.data);
    }
    if(this.screenWidth < 475){
      this._bottomSheetRef.dismiss(this.bottomSheetData);
    }
  }
}
