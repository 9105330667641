import { Component, Input, HostListener, OnInit } from '@angular/core';
import { ALIGN, ActionModel, ButtonStyleModel, CARD_SIZE, EventsService, FIT, LAYOUTPOSITION, TextPosition, ImageFit } from 'simpo-component-library';
import { animationSpeed, animationType, aspectRatio, borderType, cardSize, contentAlignment, corners, flexWrapOptions, footerType, imageFit, mapStyle, minHeight, navigationStyle, productCardTheme, spacingType, textPosition } from './style-editor.modal';
import { TemplateService } from 'src/app/services/template.service';
import { BackgroundColorEditorComponent } from '../background-color-editor/background-color-editor.component';
import { ButtonStyleEditorComponent } from '../button-style-editor/button-style-editor.component';
import { LogoEditorComponent } from '../logo-editor/logo-editor.component';

@Component({
  selector: 'app-style-editor',
  templateUrl: './style-editor.component.html',
  styleUrls: ['./style-editor.component.scss']
})
export class StyleEditorComponent implements OnInit {
  @Input() style:any;
  @Input() componentId: any;
  @Input() action?: ActionModel;
  @Input() sectionType: string | null = null;

  imageFit = imageFit;
  aspectRatio = aspectRatio;
  corners = corners
  cardSize = cardSize;
  textPosition = textPosition
  animationSpeed = animationSpeed
  animationType = animationType
  spacingType = spacingType
  minHeight = minHeight
  flexWrapOptions = flexWrapOptions;
  borderType = borderType
  TextPosition = TextPosition
  ImageFit = ImageFit
  contentAlignment = contentAlignment
  footerType = footerType
  navigationStyle = navigationStyle
  mapStyle = mapStyle;
  productCardTheme = productCardTheme

  // Slider related data
  max = 100;
  min = 0;
  showTicks = false;
  step = 1;
  maximumProduct = 20;
  maxElementInRow = 5;
  borderMax = 20;
  borderRadiusMax = 200;

  constructor(private eventEmitter: EventsService,
    private dialogSercvice: TemplateService){
      this.screenSize()
    }

    @HostListener('window:resize', ['$event'])
    screenSize(event?: any){
      this.screenWidth = window.innerWidth
    }
    screenWidth: any;

    ngOnInit(): void {
      console.log(this.componentId)
    }

  toggleBackgroundImage() {
    console.log(this.componentId)
    this.eventEmitter.backgroundImageChangeCheck.emit({
      data: this.style.background,
      id: this.componentId
    });
  }

  changeTextAlignment(textAlign: ALIGN){
    this.style.layout.align = textAlign;
    this.eventEmitter.alignmentChangeChecks.emit({id: this.componentId, data: this.style.layout})
  }

  changeImageStyle(){
    this.eventEmitter.imageStyleChangeChecks.emit({id: this.componentId, style: this.style.image})
  }

  changeCorners(){
    this.eventEmitter.cornerChangeChecks.emit({id: this.componentId, size: this.style.corners})
  }

  changeCardSize(size: CARD_SIZE){
    this.style.size = size;
    this.eventEmitter.cardSizeChangeChecks.emit({id: this.componentId, size: this.style.size})
  }

  changeTextBackground(){
    this.eventEmitter.textBackgroundChangeChecks.emit({id: this.componentId, textBackground: this.style.textBackground})
  }

  changeAnimationType() {
    this.eventEmitter.animationChangeChecks.emit({id: this.componentId,data: this.style.animation})
  }

  changeAnimationSpeed(speed: string){
    this.style.animation.speed = speed;
    this.changeAnimationType()
  }

  changeSpacingType() {
    // if (direction === 'top') {
    //   this.style.layout.spacing.top = value;
    // } else if (direction === 'bottom') {
    //   this.style.layout.spacing.bottom = value;
    // }
    this.eventEmitter.spacingChangeChecks.emit({ id: this.componentId, data: this.style.layout });
  }


  changeMinHeight(height: FIT){
    this.style.layout.fit = height;
    this.eventEmitter.spacingChangeChecks.emit({id: this.componentId,data: this.style.layout})
  }
  changeWrapOption(wrap: string) {
    this.style.direction = wrap;
    // this.eventEmitter.wrapDirection.emit(this.componentId);
  }

  changeBorder(){
    this.eventEmitter.borderChangeChecks.emit({id: this.componentId, data: this.style.border})
  }

  changeBorderType(borderType: string){
    this.style.border.type = borderType;
    this.eventEmitter.borderChangeChecks.emit({id: this.componentId, data: this.style.border})
  }

  changeLayoutAlignment(value: LAYOUTPOSITION){
    this.style.positionLayout.value = value;
    this.eventEmitter.postionLayoutChangeChecks.emit({id: this.componentId, data: this.style.positionLayout})
  }

  changeContentAlignment(){
    this.eventEmitter.contentAlignmentChangeCheck.emit({id: this.componentId,data: this.style.contentAlignment});
  }

  openBackgroundColorEditor(){
    if(this.screenWidth > 475){
      this.dialogSercvice.openDialog(BackgroundColorEditorComponent,'auto',
      '430px', {id: this.componentId, background: this.style.background, action: this.action});
    }
    if(this.screenWidth < 475){
      this.dialogSercvice.openDialog(BackgroundColorEditorComponent,'100vh',
      '430px', {id: this.componentId, background: this.style.background, action: this.action});
    }
  }

  openButtonStyleEditor(buttonStyle: ButtonStyleModel,  btnId: string){
    this.dialogSercvice.openDialog(ButtonStyleEditorComponent, 'auto',
      '430px', {id: this.componentId, btnId: btnId, buttonStyle: buttonStyle, color: this.style.background.accentColor});
  }

  changeMapStyle(){
    this.eventEmitter.mapStyleChangeCheck.emit(this.style.mapStyle);
  }

  changeTextPosition() {
    if(this.style.textPosition != TextPosition.BELOW_IMAGE) {
      this.style.image.fit = ImageFit.Cover;
      this.changeImageStyle()
    }
  }
}
