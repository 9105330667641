export interface MediaSelectorDialogDataType {
  multiple: boolean;
}

export enum MediaSource {
  'UNSPLASH' = 'UNSPLASH',
  'SHUTTER_STOCK' = 'SHUTTER_STOCK',
  'DEVICE' = 'DEVICE',
}

export interface Image {
  width: number;
  height: number;
  url: string;
}

export interface Author {
  id: string;
  userName: string | null;
  name: string | null;
  twitterUserName: string | null;
  portFolioUrl: string | null;
  instagramUserName: string | null;
}

export interface Media {
  id?: string;
  businessId: string;
  uniqueId: string;
  blurHash: string | null;
  assets: Image[];
  author?: Author | null;
  media: MediaSource;
  description: string;
  altDescription: string;
  selected: boolean;
  file?: File;
}
