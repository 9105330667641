import { NOTIFY, PRIORITY, TASK_END_ON, TASK_REPEAT, TASK_STATUS, TaskRepeat } from './../../../../crm-models';
import { Component, HostListener, Inject, PipeTransform } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { DbService } from 'src/app/services/db-service/db.service';
import { CrmServiceService } from '../../../../service/crm-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, map, startWith } from 'rxjs';
import { StaffServiceService } from 'src/app/master-config-components/micro-apps/staff/service/staff-service.service';
import { Task } from '../../../../crm-models';
import moment from 'moment';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { StorageKeys } from 'src/app/shared-module-files/simpo.constant';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { MomentDateAdapter } from "@angular/material-moment-adapter";

export const MY_FORMATS = {
  parse: {
    dateInput: "DD-MM-YYYY"
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMM YYYY",
  }
};

@Component({
  selector: 'app-create-task',
  templateUrl: './create-task.component.html',
  styleUrls: ['./create-task.component.scss', './mq-create-task.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    DatePipe
  ]
})
export class CreateTaskComponent {
  constructor(
    public dialogRef: MatDialogRef<CreateTaskComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dbService: DbService,
    private crmService: CrmServiceService,
    private snackbar: MatSnackBar,
    private staffService: StaffServiceService,
  ) {

  }
  createLoader: boolean = false;

  ngOnInit() {

    this.getStaffListing();
    if (this.data.data.edit && this.data.data.type !== 'ACTIVITY') {
      this.taskDetails = JSON.parse(JSON.stringify(this.data.data.data));
    }
    if (this.data.data.type == 'ACTIVITY' && this.data.data.edit) {
      this.getTaskDetails()
    }
    if ((this.data.data.type == 'TASKS' || this.data.data.type == 'ACTIVITY' || this.data.data.type == 'ALL_TASKS') && !this.data.data.edit) {
      this.taskDetails.parentId = this.dbService.getLeadDetails().id;
      this.taskDetails.parentName = this.dbService.getLeadDetails().personalInfo.name;
      this.selectedContact = this.dbService.getLeadDetails().personalInfo.name;
    }

    if (this.data.data.edit && this.data.data.type === 'ALL_TASKS') {
      this.taskDetails = JSON.parse(JSON.stringify(this.data.data.data));
      this.selectedContact = this.taskDetails.parentName;
    }
    
    this.completedStatus = this.taskDetails?.taskStatus == TASK_STATUS.COMPLETED;

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
    this.updateTimeOptions(); 
  }

  myControl = new FormControl('');
  options: any[] = []
  filteredOptions: Observable<any> | undefined;

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.personalInfo.name.toLowerCase().includes(filterValue));
  }
  minDate: Date = new Date();
  // selectedOption: string ="INDIVIDUAL";
  selectedContact = ""
  isTaskRepeated: boolean = false;
  setReminder: any;
  showReminder: boolean = false;
  priorityList = [
    { value: PRIORITY.HIGH, viewValue: "High" },
    { value: PRIORITY.MEDIUM, viewValue: "Medium" },
    { value: PRIORITY.LOW, viewValue: "Low" },
  ]

  pageNo = 0;
  size = 100;
  staffList: any = [];
  getStaffListing() {
    this.staffService.getStaffListing(this.dbService.getBusinessId(), this.pageNo, this.size, 'ACTIVE').subscribe({
      next: (res: any) => {
        this.staffList = res.data.data;
      },
      error: (err: any) => {
        this.snackbar.open(
          `${err.error.message}`,
          'Close',
          {
            duration: 1500
          }
        )
      }
    })
  }

  changeStaff() {
    for (let ele of this.staffList) {
      if (ele.id === this.taskDetails.assignedTo) {
        this.taskDetails.assignToMailId = ele.contact.email;
        this.taskDetails.assignedName = ele.name;

        break;
      }
    };
  }

  status = [
    { value: TASK_STATUS.NEW, viewValue: "New" },
    { value: TASK_STATUS.NOT_STARTED, viewValue: "Not Started" },
    { value: TASK_STATUS.IN_PROGRESS, viewValue: "In progress" },
    { value: TASK_STATUS.COMPLETED, viewValue: "Completed" },
    { value: TASK_STATUS.WAITING_FOR_INPUT, viewValue: "Waiting for input" },
  ]

  repeatType = [
    { value: TASK_REPEAT.DAILY, viewValue: "Daily" },
    { value: TASK_REPEAT.WEEKLY, viewValue: "Weekly" },
    { value: TASK_REPEAT.MONTHLY, viewValue: "Monthly" },
    { value: TASK_REPEAT.YEARLY, viewValue: "Yearly" },
  ]

  reminderAlert = [
    { value: NOTIFY.EMAIL, viewValue: "Email" }
  ]


  
  timeArray: { value: string; viewValue: string; disabled?: boolean }[] =  [
    { value: "12", viewValue: "12:00 AM" },
    { value: "01", viewValue: "1:00 AM" },
    { value: "02", viewValue: "2:00 AM" },
    { value: "03", viewValue: "3:00 AM" },
    { value: "04", viewValue: "4:00 AM" },
    { value: "05", viewValue: "5:00 AM" },
    { value: "06", viewValue: "6:00 AM" },
    { value: "07", viewValue: "7:00 AM" },
    { value: "08", viewValue: "8:00 AM" },
    { value: "09", viewValue: "9:00 AM" },
    { value: "10", viewValue: "10:00 AM" },
    { value: "11", viewValue: "11:00 AM" },
    { value: "13", viewValue: "1:00 PM" },
    { value: "14", viewValue: "2:00 PM" },
    { value: "15", viewValue: "3:00 PM" },
    { value: "16", viewValue: "4:00 PM" },
    { value: "17", viewValue: "5:00 PM" },
    { value: "18", viewValue: "6:00 PM" },
    { value: "19", viewValue: "7:00 PM" },
    { value: "20", viewValue: "8:00 PM" },
    { value: "21", viewValue: "9:00 PM" },
    { value: "22", viewValue: "10:00 PM" },
    { value: "23", viewValue: "11:00 PM"},
  ]



  reminderDay = [
    { value: "1", viewValue: "1 day" },
    { value: "2", viewValue: "2 days" },
    { value: "3", viewValue: "3 days" },
    { value: "4", viewValue: "4 days" },
    { value: "5", viewValue: "5 days" },
    { value: "6", viewValue: "6 days" },
  ]

  MandatoryFields(): boolean {
    return !(this.taskDetails.name && 
              this.taskDetails.dueDate && 
              this.taskDetails.assignedTo && 
              this.taskDetails.description &&
              (this.data.data.type == 'ALL_TASKS' ? this.participantType : true) &&
              this.selectedContact);
  }

  taskRepeatText: string = '';
  taskRepeatDetails: TaskRepeat = {
    startRemainder: "",
    endRemainder: "",
    taskRepeat: TASK_REPEAT.DAILY,
    taskEndOn: TASK_END_ON.AFTER_N_TIME,
    repeatCount: 1,
    endsOn: moment().add(1, 'days').format('YYYY-MM-DD'),
    taskStatus: null
  }

  taskDetails: Task = {
    // id: "",
    name: "",
    priority: PRIORITY.HIGH,
    dueDate: "",
    tasksRepeat: null,
    notify: NOTIFY.EMAIL,
    taskStatus: TASK_STATUS.NEW,
    description: "",
    parentId: "",
    parentName: "",
    createdById: '',
    createdByName: '',
    assignedTo: "",
    assignToMailId: "",
    assignedName: "",
    businessId: "",
    reminderDate: ""
  }
  repeatNumer: boolean = false
  addLoader: boolean = false;

  selectedTime: string = '';

  toggleTaskRepeat(): void {

    if (!this.isTaskRepeated) {
      this.taskRepeatText = '';
    }
  }

  private prepareTaskRepeatText() {
    const titlecasePipe = new TitleCasePipe();

    this.taskRepeatText = '';

    this.taskRepeatText += titlecasePipe.transform(this.taskRepeatDetails.taskRepeat.split('_').join(' '));
    this.taskRepeatText += ', '

    if (this.taskRepeatDetails.taskEndOn === TASK_END_ON.NEVER) {
      this.taskRepeatText += 'Ends ' + titlecasePipe.transform(this.taskRepeatDetails.taskEndOn.split('_').join(' '));
    } else if (this.taskRepeatDetails.taskEndOn === TASK_END_ON.AFTER_N_TIME) {
      this.taskRepeatText += `Ends after ${this.taskRepeatDetails.repeatCount} time(s)`;
    } else {
      const datePipe = new DatePipe('en-US');
      this.taskRepeatText += `Ends On ${datePipe.transform(this.taskRepeatDetails.endsOn, 'mediumDate')}`
    }
  }

  saveTaskRepeatDetails() {
    this.prepareTaskRepeatText();

    this.closeTaskRepeatPopup();
  }

  closeTaskRepeatPopup() {
    const element = document.getElementById('TaskrepeatMenuTrigger');

    element?.click();
  }

  // selectTime(item: any) {
  //   this.taskDetails.reminderDate = moment(this.taskDetails.reminderDate).set({ hour: item.value, minute: 0 }).format();
  // }

  get isInValid(): boolean {
    return (
      !this.taskDetails.name ||
      !this.taskDetails.dueDate ||
      !this.taskDetails.assignedTo ||
      !this.taskDetails.description
    );
  }

  private get processTaskDetails(): Task {
    const taskDetails: Task = JSON.parse(JSON.stringify(this.taskDetails));

    if (!this.isTaskRepeated) {
      taskDetails.tasksRepeat = null;
    } else {
      taskDetails.tasksRepeat = { ...this.taskRepeatDetails };

      if (this.taskRepeatDetails.taskEndOn === TASK_END_ON.ON_DATE) {
        taskDetails.tasksRepeat.endsOn = moment(taskDetails.tasksRepeat.endsOn).format();
      }
    }

    taskDetails.createdById = localStorage.getItem(StorageKeys.STAFF_ID) || '';
    taskDetails.createdByName = localStorage.getItem(StorageKeys.STAFF_NAME) || '';
    taskDetails.businessId = this.dbService.getBusinessId() || '';

    taskDetails.dueDate = moment(taskDetails.dueDate).format();

    if (taskDetails.reminderDate) {
      taskDetails.reminderDate = moment(taskDetails.reminderDate).format();
    }

    return taskDetails;
  }

  createTask() {
    
    if (this.isInValid || !this.taskDetails.parentId) {
      this.crmService.openSnack("Please fill all manadatory details", "Ok");
      return;
    };

    // this.addLoader = true;
    this.createLoader = true;

    const taskDetails: Task = this.processTaskDetails;

    this.crmService.createTask(taskDetails).subscribe(
      {
        next: (response: any) => {
          this.dialogRef.close("success");
          this.addLoader = false;
        },
        error: (err: any) => {
          // this.addLoader = false;
          this.createLoader = false;
          this.snackbar.open(`${err.error.message}`, 'close', {
            duration: 1500,
          });
        }
      }
    )
  }


  completedStatus: boolean = false;
  editTask() {
    if (this.completedStatus && this.taskDetails.taskStatus == TASK_STATUS.COMPLETED) {
      this.snackbar.open('Cannot create task', 'close', {
        duration: 1500
      });
    }
    else{
      if (this.isInValid || !this.taskDetails.parentId) {
        this.crmService.openSnack("Please fill all manadatory details", "Ok");
        return;
      };
  
      // this.addLoader = true;
      this.createLoader = true;
  
      const taskDetails: Task = this.processTaskDetails;
  
      this.crmService.editTask(taskDetails).subscribe(
        {
          next: (response: any) => {
            this.dialogRef.close();
          },
          error: (err: any) => {
            // this.addLoader = false;
            this.createLoader = false;
            this.snackbar.open(`${err.error.message}`, 'close', {
              duration: 1500,
            });
          }
        }
      )
    }
  }
  cancel() {
    this.dialogRef.close()
  }
  participantType: any = "LEAD"
  type = [
    { value: "LEAD", viewValue: "Leads" },
    { value: "CUSTOMER", viewValue: "Customers" }
  ]
  getContacts() {
    this.crmService.searchLeadsCustomers(this.dbService.getBusinessId(), 0, 1000, this.participantType, this.selectedContact).subscribe(
      (res: any) => {
        this.options = [];
        this.options = res.data.data;

        this.filteredOptions = this.myControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value || '')),
        );
      },
      (err) => {
        this.snackbar.open(
          `${err.error.message}`,
          'Close',
          { duration: 1500 }
        )
      }
    )
  }



  contactSelected(contactData: any) {
    this.taskDetails.parentName = contactData.personalInfo.name;
    this.taskDetails.parentId = contactData.id;
  }
  repeatNumber = "";
  repeatDate = "";

  getTaskDetails() {
    this.crmService.getTaskDetails(this.data.data.taskId).subscribe(
      {
        next: (response: any) => {
          this.taskDetails = response.data;
          this.fillInitialTaskDetails();
        }
      }
    )
  }

  private fillInitialTaskDetails() {
    if (this.taskDetails.tasksRepeat) {
      this.isTaskRepeated = true;
      this.taskRepeatDetails = this.taskDetails.tasksRepeat;
      this.prepareTaskRepeatText();
    }

    if (this.taskDetails.reminderDate) {
      this.selectedTime = moment(this.taskDetails.reminderDate).get('hour').toString().padStart(2, '0');
    }
  }

  updateTimeOptions() {
    const now = moment();
    const selectedDate = moment(this.taskDetails.reminderDate);

    if (selectedDate.isSame(now, 'day')) {
      const currentHour = now.hour();
      this.timeArray.forEach(time => {
        time.disabled = parseInt(time.value) <= currentHour;
      });
    } else {
      this.timeArray.forEach(time => {
        time.disabled = false;
      });
    }
  }

  onReminderDateChange() {
    this.updateTimeOptions();
    this.selectedTime = '';
    this.taskDetails.reminderDate = moment(this.taskDetails.reminderDate).set({ hour: 0, minute: 0 }).format();
  }

  selectTime(item: any) {
    if (!item.disabled) {
      this.taskDetails.reminderDate = moment(this.taskDetails.reminderDate).set({ hour: parseInt(item.value), minute: 0 }).format();
    }
  }
}
