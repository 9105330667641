import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-input-element',
  templateUrl: './input-element.component.html',
  styleUrls: ['./input-element.component.scss']
})
export class InputElementComponent implements OnInit{

  @Input() responseData : any;

  emailRegex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

  data : any;

  constructor(){}

  ngOnInit(): void {
    this.data = this.responseData;
  }

}
