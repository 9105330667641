<section class="p-3">
    <div class="d-flex justify-content-between align-items-center">
        <h6 class="fw-bold" style="font-size: 22px;">Filters</h6>
        <span class="cursor-pointer" (click)="closeFilter()"><img src="../../../../../../../../assets/images/common/close-img.svg" alt="Close"></span>
    </div>

    <div class="d-flex flex-column">
        <div class="mt-2">
            <h6 class="text-dark" style="font-size: 16px; color: black;">Author</h6>
            <mat-form-field class="custom-mat-form-field simpo-mat-chip w-100" appearance="outline">
                <mat-chip-grid #authorChipGrid aria-label="Author selection">
                  <mat-chip-row *ngFor="let author of authors" (removed)="removeAuthor(author)">
                    <strong>{{ author.fieldText }}</strong>
                    <button matChipRemove [attr.aria-label]="'remove ' + author">
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip-row>
                  <input
                    placeholder="Pick author"
                    aria-label="Author"
                    [formControl]="authorControl"
                    [matAutocomplete]="auto1"
                    [matChipInputFor]="authorChipGrid"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    (matChipInputTokenEnd)="addNewAuthor($event)"
                    #AuthorInput
                  >
                  <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete">
                    <mat-option *ngFor="let option of filteredAuthors" [value]="option.authorName" (click)="selectAuthor(option, AuthorInput)">
                      {{option.authorName}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-chip-grid>
              </mat-form-field>
        </div>
        <div class="mt-2 filters">
            <h6 class="text-dark" style="font-size: 16px; color: black">Tags</h6>
            <mat-form-field class="custom-mat-form-field simpo-mat-chip w-100" appearance="outline">
                <mat-chip-grid #tagsChipGrid aria-label="Tags selection">
                  <mat-chip-row *ngFor="let tag of tags" (removed)="removeTag(tag)">
                    <strong>{{ tag.fieldText }}</strong>
                    <button matChipRemove [attr.aria-label]="'remove ' + tag">
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip-row>
                  <input placeholder="Add Tag..." [formControl]="tagControl" [matChipInputFor]="tagsChipGrid"
                  [matAutocomplete]="auto" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="addNewTag($event)" #TagInput/>
                </mat-chip-grid>
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let item of filteredTags" [value]="item.tagName" (click)="selectTag(item, TagInput)" (keyup.enter)="selectTag(item, TagInput)">
                    {{ item.tagName }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
        </div>
        <div class="mt-2">
            <h6 class="text-dark" style="font-size: 16px; color: black">Publish Date</h6>
            <mat-form-field class="w-100 form-field-h-40px custom-mat-form-field" appearance="outline" >
                <input matInput [matDatepicker]="picker" placeholder="Select a date" [(ngModel)]="publishedDate" style="margin-left: 2px;">
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
        </div>
    </div>

    <div class="d-flex justify-content-end align-items-center mt-3" *ngIf="screenWidth > 475">
        <span class="text-secondary cursor-pointer" (click)="clearFilters()">Clear</span>
        <span class="h-80 vr mx-3"></span>
        <span class="text-primary-text cursor-pointer" (click)="applyFilters()">Apply</span>
    </div>

    <div class="d-flex mt-3" *ngIf="screenWidth < 475" >
      <span class="text-secondary cursor-pointer" (click)="clearFilters()">Clear</span>
      <span class="h-80 vr mx-3"></span>
      <span class="text-primary-text cursor-pointer" (click)="applyFilters()">Apply</span>
  </div>
</section>
