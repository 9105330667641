import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cta-action',
  templateUrl: './cta-action.component.html',
  styleUrls: ['./cta-action.component.scss','./mq-cta-action.component.scss']
})
export class CtaActionComponent implements OnInit{

  constructor(
    private _router : Router
  ){
  }

  ngOnInit(){
      
  }
  
  redirectTo(){
    this._router.navigate(['business-type'])
  }
}
