import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { alignmentTabs, animationSpeed, animationTypes, height, spacing, tabs } from '../floating-editor/floating-editor.modal';
import { DomSanitizer } from '@angular/platform-browser';
import { EventsService } from 'simpo-component-library';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-multi-item-carousel',
  templateUrl: './multi-item-carousel.component.html',
  styleUrls: ['./multi-item-carousel.component.scss']
})
export class MultiItemCarouselComponent {
  
  @Input() data: any;
  @Output() editSingleBanner : EventEmitter<any> = new EventEmitter<any>();
  @Output() addNewItem: EventEmitter<any> = new EventEmitter<any>();
  tabs = tabs;
  alignmentTabs = alignmentTabs;
  animationTypes = animationTypes;
  animationSpeed = animationSpeed;
  spacing = spacing;
  height = height;
  selecteAnimation: string = animationTypes[0].type;
  selectedTab: string = 'CONTENT';
  Object =  Object;

  constructor(
    public ds: DomSanitizer,
    public eventEmitter: EventsService
  ) {}

  contentData : any;
  ngOnInit(): void {
    console.log(this.data);
    // this.contentData = this.data.content;
    // this.alignmentTabs.forEach(element => {
    //   if (element.name === this.data.styles.layout.layoutAlignment.value) {
    //     element.status = true;
    //   } else {
    //     element.status = false;
    //   }
    // });
    // this.animationSpeed.forEach(element => {
    //   if (element.name === this.data.styles.animation.speed) {
    //     element.status = true;
    //   } else {
    //     element.status = false;
    //   }
    // });
    // this.height.forEach(element => {
    //   if (element.name === this.data.styles.layout.fit) {
    //     element.status = true;
    //   } else {
    //     element.status = false;
    //   }
    // });
  }

  tabShift(tab: string) {
    this.tabs.forEach((element:any) => {
      if (element.name === tab) {
        element.status = true;
        this.selectedTab = tab;
      } else {
        element.status = false;
      }
    });
  }

  //background image toggle
  toggleChanged(ev: any) {
    this.data.styles.background.showImage = ev.checked;
    this.eventEmitter.backgroundImageChangeCheck.emit({
      data: this.data.styles.background,
      type:this.data.sectionType
    });
  }

  //alignment tabs
  changeAlignmentTab(item: string) {
    // this.alignmentTabs.forEach(element => {
    //   if (element.name === item) {
    //     element.status = true;
    //     this.data.styles.layout.layoutAlignment?.value = item;
    //     this.eventEmitter.alignmentChangeChecks.emit({
    //       data: this.data.styles.layout,
    //       type:this.data.sectionType
    //     });
    //   } else {
    //     element.status = false;
    //   }
    // });
  }

  changeAnimationType() {
    this.eventEmitter.animationChangeChecks.emit({
      data:this.data.styles.animation,
      type:this.data.sectionType
    });
  }

  changeAnimationSpeed(item: any) {
    this.animationSpeed.forEach((element:any) => {
      if (element.name === item) {
        element.status = true;
        this.data.styles.animation.speed = item;
        this.eventEmitter.animationChangeChecks.emit({
          data:this.data.styles.animation,
          type:this.data.sectionType
        });
      } else {
        element.status = false;
      }
    });
  }

  //spacing Releate functions

  spacingTop() {
    this.eventEmitter.spacingChangeChecks.emit({
      data: this.data.styles.layout,
      type:this.data.sectionType
    });
  }

  changesingleHeightTab(item: any) {
    this.height.forEach((element:any) => {
      if (element.name === item) {
        element.status = true;
        this.data.styles.layout.fit = item;
        this.eventEmitter.spacingChangeChecks.emit({
          data: this.data?.styles.layout,
          type:this.data.sectionType
        });
      } else {
        element.status = false;
      }
    });
  }

  toggleBorder() {
    this.eventEmitter.borderChangeChecks.emit({
      data: this.data?.styles.border,
      type:this.data.sectionType
    });
  }

  atValues = [
    { id: 1, value: 'Fredrik Sundqvist', link: 'https://google.com' },
    { id: 2, value: 'Patrik Sjölin' }
  ];
  hashValues = [
    { id: 3, value: 'Fredrik Sundqvist 2' },
    { id: 4, value: 'Patrik Sjölin 2' }
  ]


  quillConfig={
    //toolbar: '.toolbar',
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['code-block'],
        // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        //[{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        //[{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        //[{ 'direction': 'rtl' }],                         // text direction
        ['link'],
        // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        //[{ 'font': [] }],
        //[{ 'align': [] }],

        // ['clean'],                                         // remove formatting button

        ['link', 'image', 'video']
      ],

    },
    keyboard: {
      bindings: {
        // shiftEnter: {
        //   key: 13,
        //   shiftKey: true,
        //   handler: (range, context) => {
        //     // Handle shift+enter
        //     console.log("shift+enter")
        //   }
        // },
        enter:{
          key:13,
          handler: (range:any, context:any)=>{
            // console.log("enter");
            return true;
          }
        }
      }
    }
  };

    getOriginalObjectStructure(){
    return 0;
  }


  showEditor : boolean = false;
  sectionData : any;
  editItem(item:any,index:any){
    console.log(item)
    // this.dialogService.multiTabEditor.emit({dat:item,showEditor:true});
    this.editSingleBanner.emit({data:item,showSingleEditor:true,activeItem:index})
  }

  addNewListItem(item:any) {
    // let height = window.innerWidth > 475 ? '85vh' : '100vh';

    // let dialog = this.dialogService.openDialog(ListItemComponentComponent, height,
    //   '460px', { data: JSON.parse(JSON.stringify(item[0].content.listItem.data[0])), newItem: true, buttonData: false, templatePage: 0, currentPageIndex: 0 });

    // console.log(item);
    this.addNewItem.emit({addItem:true})
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.data.content.listItem.data, event.previousIndex, event.currentIndex);
  }

  getSanitizedData(value: string){
    const regex = /(<([^>]+)>)/ig;
    return value.replace(regex, '');
  }

  deleteItem(item:any,index:any){
    console.log(this.data.content.listItem.data)
    this.data.content.listItem.data.forEach((element:any,i:any) => {
      if(index === i){
        this.data.content.listItem.data.splice(index, 1);
      }
    });
  }
}
