import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TemplateService } from 'src/app/services/template.service';

@Component({
  selector: 'app-v3-widgets',
  templateUrl: './v3-widgets.component.html',
  styleUrls: ['./v3-widgets.component.scss']
})
export class V3WidgetsComponent {
  selectedOption: any
  constructor(
    public dialogRef: MatDialogRef<V3WidgetsComponent>,
    private templateService: TemplateService,
  )
  {
    this.selectedOption = this.options[0].value;
  }
  options = [
    { value: 'RECOMMENDED', viewValue: 'Recommended', status: 'true' },
    { value: 'MOST_POPULAR', viewValue: 'Most Popular', status: 'true' },
  ]
  imagesArray: any = {
    "Banner": 'https://cdn.durable.co/assets/new-blocks/banner.svg',
    "Text": 'https://cdn.durable.co/assets/new-blocks/text.svg',
    "Image": 'https://cdn.durable.co/assets/new-blocks/image.svg',
    "Text + Image": 'https://cdn.durable.co/assets/new-blocks/hero.svg',
    "Service": 'https://cdn.durable.co/assets/new-blocks/service-list.svg',
    "Testimonial": 'https://cdn.durable.co/assets/new-blocks/quote.svg',
    "Image Grid Section": 'https://cdn.durable.co/assets/new-blocks/image-grid.svg',
    "Image Carousel Section": 'https://cdn.durable.co/assets/new-blocks/image-carousel.svg',
    "Video": 'https://cdn.durable.co/assets/new-blocks/video.svg',
    "Team Members": 'https://cdn.durable.co/assets/new-blocks/team-list.svg',
    // "FAQ": 'https://cdn.durable.co/assets/new-blocks/faq.svg',
    // "Contact Form": 'https://cdn.durable.co/assets/new-blocks/contact.svg',
    // "Location": 'https://cdn.durable.co/assets/new-blocks/location.svg',
  }
  sectionName : any = ""
  desc : any = ""
  imgUrl : any = ""
  componentLibrary  : any = []
  ngOnInit()
  {
    // this.invoiceService.getAllComponents().subscribe(
    //   (res:any) =>{
    //     console.log(res.data);
    //     res.data.forEach(element => {
    //       element.imgUrl = this.imagesArray[element.sectionName]; 
    //     });
    //     this.componentLibrary = res.data;
    //   },
    //   (err) => {
    //     console.log(err)
    //   }
    // )
    
  }

  closePopup()
  {
      this.dialogRef.close();
  }

  selectComponent(componenetId:any){
    this.dialogRef.close({id: componenetId});
  }
}
