import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { BlogServiceService } from '../blog-service.service';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';
import { Blog, BlogFilterRequest, BlogStatus, BlogType, SelectedOption } from '../blog';
import { BlogFilterComponent } from '../blog-filter/blog-filter.component';
import { BlogPluginComponent } from '../blog-plugin/blog-plugin.component';
import { StaffServiceService } from 'src/app/master-config-components/micro-apps/staff/service/staff-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-list-blogs',
  templateUrl: './list-blogs.component.html',
  styleUrls: ['./list-blogs.component.scss', './mq-list-blogs.component.scss']
})
export class ListBlogsComponent {
BlogStatus: any;
  constructor(
    private blogService: BlogServiceService,
    private router: Router,
    private _eventHelper : EventEmmiterService,
    private staffService: StaffServiceService,
    private snackBar: MatSnackBar
  ) {
    this.getScreenSize();
  }

  viewAccess: boolean = true;
  editAccess: boolean = true;
  createAccess: boolean = true;

  ngOnInit(): void {
    this.getStaffAccess();
    this.blogFilterRequest.businessId = localStorage.getItem('businessId') || '';
    this.checkingSwithBusiness()
    console.log(this.filters)
    this.getBlogsList();
  }

  getStaffAccess() {
    this.viewAccess = this.staffService.getStaffPermission('BLOGS', 'VIEW')
    this.editAccess = this.staffService.getStaffPermission('BLOGS', 'EDIT')
    this.createAccess = this.staffService.getStaffPermission('BLOGS', 'CREATE')
  }

  ngOnDestroy(){
    if(this.checkSiwtchBusiess){
      this.checkSiwtchBusiess.unsubscribe();
    }
  }

  blogFilterRequest: BlogFilterRequest = {
    authors: [],
    tags: [],
    businessId: '',
    publishDate: null,
    searchText: '',
    status: BlogStatus.ALL,
    page: 0,
    size: 20,
  }

  observeElementForInfiniteLoading() {
    const intersectionObserver = new IntersectionObserver(entries => {
      if (entries[0].intersectionRatio <= 0) return;
      // load more content;
      if (this.blogs.length === (this.blogFilterRequest.size * (this.blogFilterRequest.page + 1)) && !this.moreDataLoading) {
        this.blogFilterRequest.page++;
        this.getBlogsList(true);
      }
    });
    // start observing

    const elementToObserve = document.getElementById("hiddenElement");
    elementToObserve ? intersectionObserver.observe(elementToObserve) : null;
  }

  checkSiwtchBusiess : any;
  checkingSwithBusiness(){
    this.checkSiwtchBusiess = this._eventHelper.switchBusiness.subscribe(
      (res:any) => {
        if(res.switchBusiness){
          this.getStaffAccess();
          // setTimeout(() => {
            this.blogFilterRequest.businessId = localStorage.getItem('businessId') || '';
            this.getBlogsList()
          // }, 500);
        }
      }
    )
  }

  screenWidth: any;
  @HostListener("window: resize", ["$event"])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  selectedBlogOption: string = "All";

  Blogs = [
    { name: 'All', value: BlogStatus.ALL },
    { name: 'Drafts', value: BlogStatus.SAVE },
    // { name: 'Scheduled', value: BlogStatus.Scheduled },
    { name: 'Published', value: BlogStatus.PUBLISH },
  ]

  onChangeBlogType(value: any) {
    if (value === this.blogFilterRequest.status) return;

    this.blogFilterRequest.page = 0;
    this.blogFilterRequest.status = value;
    this.selectedBlogOption = value.name;
    this.getBlogsList();
  }
  filters: SelectedOption[] = [];

  blogs: Blog[] = [];
  totalBlogs: number = 0;

  emptyloader: boolean = false;
  moreDataLoading: boolean = false;

  getBlogsList(moreLoading: boolean = false) {
    !moreLoading ? (this.emptyloader = true) : (this.moreDataLoading = true);

    this.blogService.getBlogByBuisness(this.blogFilterRequest).subscribe
      ({
        next: (res: any) => {
          this.blogs = !moreLoading ? res.data.data : [...this.blogs, ...res.data.data];

          this.totalBlogs = res.data.total;
          this.emptyloader = false;
          this.moreDataLoading = false;
        },
        error: (err: any) => {
          this.blogs = [];
          this.blogService.openSnack(err.error.message, "close");

          this.emptyloader = false;
          this.moreDataLoading = false;
        },
        complete: () => {
          this.observeElementForInfiniteLoading();
        }
      })
  }

  // search api integration

  searchbar: boolean = false;
  search() {
    this.searchbar = !this.searchbar;
  }
  closesearch() {
    this.searchbar = false;
  }

  handleSearch = this.blogService.debounce(() => this._searchBlog());

  _searchBlog() {
    this.blogFilterRequest.page = 0;
    this.getBlogsList();
  }

  clickCards(blogId: any) {
    //console.log(cards);
    this.router.navigate(['/admin/website/blogs/view-blog'], { queryParams: { blogId: blogId } })
  }


  createBlog() {
    if(!this.createAccess) {
      this.snackBar.open('You dont have create access', 'close', {duration: 2000});
      return;
    }
    this.blogService.removeBlogData()
    this.router.navigate(["/create-blog"]);
  }

  editBlog(blog: any) {
    if(!this.editAccess) {
      this.snackBar.open('You dont have edit access', 'close', {duration: 2000});
      return;
    }
    this.blogService.setBlogData(blog);
    this.router.navigate(["/create-blog"], { queryParams: { edit: true } });
  }

  filterBlogs() {
    console.log("filter blogs test",this.filterBlogs)
    if (this.screenWidth > 475){
      let blogModel = this.blogService.filterDialog(
        BlogFilterComponent,
        'filter-dialog-container',
        'auto',
        '330px',
        'relative',
        {
          type: BlogType.BUSINESS,
          filters: this.filters
        },
      );

      blogModel.afterClosed().subscribe({
        next: (data: any) => {
          if (!data) return;

          this.filters = [];
          this.blogFilterRequest.page = 0;

          this.blogFilterRequest.authors = data.appliedFilters.authors.fieldText;
          this.blogFilterRequest.tags = data.appliedFilters.tags;
          this.blogFilterRequest.publishDate = data.appliedFilters.publishDate;

          for(let key in data.appliedFilters) {
            if (data.appliedFilters[key]) {
              if (Array.isArray(data.appliedFilters[key])) {
                this.filters.push(...data.appliedFilters[key]);

                if (this.blogFilterRequest.hasOwnProperty(key)) {
                  this.blogFilterRequest[key] = data.appliedFilters[key] ? data.appliedFilters[key].map((item: SelectedOption) => item.fieldValue) : []
                }
              } else {
                this.filters.push(data.appliedFilters[key]);

                if (this.blogFilterRequest.hasOwnProperty(key)) {
                  this.blogFilterRequest[key] = data.appliedFilters[key] ? data.appliedFilters[key].fieldValue : null;
                }
              }
            }
          }
          console.log(this.filters)
          this.getBlogsList();
        }
      });
    }

    if (this.screenWidth < 475){
      let bottomSheet = this.blogService.openBottomSheet(
        BlogFilterComponent,
        'filter-dialog-container',
        {
          type: BlogType.BUSINESS,
          filters: this.filters
        },
      );

      bottomSheet.afterDismissed().subscribe({
        next: (data: any) => {
          if (!data) return;

          this.filters = [];
          this.blogFilterRequest.page = 0;

          this.blogFilterRequest.authors = data.appliedFilters.authors;
          this.blogFilterRequest.tags = data.appliedFilters.tags;
          this.blogFilterRequest.publishDate = data.appliedFilters.publishDate;

          for(let key in data.appliedFilters) {
            if (data.appliedFilters[key]) {
              if (Array.isArray(data.appliedFilters[key])) {
                this.filters.push(...data.appliedFilters[key]);

                if (this.blogFilterRequest.hasOwnProperty(key)) {
                  this.blogFilterRequest[key] = data.appliedFilters[key] ? data.appliedFilters[key].map((item: SelectedOption) => item.fieldValue) : []
                }
              } else {
                this.filters.push(data.appliedFilters[key]);

                if (this.blogFilterRequest.hasOwnProperty(key)) {
                  this.blogFilterRequest[key] = data.appliedFilters[key] ? data.appliedFilters[key].fieldValue : null;
                }
              }
            }
          }

          this.getBlogsList();
        }
      });
    }
  }

  removeFilter(item: SelectedOption, index: number) {
    this.filters.splice(index, 1);

    if (Array.isArray(this.blogFilterRequest[item.fieldName])) {
      this.blogFilterRequest[item.fieldName] = this.filters.filter((filter: SelectedOption) => filter.fieldName === item.fieldName).map((filter: SelectedOption) => filter.fieldValue);
    } else {
      this.blogFilterRequest[item.fieldName] = null;
    }

    this.blogFilterRequest.page = 0;

    this.getBlogsList();
  }

  openPluginData() {

    if (this.screenWidth > 475) {
      let blogModel= this.blogService.openDialog(
        BlogPluginComponent,
        'auto',
        { data: localStorage.getItem('businessId') || '' },
        '650px'
      );
    }

    if (this.screenWidth < 475) {
      let bottomSheet = this.blogService.openBottomSheet(
        BlogPluginComponent, '',
        { data: localStorage.getItem('businessId') || '' }
      );

      // let bottomSheet = this.blogService.openBottomSheet(
      //   BlogPluginComponent,
      //   { data: localStorage.getItem('businessId') || '' },
      // );
    }
  }
  
  options: AnimationOptions = {
    path: './../../../../assets/animations/animation.json',
  };

  animationCreated(animationItem: AnimationItem): void {
  }
}
