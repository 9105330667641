<div class="main_section">
    <div class="sticky-md-top bg-white ">
        <div class="d-flex justify-content-between py-4 px-4">
            <div class="fs-6 fw-bold">Widgets</div>
            <div class="cursor-pointer" (click)="closePopup()">
                <img src="../../../../../../assets/images/common/close-img.svg" alt="">
            </div>
        </div>
        <hr class="section-divider">
        <div class="d-flex justify-content-between px-4 py-4">
                <mat-form-field class="sfull-width custom-picker domain-input" appearance="outline">
                    <input class="inputStyle" matInput placeholder="Search"/>
                    <mat-icon matPrefix>search</mat-icon>
                </mat-form-field>
            <mat-form-field class="sfull-width custom-picker domain-input"  appearance="outline">
                <mat-select [(ngModel)]="selectedOption" style="height: 36px;display: flex;align-items: center;font-size: 14px;">
                    <ng-container *ngFor="let option of options">
                        <mat-option [value]="option.value">
                            {{ option.viewValue}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <!-- Card section -->
    <div class="bottom_section px-4 py-4 d-flex justify-content-between flex-wrap gap-3">
        <!-- <ng-container> -->
            <div class="card-section card border cursor-pointer" style="width: 24rem;">
                <div class="row">
                    <div class="col-md-8">
                        <div class="card-body">
                            <div class="card-title fs-6 fw-bold">Cookie Notice</div>
                            <div class="description card-text fs 5 fw-lighter">Inform users that site uses cookies</div>
                            <div class="tag card-text fs-6 fw-light" style="margin-top: 10px;">By Simpo</div>
                        </div>
                    </div>
                    <div class="col-md-4 d-flex justify-content-center align-items-center"  >
                        <img src="https://cdn.durable.co/assets/blocks/cookie-notice.svg" class="card-img px-4 " style="width: 145px" alt="Banner Image">
                    </div>
                </div>
            </div>
            <div class="card-section card border cursor-pointer" style="width: 24rem;">
                <div class="row">
                    <div class="col-md-8">
                        <div class="card-body">
                            <div class="card-title fs-6 fw-bold">Messenger Chat</div>
                            <div class="description card-text fs 5 fw-lighter">Allow users to start chat using Messenger</div>
                            <div class="tag card-text fs-6 fw-light" style="margin-top: 10px;">By Simpo</div>
                        </div>
                    </div>
                    <div class="col-md-4 d-flex justify-content-center align-items-center"  >
                        <img src="https://website-assets.commoninja.com/distribution/1664692573347_messenger-icon.png" class="card-img px-4 " style="width: 145px" alt="Banner Image">
                    </div>
                </div>
            </div>
            <div class="card-section card border cursor-pointer" style="width: 24rem;">
                <div class="row">
                    <div class="col-md-8">
                        <div class="card-body">
                            <div class="card-title fs-6 fw-bold">Telegram Button</div>
                            <div class="description card-text fs 5 fw-lighter">Allow users to start chat using Telegram</div>
                            <div class="tag card-text fs-6 fw-light" style="margin-top: 10px;">By Simpo</div>
                        </div>
                    </div>
                    <div class="col-md-4 d-flex justify-content-center align-items-center"  >
                        <img src="https://website-assets.commoninja.com/distribution/1664692555317_telegram-icon.png" class="card-img px-4 " style="width: 145px" alt="Banner Image">
                    </div>
                </div>
            </div>
            <div class="card-section card border cursor-pointer" style="width: 24rem;">
                <div class="row">
                    <div class="col-md-8">
                        <div class="card-body">
                            <div class="card-title fs-6 fw-bold">Whatsapp Button</div>
                            <div class="description card-text fs 5 fw-lighter">Allow users to start chat using Whatsapp</div>
                            <div class="tag card-text fs-6 fw-light" style="margin-top: 10px;">By Simpo</div>
                        </div>
                    </div>
                    <div class="col-md-4 d-flex justify-content-center align-items-center"  >
                        <img src="https://website-assets.commoninja.com/distribution/1664692535139_whatsapp-icon.png" class="card-img px-4 " style="width: 145px" alt="Banner Image">
                    </div>
                </div>
            </div>
        <!-- </ng-container> -->
    </div>
</div>