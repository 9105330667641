import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { WebisiteService } from '../webisite.service';
import { SeoImageUploadService } from './../../../../services/ImageUploadService/seo-image-upload.service';
import { TemplateService } from './../../../../services/template.service';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';
import { StaffServiceService } from '../../staff/service/staff-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomDeleteComponent } from 'src/app/master-config-components/micro-apps/crm/custom-delete/custom-delete.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-seo',
  templateUrl: './seo.component.html',
  styleUrls: ['./seo.component.scss', './mq-seo.component.scss']
})
export class SeoComponent implements OnInit{

  metaTitle = '';
  metaTitlelength = 0;
  metaDescription = '';
  metaDescriptionLength = 0;

  facebookTitle = '';
  facebookTitleLength = 0;
  facebookDescription = '';
  facebookDescriptionLength = 0;

  Title = '';
  TitleLength = 0;
  Description = '';
  DescriptionLength = 0;
  domainName:String="";

  constructor(
    public ds : DomSanitizer,
    private templateService : TemplateService,
    private _seoImageUploadService : SeoImageUploadService,
    private _websiteService : WebisiteService,
    private _eventHelper : EventEmmiterService,
    private staffService: StaffServiceService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ){

  }

  businessDetails:any;
  pageLoader : boolean = false;
  gettingData: any;

  viewAccess: boolean = true;
  editAccess: boolean = true;

  ngOnInit(): void {
    this.getStaffAccess();
    let bDetails = window.localStorage.getItem('bDetails') || '';
    if(bDetails != '') {
      this.businessDetails = JSON.parse(bDetails);
      this.getWebsiteWithDetails();
      this.getFavIcon();
    }

    this.checkingSwithBusiness();
  }

  favIcon: any;
  getFavIcon() {
    this.templateService.getFavIcon(this.businessDetails.id).subscribe((res: any) => {
      this.favIcon = {file: null,url: res.data};
    })
  }

  async updateFavicon() {
    let imgUrl;
    if(this.favIcon.file) {
      let img: any = await this._seoImageUploadService.uploadSEOimages(this.favIcon.file)
      imgUrl = img.Location
    }
    if(!imgUrl) {
      imgUrl = this.favIcon.url;
    }

    this.templateService.updateFavicon(this.businessDetails.id, imgUrl).subscribe((res: any) => {
      this.templateService.openSnack(
        `SEO tags updated successfully`,
        'Ok'
      );
      this.pageLoader = false
    },(err) => {
      this.pageLoader = false;
      this.templateService.openSnack(
        `${err.error.message}`,
        'Close'
      )
    })
  }

  getStaffAccess() {
    this.viewAccess = this.staffService.getStaffPermission('SEO', 'VIEW');
    this.editAccess = this.staffService.getStaffPermission('SEO', 'EDIT');
  }

  ngOnDestroy(){
    if(this.checkSwitchBusinessSubscription){
      this.checkSwitchBusinessSubscription.unsubscribe();
    }
  }

  checkSwitchBusinessSubscription : any
  checkingSwithBusiness(){
    this.checkSwitchBusinessSubscription = this._eventHelper.switchBusiness.subscribe(
      (res:any) => {
        if(res.switchBusiness){
          this.getStaffAccess();
          let bDetails = window.localStorage.getItem('bDetails') || '';
          if(bDetails != '') {
            this.businessDetails = JSON.parse(bDetails);
            this.getWebsiteWithDetails();
          }
        }
      }
    )
  }

  getWebsiteWithDetails(){
    this.pageLoader = true;
    this.templateService.getWebsiteTemplate(this.businessDetails.templateId).subscribe(
      (res:any) => {
        // console.log(res.data)
        this.gettingData = res.data[0];
        this.pageLoader = false;
      },
      (err) => {
        this.pageLoader = false;
        console.log(err)
      }
    )
  }

  getBusinessDetailsByBusinessId(){
    this.pageLoader = true;
    this.templateService.getBusinessDetailsById(this.businessDetails.id).subscribe(
      (res:any) => {
        //console.log(res.data);
        this.gettingData = res.data;
        this.metaTitle = this.gettingData.website.metaTitle;
        this.metaDescription = this.gettingData.website.metaDesc;
        this.facebookImage = this.gettingData.website.fbImage ? this.gettingData.website.fbImage : undefined;
        this.facebookTitle = this.gettingData.website.fbTitle;
        this.facebookDescription = this.gettingData.website.fbDesc;
        this.Image = this.gettingData.website.Image ? this.gettingData.website.Image : undefined;
        this.Title = this.gettingData.website.Title;
        this.Description = this.gettingData.website.Desc;
        if(this.gettingData.website.domainUrl) {
          this.domainName = this.gettingData.website.domainUrl;
        } else {
          this.domainName = this.gettingData.website.websiteUrl;
        }
        // //console.log(this.websiteStatus)
        this.pageLoader = false;
      },
      (err) => {
        this.pageLoader = false;
        this.templateService.openSnack(
          `${err.error.message}`,
          'Close'
        )
      }
    )
  }

  //meta card

  metaTitleFun(){
    this.metaTitlelength = this.gettingData.seoData.metaTitle.length;
  }
  metaDescFun(){
    this.metaDescriptionLength = this.gettingData.seoData.metaDescription.length;
  }

  //facebook card
  facebookTitleFun(){
    this.facebookTitleLength = this.facebookTitle.length;
  }
  facebookDescriptionFun(){
    this.facebookDescriptionLength = this.facebookDescription.length
  }
  browseFcImage(){
    document.getElementById('browse_fc_image')?.click();
  }
  facebookImageLoader : boolean = false;
  facebookImage :any ;
  async updateFcImage(ev:any){
    this.pageLoader = true;
    // ////console.log();
    // let fcImg = URL.createObjectURL(ev.srcElement.files[0])
    // ////console.log(a);
    // this.facebookImage = this.ds.bypassSecurityTrustUrl(fcImg)
    let img:File = ev.srcElement.files[0];
    const file = ev.srcElement.files[0];
    // //console.log(this.businessDetails);

    this.facebookImage = await this._seoImageUploadService.uploadSEOimages(file)
    this.facebookImage = this.facebookImage.Location;
    this.pageLoader = false;
  }

  dropHandler(ev:any) {
    // ////console.log('File(s) dropped');

    // Prevent default behavior (Prevent file from being opened)
    // ev.preventDefault();

    // if (ev.dataTransfer.items) {
    //   // Use DataTransferItemList interface to access the file(s)
    //   [...ev.dataTransfer.items].forEach((item, i) => {
    //     // If dropped items aren't files, reject them
    //     if (item.kind === 'file') {
    //       const file = item.getAsFile();
    //       // ////console.log(`… file[${i}].name = ${file.name}`);
    //     }
    //   });
    // } else {
    //   // Use DataTransfer interface to access the file(s)
    //   [...ev.dataTransfer.files].forEach((file, i) => {
    //     // ////console.log(`… file[${i}].name = ${file.name}`);
    //   });
    // }
    // ////console.log('first working');
    // ev.preventDefault();
    // // ev.dataTransfer.dropEffect = "move";
    // ////console.log(ev.dataTransfer);
    ev.preventDefault();
    // ////console.log(ev.srcElement.files);
    let html = ev.dataTransfer.getData("text/html");
    // ////console.log(html);
    let src = new DOMParser().parseFromString(html,"text/html").querySelector('img')?.src;
    ////console.log(src);


  }

  kk : any;
  dragOverHandler(ev:any) {
    // ////console.log('File(s) in drop zone');

    // Prevent default behavior (Prevent file from being opened)
    // ev.preventDefault();
    // // ////console.log('second working');
    // ////console.log(ev);
    ev.preventDefault();
    // Get the id of the target and add the moved element to the target's DOM
    // const data = ev.dataTransfer.getData("text/plain");
    // ev.target.appendChild(document.getElementById(data));


  }

  // Card
  browseImage(){
    document.getElementById('browse__image')?.click();
  }
  Image :any ;
  async updateImage(ev:any){
    this.pageLoader = true;
    // ////console.log();
    // let fcImg = URL.createObjectURL(ev.srcElement.files[0])
    // // ////console.log(a);
    // this.Image = this.ds.bypassSecurityTrustUrl(fcImg)
    let img:File = ev.srcElement.files[0];
    const file = ev.srcElement.files[0];
    // //console.log(this.businessDetails);

    this.Image = await this._seoImageUploadService.uploadSEOimages(file)
    this.Image = this.Image.Location;
    this.pageLoader = false
  }
  TitleFun(){
    this.TitleLength = this.Title.length;
  }
  DescriptionFun(){
    this.DescriptionLength = this.Description.length;
  }



  updateSeoTags(){
    if(!this.editAccess) {
      this.snackBar.open('You dont have edit access', 'close', {duration: 2000});
      return;
    }
    this.pageLoader = true;
    let data = [
      this.gettingData
    ]
    console.log(data)
    this.templateService.saveTemplatePage(data).subscribe(
      (res:any) => {
        this.updateFavicon();

      },
      (err) => {
        this.pageLoader = false;
        this.templateService.openSnack(
          `${err.error.message}`,
          'Close'
        )
      }
    )
    // let data = {
    //   businessId:this.gettingData.website.businessId,
    //   metaTitle:this.metaTitle,
    //   metaDesc:this.metaDescription,
    //   fbImage:this.facebookImage,
    //   fbTitle:this.facebookTitle,
    //   fbDesc:this.facebookDescription,
    //   Image:this.Image,
    //   Title:this.Title,
    //   Desc:this.Description
    // }

    // //console.log(data);
    // this._websiteService.updateSEOTagsFromSpecificWebsite(data).subscribe(
    //   (res:any) => {
    //     //console.log(res.data);

    //     this.templateService.openSnack(
    //       `SEO Tags Updated Successfully`,
    //       'Ok'
    //     );
    //     this.pageLoader = false
    //   },
    //   (err) => {
    //     this.pageLoader = false;
    //     this.templateService.openSnack(
    //       `${err.error.message}`,
    //       'Close'
    //     )
    //   }
    // )
  }

  uploadFavIcon(event: any) {
    console.log(event)
    let file = event.target.files[0];
    const maximumFileSize = 16 * 16; // Max is 4mb

    // if (maximumFileSize < file.size) {
    //   this.templateService.openSnackbar('Maximum file size limit is 0.8 kb');
    //   return;
    // }
    const reader = new FileReader();
    reader.onload = (res) => {
      this.favIcon= {
        url: res.target?.result,
        file: file
      }
    }
    reader.readAsDataURL(file);
  }

  deleteFavIcon(){
    const dialogRef = this.dialog.open(CustomDeleteComponent, {
      width: '400px',
      data: {
        heading: 'Delete Favicon',
        subText: 'Are you sure you want to delete the favicon?',
        primaryButton: 'Delete',
        secondaryButton: 'Cancel'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.response === 'success') {
        this.favIcon = null;
        this.templateService.openSnack('Favicon deleted successfully', 'Ok');
      }
    });
  }

}

