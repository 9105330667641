import { Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DbService } from 'src/app/services/db-service/db.service';
import { TemplateService } from 'src/app/services/template.service';
import { AuthServiceService } from 'src/app/services/auth-service.service';
import { Industry, StorageKeys } from 'src/app/shared-module-files/simpo.constant';

@Component({
  selector: 'app-generate-website-popup',
  templateUrl: './generate-website-popup.component.html',
  styleUrls: ['./generate-website-popup.component.scss']
})
export class GenerateWebsitePopupComponent {


  constructor(
    private webService: TemplateService,
    public dialogRef: MatDialogRef<GenerateWebsitePopupComponent>,
    private dbService: DbService,
    private authService: AuthServiceService,
  ){

  }
  ngOnInit(){
    this.getAllCountries()
  }
  // leadEmail = new UntypedFormControl("", [Validators.required, Validators.email]);
  // leadMobile = new UntypedFormControl("", [Validators.maxLength(10), Validators.minLength(10)]);
  // leadDetails: any = {
  //   mobile
  // }

  mobile:string = '';
  email:string = '';
  name: string = '';
  submitLoader: boolean = false;
  countryCodes: any[] = [];
  selectedCountry: any;
  disableCountryCodeDropdown: boolean = false;
  getWebsiteGenerate(){
    this.submitLoader = true;
    let data = {
      mobile:this.mobile ,
      email: this.email,
      name: this.name
    }
    this.webService.generateLead(data).subscribe(
      {
        next:(res:any)=>{
          this.submitLoader = false;
          // console.log(res.data)
          this.dialogRef.close({leadId: res.data});
          window.localStorage.setItem('user','added');
        },
        error:(err)=>{
          this.webService.openSnack(err.error.message , 'Close')
        }
      }
    )
  }

  getAllCountries() {
    this.authService.getAllCountries().subscribe(
      (res: any) => {
        this.countryCodes = res.data?.data ?? [];

        const shortCode = localStorage.getItem(StorageKeys.SHORT_CODE);

        let dialCode;
        if (localStorage.getItem(StorageKeys.dialCodeDropdown) === 'true') {
          dialCode = true
        }
        else {
          dialCode = false
        }

        let shortCodeIndex = -1;

        if (shortCode) {
          shortCodeIndex = this.countryCodes.findIndex((c: any) => c.shortCode?.toLowerCase() === shortCode.toLowerCase())
        } else {
          shortCodeIndex = this.countryCodes.findIndex((c: any) => c.shortCode?.toLowerCase() === 'in');
        }

        if (shortCodeIndex > -1) {
          this.selectedCountry = this.countryCodes[shortCodeIndex];
          this.disableCountryCodeDropdown = dialCode ? true : false;
        }
      },
      (err) => {
        this.webService.openSnack(
          `${err.error.message}`,
          'Close',
        )
      }
    )
  }

}
