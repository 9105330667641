<section class="main_section">
    <div class="all-leads">
        <ng-container *ngIf="allLeads.length > 0; else emptyScreen">        
        <ng-container *ngFor="let item of allLeads">
            <div class="single_lead">
                <div class="full_name">
                    <div class="user_avatar">
                        <img src="./../../../../../assets/images/common/user.png" alt="user">
                    </div>
                    <div class="name">
                        {{item?.name | titlecase}}
                    </div>
                </div>
                <div class="mobile" *ngIf="item.mobileNo != null || item.mobileNo != ''">
                    <div class="m_icon">
                        <img src="./../../../../../assets/images/common/mobile.svg" alt="mobile">
                    </div>
                    <div class="m_text">
                        {{item?.mobileNo ? item?.mobileNo : 'N/A'}}
                    </div>
                </div>
                <div class="mail">
                    <div class="m_icon">
                        <img src="./../../../../../assets/images/common/mail.svg" alt="mail">
                    </div>
                    <div class="m_text">
                        {{item.email}}
                    </div>
                </div>
                <div class="comment" *ngIf="item.message != null || item.message != ''">
                    <div class="m_icon">
                        <img src="./../../../../../assets/images/common/comment.png" alt="comment">
                    </div>
                    <div class="m_text">
                        {{item?.message ? item?.message : 'N/A'}}
                    </div>
                </div>
                <div class="mail">
                    <div class="m_icon">
                        <img src="./../../../../../assets/images/common/datetime.png" alt="date and time">
                    </div>
                    <div class="m_text">
                        {{item?.createdTimeStamp | date}}
                    </div>
                </div>
            </div>
        </ng-container>
        </ng-container>
        <ng-template #emptyScreen>
            <div class="empty-section-heading">
            You don't have any lead Yet!!
            </div>
        </ng-template>
    </div>
</section>