<section class="main_section" *ngIf="!pageLevelEditor && (!data || data.isEditor)">
    <div class="heading_section" *ngIf="screenWidth<475">
        <div class="heading">
            {{componentData?.category.name}}
        </div>
        <!-- <div class="done_btn" *ngIf="screenWidth>475">
            <button class="discard-btn" (click)="discardChanges()">Discard</button>
            <button class="save-btn" (click)="applyChanges()">Apply</button>
        </div> -->
        <div class="closing_bar" (click)="close($event)"><mat-icon>close</mat-icon></div>
    </div>
    <div class="hr_line"></div>
    <div style="position: relative;">
        <div class="bottom_editor">
            <!-- <div class="small_hr_line"></div> -->
            <ng-container *ngFor="let item of componentData?.styles | keyvalue : getOriginalObjectStructure">
                <div *ngIf="getValue(item.value) === 'text' && item.key != 'locationTitle' && item.key != 'locationValue' && item.key != 'longitude' &&
                item.key != 'latitude' && item.key != 'mailTitle' && item.key != 'mailValue' && item.key != 'contactTitle' && item.key != 'contactValue' &&
                item.key != ''">
                    <app-text-editor-card [data]="item.value" [selectedComponent]="componentData"></app-text-editor-card>
                </div>

                <!-- only for rating cards -->
                <div *ngIf="getValue(item.value)==='RATING'">
                    <app-ratin-card [data]="item.value"></app-ratin-card>
                </div>
                <!-- <div class="hr_line"></div> -->

                <!-- contact us form related contact data rendering starts here -->
                <div>
                    <div *ngIf="getValue(item.value) === 'text' && item.key === 'locationTitle'">
                        <div class="single_item">
                            <div class="image_text_container">
                                <div class="text_container">
                                    <div class="heading_text">
                                        {{(componentData?.styles['locationTitle'].value.length > 15) ?
                                        (componentData?.styles['locationTitle'].value | slice:0:15)+'...' :
                                        (componentData?.styles['locationTitle'].value)}}
                                    </div>
                                    <div class="description_text">
                                        {{(componentData?.styles['locationValue'].value.length > 33) ?
                                        (componentData?.styles['locationValue'].value | slice:0:33)+'...' :
                                        (componentData?.styles['locationValue'].value)}}
                                    </div>
                                </div>
                            </div>
                            <div class="icons_container">
                                <div class="edit_icon"
                                    (click)="editLocationDetails(componentData?.styles['locationTitle'],componentData?.styles['locationValue'],componentData?.styles['longitude'],componentData?.styles['latitude'])">
                                    <img src="./../../../../../../../../../assets/images/editor/edit_black_icon.svg"
                                        alt="edit_icon">
                                </div>
                                <div class="display_section switch_toggle_btn">
                                    <ng-switchery name="switchery-check"
                                        [(ngModel)]="componentData?.styles['locationTitle'].status"></ng-switchery>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="getValue(item.value) === 'text' && item.key === 'contactTitle'">
                        <div class="single_item">
                            <div class="image_text_container">
                                <div class="text_container">
                                    <div class="heading_text">
                                        {{(componentData?.styles['contactTitle'].value.length > 15) ?
                                        (componentData?.styles['contactTitle'].value | slice:0:15)+'...' :
                                        (componentData?.styles['contactTitle'].value)}}
                                    </div>
                                    <div class="description_text">
                                        {{(componentData?.styles['contactValue'].value.length > 33) ?
                                        (componentData?.styles['contactValue'].value | slice:0:33)+'...' :
                                        (componentData?.styles['contactValue'].value)}}
                                    </div>
                                </div>
                            </div>
                            <div class="icons_container">
                                <div class="edit_icon"
                                    (click)="editContactDetails(componentData?.styles['contactTitle'],componentData?.styles['contactValue'])">
                                    <img src="./../../../../../../../../../assets/images/editor/edit_black_icon.svg"
                                        alt="edit_icon">
                                </div>
                                <div class="display_section switch_toggle_btn">
                                    <ng-switchery name="switchery-check"
                                        [(ngModel)]="componentData?.styles['contactTitle'].status"></ng-switchery>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="getValue(item.value) === 'text' && item.key === 'mailTitle'">
                        <div class="single_item">
                            <div class="image_text_container">
                                <div class="text_container">
                                    <div class="heading_text">
                                        {{(componentData?.styles['mailTitle'].value.length > 15) ?
                                        (componentData?.styles['mailTitle'].value | slice:0:15)+'...' :
                                        (componentData?.styles['mailTitle'].value)}}
                                    </div>
                                    <div class="description_text">
                                        {{(componentData?.styles['mailValue'].value.length > 33) ?
                                        (componentData?.styles['mailValue'].value | slice:0:33)+'...' :
                                        (componentData?.styles['mailValue'].value)}}
                                    </div>
                                </div>
                            </div>
                            <div class="icons_container">
                                <div class="edit_icon"
                                    (click)="editContactDetails(componentData?.styles['mailTitle'],componentData?.styles['mailValue'])">
                                    <img src="./../../../../../../../../../assets/images/editor/edit_black_icon.svg"
                                        alt="edit_icon">
                                </div>
                                <div class="display_section switch_toggle_btn">
                                    <ng-switchery name="switchery-check"
                                        [(ngModel)]="componentData?.styles['mailTitle'].status"></ng-switchery>
                                </div>
                            </div>
                        </div>
                        <div class="hr_line"></div>
                    </div>
                </div>
                <!-- contact us form related contact data rendering ends here -->

                <div *ngIf="getValue(item.value) === 'button'">
                    <app-button-editor-card [data]="item.value" [allPages]="allPages"
                        [samePageComponents]="samePageComponents"></app-button-editor-card>
                </div>
                <div *ngIf="getValue(item.value) === 'attachment'">
                    <div *ngIf="!isNavbarSection">
                        <app-image-editor-card [data]="item.value"></app-image-editor-card>
                    </div>
                    <div *ngIf="isNavbarSection">
                        <app-navbar-image-editor-card [data]="item.value"></app-navbar-image-editor-card>
                    </div>
                </div>

                <!-- animation relatedrendering starts here -->
                <ng-container
                    *ngIf="getValue(item.value) === 'animation' && !componentData.name.includes('Nav Bar') && !componentData.name.includes('Footer')">
                    <label>Animations</label>
                    <div class="outer_box">
                        <mat-select class="button_dropdown" [(ngModel)]="componentData?.styles['animation'].type">
                            <mat-option value="None">None</mat-option>
                            <mat-option [value]="type.value" (click)="changeAnimation()" *ngFor="let type of animationType"
                                class="editor_button_dropdown">
                                {{type.value | titlecase}}
                            </mat-option>
                        </mat-select>
                    </div>

                    <label *ngIf="componentData?.styles['animation'].type != 'None'">Speed</label>
                    <div class="outer_box" *ngIf="componentData?.styles['animation'].type != 'None'">
                        <mat-select class="button_dropdown" [(ngModel)]="componentData?.styles['animation'].speed">
                            <mat-option [value]="speed.value" (click)="changeAnimation()"
                                *ngFor="let speed of animationSpeed" class="editor_button_dropdown">
                                {{speed.value | titlecase}}
                            </mat-option>
                        </mat-select>
                    </div>
                </ng-container>
                <!-- animation relatedrendering ends here -->

            </ng-container>


            <!-- dynamic items starts here for like testimonial, process sections, service section-->
            <div *ngIf="componentData?.itemStyles != null && !isContactUsSection">
                <div class="items" *ngIf="!isNavbarSection">
                    <div class="lead_title">Items List</div>
                    <div class="list_items">
                        <ng-container *ngFor="let item of componentData?.itemStyles; let i = index">
                            <div class="single_item">
                                <div class="image_text_container">
                                    <div class="image_container" *ngIf="item['image'] != null">
                                        <img [src]="item['image'].attachment" alt="image_container">
                                    </div>
                                    <div class="text_container">
                                        <div class="heading_text" *ngIf="item['title'] != null">
                                            {{(item['title'].value.length > 15) ? (item['title'].value | slice:0:15)+'...' :
                                            (item['title'].value)}}
                                        </div>
                                        <div class="description_text" *ngIf="item['description'] != null">
                                            {{(item['description'].value.length > 33) ? (item['description'].value |
                                            slice:0:33)+'...' : (item['description'].value)}}
                                        </div>
                                    </div>
                                </div>
                                <div class="icons_container">
                                    <div class="edit_icon" (click)="editDynamicItem(item)">
                                        <img src="./../../../../../../../../../assets/images/editor/edit_black_icon.svg"
                                            alt="edit_icon">
                                    </div>
                                    <div class="delete_icon" (click)="deleteItem(i)">
                                        <img src="./../../../../../../../../../assets/images/editor/delete_icon.svg"
                                            alt="delete_icon">
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div (click)="addNewItem()" class="new_item">
                        <span class="add_page_text">
                            <i class="fa fa-plus-circle" aria-hidden="true"></i> Add New List
                        </span>
                    </div>
                </div>
            </div>
            <!-- dyanmic items ends here -->

            <!-- dynamic items for contactus section starts here -->
            <!-- <div class="hr_line"></div> -->
            <div *ngIf="isContactUsSection">
                <div class="contactform_title">Contact Form</div>
                <div class="all_items">
                    <ng-container *ngFor="let item of componentData?.itemStyles; let i = index">
                        <div class="single_contact_item">
                            <mat-checkbox class="example-margin" *ngIf="item.details.paramType === 'lm_name'
                            || item.details.paramType === 'lm_email' || item.details.paramType === 'lm_mobile'"
                                [(ngModel)]="item.details.fixedParam"></mat-checkbox>
                            <div class="first_layer">
                                <div class="layer_title">
                                    {{(item.title.value.length > 10) ? (item.title.value | slice:0:10)+'...' :
                                    (item.title.value)}}
                                </div>
                                <div class="edit_icon" (click)="editContactField(item)">
                                    <img src="./../../../../../../../../../assets/images/editor/edit_black_icon.svg"
                                        alt="edit_icon">
                                </div>
                            </div>
                            <div class="second_layer">
                                <div class="second_layer_text">
                                    <span *ngIf="item.details.type === 'TEXTAREA' || item.details.type === 'INPUT'">
                                        Text Field
                                    </span>
                                    <span *ngIf="item.details.type === 'DROPDOWN'">
                                        Dropdown
                                    </span>
                                </div>
                                <div class="delete_icon" (click)="deleteItem(i)" *ngIf="item.details.paramType !== 'lm_name' &&
                                item.details.paramType !== 'lm_email' && item.details.paramType !== 'lm_mobile'">
                                    <img src="./../../../../../../../../../assets/images/editor/delete_icon.svg"
                                        alt="delete_icon">
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div class="add_new_contact_item" (click)="addContactItem()">
                        <div class="add_contact_image">
                            <img src="./../../../../../../../../../assets/images/editor/colored_square_plus_icon.svg"
                                alt="plus_icon">
                        </div>
                        <div class="add_contact_text">
                            Add Field
                        </div>
                    </div>
                </div>
            </div>
            <!-- dynamic items for contact us section ends here -->

            <!-- navbar editor section is starts here -->
            <div *ngIf="isNavbarSection && !isContactUsSection && componentData.itemStyles != null">
                <ng-container *ngFor="let item of componentData.itemStyles; let i = index">
                    <div class="single_item">
                        <div class="image_text_container">
                            <div class="text_container">
                                <div class="heading_text">
                                    {{(item.item.displayValue.length > 15) ? (item.item.displayValue | slice:0:15)+'...' :
                                    (item.item.displayValue)}}
                                </div>
                            </div>
                        </div>
                        <div class="icons_container">
                            <div class="edit_icon" (click)="editNavbarDynamicItem(item)">
                                <img src="./../../../../../../../../../assets/images/editor/edit_black_icon.svg"
                                    alt="edit_icon">
                            </div>
                            <div class="delete_icon" (click)="deleteItem(i)" *ngIf="item.item.displayValue != 'Home'">
                                <img src="./../../../../../../../../../assets/images/editor/delete_icon.svg"
                                    alt="delete_icon">
                            </div>
                        </div>
                    </div>
                </ng-container>
                <div (click)="addNewNavbarItem()" class="new_item">
                    <span class="add_page_text">
                        <i class="fa fa-plus-circle" aria-hidden="true"></i> Add New List
                    </span>
                </div>
            </div>
            <div class="hr_line"></div>

            <div *ngIf="!componentData.name.includes('Nav Bar') && !componentData.name.includes('Footer')" style="margin-bottom: 15px;">
               <div style="display: flex;align-items: center;justify-content: space-between;margin-top: 10px;">
                    <label class="label_heading">Background Image</label>
                    <ng-switchery name="switchery-check" style="position: relative;top: 8px;"
                        (ngModelChange)="toggleBackgrondImage($event)"
                        [(ngModel)]="componentData?.styles['option'].bgImage"></ng-switchery>
                </div>
                <div *ngIf="componentData?.styles['option'].bgImage">
                    <app-bg-image-editor [imageData]="componentData?.styles['bgImage']"
                        [componentIndex]="componentIndex" [componentData]="componentData"></app-bg-image-editor>
                </div>
            </div>
            <div class="page_level_style">
                <div class="label_heading">Background Color</div>
                <div class="bgcolor input-group">
                    <input type="color" name="bgColor" class="color_box" placeholder="text"
                        [(ngModel)]="componentData.bgColor" (ngModelChange)="changeColor()">
                    <input type="text" class="color_code" [(ngModel)]="componentData.bgColor" (keyup)="changeColor()">
                </div>
            </div>
            <!-- <ng-container *ngIf="screenWidth<475">
                <div class="hr_line"></div>
                <div class="done_btn">

                </div>
            </ng-container> -->
        </div>
        <div class="hr_line" *ngIf="screenWidth<=475"></div>
        <div class="done_btn" *ngIf="screenWidth<=475">
            <button class="discard-btn" (click)="discardChanges()">Discard</button>
            <button class="save-btn" (click)="applyChanges()">Apply</button>
        </div>

            <div class="done_btn" *ngIf="screenWidth>475">
                <div>
                    <button class="discard-btn" (click)="discardChanges()">Discard</button>
                    <button class="save-btn" (click)="applyChanges()">Save</button>
                </div>
            </div>

    </div>
</section>
<section *ngIf="pageLevelEditor && (!data || data.isEditor)" class="main_section">
    <div class="heading_section" *ngIf="screenWidth<475">
        <div class="heading">
            {{pageLevelData.pageName}}
        </div>
        <div class="closing_bar" (click)="close($event)"><mat-icon>close</mat-icon></div>
        <!-- <div class="done_btn" *ngIf="screenWidth >475">
            <button class="discard-btn">Discard</button>
            <button class="save-btn" (click)="applyChanges()">Apply</button>
        </div> -->
    </div>
    <div class="hr_line"></div>
    <div class="position-relative">
        <div class="bottom_editor">
            <div class="routings">
                <div class="routing_name input_card">
                    <div class="title">Page Name</div>
                    <mat-form-field class="sfull_width" appearance="outline">
                        <input matInput [(ngModel)]="pageLevelData.pageName"
                            [disabled]="pageLevelData.pageName === 'Home'" (keyup)="pageNameChange()" />
                    </mat-form-field>
                </div>
                <div class="routing_name input_card">
                    <div class="title">Routing Path</div>
                    <mat-form-field class="sfull_width" appearance="outline">
                        <input matInput [(ngModel)]="pageLevelData.path"
                            [disabled]="pageLevelData.pageName === 'Home'" />
                    </mat-form-field>
                </div>
            </div>
            <div class="hr_line"></div>
            <div class="seo_tags_related position-relative">
                <div class="heading-text">
                    SEO
                </div>
                <div class="optimise-text">
                    Set up and optimize how your site appears in search engines.
                </div>
                <ng-container>
                    <div class="heading-text">
                        Meta Title
                    </div>
                    <div class="input_card seo_input_box">
                        <mat-form-field class="sfull_width material-form-width" appearance="outline">
                            <input matInput type="text" name="first" placeholder="Enter meta title"
                                [(ngModel)]="metaTitle" (keyup)="updateMetaTitle()" />

                        </mat-form-field>
                    </div>
                    <div class="heading-text">
                        Meta Description
                    </div>
                    <div>
                        <textarea name="" id="" cols="" rows="" placeholder="Meta Description"
                            [(ngModel)]="metaDescription" (keyup)="updateMetaDescription()"
                            class="text-area"></textarea>
                    </div>
                    <hr class="hr-line">
                    <div class="card_right_part">
                        <div class="box_title">Search Engine Result Preview</div>
                        <div class="search_engine_box">
                            <div class="google_searchbox">
                                <div class="google_img">
                                    <img src="./../../../../../../../../../assets/images/common/google.svg"
                                        alt="google">
                                </div>
                                <mat-form-field class="google_search" appearance="outline">
                                    <input matInput disabled [(ngModel)]="metaTitle" />
                                    <mat-icon matSuffix>search</mat-icon>
                                </mat-form-field>
                            </div>
                            <div class="google_result">
                                {{businessDetails?.websiteUrl}} › home
                            </div>
                            <div class="meta_title">
                                {{metaTitle ? metaTitle : 'Meta Title'}}
                            </div>
                            <div class="meta_description">
                                <!-- {{pageLevelData?.seoData.metaDescription ? pageLevelData?.seoData.metaDescription : 'Meta Description'}} -->
                                {{metaDescription ? metaDescription : 'Meta Description'}}
                            </div>
                        </div>
                    </div>

                    <hr class="hr-line">
                    <div class="heading-text">
                        Keywords
                    </div>
                    <div class="input_card seo_input_box keywords">
                        <mat-form-field class="sfull_width material-form-width" appearance="outline">
                            <input matInput type="text" name="first" placeholder="Enter meta title"
                                [(ngModel)]="keywords" (keyup)="updateKeywords()" />
                        </mat-form-field>
                    </div>
                    <!-- <div class="og-image">

                    </div> -->
                    <!-- <hr class="hr-line">
                    <div class="card_right_part">
                        <div class="box_title">Facebook Card Preview</div>
                        <div class="search_engine_box">
                            <div class="facebook_imgUrl" *ngIf="facebookImage === undefined; else upImg">
                                Facebook
                            </div>
                            <ng-template #upImg>
                                <div class="fb_imgUrl">
                                    <img [src]="facebookImage">
                                </div>
                            </ng-template>
                            <div class="google_result">
                                {{domainName}} › home
                            </div>
                            <div class="meta_title">
                                {{facebookTitle ? facebookTitle : 'Title'}}
                            </div>
                            <div class="meta_description">
                                {{facebookDescription ? facebookDescription : 'Description'}}
                            </div>
                        </div>
                    </div>
                    <hr class="hr-line">
                    <div class="card_right_part">
                        <div class="box_title">Twitter Card Preview</div>
                        <div class="search_engine_box">
                            <div class="twitter_imgUrl" *ngIf="twitterImage === undefined; else twitterImg">
                                Twitter
                            </div>
                            <ng-template #twitterImg>
                                <div class="fb_imgUrl">
                                    <img [src]="twitterImage">
                                </div>
                            </ng-template>
                            <div class="google_result">
                                {{domainName}} › home
                            </div>
                            <div class="meta_title">
                                {{twitterTitle ? twitterTitle : 'Title'}}
                            </div>
                            <div class="meta_description">
                                {{twitterDescription ? twitterDescription : 'Description'}}
                            </div>
                        </div>
                    </div> -->
                </ng-container>
            </div>
        </div>
        <div class="hr_line" *ngIf="screenWidth<475"></div>
        <div class="done_btn" style="left: 0;" *ngIf="screenWidth<=475">
            <button class="discard-btn" (click)="discardChanges()">Discard</button>
            <button class="save-btn" (click)="applyChanges()">Apply</button>
        </div>
        <div class="done_btn" *ngIf="screenWidth>475">
            <p>Save Changes</p>
            <div>
                <button class="discard-btn" (click)="discardChanges()">Discard</button>
                <button class="save-btn" (click)="applyChanges()">Save</button>
            </div>
        </div>
    </div>
</section>
<section *ngIf="screenWidth < 475 && (data && !data.isEditor)" class="main_section">
    <div class="heading_section">
        <div class="heading">
            Page
        </div>
        <div class="closing_bar" (click)="close('')" *ngIf="screenWidth<475"><mat-icon>close</mat-icon></div>
    </div>
    <div class="hr_line"></div>
    <div *ngFor="let page of data.totalWebsiteData;let i = index">
        <div class="heading_section">
            <div class="heading" (click)="closeDialog(page.id,'pageChange')">
                {{page.pageName}}
            </div>
            <div class="done_btn">
                <button class="home-icon" *ngIf="page.pageName != 'Home'"
                    (click)="closeDialog(page,'deletePage')"><mat-icon>delete</mat-icon></button>
                <button class="home-icon" (click)="closeDialog(i,'openEditor')"><mat-icon>settings</mat-icon></button>
            </div>
        </div>
    </div>
    <div class="hr_line"></div>
    <div class="button-parent">
        <button class="cancel-button" (click)="close('')">Cancel</button>
        <button class="add-new-page" (click)="closeDialog('addPage','addPage')">Add New Page</button>
    </div>
</section>
<section class="loader_section" *ngIf="pageLoader">
    <div class="api_loader">
        <div class="dots-container">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </div>
    </div>
</section>
