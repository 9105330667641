interface Tab {
  name: string;
  status: boolean;
}

interface AnimationType {
  type: string;
}

interface HeightOption {
  name: string; // Descriptive name for the height option
  status: boolean; // Flag indicating if the option is currently selected
}

interface AnimationSpeed {
  name: string; // Descriptive name for the speed option
  speed: string; // Actual speed value in seconds
  status: boolean; // Flag indicating if the option is currently selected
}

interface AlignmentOption {
  name: 'left' | 'center' | 'right'; // Use a union type for valid alignment values
  status: boolean; // Flag indicating if the option is currently selected
}

export const tabs: Array<Tab> = [
  { name: 'CONTENT', status: true },
  { name: 'STYLES', status: false },
];

export const animationTypes: Array<AnimationType> = [
  { type: 'none' },
  { type: 'left' },
  { type: 'top' },
  { type: 'right' },
  { type: 'bottom' },
  { type: 'fadeIn' },
  { type: 'zoom' },
];

export const animationSpeed: Array<AnimationSpeed> = [
  { name: 'slow', speed: '1s', status: false },
  { name: 'medium', speed: '2s', status: false },
  { name: 'fast', speed: '3s', status: false },
];

export const spacing: Array<string> = ['none', 'small', 'medium', 'large'];

// Array of height options with type declaration and default status
export const height: Array<HeightOption> = [
  { name: 'content', status: false },
  { name: 'screen', status: false },
];

export const alignmentTabs: Array<AlignmentOption> = [
  { name: 'left', status: true },
  { name: 'center', status: false },
  { name: 'right', status: false },
];
