import { Component, HostListener , Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { BlogServiceService } from '../../blog-service.service';
import { AddTagsComponent } from '../add-tags/add-tags.component';
import { DeleteTagsComponent } from '../delete-tags/delete-tags.component';

@Component({
  selector: 'app-view-tags',
  templateUrl: './view-tags.component.html',
  styleUrls: ['./view-tags.component.scss']
})
export class ViewTagsComponent {
  constructor(
    private dialogRef: MatDialogRef<ViewTagsComponent>,
    private _bottomSheetRef: MatBottomSheetRef<ViewTagsComponent>,
    @Optional()   @Inject(MAT_DIALOG_DATA) public data,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData: any,
    private blogService : BlogServiceService){
      this.getScreenSize()
  }
  tagId : any ;
  ngOnInit(): void {
      this.dialogRef.updatePosition({ top: '0px', right: `0px` });
      // if (this.scrWidth > 475){
      //   this.tagId = this.data.data;
      // } else {this.tagId = this.bottomSheetData.data;}
      this.tagId = this.data.data
      this.getViewTag();
  }

  scrWidth: any;
  scrHeight: any;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }


  closeBtn(){
    // if (this.scrWidth > 475){
    //   this.dialogRef.close();
    // }
    // if (this.scrWidth < 475){
    //   this._bottomSheetRef.dismiss()
    // }
    this.dialogRef.close();
  }

  editTag(data:any){
    let height= window.innerHeight > 475 ? 'auto' : '100%'
    let width = window.innerWidth > 475 ? '400px' : '450px'
    let dialog = this.blogService.openDeleteDialog(
      AddTagsComponent,
      height,
      width,
      {edit:true,data:data , type:'EDITTAG'},
      '100vw'
    );
    dialog.afterClosed().subscribe((data:any) => {
      this.dialogRef.close('true');
    });
  }


  deleteTag(tagId:any){
    if (this.scrWidth > 475){
      let dialog = this.blogService.openDeleteDialog(
        DeleteTagsComponent,
        '250px',
        '350px',
        {data:this.data.data , type:'DELETETAG'},
        '80vw'
      );
      dialog.afterClosed().subscribe((data:any) => {
        this.dialogRef.close('success');
      });
    }
    if (this.scrWidth < 475){
      let dialog = this.blogService.openBottomSheet(
        DeleteTagsComponent,
        '250px',
        {data:this.data.data , type:'DELETETAG'},
      );
      dialog.afterDismissed().subscribe((data:any) => {
        this._bottomSheetRef.dismiss('success');
      });
    }
  }

  dataSource : any = [];
  getViewTag(){
    this.blogService.getViewByTagId(this.tagId).subscribe((res:any)=>{
      this.dataSource = res.data;
    })
  }



  // blogs = [
  //   { name : 'Blogs Created :', value : '03' },
  //   { name : 'Created On:', value : '21 Dec 2022' },
  //   { name : 'Slug :', value : 'grmconstruction.beeos.com/tag/news/' },
  // ]

  // blogListing = [
  //   { name : 'Agile Project Management in the Construction Industry', value: 'Gaurav Sharma' , status: 'Residential Construction' },
  //   { name : 'Agile Project Management in the Construction Industry', value: 'Gaurav Sharma' , status: 'Residential Construction' }
  // ]


}
