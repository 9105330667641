<section>
  <!-- background color start -->
  <div *ngIf="style?.background?.color">
    <div class="color-parent">
      <label class="heading">Color</label>
      <p class="change-color" (click)="openBackgroundColorEditor()">Change</p>
    </div>
    <div class="color-pallet" (click)="openBackgroundColorEditor()"
      [ngStyle]="{'background-color' : style?.background?.color}">
      <div class="accent-color">
        <div [ngStyle]="{'background-color' : style?.background?.accentColor}"></div>
        <p>Dd</p>
      </div>
    </div>
  </div>
  <hr class="line" *ngIf="style?.background?.color">
  <!-- background color end -->

  <!-- Button style start -->
  <div *ngIf="action && (!action.hasOwnProperty('display') || action.display)">
    <div class="color-parent">
      <label class="heading">Button Style</label>
      <p class="change-color" *ngIf="action.buttons.length == 1"
        (click)="openButtonStyleEditor(action.buttons[0].styles, action.buttons[0].id)">Change</p>
    </div>
    <div class="btn-display">
      <div *ngFor="let button of action.buttons;let i= index">
        <div class="color-parent" *ngIf="action.buttons.length > 1">
          <p class="button-label">{{i === 0 ? '1st' : '2nd'}} button</p>
          <p class="change-color" (click)="openButtonStyleEditor(button.styles, button.id)">Change</p>
        </div>
        <button class="btn-style" (click)="openButtonStyleEditor(button.styles, button.id)"
          [ngClass]="{'outline-btn': button.styles.type === 'Outline', 'solid-btn': button.styles.type === 'Solid'
          ,'round-btn': button.styles.shape === 'Round', 'square-btn': button.styles.shape === 'Square'}">{{button.content ? button.content.label : 'Style'}}</button>
      </div>
    </div>
    <hr class="line">
  </div>
  <!-- Button style end -->

  <!-- Background Image Start -->
  <div *ngIf="sectionType != 'image'">
    <div class="background-image" *ngIf="style?.background != undefined">
      <p class="mb-0">Background Image</p>
      <div class="form-check form-switch">
        <input class="form-check-input switch-btn" type="checkbox" [(ngModel)]="style?.background.showImage"
          (change)="toggleBackgroundImage()" role="switch">
      </div>
    </div>

    <div class="mt-10" *ngIf="style?.background?.showImage">
      <app-background-image-editor [data]="style.background" [componentId]="componentId"></app-background-image-editor>
    </div>
    <hr class="line">
  </div>
  <!-- Background Image End -->

  <!-- Map theme style property start -->
  <div *ngIf="style.hasOwnProperty('mapStyle')">
    <p class="heading">Theme</p>
    <mat-form-field appearance="outline" style="width: 100%;">
      <mat-select [(ngModel)]="style.mapStyle" (ngModelChange)="changeMapStyle()">
        <ng-container *ngFor="let item of mapStyle">
          <mat-option [value]="item.type">{{item.type | titlecase}}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
    <hr class="line">
  </div>
  <!-- Map theme style property end -->

  <!-- footer type property start -->
  <div *ngIf="style.hasOwnProperty('footerType')">
    <p class="heading">Type</p>
    <mat-form-field appearance="outline" style="width: 100%;height: 36px;">
      <mat-select [(ngModel)]="style.footerType">
        <ng-container *ngFor="let item of footerType">
          <mat-option [value]="item.type">{{item.type}}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
    <hr class="line">
  </div>
  <!-- footer type property end -->

  <!-- Position alignment property start -->
  <div *ngIf="style.hasOwnProperty('positionLayout')">
    <p class="heading">Layout</p>
    <div class="display-flex">
      <label class="input-label fs-15 mb-0">{{style.positionLayout.lable}}</label>
      <div class="buttons ml-auto">
        <div class="left-tab" (click)="changeLayoutAlignment('left')"
          *ngIf="style.positionLayout.types.includes('left')"
          [ngClass]="{'selected-tab' : style.positionLayout.value === 'left'}">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" width="24" height="24"
            class="icon w-5 h-5" aria-hidden="true">
            <path
              d="M2.25 7c0-1.24264 1.00736-2.25 2.25-2.25h2c1.24264 0 2.25 1.00736 2.25 2.25v6c0 1.2426-1.00736 2.25-2.25 2.25h-2c-1.24264 0-2.25-1.0074-2.25-2.25V7Zm2.25-.75c-.41421 0-.75.33579-.75.75v6c0 .4142.33579.75.75.75h2c.41421 0 .75-.3358.75-.75V7c0-.41421-.33579-.75-.75-.75h-2ZM11.25 6c0-.41421.3358-.75.75-.75h3c.4142 0 .75.33579.75.75s-.3358.75-.75.75h-3c-.4142 0-.75-.33579-.75-.75Zm0 4c0-.41421.3358-.75.75-.75h5c.4142 0 .75.33579.75.75 0 .4142-.3358.75-.75.75h-5c-.4142 0-.75-.3358-.75-.75Zm0 4c0-.4142.3358-.75.75-.75h4c.4142 0 .75.3358.75.75s-.3358.75-.75.75h-4c-.4142 0-.75-.3358-.75-.75Z">
            </path>
          </svg>
        </div>
        <div class="mid-tab" (click)="changeLayoutAlignment('right')"
          *ngIf="style.positionLayout.types.includes('right')"
          [ngClass]="{'selected-tab' : style.positionLayout.value === 'right'}">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" width="24" height="24"
            class="icon w-5 h-5" aria-hidden="true">
            <path
              d="M2.25 6c0-.41421.33579-.75.75-.75h3c.41421 0 .75.33579.75.75s-.33579.75-.75.75H3c-.41421 0-.75-.33579-.75-.75Zm0 4c0-.41421.33579-.75.75-.75h5c.41421 0 .75.33579.75.75 0 .4142-.33579.75-.75.75H3c-.41421 0-.75-.3358-.75-.75Zm0 4c0-.4142.33579-.75.75-.75h4c.41421 0 .75.3358.75.75s-.33579.75-.75.75H3c-.41421 0-.75-.3358-.75-.75Zm9-7c0-1.24264 1.0074-2.25 2.25-2.25h2c1.2426 0 2.25 1.00736 2.25 2.25v6c0 1.2426-1.0074 2.25-2.25 2.25h-2c-1.2426 0-2.25-1.0074-2.25-2.25V7Zm2.25-.75c-.4142 0-.75.33579-.75.75v6c0 .4142.3358.75.75.75h2c.4142 0 .75-.3358.75-.75V7c0-.41421-.3358-.75-.75-.75h-2Z">
            </path>
          </svg>
        </div>
        <div class="mid-tab" (click)="changeLayoutAlignment('top')" *ngIf="style.positionLayout.types.includes('top')"
          [ngClass]="{'selected-tab' : style.positionLayout.value === 'top'}">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" width="24" height="24"
            class="icon w-5 h-5" aria-hidden="true">
            <path
              d="M5.25 4.5c0-1.24264 1.00736-2.25 2.25-2.25h5c1.2426 0 2.25 1.00736 2.25 2.25v3c0 1.24264-1.0074 2.25-2.25 2.25h-5c-1.24264 0-2.25-1.00736-2.25-2.25v-3Zm2.25-.75c-.41421 0-.75.33579-.75.75v3c0 .41421.33579.75.75.75h5c.4142 0 .75-.33579.75-.75v-3c0-.41421-.3358-.75-.75-.75h-5ZM5.25 13c0-.4142.33579-.75.75-.75h8c.4142 0 .75.3358.75.75s-.3358.75-.75.75H6c-.41421 0-.75-.3358-.75-.75Zm-2 4c0-.4142.33579-.75.75-.75h12c.4142 0 .75.3358.75.75s-.3358.75-.75.75H4c-.41421 0-.75-.3358-.75-.75Z">
            </path>
          </svg>
        </div>
        <div class="right-tab" (click)="changeLayoutAlignment('bottom')"
          *ngIf="style.positionLayout.types.includes('bottom')"
          [ngClass]="{'selected-tab' : style.positionLayout.value === 'bottom'}">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" width="24" height="24"
            class="icon w-5 h-5" aria-hidden="true">
            <path
              d="M5.25 3c0-.41421.33579-.75.75-.75h8c.4142 0 .75.33579.75.75s-.3358.75-.75.75H6c-.41421 0-.75-.33579-.75-.75Zm-2 4c0-.41421.33579-.75.75-.75h12c.4142 0 .75.33579.75.75s-.3358.75-.75.75H4c-.41421 0-.75-.33579-.75-.75Zm2 5.5c0-1.2426 1.00736-2.25 2.25-2.25h5c1.2426 0 2.25 1.0074 2.25 2.25v3c0 1.2426-1.0074 2.25-2.25 2.25h-5c-1.24264 0-2.25-1.0074-2.25-2.25v-3Zm2.25-.75c-.41421 0-.75.3358-.75.75v3c0 .4142.33579.75.75.75h5c.4142 0 .75-.3358.75-.75v-3c0-.4142-.3358-.75-.75-.75h-5Z">
            </path>
          </svg>
        </div>
      </div>
    </div>
    <hr class="line">
  </div>
  <!-- Position alignment property start -->

  <!-- maximumProduct property start -->
  <div *ngIf="style.hasOwnProperty('maximumProduct')">
    <p class="heading mb-0">Maximum products to show</p>
    <div class="max-product-slider">
      <mat-slider style="width: 100%;margin-left: 0;" [max]="maximumProduct" [min]="min" [step]="step">
        <input matSliderThumb [(ngModel)]="style.maximumProduct">
      </mat-slider>
      <p class="mb-0 sliderValue" style="left: 0;">{{style.maximumProduct}}</p>
    </div>
    <hr class="line">
  </div>
  <!-- maximumProduct property end -->

  <!-- element in a row property start -->
  <div *ngIf="style.hasOwnProperty('elementInRow')">
    <p class="heading mb-0">No of columns on desktop</p>
    <div class="max-product-slider">
      <mat-slider style="width: 100%;margin-left: 0;" [max]="maxElementInRow" [min]="min" [step]="step">
        <input matSliderThumb [(ngModel)]="style.elementInRow">
      </mat-slider>
      <p class="mb-0 sliderValue" style="left: 0;">{{style.elementInRow}}</p>
    </div>
    <hr class="line">
  </div>
  <!-- element in a row property end -->

  <!-- featured product themes start  -->
  <div *ngIf="style?.theme">
    <div class="animation-speed">
      <p class="heading">Theme</p>
      <div class="buttons w-fit">
        <div *ngFor="let speed of productCardTheme;let i = index" (click)="style.theme = speed.type"
          [ngClass]="{'selected-tab' : speed.type === style.theme, 'left-tab': i == 0, 'right-tab' : i == productCardTheme.length - 1}">
          {{speed.type | titlecase}}
        </div>
      </div>
    </div>
    <hr class="line">
  </div>
  <!-- featured product themes end -->

  <!-- mobile column property start -->
  <div *ngIf="style.mobileColumn">
    <p class="heading">No of columns on mobile</p>

    <div class="buttons w-fit">
      <div [ngClass]="{'selected-tab' : style.mobileColumn === 1, 'left-tab': true}" (click)="style.mobileColumn = 1">
        1 column
      </div>
      <div [ngClass]="{'selected-tab' : style.mobileColumn === 2, 'right-tab' : true}" (click)="style.mobileColumn = 2">
        2 columns
      </div>
    </div>
    <hr class="line">
  </div>
  <!-- mobile column property end -->

  <!-- contact form opacity property start -->
  <div *ngIf="style.hasOwnProperty('inputOpacity')">
    <p class="heading">Inputs</p>
    <div class="slider">
      <p class="input-label mb-0 fw-600">Opacity</p>
      <div class="w-65">
        <mat-slider style="width: 100%;" [max]="max" [min]="min" [step]="step">
          <input matSliderThumb [(ngModel)]="style.inputOpacity">
        </mat-slider>
      </div>
      <p class="mb-0 sliderValue">{{style.inputOpacity}}%</p>
    </div>
    <hr class="line">
  </div>
  <!-- contact form opacity property end -->

  <!-- content alignment property start -->
  <div *ngIf="style.hasOwnProperty('contentAlignment')" class="mt-35">
    <label class="input-label">Align content to</label>
    <mat-form-field appearance="outline" style="width: 100%;height: 36px;">
      <mat-select [(ngModel)]="style.contentAlignment" (ngModelChange)="changeContentAlignment()">
        <ng-container *ngFor="let item of contentAlignment">
          <mat-option [value]="item.type">{{item.type | titlecase}}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
    <hr class="line">
  </div>
  <!-- content alignment property start -->

  <!-- text alignment start -->
   <ng-container *ngIf="style?.layout?.align">
    <div class="link-type">
      <p>Alignment</p>
      <div class="buttons ml-auto">
        <div class="left-tab" (click)="changeTextAlignment('left')"
          [ngClass]="{'selected-tab' : style.layout.align === 'left'}">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" width="24" height="24"
            class="icon w-5 h-5" aria-hidden="true">
            <path d="M4 3c-.55228 0-1 .44772-1 1v12c0 .5523.44772 1 1 1s1-.4477 1-1V4c0-.55228-.44772-1-1-1Z"></path>
            <path fill-rule="evenodd"
              d="M8 6c-.55228 0-1 .44772-1 1v6c0 .5523.44772 1 1 1h8c.5523 0 1-.4477 1-1V7c0-.55228-.4477-1-1-1H8Zm1 2v4h6V8H9Z"
              clip-rule="evenodd"></path>
          </svg>
        </div>
        <div class="mid-tab" (click)="changeTextAlignment('center')"
          [ngClass]="{'selected-tab' : style.layout.align === 'center'}">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" width="24" height="24"
            class="icon w-5 h-5" aria-hidden="true">
            <path fill-rule="evenodd"
              d="M14 6c.5523 0 1 .44772 1 1v6c0 .5523-.4477 1-1 1h-3v2c0 .5523-.4477 1-1 1-.55228 0-1-.4477-1-1v-2H6c-.55228 0-1-.4477-1-1V7c0-.55228.44772-1 1-1h3V4c0-.55228.44772-1 1-1 .5523 0 1 .44772 1 1v2h3Zm-7 6V8h6v4H7Z"
              clip-rule="evenodd"></path>
          </svg>
        </div>
        <div class="right-tab" (click)="changeTextAlignment('right')"
          [ngClass]="{'selected-tab' : style.layout.align === 'right'}">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" width="24" height="24"
            class="icon w-5 h-5" aria-hidden="true">
            <path d="M16 3c-.5523 0-1 .44772-1 1v12c0 .5523.4477 1 1 1s1-.4477 1-1V4c0-.55228-.4477-1-1-1Z"></path>
            <path fill-rule="evenodd"
              d="M4 6c-.55228 0-1 .44772-1 1v6c0 .5523.44772 1 1 1h8c.5523 0 1-.4477 1-1V7c0-.55228-.4477-1-1-1H4Zm1 2v4h6V8H5Z"
              clip-rule="evenodd"></path>
          </svg>
        </div>
      </div>
    </div>
    <hr class="line">
  </ng-container>
  <!-- text alignment end -->

  <!-- borderless image property start -->
   <ng-container *ngIf="style.hasOwnProperty('borderLessImage')">
    <div class="display-flex mt-10">
      <p class="mb-0 heading">Border less</p>
      <div class="form-check form-switch ml-auto">
        <input class="form-check-input switch-btn" type="checkbox" [(ngModel)]="style.borderLessImage" role="switch">
      </div>
    </div>
    <hr class="line">
   </ng-container>
  <!-- borderless image property end -->

  <!-- remove gap property start -->
  <div class="display-flex mt-15" *ngIf="style.hasOwnProperty('removeGaps')">
    <p class="mb-0 heading">Remove gaps</p>
    <div class="form-check form-switch ml-auto">
      <input class="form-check-input switch-btn" type="checkbox" [(ngModel)]="style.removeGaps" role="switch">
    </div>
  </div>
  <!-- remove gap property end -->

  <!-- Full width property start -->
  <ng-container *ngIf="style.hasOwnProperty('fullWidth')">
    <div class="display-flex mt-10">
      <p class="mb-0 heading">Full width</p>
      <div class="form-check form-switch ml-auto">
        <input class="form-check-input switch-btn" type="checkbox" [(ngModel)]="style.fullWidth" (ngModelChange)="style.removeGaps = (style.fullWidth ?? style.removeGaps)" role="switch">
      </div>
    </div>
    <hr class="line">
  </ng-container>
  <!-- Full width property end -->

  <!-- Full Screen property start -->
  <div class="display-flex mt-15" *ngIf="style.hasOwnProperty('fullScreen')">
    <p class="mb-0 heading">Fullscreen</p>
    <div class="form-check form-switch ml-auto">
      <input class="form-check-input switch-btn" type="checkbox" [(ngModel)]="style.fullScreen" role="switch">
    </div>
    <hr class="line">
  </div>
  <!-- Full screen property end -->

  <!-- image fit start -->
  <div *ngIf="style.image">
    <p class="heading">Image</p>

    <div class="mt-20" *ngIf="style.image.fit && (!style.hasOwnProperty('textPosition') || style.textPosition === TextPosition.BELOW_IMAGE)">
      <label class="input-label">Image fit</label>
      <mat-form-field appearance="outline" style="width: 100%;">
        <mat-select [(ngModel)]="style.image.fit" (ngModelChange)="changeImageStyle()">
          <ng-container *ngFor="let item of imageFit">
            <mat-option [value]="item.type">{{item.type}}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="style.image.ratio && style.image.fit != 'Contain'">
      <label class="input-label">Aspect ratio</label>
      <mat-form-field appearance="outline" style="width: 100%;">
        <mat-select [(ngModel)]="style.image.ratio" (ngModelChange)="changeImageStyle()">
          <ng-container *ngFor="let item of aspectRatio">
            <mat-option [value]="item.value">{{item.value}} | {{item.key}}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
    <hr class="line">
  </div>
  <!-- image fit end -->

  <!-- rounded corners start -->
  <div *ngIf="style.corners">
    <p class="heading mb-0">Rounded corners
      <mat-icon mat-ra ised-button #tooltip="matTooltip" class="i-icon"
          matTooltip="{{'This style will be applied to the image not for the background'}}"
          matTooltipPosition="right" matTooltipClass="infotooltip" (click)="tooltip.toggle()"
          style="color: grey; font-size: 15px !important; height: 24px">info</mat-icon>
    </p>
    <mat-form-field appearance="outline" style="width: 100%;">
      <mat-select [(ngModel)]="style.corners" (ngModelChange)="changeCorners()">
        <ng-container *ngFor="let item of corners">
          <mat-option [value]="item.type">{{item.type}}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
    <hr class="line">
  </div>
  <!-- rounded corners end -->

  <!-- card size start -->
  <div *ngIf="style.size">
    <p class="heading">Item Size</p>

    <div class="buttons w-fit">
      <div *ngFor="let size of cardSize;let i = index" (click)="changeCardSize(size.type)"
        [ngClass]="{'selected-tab' : style.size === size.type, 'left-tab': i == 0, 'right-tab' : i == cardSize.length - 1}">
        {{size.type}}
      </div>
    </div>
    <hr class="line">
  </div>
  <!-- card size end -->

  <!-- text position start -->
  <div *ngIf="style.textPosition && style.image.fit != ImageFit.Contain">
    <p class="heading">Text position</p>
    <mat-form-field appearance="outline" style="width: 100%;">
      <mat-select [(ngModel)]="style.textPosition" (ngModelChange)="changeTextPosition()">
        <ng-container *ngFor="let item of textPosition">
          <mat-option [value]="item.type">{{item.type}}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>

    <div *ngIf="style.textPosition === TextPosition.TOP_OF_IMAGE || style.textPosition === TextPosition.COVERING_IMAGE">
      <p class="heading">Text Background</p>
      <div class="text-background">
        <input type="color" class="color" [(ngModel)]="style.textBackground.color"
          (ngModelChange)="changeTextBackground()">
        <input type="text" class="color-code" [(ngModel)]="style.textBackground.color" disabled>
      </div>
      <div class="slider mt-10">
        <p class="input-label mb-0 fw-600">Amount</p>
        <div class="w-61">
          <mat-slider style="width: 100%;" [max]="max" [min]="min" [step]="step">
            <input matSliderThumb [(ngModel)]="style.textBackground.amount" (ngModelChange)="changeTextBackground()">
          </mat-slider>
        </div>
        <p class="mb-0 sliderValue">{{style.textBackground.amount}}%</p>
      </div>
    </div>
    <hr class="line">
  </div>
  <!-- text position end -->


  <!-- Animation Start -->
  <div *ngIf="style.animation">
    <p class="heading">Animations</p>
    <mat-form-field appearance="outline" style="width: 100%;">
      <mat-select [(ngModel)]="style.animation.type" (ngModelChange)="changeAnimationType()">
        <ng-container *ngFor="let item of animationType">
          <mat-option [value]="item.type">{{item.type | titlecase}}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>

    <div class="animation-speed" *ngIf="style.animation.type != 'none'">
      <p class="heading">Speed</p>
      <div class="buttons w-fit">
        <div *ngFor="let speed of animationSpeed;let i = index" (click)="changeAnimationSpeed(speed.type)"
          [ngClass]="{'selected-tab' : speed.type === style.animation.speed, 'left-tab': i == 0, 'right-tab' : i == animationSpeed.length - 1}">
          {{speed.type | titlecase}}
        </div>
      </div>
    </div>
    <hr class="line">
  </div>
  <!-- Animation End -->

  <!-- header navigation style property start -->
  <div *ngIf="style.hasOwnProperty('navigationStyle')">
    <p class="heading">Navigation Style</p>
    <mat-form-field appearance="outline" style="width: 100%;">
      <mat-select [(ngModel)]="style.navigationStyle">
        <ng-container *ngFor="let item of navigationStyle">
          <mat-option [value]="item.type">{{item.type | titlecase}}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
    <hr class="line">
  </div>
  <!-- header navigation style property end -->

  <!-- header sticky property start -->
  <div class="display-flex mt-15" *ngIf="style.hasOwnProperty('sticky')">
    <p class="mb-0 heading">Sticky</p>
    <div class="form-check form-switch ml-auto">
      <input class="form-check-input switch-btn" type="checkbox" [(ngModel)]="style.sticky" role="switch">
    </div>
    <hr class="line">
  </div>
  <!-- header sticky property end -->

  <!-- Heading spacing property start -->
  <div *ngIf="style.layout?.headingSpacing">
    <p class="heading">Heading spacing</p>
    <div class="mt-20">
      <mat-form-field appearance="outline" style="width: 100%;">
        <mat-select [(ngModel)]="style.layout.headingSpacing">
          <ng-container *ngFor="let item of spacingType">
            <mat-option [value]="item.type">{{item.type | titlecase}}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
    <hr class="line">
  </div>
  <!-- Heading spacing property end  -->

  <!-- Horizontal spacing property start -->
  <div *ngIf="style.layout?.spacingHorizontal">
    <p class="heading">Horizontal spacing</p>
    <div class="mt-20">
      <mat-form-field appearance="outline" style="width: 100%;">
        <mat-select [(ngModel)]="style.layout.spacingHorizontal">
          <ng-container *ngFor="let item of spacingType">
            <mat-option [value]="item.type">{{item.type | titlecase}}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
    <hr class="line">
  </div>
  <!-- Heading spacing property end  -->

  <!-- component spacing start -->
  <div *ngIf="style.layout?.spacing">
    <p class="heading">Spacing</p>
    <div class="mt-20" *ngIf="style.layout?.spacing.top">
      <label class="input-label">Top</label>
      <mat-form-field appearance="outline" style="width: 100%;">
        <mat-select [(ngModel)]="style.layout.spacing.top" (ngModelChange)="changeSpacingType()">
          <ng-container *ngFor="let item of spacingType">
            <mat-option [value]="item.type">{{item.type | titlecase}}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>

    <div *ngIf="style.layout?.spacing.bottom">
      <label class="input-label">Bottom</label>
      <mat-form-field appearance="outline" style="width: 100%;">
        <mat-select [(ngModel)]="style.layout.spacing.bottom" (ngModelChange)="changeSpacingType()">
          <ng-container *ngFor="let item of spacingType">
            <mat-option [value]="item.type">{{item.type | titlecase}}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>

  <!-- <div class="mt-20" *ngIf="style.layout?.spacing?.top">
    <label class="input-label">Top</label>
    <div class="spacing-container">
      <div class="spacing-option"
           *ngFor="let item of spacingType"
           (click)="changeSpacingType(item.type, 'top')"
           [ngClass]="{ 'selected': style.layout.spacing.top === item.type }">
        {{ item.type | titlecase }}
      </div>
    </div>
  </div> -->

  <!-- <div class="mt-20" *ngIf="style.layout?.spacing?.bottom">
    <label class="input-label">Bottom</label>
    <div class="spacing-container">
      <div class="spacing-option"
           *ngFor="let item of spacingType"
           (click)="changeSpacingType(item.type, 'bottom')"
           [ngClass]="{ 'selected': style.layout.spacing.bottom === item.type }">
        {{ item.type | titlecase }}
      </div>
    </div>
  </div> -->

    <hr class="line">
  </div>
  <div *ngIf="style.layout?.fit" class="display-flex">
    <p class="input-label mb-0 fw-600">Min. Height</p>
    <div class="buttons w-fit ml-auto">
      <div *ngFor="let height of minHeight;let i = index" (click)="changeMinHeight(height.type)"
        [ngClass]="{'selected-tab' : height.type === style.layout.fit, 'left-tab': i == 0, 'right-tab' : i == minHeight.length - 1}">
        {{height.type | titlecase}}
      </div>
    </div>
    <hr class="line">
  </div>
  <!-- component spacing end -->

  <div *ngIf="style.hasOwnProperty('direction')" class="d-flex">
    <p class="input-label mb-0 fw-600">Wrap Direction</p>
    <div class="buttons w-fit ml-auto">
      <div *ngFor="let wrapOption of flexWrapOptions;let i = index" (click)="changeWrapOption(wrapOption)"
        [ngClass]="{'selected-tab' : wrapOption === style.direction, 'left-tab': i == 0, 'right-tab' : i == minHeight.length - 1}">
        {{wrapOption | titlecase}}
      </div>
    </div>
  </div>
  <div *ngIf="style.hasOwnProperty('containerHeight')">
    <div class="slider">
      <p class="input-label mb-0 fw-600">Height</p>
      <div class="w-55">
        <mat-slider style="width: 100%;" max="400" [min]="min" [step]="step">
          <input matSliderThumb [(ngModel)]="style.containerHeight" (ngModelChange)="changeBorder()">
        </mat-slider>
      </div>
      <p class="mb-0 sliderValue">{{style.containerHeight}}px</p>
    </div>
  </div>
  <div *ngIf="style.hasOwnProperty('containerWidth')">
    <div class="slider">
      <p class="input-label mb-0 fw-600">Width</p>
      <div class="w-55">
        <mat-slider style="width: 100%;" max="400" [min]="min" [step]="step">
          <input matSliderThumb [(ngModel)]="style.containerWidth" (ngModelChange)="changeBorder()">
        </mat-slider>
      </div>
      <p class="mb-0 sliderValue">{{style.containerWidth}}px</p>
    </div>
  </div>

  <!-- border style start -->
  <div *ngIf="style.border">
    <div class="display-flex">
      <p class="heading">Border</p>
      <div class="form-check form-switch">
        <input class="form-check-input switch-btn" type="checkbox" [(ngModel)]="style.border.display"
          (ngModelChange)="changeBorder()" role="switch">
      </div>
    </div>
    <div *ngIf="style.border.display" class="border-style">
      <div class="text-background">
        <input type="color" class="color" [(ngModel)]="style.border.color" (ngModelChange)="changeBorder()">
        <input type="text" class="color-code" [(ngModel)]="style.border.color" disabled>
      </div>
      <div class="slider">
        <p class="input-label mb-0 fw-600">Thickness</p>
        <div class="w-55">
          <mat-slider style="width: 100%;" [max]="borderMax" [min]="min" [step]="step">
            <input matSliderThumb [(ngModel)]="style.border.thickness" (ngModelChange)="changeBorder()">
          </mat-slider>
        </div>
        <p class="mb-0 sliderValue">{{style.border.thickness}}px</p>
      </div>
      <div class="slider">
        <p class="input-label mb-0 fw-600">Border Radius</p>
        <div class="w-55">
          <mat-slider style="width: 100%;" [max]="borderRadiusMax" [min]="min" [step]="step">
            <input matSliderThumb [(ngModel)]="style.border.radius" (ngModelChange)="changeBorder()">
          </mat-slider>
        </div>
        <p class="mb-0 sliderValue">{{style.border.radius}}px</p>
      </div>
      <div class="display-flex">
        <p class="input-label mb-0 fw-600">Type</p>
        <div class="buttons w-fit ml-auto">
          <div *ngFor="let border of borderType;let i = index" (click)="changeBorderType(border.type)"
            [ngClass]="{'selected-tab' : border.type === style.border.type, 'left-tab': i == 0, 'right-tab' : i == borderType.length - 1}">
            {{border.type}}
          </div>
        </div>
        <hr class="line">
      </div>
    </div>
    <hr class="line">
  </div>
  <!-- border style end -->

  <!-- merge with header property start -->
   <ng-container *ngIf="style.hasOwnProperty('merge')">
    <div class="display-flex mt-15">
      <div class="w-80">
        <p class="mb-0 heading">Combine with header</p>
        <span>Seamlessly blend banner background with page header</span>
      </div>
      <div class="form-check form-switch ml-auto">
        <input class="form-check-input switch-btn" type="checkbox" [(ngModel)]="style.merge" role="switch">
      </div>
    </div>
   </ng-container>
  <!-- merge with header property end -->
</section>
