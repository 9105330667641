import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { DbService } from './db-service/db.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  
  isLoggedIn(){
    return !localStorage.getItem('businessId');
  }

  //here temporarly setting staffid for login

  constructor(
    private _router : Router,
    private _dbService : DbService
  ){}

  canActivate(){
    if(this._dbService.getBusinessId()){
      return true;
    }
    this._router.navigate(['']);
    return false;
  }
  
}
