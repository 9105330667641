import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DbService } from 'src/app/services/db-service/db.service';
import { StorageKeys } from 'src/app/shared-module-files/simpo.constant';

@Component({
  selector: 'app-without-signup-edit',
  templateUrl: './without-signup-edit.component.html',
  styleUrls: ['./without-signup-edit.component.scss']
})
export class WithoutSignupEditComponent implements OnInit{

  constructor(
    private _router : Router,
    private _route : ActivatedRoute,
    private _dbService : DbService
  ){}

  url:any = '';

  ngOnInit(): void {
  }

  continue(){
    localStorage.setItem(StorageKeys.WEBSITE_ID, this.url.split("=")[1]);
    this._router.navigate(['/edit-website'])
  }

}
