<div class="card-section" [ngStyle]="{'background-color': dynamicData?.bgColor}">
    <div class="image-service">
        <img [src]="dynamicData.image.attachment" [alt]="dynamicData.image.altName">
    </div>
    <div class="card-contentsection ptb-10">
        <div class="heading mt-10">
            <app-text-element id="title" [data]="dynamicData.title"></app-text-element>
        </div>
        <div class="description mt-15">
            <app-text-element id="description" [data]="dynamicData.description" ></app-text-element>
        </div>
    </div>
</div>