<div class="main_section">
    <div class="location">
        <img class="location_card" [src]="data.image.attachment">
        <div class="details_section">
            <ng-container *ngFor ="let item of itemData">
                <div class="card_container">
                    <img class="card_image" [src]="item.image.attachment">
                    <app-text-element [data] = "item.description"></app-text-element>
                </div>
            </ng-container>
        </div>
    </div>
</div>