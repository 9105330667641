<div class="total_container">
    <div class="test" [ngStyle]="{'background-image': data.attachment === '' ? 'url(./../../../../assets/images/hero_banner/hero-banner-card.svg)' : 'url( '+ data.attachment + ')'}">
    </div>
    <div class="show_btns" *ngIf="all">
        <div class="full_btns">
            <div class="full-edit-section" (click)="editSection()">
                <div class="edit image">
                    <img src="./../../../assets/images/editor/edit_blue.svg" alt="buleditIcon">
                </div>
                <div class="editsection-text">
                    Edit Section
                </div>
            </div>
            <div class="full-edit-section">
                <div class="edit image">
                    <img src="./../../../assets/images/editor/loaderRegenrate.svg"
                        alt="regenrate">
                </div>
                <div class="regenerate-text" (click)="addNewSection()">
                    Add Section
                </div>
            </div>
            <div class="full-edit-section">
                <div class="all_icons">
                    <img src="./../../../../../../../../assets/images/editor/upArrowEnable.svg" alt="uparrow" *ngIf="index != 1" (click)="positionMoveUp()" class="upward_arrow">
                    <div class="downward_arrow">
                        <img src="./../../../../../../../../assets/images/editor/down_arrow.svg" alt="downarrow" (click)="positionMoveDown()">
                    </div>
                    <div class="delete_icon">
                        <img src="./../../../../../../../../assets/images/editor/delete_icon.svg" alt="delete_icon" (click)="deleteComponent()">
                    </div>
                </div>
            </div>
        </div>
        <button class="add_btn" (click)="addSection()">
            + Add Section
        </button>
    </div>
</div>