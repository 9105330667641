import { Component, OnInit } from '@angular/core';
import { WebisiteService } from '../../../../webisite.service';
import { MatSnackBar } from '@angular/material/snack-bar';
// import { UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FormControl,FormGroup,Validators } from '@angular/forms';

@Component({
  selector: 'app-contact-us-with-social-links',
  templateUrl: './contact-us-with-social-links.component.html',
  styleUrls: ['./contact-us-with-social-links.component.scss']
})
export class ContactUsWithSocialLinksComponent implements OnInit{
  leadMobile = new FormControl("", [Validators.maxLength(15), Validators.minLength(8)])
  emailRegex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
  fullName : any = '';
  mailId : any = '';
  mobileNumber : any = '';
  message : any = '';


  constructor(
    private _paymentService : WebisiteService,
    private snackbar : MatSnackBar,
    private router :Router
  ){

  }
  // navigateToHome()
  // {
  //   setTimeout(() => {
  //   this.router.navigate(['']);
  //   },2000);
  // }
  ngOnInit(){

  }

  contactBtnLoader : boolean = false;

  submit(){
    this.contactBtnLoader = true;
    if(this.emailRegex.test((this.mailId).toLowerCase())){
      let data = {
        businessId:null,
        name:this.fullName,
        email:this.mailId,
        mobileNo:this.mobileNumber,
        message:this.message
      }
      this._paymentService.contactUs(data).subscribe(
        (res:any) => {
          this.snackbar.open(
            'Thank you, our executive will contact you',
            'Ok',
            {
              duration:1500
            }
          );
          this.contactBtnLoader = false;
          this.fullName = '';
          this.mailId = '';
          this.mobileNumber = '';
          this.message = '';
        },
        (err) => {
          this.contactBtnLoader = false;
          this.snackbar.open(
            `${err.error.message}`,
            'Close',
            {
              duration:1500
            }
          );
        }
      )
       
  }
  else {
    this.snackbar.open(
      'Please enter valid mail id',
      'Ok',
      {
        duration:1500
      }
    );
    this.contactBtnLoader = false;
  }
}
}
