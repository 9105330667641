import { Component, OnInit } from '@angular/core';
import { TemplateService } from '../services/template.service';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit{

  constructor(
    private templateService : TemplateService,
  ){}

  monitorScreen : boolean = true;
  mobileScreen : boolean = false;

  heroBannerComponent : boolean = false;
  heroBanner_ComponentData : any;

  ngOnInit(): void {
    //console.log(window.localStorage.getItem('webId'))
    this.templateService.getTemplateDataByTemplateId(window.localStorage.getItem('webId')).subscribe(
      (res:any) => {
        //console.log(res.data);
        res.data[0].componentContentList.forEach((element:any) => {
          if(element.componentName === "heroBanner"){
            this.heroBannerComponent = true;
            this.heroBanner_ComponentData = element.data[0];
          }
        })
      },
      (err) => {
        //console.log(err)
      }
    )
  }

  changeScreen(element:any){
    if(element === 'monitor'){
      this.monitorScreen = true;
      this.mobileScreen = false
    }
    else{
      this.mobileScreen = true;
      this.monitorScreen = false;
      // window.resizeTo(475,667);
    }
  }

}
