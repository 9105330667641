import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ElementHelper {
  constructor() { }
map : any = {
    "title":{
        "color":"red",
        "font-size":"28px",
        "font-weight":"bold",
        "line-height":"40px",
        "value":"Title",
        "lm_title":"Heading",
        "lm_type":"TEXT_TITLE"
  },
  "description":{
    "color":"pink",
    "font-size":"16px",
    "font-weight":"500",
    "line-height":"26px",
    "value":"Self-contained, mobile friendly slider component for Angular 6+ based on angularjs-slider.",
    "lm_title":"Description",
    "lm_type":"TEXT_DESC"
  },
  "item_title":{
    "color":"red",
    "font-size":"18px",
    "font-weight":"700",
    "line-height":"28px",
    "value":"Item Title",
    "lm_title":"Heading",
    "lm_type":"TEXT_ITEM_TITLE"
},
  "button_solid_sharp":{
    "color":"white",
    "background-color":"blue",
    "font-size":"14px",
    "font-weight":"700",
    "padding":"8px 15px",
    "height":"44px",
    "border":"none",
    "border-radius":"none",
    "value":"send",
    "lm_title":"Button",
    "lm_type":"BUTTON_SOLID_SHARP",
    "redirectTo":""
  },
  "button_solid_rounded":{
    "color":"white",
    "background-color":"blue",
    "font-size":"14px",
    "font-weight":"700",
    "padding":"8px 15px",
    "height":"44px",
    "border":"none",
    "border-radius":"10px",
    "value":"send",
    "lm_title":"Button",
    "lm_type":"BUTTON_SOLID_ROUNDED",
    "redirectTo":""
  },
  "button_outline_sharp":{
    "color":"white",
    "background-color":"transparent",
    "font-size":"14px",
    "font-weight":"700",
    "padding":"8px 15px",
    "height":"44px",
    "border":"2px solid red",
    "border-radius":"none",
    "value":"send",
    "lm_title":"Button",
    "lm_type":"BUTTON_OUTLINE_SHARP",
    "redirectTo":""
  },
  "button_outline_rounded":{
    "color":"white",
    "background-color":"transparent",
    "font-size":"14px",
    "font-weight":"700",
    "padding":"8px 15px",
    "height":"44px",
    "border":"2px solid red",
    "border-radius":"10px",
    "value":"send",
    "lm_title":"Button",
    "lm_type":"BUTTON_OUTLINE_ROUNDED",
    "redirectTo":""
  },
  "number":{
    "value":'4',
    "lm_title":"rating",
    "lm_type":"NUMBER"
  }
};


getTextTitleElement(text: String, color: String, editTitle: String, keyForBackend?: string) {
  let temp = Object.assign({}, this.map.title);
  temp.color = color;
  temp.value = text;
  if (editTitle === '') {
    temp.lm_title = 'Heading';
  }
  else {
    temp.lm_title = editTitle;
  }
  temp.param = keyForBackend;
  return temp;
}

  getNumberElement(text: Number, keyForBackend?: string) {
    let temp = Object.assign({}, this.map.number);
    temp.value = text;
    temp.param = keyForBackend;
    return temp;
  }

  getTextDescElement(text: String, color: String, editTitle: String, keyForBackend?: string) {
    let temp = Object.assign({}, this.map.description);
    temp.color = color;
    temp.value = text;
    if (editTitle === '') {
      temp.lm_title = 'Description';
    }
    else {
      temp.lm_title = editTitle;
    }
    temp.param = keyForBackend;
    return temp;
  }

  getTextItemTitleElement(text: String, color: String, editTitle: String, keyForBackend?: string) {
    let temp = Object.assign({}, this.map.item_title);
    temp.color = color;
    temp.value = text;
    if (editTitle === '') {
      temp.lm_title = 'Title';
    }
    else {
      temp.lm_title = editTitle;
    }
    temp.param = keyForBackend;
    return temp;
  }

  getButtonSolidSharpElement(text: String, color:String, bgColor:String,editTitle:String) {
    let temp = Object.assign({}, this.map.button_solid_sharp);
    temp.color = color;
    temp['background-color'] = bgColor;
    temp.value = text;
    if(editTitle === ''){
        temp.lm_title = 'Button';
    }
    else{
        temp.lm_title = editTitle;
    }
    return temp; 
  }
  getRedirectUrlElement(redirectUrl: string, color: String, editTitle: String, fontSize: String, lineHight: String, fontWeight: String, keyForBackend?: String) {
    let temp = Object.assign({}, this.map.item_title);
    temp.color = color;
    temp.value = redirectUrl ? redirectUrl : '#';
    temp['font-size'] = fontSize;
    temp['line-height'] = lineHight;
    temp['font-weight'] = fontWeight;
    if (editTitle === '') {
      temp.lm_title = 'Redirect Url';
    }
    else {
      temp.lm_title = editTitle;
    }
    temp.param = keyForBackend;
    return temp;
  }

  getButtonSolidRoundedElement(text: String, color:String, bgColor:String,editTitle:String ,  keyForBackend?: String) {
    let temp = Object.assign({}, this.map.button_solid_rounded);
    temp.color = color;
    temp['background-color'] = bgColor;
    temp.value = text;
    if(editTitle === ''){
        temp.lm_title = 'Button';
    }
    else{
        temp.lm_title = editTitle;
    }
    temp.param = keyForBackend;
    return temp; 
  }

  getTextTitleElementWithFontsize(text: String, color: String, editTitle: String, fontSize: String, lineHight: String, fontWeight: String, keyForBackend?: string) {
    let temp = Object.assign({}, this.map.title);
    temp.color = color;
    temp.value = text;
    temp['font-size'] = fontSize;
    temp['line-height'] = lineHight;
    temp['font-weight'] = fontWeight;

    if (editTitle === '') {
      temp.lm_title = 'Heading';
    }
    else {
      temp.lm_title = editTitle;
    }
    temp.param = keyForBackend;
    return temp;
  }

  


  getButtonOutlineSharpElement(text: String, color:String, bgColor:String,editTitle:String) {
    let temp = Object.assign({}, this.map.button_outline_sharp);
    temp.color = color;
    temp.border = "2px solid " + bgColor;
    temp.value = text;
    if(editTitle === ''){
        temp.lm_title = 'Button';
    }
    else{
        temp.lm_title = editTitle;
    }
    return temp; 
  }

  getButtonOutlineRoundedElement(text: String, color:String, bgColor:String,editTitle:String) {
    let temp = Object.assign({}, this.map.button_outline_rounded);
    temp.color = color;
    temp.border = "2px solid " + bgColor;
    temp.value = text;
    if(editTitle === ''){
        temp.lm_title = 'Button';
    }
    else{
        temp.lm_title = editTitle;
    }
    return temp; 
  }

  
  getTextItemTitleElementWithFontsize(text: String, color: String, editTitle: String, fontSize: String, lineHight: String, fontWeight: String, keyForBackend?: String) {
    let temp = Object.assign({}, this.map.item_title);
    temp.color = color;
    temp.value = text;
    temp['font-size'] = fontSize;
    temp['line-height'] = lineHight;
    temp['font-weight'] = fontWeight;
    if (editTitle === '') {
      temp.lm_title = 'Title';
    }
    else {
      temp.lm_title = editTitle;
    }
    temp.param = keyForBackend;
    return temp;
  }

  getTextDescElementWithFontsize(text: String, color: String, editTitle: String, fontSize: String, lineHight: String, fontWeight: String, keyForBackend?: string) {
    let temp = Object.assign({}, this.map.description);
    temp.color = color;
    temp.value = text;
    temp['font-size'] = fontSize;
    temp['line-height'] = lineHight;
    temp['font-weight'] = fontWeight;
    if (editTitle === '') {
      temp.lm_title = 'Description';
    }
    else {
      temp.lm_title = editTitle;
    }
    temp.param = keyForBackend;
    return temp;
  }
  getButtonOutlineRoundedElementFontSize(text: String, color: String, bgColor: String, editTitle: String, fontSize: String, fontWeight: String, letterSpacing: String, height: String, keyForBackend?: string) {
    let temp = Object.assign({}, this.map.button_outline_rounded);
    temp.color = color;
    temp.border = "2px solid " + bgColor;
    temp.value = text;
    temp['font-size'] = fontSize;
    temp['font-weight'] = fontWeight;
    temp['letter-spacing'] = letterSpacing;
    temp['height'] = height
    if (editTitle === '') {
      temp.lm_title = 'Button';
    }
    else {
      temp.lm_title = editTitle;
    }
    temp.param = keyForBackend;
    return temp;
  }
  getTextItemTitleElementWithFontsizeMargin(text: String, color:String,editTitle:String,fontSize:String , lineHight:String , fontWeight:String, margin: String,keyForBackend?:string) {
    let temp = Object.assign({}, this.map.item_title);
    temp.color = color;
    temp.value = text;
    temp['font-size'] = fontSize;
    temp['line-height']= lineHight;
    temp['font-weight'] = fontWeight;
    temp['margin-top'] = margin;
    if(editTitle === ''){
      temp.lm_title = 'Title';
    }
    else{
      temp.lm_title = editTitle;
    }
    temp.param = keyForBackend;
    return temp; 
  }
}
