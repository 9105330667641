<div class="sticky-md-top bg-white ">
    <div class="d-flex justify-content-between py-4 px-4">
        <div class="view-title">View Site</div>
        <div class="cursor-pointer" (click)="closePopup()">
            <img src="../../../../../../assets/images/common/close-img.svg" alt="">
        </div>
    </div>
    <hr class="section-divider">
</div>
<div class="body-section">
    <div class="top-secton">
        <div>
            <h5 class="body-title">Website preview address</h5>
        </div>
    <button class="preview-button" >
        <mat-icon>lock</mat-icon>
         <span class="website-url">{{businessDetails.websiteUrl}}</span>
    </button>
    </div>
    <div class="card-section">
        <div class="card-height">
            <div class="col-md-8 card-width">
                <div class="card-body">
                    <div class="lock-image"  >
                        <img src="https://cdn.durable.co/assets/blocks/cookie-notice.svg" class="card-img px-4 " style="width: 145px" alt="Banner Image">
                    </div>
                    <div class="title-desc">
                    <div class="card-title">Upgrade and get a professional domain</div>
                    <div class="description">Level up your business with a custom domain, automated SEO, analytics, and more!</div>
                    <!-- <div class="tag card-text fs-6 fw-light" style="margin-top: 10px;">By Simpo</div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<hr class="section-divider">
<div class="footer px-4">
    <button (click)="upgradePlan()" class="border view-plans" style="width: 25%;height: 40px; border-radius: 5px;font-size: 14px; font-weight: 600;background-color: white;">View Plans</button>
    <button (click)="viewPerview()" class="border view-plans" style="width: 25%; height: 40px; border-radius: 5px;font-size: 14px; font-weight: 600;background-color: #0496ff; color: white;">View</button>
</div>
