import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { DbService } from './db-service/db.service';

@Injectable({
  providedIn: 'root'
})
export class HomeGuard implements CanActivate {
  
  isLoggedIn(){
    return !localStorage.getItem('staffId');
  }

  //here temporarly setting staffid for login

  constructor(
    private _router : Router,
    private _dbService : DbService
  ){}

  canActivate(){
    if(this._dbService.getBusinessId()){
        this._router.navigate(['/admin/home']);
      return false;
    }
    
    return true;
  }
  
}
