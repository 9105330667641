<div class="label" [ngStyle]="data.title ? data.title : {}">
    {{data.title.value}}
</div>
<mat-form-field class="sfull_width input_card casual_dropdown" appearance="outline">
    <mat-select [(ngModel)]="optionValue">
        <mat-option value = "">--Select--</mat-option>
        <mat-option [value]="item" *ngFor="let item of data.options">
            {{item | titlecase}}
        </mat-option>
    </mat-select>
</mat-form-field>
<!-- <mat-form-field appearance="outline">
    <mat-select [(ngModel)]="optionValue">
        <mat-option value = "">--Select--</mat-option>
        <mat-option [value]="country" *ngFor="let country of dropdownValues">{{country}}</mat-option>
    </mat-select>
  </mat-form-field> -->