import { Component, Input } from '@angular/core';
import { animationSpeed, animationType, corners } from './other-styles.modal';
import { WebsiteEditor } from 'src/app/website-generation/website-editor.service';
import { ANIMATION_DIRECTION, Corners } from 'simpo-component-library';

@Component({
  selector: 'app-other-styles',
  templateUrl: './other-styles.component.html',
  styleUrls: ['./other-styles.component.scss']
})
export class OtherStylesComponent {
  @Input() templatePage: any[] = [];any

  constructor(private editorService: WebsiteEditor){}

  animationType = animationType;
  animationSpeed = animationSpeed;
  corners = corners;

  selectedAnimationType = 'none'
  selectedAnimationSpeed = 'medium'
  selectedCorners:Corners = Corners.None

  changeAnimationType(){
    this.editorService.changeGlobalAnimation(this.selectedAnimationType, this.selectedAnimationSpeed, this.templatePage);
  }

  changeAnimationSpeed(speed: string){
    this.selectedAnimationSpeed = speed
  }

  changeCorners(){
    this.editorService.changeGlobalCorners(this.selectedCorners, this.templatePage);
  }
}
