import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TemplateService } from 'src/app/services/template.service';

@Component({
  selector: 'app-contact-us4',
  templateUrl: './contact4.component.html',
  styleUrls: ['./contact4.component.scss' , './mq-contact4-us.scss']
})
export class ContactUs4Component {

  constructor( private templatesService:TemplateService,
    private _matPopup : MatSnackBar,){

  }
  componentData:any=null;
  ngOnInit(): void {
    this.templatesService.updateElements.subscribe({
      next:(value:any)=>{
        console.log("value check",value);
        this.templateData=value;  
        this.dataAssign();     
      }
    })
  }


  @Input() templateData:any = {
    "componentCategory" : "contact us",
    "ranking" : 0,
    "businessCategoryId" : "",
    "businessCategoryName" : "All category",
    "data" : [
      {
        value : "Get in touch with us & let's talk.",
        field : 'heading',
        type : 'heading',
        style : {'font-size':'42px','font-weight':900,'padding':'0 12%'}
      },
      {
        value : "Office Hours",
        field : 'subtext',
        type : 'subtext',
        style : {'font-size':'22px','font-weight':600}
      },
      {
        value : "Our Address",
        field : 'subtext',
        type : 'subtext',
        style : {'font-size':'22px','font-weight':700}
      },
      {
        value : "Office 2",
        field : 'subtext',
        type : 'subtext',
        style : {'font-size':'22px','font-weight':700}
      },
      {
        value : "Get In Touch",
        field : 'subtext',
        type : 'subtext',
        style : {'font-size':'22px','font-weight':700}
      },
      {
        value : ['','8:00 am to 5:00 pm',
        {
          value : "Monday-Friday",
          type :'text', field :'text', style : {}
        }
      ],
        field : 'subtext',
        type : 'subtext',
        style : {'font-size':'18px','color':'#959393','margin-top':'20px'}
      },
      {
        value : "8502 Preston Rd. Ingle, Maine 98380, USA",
        field : 'subtext',
        type : 'subtext',
        style : {}
      },
      {
        value : "8502 Preston Rd. Ingle, Maine 98380, USA",
        field : 'subtext',
        type : 'subtext',
        style : {}
      },
      {
        value : ['+1-246-888-0653','+1-222-632-0194'],
        field : 'subtext',
        type : 'subtext',
        style : {}
      },
      {
        imgUrl : "./../../../../../../../../assets/images/contact_us/clock.png",
        display : true,
        type : 'image',
        field : 'image',
        style : {'width':'25px','height':'25px','margin-right':'10px'}
      },
      {
        imgUrl : "./../../../../../../../../assets/images/contact_us/location.svg",
        display : true,
        type : 'image',
        field : 'image',
        style : {'width':'25px','height':'25px'}
      },
      {
        imgUrl : "./../../../../../../../../assets/images/contact_us/briefcase.png",
        display : true,
        type : 'image',
        field : 'image',
        style : {'width':'25px','height':'25px'}
      },
      {
        imgUrl : "./../../../../../../../../assets/images/contact_us/telephone.png",
        display : true,
        type : 'image',
        field : 'image',
        style : {'width':'25px','height':'25px'}
      },
      {
        imgUrl : "./../../../../../../../../assets/images/contact_us/profile.jpg",
        display : true,
        type : 'image',
        field : 'image',
        style : {'width':'100%','height':'100%','border-radius':'25px'}
      },
      {
        value : "Send",
        field : 'button',
        type : 'text',
        style : {}
      },
      {
        "value": "Contact Us",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 500,"font-size": "36px", "color":"#000000"}
      },
      {
        "value": "We're glad you're interested in reaching out to us.Whether you have a question about our products, want to give us feedback, or just want to say hi,we're here to help.You can get in touch with us filing out this form",
        "type":"Text",
        "field":"Text",
        "style":{"font-weight": 500,"font-size": "18px", "color":"rgb(149, 147, 147)","padding-top": "2%"}
      },
    ],
    "createdTimeStamp" : "",
    "language" : "English",
    "componentName" : "contact4Component"
  }
  componentName = this.templateData.componentName;
  data = this.templateData.data;
  heading = this.data[0];
  subtext1 = this.data[1];
  subtext2 = this.data[2];
  subtext3 = this.data[3];
  subtext4 = this.data[4];
  text1 = this.data[5];
  text2 = this.data[6];
  text3 = this.data[7];
  text4 = this.data[8];
  icon1 = this.data[9];
  icon2 = this.data[10];
  icon3 = this.data[11];
  icon4 = this.data[12];
  profile_img = this.data[13];
  button = this.data[14];

  dataAssign(){
    this.templateData.componentContentList.forEach((value:any) => {
      if(value.componentName=='app-contact-us4'){
        this.componentData=value;
        this.data=value.data;
      }
    });
    this.heading = this.data[0];
    this.subtext1 = this.data[1];
    this.subtext2 = this.data[2];
    this.subtext3 = this.data[3];
    this.subtext4 = this.data[4];
    this.text1 = this.data[5];
    this.text2 = this.data[6];
    this.text3 = this.data[7];
    this.text4 = this.data[8];
    this.icon1 = this.data[9];
    this.icon2 = this.data[10];
    this.icon3 = this.data[11];
    this.icon4 = this.data[12];
    this.profile_img = this.data[13];
  }

  showMessage(){
    this._matPopup.open(
      'You need to signup to access this feature',
      'Ok'
    )
  }
}
