import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-contactus-details-edit-card',
  templateUrl: './contactus-details-edit-card.component.html',
  styleUrls: ['./contactus-details-edit-card.component.scss']
})
export class ContactusDetailsEditCardComponent implements OnInit{

  constructor(
    public dialogRef: MatDialogRef<ContactusDetailsEditCardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ){}

  gettingArray : any;
  
  ngOnInit(): void {
    console.log(this.data);
    this.gettingArray = this.data.data
  }

  close(){
    this.dialogRef.close();
  }

}
