<section class="popup_section">
    <div class="popup_title_close_btn">
        <div class="popup_title">Edit List</div>
        <div class="close_btn" (click)="close()">
            <mat-icon>close</mat-icon>
        </div>
    </div>
    <div class="hr_line"></div>

    <div>
        <div class="label">Label</div>
        <textarea name="" id="multiliner" class="textarea"
        [(ngModel)]="singleValue.displayValue" [cdkTextareaAutosize]="true"
        style="max-height: 100px;"></textarea>
    </div>
    <div class="link_related">
        <div class="link_label">Link</div>
        <div class="link_and_value">
            <div class="outer_box">
                <mat-select [(ngModel)]="buttonRedirectionValue" class="button_dropdown">
                    <mat-option value="">--Select--</mat-option>
                    <mat-option [value]="item.value" *ngFor="let item of buttonLinks" class="editor_button_dropdown">
                        {{item.name | titlecase}}
                    </mat-option>
                </mat-select>
                <div class="between-vr-line"></div>
                <input matInput [disabled]="buttonRedirectionValue === ''" class="right_dropdown_input" *ngIf="buttonRedirectionValue === ''" />
                <input matInput placeholder="External link" (keyup)="buttonRedirections('EXTERNAL')"
                 [(ngModel)]="externalPageRedirectionValue" class="right_dropdown_input" *ngIf="buttonRedirectionValue === 'EXTERNAL'">
                <mat-select [(ngModel)]="internalPageRedirectionValue" (selectionChange)="buttonRedirections('INTERNAL')" class="right_dropdown" *ngIf="buttonRedirectionValue === 'INTERNAL'">
                    <mat-option value="">--Select--</mat-option>
                    <mat-option [value]="page.path" *ngFor="let page of allPages"
                    class="editor_button_dropdonw">{{page.pageName | titlecase}}</mat-option>
                </mat-select>
                <mat-select [(ngModel)]="samePageRedirectionValue" (selectionChange)="buttonRedirections('SAME_PAGE')" class="right_dropdown" *ngIf="buttonRedirectionValue === 'SAME_PAGE'">
                    <mat-option value="">--Select--</mat-option>
                    <mat-option [value]="i" *ngFor="let samePage of samePageComponents.components; let i = index"
                    class="editor_button_dropdonw">{{samePage.category.name | titlecase}}</mat-option>
                </mat-select>
            </div>
        </div>
    </div>
    <div class="button_section" *ngIf="!data.edit">
        <button (click)="updateList()">Update List</button>
    </div>
</section>