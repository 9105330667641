<section class="main_section">
    <section class="top_navbar">
        <app-home-nav-bar></app-home-nav-bar>
    </section>
    <section class="mid_age_section">
        <!-- <div class="left_section">
            <div class="left_section_title">
                Contact Us
            </div>
            <div class="left_section_sub_title">
                Tell us how we can help and we’ll get in touch shortly.
            </div>
            <div class="left_section_bottom">
                <div class="icon_name">
                    <div class="icon">
                        <img src="./../../../../assets/images/contact_us/whats_app.svg" alt="whatsapp">
                    </div>
                    <div class="name">
                        <a href="https://api.whatsapp.com/send?phone=+918801415833">9876543219</a>
                    </div>
                </div>
                <div class="icon_name">
                    <div class="icon">
                        <img src="./../../../../assets/images/contact_us/mobile_icon.svg" alt="mobile_icon">
                    </div>
                    <div class="name">
                        <a href="">+91-9876543219</a>
                    </div>
                </div>
                <div class="icon_name">
                    <div class="icon">
                        <img src="././../../../../assets/images/contact_us/mail.svg" alt="mail_icon">
                    </div>
                    <div class="name">
                        <a href="">hello@simpo.ai</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="right_section">

        </div> -->
        <app-contact-us-with-social-links></app-contact-us-with-social-links>
    </section>
    <section class="footer_section">
        <app-our-footer></app-our-footer>
    </section>
</section>