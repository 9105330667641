<section class="position-relative" *ngIf="screenWidth > 475">
  <div class="header-section">
      <div class="left-header">
          <span (click)="confirmationFilter()">
              <img src="./../../../../../../../../assets/images/blogs/left-arrow.svg" alt="left-arrow">
          </span>
          <span class="back-text" (click)="confirmationFilter()">Blogs</span>
      </div>
      <div class="right-header">
          <button class="outlined-blue-button" style="font-size: 14px; font-weight:500;"  (click)="saveToDraft()">
            <span *ngIf="screenLoading"><i class="fas fa-spinner fa-spin"></i></span>
            <span *ngIf="!screenLoading"></span>Save
          </button>
          <button class="flat-blue-button" style="font-size: 14px; font-weight:500;" (click)="publishBlog()">Publish</button>
      </div>
  </div>
  <div class="configuration-tab d-flex flex-row">
      <span class="bs-medium cursor-pointer" (click)="openBlogSettingsDialog($event)">
          <mat-icon style="color: #F79824;">settings</mat-icon>
          <!-- <img src="./../../../../../assets/images/blogs/settings.svg" /> -->
      </span>
      <span class="bs-medium cursor-pointer ml-3" [cdkMenuTriggerFor]="analytics">
          <mat-icon style="color: #F79824;"> bar_chart</mat-icon>
      </span>
      <span class="bs-medium cursor-pointer ml-3" (click)="deleteBlog()" *ngIf="blog.id">
          <mat-icon style="color: #F79824;">delete</mat-icon>
      </span>
  </div>
  <!-- editorjs-block -->
  <h3 style="margin-left:29%">Add Your Blog here..</h3>
  <div #editor id="editorjs" class="editorjs-block" (keyup)="wordCounter($event)" #text></div>

  <!-- <ng-template [ngIf]="screenLoading">
      <app-screen-loader></app-screen-loader>
  </ng-template> -->
  <span *ngIf="screenLoading"><i class="fas fa-spinner fa-spin"></i>&nbsp;Loading</span>
</section>

<ng-template #analytics>
  <div style="width: 300px;" class="bg-white shadow p-3 rounded" cdkMenu>
      <h3 class="border-dark" style="font-size: 16px;">Word Counter</h3>
      <hr>
      <div class="d-flex justify-content-between align-items-center mt-2" style="font-size: 14px;">
          <span class="text-secondary">Reading Time</span>
          <span class="text-dark fw-medium">{{  readTime  }}</span>
      </div>
      <div class="d-flex justify-content-between align-items-center mt-3" style="font-size: 14px;">
          <span class="text-secondary">Words</span>
          <span class="text-dark fw-medium">{{ wordCount }}</span>
      </div>
      <div class="d-flex justify-content-between align-items-center mt-3" style="font-size: 14px;">
          <span class="text-secondary">Characters</span>
          <span class="text-dark fw-medium">{{ characterCount }}</span>
      </div>
      <div class="d-flex justify-content-between align-items-center mt-3" style="font-size: 14px;">
          <span class="text-secondary">Sentences</span>
          <span class="text-dark fw-medium">{{ sentenceCount }}</span>
      </div>
      <!-- <div class="d-flex justify-content-between align-items-center mt-3" style="font-size: 14px;">
          <span class="text-secondary">Paragraphs</span>
          <span class="text-dark fw-medium">{{ paragraphCount }}</span>
      </div> -->
  </div>
</ng-template>

<section class="position-relative" *ngIf="screenWidth < 475">
<div class="header-section">
    <div class="left-header">
        <span (click)="confirmationFilter()">
            <img src="./../../../../../../../../assets/images/blogs/left-arrow.svg" alt="left-arrow">
        </span>
        <span class="back-text" (click)="confirmationFilter()">Blogs</span>
    </div>
</div>
<div class="configuration-tab">
  <span class="bs-medium cursor-pointer" (click)="openBlogSettingsDialog($event)">
      <mat-icon style="color: #F79824;">settings</mat-icon>
      <!-- <img src="./../../../../../assets/images/blogs/settings.svg" /> -->
  </span>
  <span class="bs-medium cursor-pointer ml-3">
    <button class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">
      <mat-icon style="color: #F79824;"> bar_chart</mat-icon>
    </button>
  </span>
  <span class="bs-medium cursor-pointer ml-3" (click)="deleteBlog()" *ngIf="blog.id">
      <mat-icon style="color: #F79824;">delete</mat-icon>
  </span>
</div>

<!-- editorjs-block -->
<!-- <h3 style="display: flex; justify-content: center; margin-top: 6%;">Add Your Blog here..</h3>
<div class="editorjs-block" (keyup)="wordCounter($event)" ></div> -->

<!-- <ng-template [ngIf]="screenLoading">
    <app-screen-loader></app-screen-loader>
</ng-template> -->
<!-- editorjs-block -->
<h3 style="margin-left:29%">Add Your Blog here..</h3>
<div #editor id="editorjs" class="editorjs-block" (keyup)="wordCounter($event)" #text></div>

<span *ngIf="screenLoading"><i class="fas fa-spinner fa-spin"></i>&nbsp;Loading</span>
<div class="right-header">
  <button class="outlined-blue-button" style="font-size: 14px; font-weight:500;"  (click)="saveToDraft()">
    <span *ngIf="screenLoading"><i class="fas fa-spinner fa-spin"></i></span>
    <span *ngIf="!screenLoading"></span>Save
  </button>
  <button class="flat-blue-button" style="font-size: 14px; font-weight:500;" (click)="publishBlog()">Publish</button>
</div>
<div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel" *ngIf="screenWidth < 475">
  <div class="offcanvas-body small">
    <h3 class="border-dark" style="font-size: 16px;">Word Counter</h3>
    <hr>
    <div class="d-flex justify-content-between align-items-center mt-2" style="font-size: 14px;">
      <span class="text-secondary">Reading Time</span>
      <span class="text-dark fw-medium">{{  readTime  }}</span>
    </div>
    <div class="d-flex justify-content-between align-items-center mt-3" style="font-size: 14px;">
        <span class="text-secondary">Words</span>
        <span class="text-dark fw-medium">{{ wordCount }}</span>
    </div>
    <div class="d-flex justify-content-between align-items-center mt-3" style="font-size: 14px;">
        <span class="text-secondary">Characters</span>
        <span class="text-dark fw-medium">{{ characterCount }}</span>
    </div>
    <div class="d-flex justify-content-between align-items-center mt-3" style="font-size: 14px;">
        <span class="text-secondary">Sentences</span>
        <span class="text-dark fw-medium">{{ sentenceCount }}</span>
    </div>
    <!-- <div class="d-flex justify-content-between align-items-center mt-3" style="font-size: 14px;">
        <span class="text-secondary">Paragraphs</span>
        <span class="text-dark fw-medium">{{ paragraphCount }}</span>
    </div> -->
  </div>
</div>

</section>
