import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthServiceService } from 'src/app/services/auth-service.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit{

  constructor(
    public dialogRef: MatDialogRef<ForgotPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private _authService : AuthServiceService,
    private _snackBar : MatSnackBar
  ){}

  mailId = '';
  emailRegex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
  loader : boolean = false;

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close();
  }

  submit(){
    this.loader = true;
    if(this.emailRegex.test(this.mailId.toLowerCase())){
      let data ={
        email:this.mailId
      }
      this._authService.forgotPassword(data).subscribe(
        (res:any) => {
          this._snackBar.open(
            `${res.data}`,
            'Ok',
            {
              duration:1500
            }
          );
          this.dialogRef.close();
          this.loader = false;
        },
        (err) => {
          console.log(err);
          this._snackBar.open(
            `User Not found`,
            'Ok',
            {
              duration:1500
            }
          );
          this.loader = false;
        }
      )
    }
    else{
      this._snackBar.open(
        `Please Enter Valid Mail Id`,
        'Close',
        {
          duration:1500
        }
      );
      this.loader = false;
    }
  }

}
