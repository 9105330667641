import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DbService } from 'src/app/services/db-service/db.service';
import { PaymentsService } from 'src/app/services/payment_apis/payments.service';

@Component({
  selector: 'app-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.scss']
})
export class CancelComponent implements OnInit {
  constructor(
    private _paymentService : PaymentsService,
    private _dbService : DbService,
    private _router : Router
  ){}
  
  ngOnInit(): void {
    let paymentId = this._dbService.getPaymentId()
    this.sendingStatus(paymentId)
  }

  sendingStatus(pId){
    let data = {
      paymentId : pId,
      status:'CANCELED'
    }
    this._paymentService.sendPaymentId(data).subscribe(
      (res:any) => {
        console.log(res.data);
      },
      (err) => {
        console.log(err)
      }
    )
  }

  done(){
    this._router.navigate(['/admin/account/subscriptions'])
  }
}
