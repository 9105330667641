import { Component, Input, OnInit } from '@angular/core';
import { ElementHelper } from '../../../../elements/elements_helper';

@Component({
  selector: 'app-service-section-cards',
  templateUrl: './service-section-cards.component.html',
  styleUrls: ['./service-section-cards.component.scss']
})
export class ServiceSectionCardsComponent implements OnInit{
  @Input() responseData : any;
  constructor(
    private _elementHelper : ElementHelper
  ){}
  webComponentName : any  = 'service_section_cards';
  ngOnInit(): void {
   this.itemData=this.responseData.styles;
  }
itemData: any = 
[
  {
    "image":{
      "attachment":"https://cdn-icons-png.flaticon.com/512/3780/3780538.png",
      "altName":"banner image",
      "lm_type":"attachment",
      "lm_title":"Banner Image",
      "attachment_type":"IMAGE"
    },
    "title":this._elementHelper.getTextItemTitleElement('Digital Marketing','',''),
    "description":this._elementHelper.getTextDescElement('Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.','',''),
  },
  {
    "image":{
      "attachment":"https://toppng.com/uploads/preview/web-development-icon-png-clipart-website-development-web-dev-icon-11562967383vhxlfmnerz.png",
      "altName":"banner image",
      "lm_type":"attachment",
      "lm_title":"Banner Image",
      "attachment_type":"IMAGE"
    },
    "title":this._elementHelper.getTextItemTitleElement('Website Development','',''),
    "description":this._elementHelper.getTextDescElement('Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.','',''),
  },
  {
    "image":{
      "attachment":"https://cdn3.vectorstock.com/i/1000x1000/16/02/search-engine-optimization-flat-icons-vector-11341602.jpg",
      "altName":"banner image",
      "lm_type":"attachment",
      "lm_title":"Banner Image",
      "attachment_type":"IMAGE"
    },
    "title":this._elementHelper.getTextItemTitleElement('Search Engine Optimization','',''),
    "description":this._elementHelper.getTextDescElement('Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.','',''),
  },
  {
    "image":{
      "attachment":"https://static.vecteezy.com/system/resources/previews/006/820/146/original/cloud-hosting-line-icon-free-vector.jpg",
      "altName":"banner image",
      "lm_type":"attachment",
      "lm_title":"Banner Image",
      "attachment_type":"IMAGE"
    },
    "title":this._elementHelper.getTextItemTitleElement('Cloud Hosting','',''),
    "description":this._elementHelper.getTextDescElement('Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum.','',''),
  }
]
}
