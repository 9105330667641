<section class="bg-image-upload">

  <p class="heading">Image</p>

  <div class="upload-image" *ngIf="!data.image" (click)="updateBGImage($event)">
    <img src="https://prod-simpo.s3.ap-south-1.amazonaws.com/library-media/354514c1711643199684photo.png" alt=""
      style="height: 40px;">
    <p class="upload-file mb-0">Upload a file</p>
    <p class="mb-0">or select an existing one</p>
    <span style="color: #989DA7;">up to 32MB</span>
  </div>
  <div class="sec" *ngIf="data.image">
    <img [src]="data.image" [alt]="data.altName">
    <div class="replace-delete">
      <div class="repalce-image cursor-pointer" (click)="updateBGImage($event)">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" width="24"
          height="24" class="icon w-4 h-4" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
            d="m4 16 4.58579-4.5858c.78104-.781 2.04741-.781 2.82841 0L16 16m-2-2 1.5858-1.5858c.781-.781 2.0474-.781 2.8284 0L20 14m-6-6h.01M6 20h12c1.1046 0 2-.8954 2-2V6c0-1.10457-.8954-2-2-2H6c-1.10457 0-2 .89543-2 2v12c0 1.1046.89543 2 2 2Z">
          </path>
        </svg>
        <span>Replace</span>
      </div>
      <input type="file" name="myfile" accept="image/x-png,image/gif,image/jpeg,image/jpg" hidden
        (change)="updateBGImage($event)" class="browse-btn display_image" id="browse_bg_img"/>
      <div class="delete-image cursor-pointer" (click)="deleteBGImage()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#ffffff" viewBox="0 0 24 24" width="24"
          height="24" class="icon h-4 w-4" aria-hidden="true">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
            d="m19 7-.8673 12.1425C18.0579 20.1891 17.187 21 16.1378 21H7.86224c-1.04928 0-1.92016-.8109-1.99492-1.8575L5 7m5 4v6m4-6v6m1-10V4c0-.55228-.4477-1-1-1h-4c-.55228 0-1 .44772-1 1v3M4 7h16">
          </path>
        </svg>
      </div>
    </div>
  </div>
  <div>

  </div>
  <!-- position Releated Changes -->
  <div class="position-related">
    <p class="heading">Position</p>
    <div class="position-actions">
      <div class="horizontal-action">
        <label class="input-label" style="width: 30%;">Horizontal</label>
        <div style="width: 70%;display: flex;justify-content: center;align-items: center;">
          <input [min]="min" [max]="max" step="5" type="range" [(ngModel)]="data.position.x"
            (input)="verticleScroll()">
        </div>
      </div>
      <div class="horizontal-action" style="margin-top: 5px;">
        <label class="input-label" style="width: 30%;">Vertical</label>
        <div style="width: 70%;display: flex;justify-content: center;align-items: center;">
          <input [min]="min" [max]="max" step="5" type="range" [(ngModel)]="data.position.y"
            (input)="verticleScroll()">
        </div>
      </div>
      <div class="horizontal-action" style="margin-top: 5px;">
        <label class="input-label" style="width: 35%;">Color overlay</label>
        <div style="width: 65%;display: flex;justify-content: center;align-items: center;">
          <input [min]="min" [max]="max" step="5" type="range" [(ngModel)]="data.opacity"
            (input)="opacityIncrease()">
        </div>
      </div>
    </div>
  </div>
</section>
