<section class="main-section">
    <div class="main-heading">
        Recent Articles
    </div>
    <div class="main-blog-section">
        <div class="blog-section">
            <div class="blog-image">
                <img src="./../../../../assets/images/blogs/blog-radius.svg" alt="blog_image" />
            </div>
            <div class="blog-text">
                ABM and Demand Generation: A match made in heaven?
            </div>
            <div class="blog-link">
                Read Article
            </div>
        </div>
        <div class="blog-section">
            <div class="blog-image">
                <img src="./../../../../assets/images/blogs/blog-radius.svg" alt="blog_image" />
            </div>
            <div class="blog-text">
                ABM and Demand Generation: A match made in heaven?
            </div>
            <div class="blog-link">
                Read Article
            </div>
        </div>
        <div class="blog-section">
            <div class="blog-image">
                <img src="./../../../../assets/images/blogs/blog-radius.svg" alt="blog_image" />
            </div>
            <div class="blog-text">
                ABM and Demand Generation: A match made in heaven?
            </div>
            <div class="blog-link">
                Read Article
            </div>
        </div>
    </div>
</section>
