<section class="main-section">
    <!-- header section  -->
    <div class="header-section">

        <div class="header-left-side" *ngIf="!edit">
            Add Author
        </div>
        <div class="header-left-side" *ngIf="edit">
            Edit Author
        </div>
        <div class="header-right-side">
            <img src="./../../../../../../assets/images/close.svg" alt="close_svg" (click)="closeBtn()" />
        </div>
    </div>
    <!-- header sub text  -->
    <div class="header-sub-text" *ngIf="!edit">Add blogs author</div>
    <div class="header-sub-text" *ngIf="edit">Edit blogs author</div>
    <!-- gellery or image section  -->
    <!-- <div class="gellery-section">
        <div class="left-side-gellery">
            <img src="../../../../../../assets/images/master/add-image.svg" alt="add_image" />
        </div>
        <div class="right-side-gellery">
            <div class="image-border-box">
                Click to upload <span class="image-text">or drag and drop</span>
            </div>
        </div>
    </div> -->
    <div class="profile-img">
        <div class="image-box" *ngIf="!jeweller.jewellerLogo">
            <img class="dummy-image" src="../../../../../../../../../assets/images/master/add-image.svg" alt="">
            <div class="image-box-text" *ngIf="screenWidth > 475">
                <span class="fc_browse_btn" id="display_browse_btn" (click)="browseFcImage()">Click to upload</span>
                 <!-- or drag and drop. -->
            </div>
            <div class="image-box-text" *ngIf="screenWidth < 475">
              <span class="fc_browse_btn" id="display_browse_btn" (click)="browseFcImage()">Click to upload</span>
            </div>
            <input id="vendorImage" type="file" name="myfile" accept="image/x-png,image/jpeg,image/jpg" (change)="updatePostImage($event)"
                    class="pc-btn" id="browse_fc_image"/>
        </div>
        <div *ngIf="jeweller.jewellerLogo" class="addedImg">
            <div class="uploadedImg">
                <img [src]="ds.bypassSecurityTrustUrl(jeweller.jewellerLogo)" class="uploadedImg" alt="" >
            </div>
            <div class="image-buttons">
                <div class="button" (click)="browseFcImage()">
                    <img src="../../../../../../../../../assets/images/blogs/change_image.svg" alt="">
                    Change Image
                </div>
                <input id="vendorImage" type="file" name="myfile" accept="image/x-png,image/jpeg,image/jpg" (change)="updatePostImage($event)"
                    class="pc-btn" id="browse_fc_image"/>
                <div class="button" (click)="delete()">
                    <div class="delete">
                        <img src="./../../../../../../../../../assets/images/blogs/delete_image.svg" alt="">
                        <div>Delete</div>
                    </div>

                </div>
            </div>

        </div>
    </div>
    <!-- fields section  -->
    <div class="business-tag-section">
        <div class="field-heading">
            Author Name<sup>*</sup>
        </div>
        <mat-form-field appearance="outline">
            <input matInput placeholder="Enter Author Name" [(ngModel)]="enterAuthor">
        </mat-form-field>
    </div>

    <!-- <div class="business-slug-section">
        <div class="field-heading">
            Slug<sup>*</sup>
        </div>
        <mat-form-field appearance="outline">
            <input matInput placeholder="Enter Slug Name" [(ngModel)]="enterSlug">
          </mat-form-field>
    </div> -->
    <div class="business-description-section">
        <div class="field-heading">
            Email<sup>*</sup>
        </div>
        <mat-form-field appearance="outline">
            <input type="email" matInput placeholder="Enter Email"
            #emailCheck="ngModel"
             [(ngModel)]="enterEmail">

             <mat-error *ngIf="emailCheck.invalid && (emailCheck.dirty || emailCheck.touched)">
                <!-- <div *ngIf="emailCheck.errors?.required"> -->
                  <!-- Email is required. -->
                <!-- </div> -->
                <!-- <div *ngIf="emailCheck.errors?.email"> -->
                  Please enter a valid email address.
                <!-- </div> -->
              </mat-error>
             <!-- <mat-error *ngIf=" -->
                    <!-- !emailCheck.valid"> && -->
                    <!-- (emailCheck.dirty )">|| emailCheck.touched -->
                  <!-- Please Enter Your Email -->
                <!-- </mat-error> -->
        </mat-form-field>
    </div>

    <!-- button section  -->
    <div class="button-section">
        <div class="left-side">
            <div class="cancel-button" (click)="closeBtn()">Cancel</div>
        </div>
        <div class="right-side" *ngIf="!edit;else editTags">
            <button class="apply-button" (click)="addAuthor()" [disabled]="!enterAuthor || !enterEmail " >
                Add Author
            </button>
        </div>
        <ng-template #editTags>
            <div class="right-side">
                <button class="apply-button" (click)="editAuthor()" [disabled]="!enterAuthor || !enterEmail">
                    Edit Author
                </button>
            </div>
        </ng-template>

    </div>
</section>
