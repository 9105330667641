import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RedirectionLinkType } from 'simpo-component-library';
import { WebisiteService } from 'src/app/master-config-components/micro-apps/website/webisite.service';
import { TemplateService } from 'src/app/services/template.service';

@Component({
  selector: 'app-list-item-component',
  templateUrl: './list-item-component.component.html',
  styleUrls: ['./list-item-component.component.scss']
})
export class ListItemComponentComponent {
  constructor(
    public dialogRef: MatDialogRef<ListItemComponentComponent>,
    @Inject(MAT_DIALOG_DATA) public listItemdata: any,
    public websiteService: WebisiteService,
    private dialogSercvice: TemplateService
  ) { }

  data: any;
  sectionId: string = ''
  templatePage: any[] = [];
  currentPageIndex:number = 0;
  public loading: string | null = null;
  public regerateLoading: boolean = false;

  ngOnInit() {
    if (this.listItemdata) {
      this.data = this.listItemdata.data;
      this.sectionId = this.listItemdata.sectionId
      if (this.listItemdata.newItem) {
        this.ClearingAllData()
      }
      this.templatePage = this.listItemdata.templatePage
      this.currentPageIndex = this.listItemdata.currentPageIndex
    }
  }

  getUnsplaceImage() {
    this.regerateLoading = true;
    this.dialogSercvice.searchPhotosInUnsplash(JSON.parse(String(localStorage.getItem('bDetails')))?.subIndustryName ?? '', 0, 100).subscribe((response: any)=> {
      this.regerateLoading = false;
      const index: number = Math.floor(Math.random()*(response.results.length));
      this.data.image.url = response.results[index].urls.full;
    }, (error)=> {
      this.regerateLoading = false;
    })
  }

  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['code-block'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['link'],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['link', 'image', 'video']
      ],

    },
    keyboard: {
      bindings: {
        enter: {
          key: 13,
          handler: (range: any, context: any) => {
            return true;
          }
        }
      }
    }
  };

  closeDialog() {
    this.dialogRef.close()
  }

  ClearingAllData() {
    if (this.data.hasOwnProperty('image')) {
      this.data.image.url = ''
      if (this.data.image.altText) {
        this.data.image.altText = ''
      }
      if (this.data.image.position) {
        this.data.image.position.x = 0;
        this.data.image.position.y = 0;
      }

    }
    if (this.data.inputText) {
      for (let input of this.data.inputText) {
        input.value = ''
      }
    }
    if (this.data.hasOwnProperty('button')) {
      this.data.button = {
        linkType: RedirectionLinkType.External,
        label: '',
        redirectionUrl: '',
        newTab: false
      }
    }
    if(!this.listItemdata.buttonData && this.data.hasOwnProperty('content')) {
      this.data.content = null;
    }
  }

  addNewItem() {
    this.dialogRef.close({ data: this.data })
  }

  regerateContent(inputText) {
    if (this.loading == inputText.label)
      return;
    this.loading = inputText.label;
    const bDetails = JSON.parse(localStorage.getItem("bDetails") ?? "");
    if (!bDetails)
      return;
    const payload = {
      "dataId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      "subIndustryId": bDetails.subIndustryId,
      "businessId": bDetails.id,
      // "businessName": "string", industryId
      "customPrompt": "",
      "label": inputText.label,
      "value": inputText.value,
      "suggestedDataType": "COMPONENT",
      "tone": "PROFESSIONAL",
      // "wordLimit": 0
    }
    this.websiteService.regenerateIndividualV2(payload).subscribe((response: any)=> {
      inputText.value = response.data;
    }, (error)=> {

    })
  }

  editAdditionalData(details: any){
    let dialog = this.dialogSercvice.openDialog(ListItemComponentComponent, 'auto',
    '460px', { data: details, newItem: false, buttonData: false});
  }

  deleteAdditionalCharge(index: number){
    this.data?.additionalData.splice(index,1);
  }

  addAdditionalData() {
    let dialog = this.dialogSercvice.openDialog(ListItemComponentComponent, 'auto',
    '460px', { data: JSON.parse(JSON.stringify(this.data?.additionalData[0])), newItem: true, buttonData: false });

    dialog.afterClosed().subscribe((res: any) => {
      if(res.data) {
        this.data.additionalData.push(res.data)
      }
    })
  }

}
