<div class="mainSection">
    <div class="headingPart">
        <div class="heading" [ngStyle]="heading.style?heading.style:{}">{{heading.value}}</div>
        <div class="description" [ngStyle]="description.style?description.style:{}">{{description.value}}</div>
    </div>
    <div class="blogsContainer">
        <div class="wrapping">
            <div class="blog">
                <div class="imageContainer">
                    <img class="imageProperties" [ngStyle]="image1.style?image1.style:{}" src="{{image1.imgUrl}}" alt="">
                </div>
                <div class="content">
                    <div class="question" [ngStyle]="question.style?question.style:{}">{{question.value}}</div>
                    <div class="answer" [ngStyle]="answer.style?answer.style:{}">{{answer.value}}</div>
                    <div class="row">
                        <div class="row-growth">
                        <div class="growth" [ngStyle]="growthText.style?growthText.style:{}">{{growthText.value}}</div>
                        <div class="date" [ngStyle]="date.style?date.style:{}">{{date.value}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="blog">
                <div class="imageContainer">
                    <img class="imageProperties" [ngStyle]="image2.style?image2.style:{}" src="{{image2.imgUrl}}" alt="">
                </div>
                <div class="content">
                    <div class="question" [ngStyle]="question.style?question.style:{}">{{question.value}}</div>
                    <div class="answer" [ngStyle]="answer.style?answer.style:{}">{{answer.value}}</div>
                    <div class="row">
                        <div class="row-growth">
                        <div class="growth" [ngStyle]="growthText.style?growthText.style:{}">{{growthText.value}}</div>
                        <div class="date" [ngStyle]="date.style?date.style:{}">{{date.value}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="blog">
                <div class="imageContainer">
                    <img class="imageProperties" [ngStyle]="image3.style?image3.style:{}" src="{{image3.imgUrl}}" alt="">
                </div>
                <div class="content">
                    <div class="question" [ngStyle]="question.style?question.style:{}">{{question.value}}</div>
                    <div class="answer" [ngStyle]="answer.style?answer.style:{}">{{answer.value}}</div>
                    <div class="row">
                        <div class="row-growth">
                        <div class="growth" [ngStyle]="growthText.style?growthText.style:{}">{{growthText.value}}</div>
                        <div class="date" [ngStyle]="date.style?date.style:{}">{{date.value}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="blog">
                <div class="imageContainer">
                    <img class="imageProperties" [ngStyle]="image4.style?image4.style:{}" src="{{image4.imgUrl}}" alt="">
                </div>
                <div class="content">
                    <div class="question" [ngStyle]="question.style?question.style:{}">{{question.value}}</div>
                    <div class="answer" [ngStyle]="answer.style?answer.style:{}">{{answer.value}}</div>
                    <div class="row">
                        <div class="row-growth">
                        <div class="growth" [ngStyle]="growthText.style?growthText.style:{}">{{growthText.value}}</div>
                        <div class="date" [ngStyle]="date.style?date.style:{}">{{date.value}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="blog">
                <div class="imageContainer">
                    <img class="imageProperties" [ngStyle]="image5.style?image5.style:{}" src="{{image5.imgUrl}}" alt="">
                </div>
                <div class="content">
                    <div class="question" [ngStyle]="question.style?question.style:{}">{{question.value}}</div>
                    <div class="answer" [ngStyle]="answer.style?answer.style:{}">{{answer.value}}</div>
                    <div class="row">
                        <div class="row-growth">
                        <div class="growth" [ngStyle]="growthText.style?growthText.style:{}">{{growthText.value}}</div>
                        <div class="date" [ngStyle]="date.style?date.style:{}">{{date.value}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="blog">
                <div class="imageContainer">
                    <img class="imageProperties" [ngStyle]="image6.style?image6.style:{}" src="{{image6.imgUrl}}" alt="">
                </div>
                <div class="content">
                    <div class="question" [ngStyle]="question.style?question.style:{}">{{question.value}}</div>
                    <div class="answer" [ngStyle]="answer.style?answer.style:{}">{{answer.value}}</div>
                    <div class="row">
                        <div class="row-growth">
                        <div class="growth" [ngStyle]="growthText.style?growthText.style:{}">{{growthText.value}}</div>
                        <div class="date" [ngStyle]="date.style?date.style:{}">{{date.value}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>