<section class="main-section" #InvoiceDetails>
    <div class="header-section" *ngIf="!isprivew">
        <div class="header-invoice-secton"></div>
        <div class="header-cross-section">
            <img src="../../../../../assets/images/close.svg" (click)="closeButton()">
        </div>
    </div>

    <div class="header-section-customer" *ngIf="isCustomer">
        <div class="header-invoice-section-customer">
            ORIGINAL FOR RECIPIENT
        </div>

    </div>

    <div class="header-section-customer" *ngIf="isTransport">
        <div class="header-invoice-section-customer">
            DUPLICATE FOR TRANSPORTER
        </div>

    </div>

    <div class="header-section-customer" *ngIf="isSupplier">
        <div class="header-invoice-section-customer">
            TRIPLICATE FOR SUPPLIER
        </div>

    </div>

    <div class="header-section-customer" *ngIf="isDiliveryChalan">
        <div class="header-invoice-section-customer">
            DELIVERY CHALLAN
        </div>

    </div>
    <!-- <div class="Address-section">
        <div class="adress">
            <div class="customer-section">
                <div class="customer-name">CustomerName:</div>
                <div class="name">{{data.data.companyDetails.name}}</div>
            </div>
            <div class="customer-section">
                <div class="Email">Email:</div>
                <div class="name">{{data.data.companyDetails.email}}</div>
            </div>
            <div class="customer-section">
                <div class="mobile">Mobile:</div>
                <div class="name">{{data.data.companyDetails.mobile}}</div>
            </div>
        </div>
        <div class="customer-section-1">
            <div>
                Address:
            </div>
            <div class="name">{{data.data.shippingAddress?.addressLine1}},{{data.data.shippingAddress?.addressLine2}},
                {{data.data.shippingAddress?.cityName}},{{data.data.shippingAddress?.stateName}},{{data.data.shippingAddress?.zipCode}}
            </div>
        </div>
    </div> -->

    <div class="second-section">
        <div class="second-container">
            <div class="sender-details-text">
            {{businessName}}  </div>
              <div class="sender-details-subtext"> {{businessDetails?.address?.address}}  {{businessDetails?.address.city}}
                {{businessDetails?.address.state}} {{businessDetails?.address.postalCode}}
              </div>
                <div class="person_details">
                    <div class="gst-section">
                        <div class="gst-text">GST:</div>
                        <div class="gst">{{businessDetails?.gstNumber ? (businessDetails?.gstNumber) : 'N/A'}}</div>
                    </div>
                    <div class="phone-section">
                        <div class="phone-text">Phone:</div>
                        <div class="phone">{{businessDetails?.contact.mobile}}</div>
                    </div>
                    <div class="phone-section">
                        <div class="phone-text">Email:</div>
                        <div class="phone">{{businessDetails?.contact.email}}</div>
                    </div>
                </div>
           
        </div>
    </div>

    <div class="bill-section">
        <div class="bill-table-section">
            <div class="bill-number">Invoice No:</div>
            <div class="bill">{{data.data.prefix}} {{data.data.invoiceDisplayCode}}</div>
        </div>
        <div class="bill-table-section">
            <div class="invoice-date">Invoice date:</div>
            <div class="bill">{{data.data.createdTimeStamp | date:'mediumDate'}}</div>
        </div>
        <div class="bill-table-section">
            <div class="time-section">Due Date:</div>
            <div class="bill">{{data?.data?.dueDate |  date:'mediumDate'}}</div>
        </div>
    </div>

    <div class="address-section">
        <div class="address-container">
            <div class="customer-section">
                <div class="customer-heading">
                    Customer Details:
                </div>
                <div class="customer-text">
                    {{data.data.customerDetails.name | titlecase}}
                </div>
                <div class="customer-text">
                    {{data.data.customerDetails.mobile}}
                </div>
                <div class="customer-text">
                    {{data.data.customerDetails.email}}
                </div>
            </div>

            <div class="customer-section">
                <div class="customer-heading">
                    Billing Address: 
                </div>
                <div class="customer-text">
                    {{data.data.billingAddress.addressLine1 | titlecase }},{{data.data.billingAddress.addressLine2 | titlecase}}
                </div>
                <div class="customer-text">
                    {{data.data.billingAddress.cityName | titlecase}}
                </div>
                <div class="customer-text">
                    {{data.data.billingAddress.stateName | titlecase}}, {{data.data.billingAddress.zipCode}}
                </div>
            </div>

            <div class="customer-section">
                <div class="customer-heading">
                    Shippping Address: 
                </div>
                <div class="customer-text">
                    {{data.data.shippingAddress?.addressLine1}},  {{data.data.shippingAddress?.addressLine2}}
                </div>
                <div class="customer-text">
                    {{data.data.shippingAddress?.cityName | titlecase}}
                </div>
                <div class="customer-text">
                    {{data.data.shippingAddress?.stateName | titlecase}},{{data.data?.shippingAddress?.zipCode}}
                </div>
            </div>
        </div>

    </div>


    <ng-container *ngIf="participantsDataSource">
        <div class="table-section">
            <table mat-table [dataSource]="participantsDataSource" class="mat-elevation-z8">
                <div class="scroll-section">
                    <ng-container matColumnDef="Product">
                        <th mat-header-cell *matHeaderCellDef class="headingStyles">
                            Product
                        </th>
                        <td mat-cell *matCellDef="let element" class="personName">
                            {{element?.itemName ? (element?.itemName | titlecase) : "N/A"}} </td>
                    </ng-container>
                    <ng-container matColumnDef="Rate" >
                        <th mat-header-cell *matHeaderCellDef class="headingStyles">Rate</th>
                        <td mat-cell *matCellDef="let element" class="created td-color"> {{element?.unitPrice ? (element?.unitPrice) : "N/A"}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Quantity">
                        <th mat-header-cell *matHeaderCellDef class="headingStyles">Quantity</th>
                        <td mat-cell *matCellDef="let element" class="created td-color"> {{element?.quantity? (element?.quantity) : "N/A"}} {{element.unit.key}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Discount">
                        <th mat-header-cell *matHeaderCellDef class="headingStyles">Discount</th>
                        <td mat-cell *matCellDef="let element" class="created td-color"> {{element?.discountPercent? (element?.discountPercent) : "N/A"}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="TaxRate">
                        <th mat-header-cell *matHeaderCellDef class="headingStyles">TaxRate</th>
                        <td mat-cell *matCellDef="let element" class="created td-color">
                            <!-- <div class="paid"> -->
                            {{element?.totalTax? (element?.totalTax) : "N/A"}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="TotalPrice">
                        <th mat-header-cell *matHeaderCellDef class="headingStyles">TotalPrice</th>
                        <td mat-cell *matCellDef="let element" class="created td-color">
                            <!-- <div class="cash"> -->
                            {{element?.totalAmount? (element?.totalAmount) : "N/A"}}
                            <!-- | titlecase):"N/A" }}  -->
                            <!-- </div> -->
                        </td>
                    </ng-container>
                </div>

                <tr mat-header-row *matHeaderRowDef="participantsDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: participantsDisplayedColumns;">
                </tr>
            </table>
        </div>
    </ng-container>



    <div class="mid_sec"  *ngIf="!isDiliveryChalan">
        <div class="mid_right">
            <div class="total-section-item">
                <div class="total-section">
                    Total Amount
                </div>
                <div class="total-amount">
                    {{data?.data?.totalAmount}}
                </div>

            </div>
            <div class="discount-on-item">
                <div class="discount-text">
                    Discount on item wise
                </div>
                <div class="discount-amount">
                    {{data?.data?.discountAmount}}
                </div>

            </div>
            <div class="extra-charge" *ngFor="let charges of data.data.additionalCharges">
                <div class="extra-charge-section">
                   {{charges?.name ? (charges?.name) : 'N/A'}}
                </div>
                <div class="extra-amount">
                    {{charges?.withTaxValue ? (charges?.withTaxValue) : 'N/A'}}
                </div>
            </div>
            <div class="tax-section">
                <div class="tax">
                    tax
                </div>
                <div class="tax-amount">{{data.data.taxAmount}}
                </div>
            </div>
            <div class="total-amount-words">
                <div class="total-words">
                    Total Amount (in Words):
                </div>
                <div class="words">
                    {{data?.data?.payableAmountWords}}
                </div>

            </div>
            <div class="amount-pay-sec">
                <div class="amount-payable">
                    Amount Payable 
                </div>
                <div class="amount-amount">
                  {{data.data.totalPayableAmount}}
                </div>
            </div>
            <div class="amount-paid-section">
                <div class="amount-paid">
                    Amount Paid:
                </div>
                <div class="paid-amount">
                    {{data?.data?.totalPaidAmount}}
                </div>

            </div>
            <div class="paid-via-section" *ngFor="let  item of timeline">
                <div class="paid-via">
                    {{item?.amount}}
                    <span class="paid-via-text">
                        paid via cash on 
                    </span>
                    <div class="paid-via-date">
                        {{item?.createdDateTime | date:'mediumDate'}}
                    </div>
                </div>
            </div>
            <div class="total-amount-due">
                <div class="total-due">
                    Total Balance Due :
                </div>
                <div class="total-due-price">
                    {{data.data.totalPayableAmount}}
                </div>

            </div>
            <div class="bussiness-name-section">
                <div class="bussiness-name-container">
                    <div class="bussiness-name">
                        {{data?.data?.businessName}}
                    </div>

                    <div class="signature-section" *ngIf="data.data.signature.imgUrl != null">
                        <img [src]="data.data.signature.imgUrl" alt="">
                    </div>

                </div>


            </div>
       
        </div>
    </div>

    <div class="bottom-section">
        <div class="bottom-container">
            <div class="bottom-header">
              <div class="header">
                <a href="https://simpo.ai">
                    Simpo | Simple Invoicing, Billing and Payments | Visit simpo.ai 
                </a>
                </div> 
              <!-- <div class="header-sec">Page 1/1 This is a digitally signed document </div> -->
            </div>
            <div class="bottom-img">
                <img src="../../../../../../assets/images/common/simpo_logo.svg" alt="">
            </div>

        </div>

    </div>

   

</section>
