import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-number-text-card',
  templateUrl: './number-text-card.component.html',
  styleUrls: ['./number-text-card.component.scss']
})
export class NumberTextCardComponent implements OnInit{

  @Input() dynamicData : any;
  @Input() indexValues : any;

  constructor(){}

  ngOnInit(): void {
  }

}
