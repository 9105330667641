<ng-container *ngIf="data">
  <section class="main-section mt-10">
    <ng-container *ngFor="let item of tabs">
      <div [ngClass]="item.status ? 'active-content-tab' : 'content-tab'" (click)="tabShift(item.name)">
        
        <span *ngIf="item.name === 'CONTENT'">
            <!-- <mat-icon mat-ra ised-button #tooltip="matTooltip" class="i-icon"
                matTooltip="{{'Change or update the content in your webpage here'}}"
                matTooltipPosition="right" matTooltipClass="infotooltip" (click)="tooltip.toggle()"
                style="color: rgba(5, 86, 148); font-size: 18px !important; height: 24px">info</mat-icon>  -->
          
          <span *ngIf="!item.status">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 20 20" width="18"
              height="18" class="icon w-5 h-5" aria-hidden="true">
              <rect width="7" height="7" stroke-width="1.5" rx="1.16667" transform="matrix(-1 0 0 1 9 3)">
              </rect>
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                d="m12.5 10 .7857-2M18 10l-.7857-2m0 0L15.25 3l-1.9643 5m3.9286 0h-3.9286"></path>
              <path stroke-linecap="round" stroke-width="1.5" d="M2 13.5h16M2 17h14"></path>
            </svg>
          </span>
          <span *ngIf="item.status">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#0099CC" viewBox="0 0 20 20" width="18"
              height="18" class="icon w-5 h-5" aria-hidden="true">
              <rect width="7" height="7" stroke-width="1.5" rx="1.16667" transform="matrix(-1 0 0 1 9 3)">
              </rect>
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
                d="m12.5 10 .7857-2M18 10l-.7857-2m0 0L15.25 3l-1.9643 5m3.9286 0h-3.9286"></path>
              <path stroke-linecap="round" stroke-width="1.5" d="M2 13.5h16M2 17h14"></path>
            </svg>
          </span>
        </span>
        <span *ngIf="item.name === 'STYLES'">
          <!-- <mat-icon mat-ra ised-button #tooltip="matTooltip" class="i-icon"
                matTooltip="{{'Style your webpage using the features here '}}"
                matTooltipPosition="right" matTooltipClass="infotooltip" (click)="tooltip.toggle()"
                style="color: rgba(5, 86, 148); font-size: 18px !important; height: 24px">info</mat-icon>
           -->
          <span *ngIf="!item.status">
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16" width="18" height="18"
              class="icon w-5 h-5" aria-hidden="true">
              <path
                d="M12.4632 14.7277c.3088.3149.6854.4723 1.13.4723.4446 0 .8236-.1574 1.1369-.4723.3133-.3148.47-.7006.47-1.1574 0-.3568-.07-.6939-.21-1.0111-.14-.3173-.31-.6117-.51-.8833l-.52-.6926c-.0933-.1222-.2133-.1833-.36-.1833s-.2667.0611-.36.1833l-.52.6926c-.2133.2716-.3867.566-.52.8833-.1333.3172-.2.6543-.2 1.0111 0 .4568.1544.8426.4631 1.1574Z">
              </path>
              <path fill-rule="evenodd"
                d="M11.0506 1.01682c.1198-.359279-.0744-.747612-.4336-.867371-.3593-.1197581-.74766.074409-.86741.433684L9.058 2.65788l-.88815-.88816c-.53558-.53557-1.40392-.53557-1.9395 0l-4.4605 4.46051c-.53558.53558-.53558 1.40391 0 1.93949l4.4605 4.46048c.15077.1508.3279.2591.51656.325.31398.1097.65988.1017.96926-.0237.1651-.067.31981-.1674.45371-.3013l4.46052-4.46048c.5355-.53557.5355-1.40391 0-1.93949l-2.4882-2.48814.9084-2.72527ZM8.57313 4.1125l-.42354 1.27063c-.11976.35928.0744.74761.43368.86737.35927.11976.74761-.07441.86737-.43368l.2067-.62011 2.00326 2.00327H2.73959L7.2001 2.73947 8.57313 4.1125Z"
                clip-rule="evenodd"></path>
            </svg>
          </span>
          <span *ngIf="item.status">
            <svg xmlns="http://www.w3.org/2000/svg" fill="#0099CC" viewBox="0 0 16 16" width="18" height="18"
              class="icon w-5 h-5" aria-hidden="true">
              <path
                d="M12.4632 14.7277c.3088.3149.6854.4723 1.13.4723.4446 0 .8236-.1574 1.1369-.4723.3133-.3148.47-.7006.47-1.1574 0-.3568-.07-.6939-.21-1.0111-.14-.3173-.31-.6117-.51-.8833l-.52-.6926c-.0933-.1222-.2133-.1833-.36-.1833s-.2667.0611-.36.1833l-.52.6926c-.2133.2716-.3867.566-.52.8833-.1333.3172-.2.6543-.2 1.0111 0 .4568.1544.8426.4631 1.1574Z">
              </path>
              <path fill-rule="evenodd"
                d="M11.0506 1.01682c.1198-.359279-.0744-.747612-.4336-.867371-.3593-.1197581-.74766.074409-.86741.433684L9.058 2.65788l-.88815-.88816c-.53558-.53557-1.40392-.53557-1.9395 0l-4.4605 4.46051c-.53558.53558-.53558 1.40391 0 1.93949l4.4605 4.46048c.15077.1508.3279.2591.51656.325.31398.1097.65988.1017.96926-.0237.1651-.067.31981-.1674.45371-.3013l4.46052-4.46048c.5355-.53557.5355-1.40391 0-1.93949l-2.4882-2.48814.9084-2.72527ZM8.57313 4.1125l-.42354 1.27063c-.11976.35928.0744.74761.43368.86737.35927.11976.74761-.07441.86737-.43368l.2067-.62011 2.00326 2.00327H2.73959L7.2001 2.73947 8.57313 4.1125Z"
                clip-rule="evenodd"></path>
            </svg>
          </span>
        </span>
        <span style="margin-left: 5px;font-size: 17px;display: flex;align-items: center; gap: 10px;" [style.color]="item.status ? '#0099CC' : ''">{{item.name |
          titlecase}}
          <mat-icon mat-ra ised-button #tooltip="matTooltip" class="i-icon"
          matTooltip="{{'Change or update the content in your webpage here'}}"
          matTooltipPosition="right" matTooltipClass="infotooltip" (click)="tooltip.toggle()"
          style="color:grey; font-size: 15px !important; height: 24px; cursor: pointer; display: flex; align-items: center; " *ngIf="item.name === 'CONTENT'">info</mat-icon> 
          
          <mat-icon mat-ra ised-button #tooltip="matTooltip" class="i-icon"
                matTooltip="{{'Style your webpage using the features here '}}"
                matTooltipPosition="right" matTooltipClass="infotooltip" (click)="tooltip.toggle()"
                style="color: grey; font-size: 15px !important; height: 24px; cursor: pointer; display: flex; align-items: center;"  *ngIf="item.name === 'STYLES'">info</mat-icon>
        </span>
      </div>
    </ng-container>
  </section>

  <section *ngIf="selectedTab === 'CONTENT'" class="actions fade-in-animation">
    <div *ngIf="data.styles.background != undefined">
      <app-content-editor [templatePage]="templatePage" [content]="contentData" [action]="data.action" [sectionType]="data.sectionType" [sectionId]="data.id"
     [currentPageIndex]="currentPageIndex"  [color]="data.styles.background.color"></app-content-editor>
    </div>
    <div *ngIf="data.styles.background === undefined">
      <app-content-editor [templatePage]="templatePage" [content]="contentData" [action]="data.action" [sectionType]="data.sectionType" [sectionId]="data.id"
     [currentPageIndex]="currentPageIndex"></app-content-editor>
    </div>
  </section>
  <section *ngIf="selectedTab === 'STYLES'" class="style-actions fade-in-animation"
  [ngStyle]="carouselBanner ? {'height':'82vh'} : {}">
    <app-style-editor [style]="data.styles" [componentId]="data.id" [action]="data.action" [sectionType]="data.sectionType"></app-style-editor>
  </section>

</ng-container>
