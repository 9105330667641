import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { TemplateService } from 'src/app/services/template.service';
import { WebisiteService } from '../webisite.service';
import { environment } from 'src/environments/environment';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';
import { StepsToFollowComponent } from '../popups/steps-to-follow/steps-to-follow.component';
import { ActivatedRoute, Router } from '@angular/router';
import { LinkDomainComponent } from '../popups/link-domain/link-domain.component';
import { ProcessInprogressComponent } from '../popups/process-inprogress/process-inprogress.component';
import { CustomSnackbarComponent } from 'src/app/helper-components/custom-snackbar/custom-snackbar.component';
import { PaymentsService } from 'src/app/services/payment_apis/payments.service';
import { UpgradePlanComponent } from '../popups/upgrade-plan/upgrade-plan.component';
import { DOCUMENT } from '@angular/common';
import { StaffServiceService } from '../../staff/service/staff-service.service';
import { duration } from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BusinessServiceService } from '../../business/services/business-service.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss', './mq-settings.component.scss']
})
export class SettingsComponent implements OnInit {

  color: any = '#0099FF';
  websiteStatus: any = '';
  searchIndex: any = 'ENABLE';
  domainUrl: any = '';
  businessDetails: any;
  pageLoader: boolean = false;

  constructor(
    private templateService: TemplateService,
    private _websiteService: WebisiteService,
    private _eventHelper: EventEmmiterService,
    private _route: ActivatedRoute,
    private _paymentService: PaymentsService,
    @Inject(DOCUMENT) private document: Document,
    private staffService: StaffServiceService,
    private _snackBar: MatSnackBar,
    private businessService:BusinessServiceService
  ) {
    this.searchDomain();
  }

  action: String = "";
  // ngOnDestroy(){

  // }

  urlEndPoit: any = environment.urlEndingPoint;
  scrollToComponentSubscribe: any;
  goToSettingsSubscribe: any;

  editAccess: boolean = true;
  viewAccess: boolean = true;
  stat : any ;
  websiteType: 'STATIC' | 'E_COMMERCE' = 'STATIC'
  ngOnInit(): void {
    this.stat = window.localStorage.getItem('status');
    this.editAccess = this.staffService.getStaffPermission('WEBSITE', 'EDIT');
    this.viewAccess = this.staffService.getStaffPermission('WEBSITE', 'VIEW');
    this._route.queryParams.subscribe(queryParams => {
      this.action = queryParams['action'];
      if (this.action != null) {
        if (this.action === 'addExistingDomain') {
          this.linkDomain();
        }
      }
    })
    this.scrollToComponentSubscribe = this.templateService.scrollToComponent.subscribe(
      (res: any) => {
        //console.log(res.goTo);
        let a = document.getElementById(`${res.goTo}`);
        if (!a) return;
        a.scrollIntoView({ behavior: 'smooth' });
      }
    );
    // this.goToSettingsSubscribe = this._eventHelper.goTosettings.subscribe(
    //   (res:any) => {
    //     if(res.setting){
    //       this.linkDomain();
    //     }
    //   }
    // )
    let bDetails = window.localStorage.getItem('bDetails');
    if (bDetails) {
      this.businessDetails = JSON.parse(bDetails);
      this.websiteType = this.businessDetails.type;
      this.getBusinessDetailsByBusinessId();
    }
    this.checkingSwithBusiness();
  }

  ngOnDestroy() {
    if (this.checkSwitchBusiness) {
      this.checkSwitchBusiness.unsubscribe();
    }
  }


  checkSwitchBusiness: any;
  checkingSwithBusiness() {
    this.checkSwitchBusiness = this._eventHelper.switchBusiness.subscribe(
      (res: any) => {
        if (res.switchBusiness) {
          let bDetails = window.localStorage.getItem('bDetails');
          if (bDetails) {
            this.dnsDomainUrl = ''
            this.businessDetails = JSON.parse(bDetails);
            this.getBusinessDetailsByBusinessId();
          }
        }
      }
    )
  }

  webStatus = [
    {
      name: 'Publish',
      message: ' It is live and viewable by anyone who visits your website URL',
      status: true,
      value: 'PUBLISHED'
    },
    {
      name: 'Private',
      message: 'Only users with specific permissions or those who have been given a special access link can view the site. ',
      status: false,
      value: 'PRIVATE'
    },
    {
      name: 'Disabled',
      message: 'Your website offline, making it inaccessible to anyone, including those with prior access',
      status: false,
      value: 'DISABLED'
    }
  ]
  searchIndexing = [
    {
      name: 'Enable',
      message: 'All search tools can index your website',
      status: true,
      value: 'ENABLE'
    },
    {
      name: 'Diable',
      message: 'No search tools can index your website',
      status: false,
      value: 'DISABLE'
    }
  ];

  getBusinessDetailsByBusinessId() {
    this.pageLoader = true;
    this.templateService.getBusinessDetailsById(this.businessDetails.id).subscribe(
      (res: any) => {
        // console.log(res.data);
        this.webStatus.forEach((status: any) => {
          if (status.value === res.data.website.status) {
            this.websiteStatus = status.value;
          }
        });
        this.domainUrl = res.data.website.websiteUrl.split('//')[1].split('.')[0];
        if (res.data.website.domainUrl) {
          if (res.data.website.domainUrl.includes('https')) {
            this.dnsDomainUrl = res.data.website.domainUrl.split('//')[1];
          }
          else {
            this.dnsDomainUrl = res.data.website.domainUrl
          }
        }
        this.dnsVerified = res.data.website.domainVerified ? "Verified" : "Not Verified";
        this.dnsDomainBtnText = res.data.website.domainVerified ? "Update" : "Verify";
        this.pageLoader = false;
      },
      (err) => {
        this.pageLoader = false;
        this.templateService.openSnack(
          `${err.error.message}`,
          'Close'
        )
      }
    )
  }

  changeWebsiteStatus(ev: any) {
    // //console.log(ev.value)
    this.pageLoader = true;
    let data = {
      bId: this.businessDetails.id,
      status: ev.value
    }
    this._websiteService.updateWebsiteStatus(data).subscribe(
      (res: any) => {
        this.webStatus.forEach((status: any) => {
          if (status.value === res.data.status) {
            this.websiteStatus = status.value;
          }
        });
        // //console.log(this.websiteStatus)
        this.domainUrl = res.data.websiteUrl.split('//')[1].split('.')[0];
        this.pageLoader = false;
        window.localStorage.setItem('status', 'PUBLISHED');
      },
      (err) => {
        this.pageLoader = false;
        this.templateService.openSnack(
          `${err.error.message}`,
          'Close'
        )
      }
    )
  }

  updateWebsiteUrl() {
    this.pageLoader = true;
    let data = {
      businessId: this.businessDetails.id,
      websiteUrl: 'https://' + (this.domainUrl).toLowerCase() + `.${environment.urlEndingPoint}`
    }
    this._websiteService.updateWebsiteUrl(data).subscribe(
      (res: any) => {
        //console.log(res.data)
        this.templateService.openSnack('Website Url Updated', 'Ok')
        this.pageLoader = false;
      },
      (err) => {
        this.pageLoader = false;
        this.templateService.openSnack(
          `${err.error.message}`,
          'Close'
        )
      }
    )
  }


  //search custom domain

  domainName = '';

  searchDomain() {
    // this.templateService.openDialog(
    //   ListSearchedDomainsComponent,
    //   '530px',
    //   '530px',
    //   this.domainName
    // );
    this._websiteService.sellEntriToken().subscribe(
      {
        next: (res: any) => {
          console.log(res.data);
          setTimeout(() => {
            this.checkSellDomain(res.data)
          }, 1000);
        },
        error: (err: any) => {
          console.log(err.error.message);
        }
      }
    )
  }
  checkSellDomain(data) {
    // const purchaseDomain = new CustomEvent('purchaseDomain', {
      let config = {
        applicationId: data.applicationId,
        token: data.token,
        dnsRecords: data.dnsRecords
      }

      const configScript = this.document.createElement('script');
      configScript.type = 'text/javascript';
      configScript.innerHTML = `var config = ${JSON.stringify(config)};`;
      try {
        this.document.body.appendChild(configScript);
        console.log('Config script appended to the document body.');
      } catch (error) {
        console.error('Failed to append config script:', error);
      }
    // });
  }

  dnsDomainUrl: any;
  dnsVerified: any;
  loader: boolean = false;
  dnsDomainBtnText;

  linkDomain() {
    if(!this.editAccess) {
      this._snackBar.open('You dont have edit access', 'close', {duration: 2000});
      return;
    }

    if (this.goToSettingsSubscribe) {
      this.goToSettingsSubscribe.unsubscribe();

      // console.log(this.goToSettingsSubscribe)
    }
    if (this.businessDetails.subscriptionUpgrade) {
      let popup = this._paymentService.openDialog(
        UpgradePlanComponent,
        '95vh',
        '550px',
        '80vw'
      )
    }
    else {
      let popup = this.templateService.openDialogWithData(
        LinkDomainComponent,
        'auto',
        '390px',
        {
          'dnsDomainUrl': this.dnsDomainUrl,
          'dnsVerified': this.dnsVerified,
        }
      )
      // setTimeout(() => {
      // }, 500)/;
      popup.afterClosed().subscribe(
        (res: any) => {
          if (res.data === null) {
            console.log('response data getting null')
          }
          else {
            this.checkDomain(res.data, res.domainUrl, res.businessId);
          }
        }
      )
    }
  }

  businessId: any;
  checkDomain(data, domainUrl, businessId) {
    let checkDomain = new CustomEvent('checkDomain', {
      detail: {
        domain: domainUrl,
        config: data
      }
    });

    let checkingDomain = document.dispatchEvent(checkDomain)
    this.businessId = businessId;
    if (checkingDomain) {
      this.showEntri(data)
    }
    else {
      let popup = this.templateService.openDialogWithData(
        StepsToFollowComponent,
        'auto',
        '625px',
        {
          'dnsDomainUrl': this.dnsDomainUrl,
          'dnsVerified': this.dnsVerified,
        }
      )
    }
  }

  callbackFunctionSubscription: any;
  snackbar: any;
  showEntri(data) {
    const showEntri = new CustomEvent('showEntri', {
      detail: {
        applicationId: data.applicationId,
        token: data.token,
        dnsRecords: data.dnsRecords
      },
    });


    let self = this;
    let showEntriChecks = document.dispatchEvent(showEntri);

    function handleOnEntriClose(event) {
      // console.log(event.detail);
      // console.log(a)
      window.removeEventListener('onEntriClose', handleOnEntriClose, false);
      if (event.detail.success) {
        let a = event.detail;
        // let businessId = localStorage.getItem('businessId');
        a = { ...a, businessId: localStorage.getItem('businessId') }
        self.templateService.openDialogWithData(
          ProcessInprogressComponent,
          'auto',
          '600px',
          { data: null }
        )
        self._websiteService.callBackEntriMethod(a).subscribe(
          (res: any) => {
            // console.log(res.data)
            if (res.data) {
              // console.log('popup')
              // self.notifyPopup();
            }
            else {
              self.snackbar = self.snackbar.openFromComponent(
                CustomSnackbarComponent,
                {
                  data: {
                    type: 'ERROR',
                    message: `Oops...! Something went wrong`,
                    buttonText: "Close",
                    snackbar: self.snackbar
                  },
                  duration: 1500,
                  panelClass: 'error-snackbar'
                }
              );
            }
          },
          (err) => {
            console.log(err.error.message);
            self.snackbar = self.snackbar.openFromComponent(
              CustomSnackbarComponent,
              {
                data: {
                  type: 'ERROR',
                  message: `Oops...! Something went wrong`,
                  buttonText: "Close",
                  snackbar: self.snackbar
                },
                duration: 1500,
                panelClass: 'error-snackbar'
              }
            );
          }
        );
      }
    }
    window.addEventListener('onEntriClose', handleOnEntriClose, false);
  }

  notifyPopup() {
    let popup = this.templateService.openDialogWithData(
      ProcessInprogressComponent,
      'auto',
      '600px',
      { data: null }
    )
    popup.afterClosed().subscribe(
      (res: any) => { }
    )
  }


  searchDomainthroughGodaddy() {
    // window.location.href = `https://www.secureserver.net/?prog_id=589411`;
    let url = 'https://www.secureserver.net/?prog_id=589411';
    let win = window.open(url, '_blank');
    win?.focus();
  }

  showSnackBar(){
    this._snackBar.open('You dont have edit access', 'close', {duration: 2000});
  }

  updatePatmentStatus(){
    this.businessService.updatePaymentGatewayStatus(this.businessDetails.paymentGatewayEnabled, this.businessDetails.id).subscribe((res: any) => {
      this._snackBar.open("Payment status updated", 'close', {duration: 2000});
      localStorage.setItem('bDetails', JSON.stringify(this.businessDetails));
    })
  }

  updateSignupConfig(){
    this.businessService.updateSignUpConfig(this.businessDetails.signupEnabled, this.businessDetails.id).subscribe((res: any) => {
      this._snackBar.open("Signup config updated", 'close', {duration: 2000});
      localStorage.setItem('bDetails', JSON.stringify(this.businessDetails));
    })
  }
}
