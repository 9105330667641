<section>
    <div class="header-section">
        <div>Module Name</div>
        <div class="close" (click)="cancel()">
            <img src="../../../../../../assets/images/common/close-img.svg" alt="">
        </div>
    </div>

    <div class="middle-section">
        <div class="input_text business-field-section">
            <div class="input-label">Plural form of module Name<sup>*</sup></div>
            <mat-form-field class="full-width" appearance="outline">
                <input matInput placeholder="add" value="">
            </mat-form-field>
        </div>

        <div class="input_text business-field-section">
            <div class="input-label">Singular form of module Name<sup>*</sup></div>
            <mat-form-field class="full-width" appearance="outline">
                <input matInput placeholder="add" value="">
            </mat-form-field>
        </div>
        <div class="button-container">
            <div class="cancel" (click)="cancel()">Cancel</div>
            <div class="apply">Done</div>
        </div>
    </div>
</section>