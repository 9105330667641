<div [id]="id" class="total_container">
    <div class="top_layer">
        <div class="title">
            <app-text-element [data]="styles.title"></app-text-element>
        </div>
        <div class="text_description">
            <app-text-element [data]="styles.description"></app-text-element>
        </div>
    </div>
    <ng-container *ngFor="let item of itemData; let i = index">
        <div>
            <div [ngClass]="i%2 === 0 ? 'main_section' : 'main_section_reverse'">
                <div class="image_container">
                    <img [src]="item.image.attachment" [alt]="item.image.altName">
                </div>
                <div class="text_container">
                    <div class="title">
                        <app-text-element [data]="item.title"></app-text-element>
                    </div>
                    <div class="description">
                        <app-text-element [data]="item.description"></app-text-element>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <div class="show_btns" *ngIf="all">
        <div class="full_btns">
            <div class="full-edit-section" (click)="editSection()">
                <div class="edit image">
                    <img src="./../../../assets/images/editor/edit_blue.svg" alt="buleditIcon">
                </div>
                <div class="editsection-text">
                    Edit Section
                </div>
            </div>
            <div class="full-edit-section">
                <div class="edit image">
                    <img src="./../../../../../../../../../assets/images/editor/plus-icon.svg"
                        alt="regenrate">
                </div>
                <div class="regenerate-text">
                    Add Section
                </div>
            </div>
        </div>
    </div>
</div>