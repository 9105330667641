import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})


export class EditorEventService {

  changePageEvent = new EventEmitter<any>();
  addPageEvent = new EventEmitter<any>();
  showEditor = new EventEmitter<any>();

  openMultiBannerCarousel = new EventEmitter<any>();
}
