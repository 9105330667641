import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TemplateService } from 'src/app/services/template.service';

@Component({
  selector: 'app-process-inprogress',
  templateUrl: './process-inprogress.component.html',
  styleUrls: ['./process-inprogress.component.scss']
})
export class ProcessInprogressComponent {

  constructor(
    private templateService : TemplateService,
    public dialogRef: MatDialogRef<ProcessInprogressComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any,
  ){}

  ngOnInit(): void{
    setTimeout(() => {
      this.notify();
    }, 15000);
  }

  notify(){
    this.dialogRef.close();
  }

}
