import { Component, HostListener, Input } from '@angular/core';

@Component({
  selector: 'app-text-card',
  templateUrl: './text-card.component.html',
  styleUrls: ['./text-card.component.scss']
})
export class TextCardComponent {
  @Input() details: any;
  // @Input() text: string | null = null;
  // @Input() bgColor: string = 'white';
  // @Input() textColor: string | null = null;
  // @Input() valueColor: string | null = null;


  constructor(
  ) {
    this.getScreenSize()
  }

  screenWidth: any;
  @HostListener("window: resize", ["$event"])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  ngOnInit(): void {
  }

}
