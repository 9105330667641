import { Component, Inject, Optional, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { BlogServiceService } from '../../blog-service.service';

@Component({
  selector: 'app-delete-author',
  templateUrl: './delete-author.component.html',
  styleUrls: ['./delete-author.component.scss']
})
export class DeleteAuthorComponent {
  constructor(
    private dialogRef: MatDialogRef<DeleteAuthorComponent>,
    private _bottomSheetRef: MatBottomSheetRef<DeleteAuthorComponent>,
    @Optional()   @Inject(MAT_DIALOG_DATA) public data,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData: any,
    private blogService : BlogServiceService
  ){
    this.getScreenSize()
  }

  screenWidth: any;

  @HostListener("window:resize",["$event"])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }


  authorId : any ;
  ngOnInit() {
    this.authorId = this.data.data;
    if(this.data.type == 'DELETEAUTHOR' ){
        this.authorId = this.data.data;
    }
  }

  deleteAuthor(){
    if (this.screenWidth > 475){
      this.blogService.deleteBlogAuthor(this.authorId).subscribe({
        next:(res:any)=>{
            this.blogService.openSnack("Author Deleted sucessfully","Close");
            this.dialogRef.close('success');
        },
        error:(err)=>{
          this.blogService.openSnack(err.error.message,"close");
        }
      })
    }

    if (this.screenWidth < 475){
      this.blogService.deleteBlogAuthor(this.bottomSheetData.data).subscribe({
        next:(res:any)=>{
            this.blogService.openSnack("Author Deleted sucessfully","Close");
            this._bottomSheetRef.dismiss('success');
        },
        error:(err)=>{
          this.blogService.openSnack(err.error.message,"close");
        }
      })
    }
  }

  closeBtn(){
    if (this.screenWidth > 475){
      this.dialogRef.close();
    }
    if (this.screenWidth < 475){
      this._bottomSheetRef.dismiss();
    }
  }
}

