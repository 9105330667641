import { Component, Input } from '@angular/core';
import { Button, RedirectionLinkType } from 'simpo-component-library';

@Component({
  selector: 'app-button-editor',
  templateUrl: './button-editor.component.html',
  styleUrls: ['./button-editor.component.scss']
})
export class ButtonEditorComponent {

  @Input() buttonData: Button = {
    linkType : RedirectionLinkType.External,
    label: '',
    redirectionUrl: '',
    pageId: '',
    newTab: false
  };
  @Input() templatePage:any[] = [];
  @Input() currentPageIndex:number = 0;

  sections: any[]= [];
  ngOnInit(){
  }


  RedirectionLink = RedirectionLinkType
  RedirectionLinkType: Array<type> = [
    {
      type: RedirectionLinkType.Page
    },
    {
      type: RedirectionLinkType.External
    },
    {
      type: RedirectionLinkType.Email
    },
    {
      type: RedirectionLinkType.Phone
    },
    {
      type: RedirectionLinkType.section
    }
  ]
}

interface type {
  type: string;
}
