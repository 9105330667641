import { Component, Input } from '@angular/core';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';

@Component({
  selector: 'app-button-element',
  templateUrl: './button-element.component.html',
  styleUrls: ['./button-element.component.scss']
})
export class ButtonElementComponent {
  
  constructor(
    private _eventHelper : EventEmmiterService
  ){}

  @Input() data:any;

  ngOnInit(){

    if(this.data.hasOwnProperty('status')){
    }
    else{
      this.data.status = true
    }
  }
  clickFunction() {
    // console.log(this.data);
    this._eventHelper.buttonCick.emit({buttonData:this.data})
  }
}
