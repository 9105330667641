import { Component, HostListener } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { TemplateService } from 'src/app/services/template.service';
import { PaymentsService } from 'src/app/services/payment_apis/payments.service';
import { UpgradePlanComponent } from 'src/app/master-config-components/micro-apps/website/popups/upgrade-plan/upgrade-plan.component';
import { LinkDomainComponent } from 'src/app/master-config-components/micro-apps/website/popups/link-domain/link-domain.component';
import { StepsToFollowComponent } from 'src/app/master-config-components/micro-apps/website/popups/steps-to-follow/steps-to-follow.component';
import { WebisiteService } from 'src/app/master-config-components/micro-apps/website/webisite.service';
import { ProcessInprogressComponent } from 'src/app/master-config-components/micro-apps/website/popups/process-inprogress/process-inprogress.component';
import { CustomSnackbarComponent } from 'src/app/helper-components/custom-snackbar/custom-snackbar.component';

@Component({
  selector: 'app-v3-view-site',
  templateUrl: './v3-view-site.component.html',
  styleUrls: ['./v3-view-site.component.scss']
})
export class V3ViewSiteComponent {
  constructor(
    public dialogRef: MatDialogRef<V3ViewSiteComponent>,
    private bottomSheet: MatBottomSheetRef<V3ViewSiteComponent>,
    private templateService: TemplateService,
    private _paymentService: PaymentsService,
    private _websiteService: WebisiteService,
  )
  {
    this.getScreenSize();
  }
  businessDetails : any
  private templateId: string | null = null;
ngOnInit(): void {
  let bDetails = window.localStorage.getItem('bDetails');
    if (bDetails) {
      this.businessDetails = JSON.parse(bDetails);
      this.templateId = this.businessDetails.templateId;
    }
  
}
  screenWidth: number = 0;
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }
  closePopup()
  {
    if (this.screenWidth > 475){
      this.dialogRef.close()
    }

    if (this.screenWidth < 475){
      this.bottomSheet.dismiss()
    }
  }
  dnsDomainUrl: any;
  dnsVerified: any;
  goToSettingsSubscribe: any;
  upgradePlan()
  {
    if (this.goToSettingsSubscribe) {
      this.goToSettingsSubscribe.unsubscribe();

      // console.log(this.goToSettingsSubscribe)
    }
    if (this.businessDetails.subscriptionUpgrade) {
      let popup = this._paymentService.openDialog(
        UpgradePlanComponent,
        '95vh',
        '550px',
        '80vw'
      )
    }
    else {
      let popup = this.templateService.openDialogWithData(
        LinkDomainComponent,
        'auto',
        '390px',
        {
          'dnsDomainUrl': this.dnsDomainUrl,
          'dnsVerified': this.dnsVerified,
        }
      )
      popup.afterClosed().subscribe(
        (res: any) => {
          if (res.data === null) {
            console.log('response data getting null')
          }
          else {
            this.checkDomain(res.data, res.domainUrl, res.businessId);
          }
        }
      )
    }
  }
  businessId: any;
  checkDomain(data, domainUrl, businessId) {
    let checkDomain = new CustomEvent('checkDomain', {
      detail: {
        domain: domainUrl,
        config: data
      }
    });

    let checkingDomain = document.dispatchEvent(checkDomain)
    this.businessId = businessId;
    if (checkingDomain) {
      this.showEntri(data)
    }
    else {
      let popup = this.templateService.openDialogWithData(
        StepsToFollowComponent,
        'auto',
        '625px',
        {
          'dnsDomainUrl': this.dnsDomainUrl,
          'dnsVerified': this.dnsVerified,
        }
      )
    }
  }
  callbackFunctionSubscription: any;
  snackbar: any;
  showEntri(data) {
    const showEntri = new CustomEvent('showEntri', {
      detail: {
        applicationId: data.applicationId,
        token: data.token,
        dnsRecords: data.dnsRecords
      },
    });


    let self = this;
    let showEntriChecks = document.dispatchEvent(showEntri);

    function handleOnEntriClose(event) {
      // console.log(event.detail);
      // console.log(a)
      window.removeEventListener('onEntriClose', handleOnEntriClose, false);
      if (event.detail.success) {
        let a = event.detail;
        // let businessId = localStorage.getItem('businessId');
        a = { ...a, businessId: localStorage.getItem('businessId') }
        self.templateService.openDialogWithData(
          ProcessInprogressComponent,
          'auto',
          '600px',
          { data: null }
        )
        self._websiteService.callBackEntriMethod(a).subscribe(
          (res: any) => {
            // console.log(res.data)
            if (res.data) {
              // console.log('popup')
              // self.notifyPopup();
            }
            else {
              self.snackbar = self.snackbar.openFromComponent(
                CustomSnackbarComponent,
                {
                  data: {
                    type: 'ERROR',
                    message: `Oops...! Something went wrong`,
                    buttonText: "Close",
                    snackbar: self.snackbar
                  },
                  duration: 1500,
                  panelClass: 'error-snackbar'
                }
              );
            }
          },
          (err) => {
            console.log(err.error.message);
            self.snackbar = self.snackbar.openFromComponent(
              CustomSnackbarComponent,
              {
                data: {
                  type: 'ERROR',
                  message: `Oops...! Something went wrong`,
                  buttonText: "Close",
                  snackbar: self.snackbar
                },
                duration: 1500,
                panelClass: 'error-snackbar'
              }
            );
          }
        );
      }
    }
    window.addEventListener('onEntriClose', handleOnEntriClose, false);
  }
  viewPerview(){
    window.open(this.businessDetails.domainUrl ? this.businessDetails.domainUrl : this.businessDetails.websiteUrl, '_blank')?.focus();
  }
}
