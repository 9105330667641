import { Component, Input } from '@angular/core';
import { ElementHelper } from '../../../elements/elements_helper';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';
import { fontSizes } from './../../../../../../../../constants/common-constant'

@Component({
  selector: 'app-button-editor-card',
  templateUrl: './button-editor-card.component.html',
  styleUrls: ['./button-editor-card.component.scss']
})
export class ButtonEditorCardComponent {
  fontSizes : any = fontSizes;
  @Input() data : any;
  @Input() allPages : any;
  @Input() samePageComponents : any

  constructor(
    private _elementHelper : ElementHelper,
    private _eventHelper : EventEmmiterService
  ){}

  ngOnInit(){

    console.log(this.data);

    this.fontSizes.forEach(fz => {
      if(this.data['font-size']){
        if(fz === Number(this.data['font-size'].split('px')[0])){
          this.fontSize = fz
        }
      }
    });

    document.getElementById('newLine')?.addEventListener('keydown', (e:any) => {
      if(e.key === 'Enter'){
        let new_line = document.createElement("br");
        new_line.innerText = e.target.value;
      }
    });
    this.gettingRedirectionUrls();
        this.checkingforBgColor();
  }

  backgroundColor : boolean = false;
  checkingforBgColor(){
    if(this.data.hasOwnProperty('background-color')){
      this.backgroundColor = true;
    }
    else{
      this.backgroundColor = false;
    }
  }


  gettingRedirectionUrls(){
    if(this.data.redirectionType){
      this.buttonLinks.forEach((btl) => {
        if(btl.value === this.data.redirectionType){
          this.buttonRedirectionValue = btl.value;
        }
      });
      if(this.buttonRedirectionValue === 'INTERNAL'){
        this.internalPageRedirectionValue = this.data.redirectTo
      }
      else if(this.buttonRedirectionValue === 'EXTERNAL'){
        this.externalPageRedirectionValue = this.data.redirectTo
      }
      else{
        this.samePageRedirectionValue = this.data.redirectTo
      }
    }
    else{
      this.buttonRedirectionValue = 'SAME_PAGE';
      this.samePageComponents.components.forEach((elem,i) => {
        if(this.data.redirectTo === i){
          this.samePageRedirectionValue = i
        }
      });
    }
  }
  fontSize: number | any ;

  buttonLinks : any = [
    {
      name:'external link',
      value:'EXTERNAL'
    },
    {
      name:'internal link',
      value:'INTERNAL'
    },
    {
      name:'same page',
      value:'SAME_PAGE'
    }
  ]

  onChange(ev){
    this.data['font-size'] = ev.value+'px';
    this._eventHelper.showSaveOption.emit({data:true});
  }

  boldWeight : any = {
    name:'B',
    status : false
  }
  style : any = {
    name:'I',
    status : false
  }
  fontWeight(){
    this.boldWeight.status = !this.boldWeight.status;
    if(this.boldWeight.status){
      this.data['font-weight'] = '900';
    }
    else{
      this.data['font-weight'] = '400';
    }
    this._eventHelper.showSaveOption.emit({data:true});
  }
  fontStyle(){
    this.style.status = !this.style.status;
    if(this.style.status){
      this.data['font-style'] = 'italic';
    }
    else{
      this.data['font-style'] = 'normal'
    }
    this._eventHelper.showSaveOption.emit({data:true});
  }

  // colorChange(){
  //   let elementId = document.getElementById('browse_color');
  //   if(!elementId) return;
  //   elementId.click()
  // }

  increaseTextAreaHeight(ev){
    let code = ev.keyCode || ev.which;
    let text = ev.target.value
    if(code === 13){
      text = text.replace(/\n/g, "<br>");
      this.data.value = text
    }
  }


  buttonRedirectionValue : any ='';
  internalPageRedirectionValue : any = '';
  samePageRedirectionValue : any = '';
  externalPageRedirectionValue : any = '';

  redirectionTypeChange(ev){
    this.data.redirectionType = this.buttonRedirectionValue;
    this.data.redirectTo = null
  }

  buttonRedirections(ev){
    this.data.redirectionType = ev;
    // this.data.redirectTo = null
    if(ev === 'INTERNAL'){
      this.data.redirectTo = this.internalPageRedirectionValue;
      // this._eventHelper.gettingButtonClickValues.emit({redirectionButtonClicked:this.buttonRedirectionValue,redirectionValue:this.internalPageRedirectionValue})
    }
    else if(ev === 'EXTERNAL'){
      this.data.redirectTo = this.externalPageRedirectionValue;
      // this._eventHelper.gettingButtonClickValues.emit({redirectionButtonClicked:this.buttonRedirectionValue,redirectionValue:this.externalPageRedirectionValue})
    }
    else{
      this.data.redirectTo = this.samePageRedirectionValue;
      // this._eventHelper.gettingButtonClickValues.emit({redirectionButtonClicked:this.buttonRedirectionValue,redirectionValue:this.samePageRedirectionValue})
    }
    // console.log(this.data);
    this._eventHelper.showSaveOption.emit({data:true});
  }

  changeButtonStatus(ev){
    this.data.status = ev;
    this._eventHelper.showSaveOption.emit({data:true});
  }

  updatingLabel(){
    this._eventHelper.showSaveOption.emit({data:true})
  }
  changeColor(ev){
    this._eventHelper.showSaveOption.emit({data:true})
  }

  changeBgColor(){
    this.data['color'] = this.changeTextColor(this.data['background-color'])
    this._eventHelper.showSaveOption.emit({data:true})
  }

  changeTextColor(bgColor:any){
    var bgColor = bgColor
    const threshold = 130; // Adjust this threshold as needed
    const r = parseInt(bgColor.slice(1, 3), 16);
    const g = parseInt(bgColor.slice(3, 5), 16);
    const b = parseInt(bgColor.slice(5, 7), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    return brightness > threshold ? '#000000' : '#ffffff';
  }

  buttonLinkType(){
    this._eventHelper.showSaveOption.emit({data:true})
  }

}
