<section class="border">
  <div class="color-related">
      <input type="color" [(ngModel)]="data.styles.border.color" id="colorPicker" (input)="changeColor()">
      <input class="input prefix" maxlength="7" type="text" [value]="data.styles.border.color"
      (click)="enterColorCode()">
  </div>
  <div class="horizontal-action" style="margin-top: 20px;">
      <div style="width: 30%;">Thickness</div>
      <div style="width: 70%;display: flex;justify-content: center;align-items: center;">
        <input [min]="min" [max]="max" step="2" type="range" [(ngModel)]="data.styles.border.thickness" (input)="changeThickness()">
      </div>
  </div>
  <div class="section-height" style="margin-top: 20px;">
      <div>Min. height</div>
      <div class="height-tabs">
          <ng-container *ngFor="let item of borderType">
              <div class="single-height-tab cursor-pointer" [ngClass]="item.status ? 'active-single-height-tab' : ''"
              (click)="changeBorderType(item.name)">
                  {{item.name}}
              </div>
          </ng-container>
      </div>
  </div>
</section>
