import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Author, BlogType, SelectedOption, Tag } from '../blog';
import { MatChipInputEvent } from '@angular/material/chips';
import { BlogServiceService } from '../blog-service.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Subscription } from 'rxjs';
import moment from 'moment';

@Component({
  selector: 'app-blog-filter',
  templateUrl: './blog-filter.component.html',
  styleUrls: ['./blog-filter.component.scss']
})
export class BlogFilterComponent implements OnInit ,OnDestroy {

  constructor(
    private blogService: BlogServiceService,
    private dialogRef: MatDialogRef<BlogFilterComponent>,
    private _bottomSheetRef: MatBottomSheetRef<BlogFilterComponent>,
    @Optional()   @Inject(MAT_DIALOG_DATA) public matData,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData: any,
  ) {
    if (window.innerWidth > 475) {
      this.dialogRef.updatePosition({ top: '250px', right: '98px' });
    }
    // else {
    //   this.dialogRef.updatePosition({ top: '120px', right: '50px' });
    // }
    this.getScreenSize();
  }
  data: any;

  ngOnInit(): void {
    this.data = this.matData ? this.matData : this.bottomSheetData;
    if (this.data && this.data.type === BlogType.BUSINESS) {
      const details = localStorage.getItem('bDetails');

      this.businessId = details ? JSON.parse(details).id : '';
    }

    this.subscriptions.add(
      this.tagControl.valueChanges.subscribe({
        next: (value: string | null) => {
          if (value) {
            this.searchForTags(value);
          }
        }
      })
    );

    this.subscriptions.add(
      this.authorControl.valueChanges.subscribe({
        next: (value: string | null) => {
          if (value) {
            this.searchForAuthors(value);
          }
        }
      })
    );

    if (this.data.filters && this.data.filters.length > 0) {
      this.data.filters.forEach((element: SelectedOption) => {
        if (element.fieldName === 'authors') {
          this.authors.push(element);
        } else if (element.fieldName === 'tags') {
          this.tags.push(element);
        } else if (element.fieldName === 'publishDate') {
          this.publishedDate = element.fieldValue;
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  subscriptions: Subscription = new Subscription();

  separatorKeysCodes: number[] = [ENTER, COMMA];  // For tags or author chips
  tagControl: FormControl = new FormControl<string>('');
  filteredTags: Tag[] = [];
  tags: SelectedOption[] = [];

  authorControl: FormControl = new FormControl<string>('');
  filteredAuthors: Author[] = [];
  authors: SelectedOption[] = [];

  businessId: string = '';

  publishedDate: string | null = null;

  screenWidth: any;
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  closeFilter() {
    if(this.screenWidth > 475){
      this.dialogRef.close();
    }
    if(this.screenWidth < 475){
      this._bottomSheetRef.dismiss();
    }
  }

  clearFilters() {
    this.authors = [];
    this.tags = [];
    this.publishedDate = '';
  }

  searchForTags(text: string) {
    this.blogService.searchBlogsTagByBusinessId(text, this.businessId).subscribe((res: any) => {
      this.filteredTags = res.data;
    })
  }

  selectTag(selectedTag: Tag, chipInput: HTMLInputElement){
    //;
    if (!this.tags.some((t: SelectedOption) => t.fieldValue === selectedTag.id)) {
      this.tags.push({
        fieldValue: selectedTag.id,
        fieldText: selectedTag.tagName,
        fieldName: 'tags'
      });
    }

    chipInput.value = '';
    this.tagControl.setValue('');
  }

  addNewTag(event: MatChipInputEvent): void {
    // const value = (event.value || '').trim();

    // if (value && !this.tags.includes(value.toLowerCase())) {
    //   this.tags.push(value.toLowerCase());
    // }

    // // Clear the input value
    // event.chipInput!.clear();
  }

  removeTag(tag: SelectedOption) {
    if (!tag) return;

    const foundIndex = this.tags.findIndex((t: SelectedOption) => t.fieldValue === tag.fieldValue);

    if (foundIndex > -1) {
      this.tags.splice(foundIndex, 1);
    }
  }

  searchForAuthors(text: string) {
    this.blogService.searchBlogsAuthorByBusinessId(text , this.businessId).subscribe({
        next: (response: any)=>{
          this.filteredAuthors = response.data;
        },
      })
  }

  selectAuthor(selectedAuthor: Author, chipInput: HTMLInputElement){
    //;
    if (!this.authors.some((author: SelectedOption) => author.fieldValue === selectedAuthor.authorId)) {
      this.authors.push({
        fieldValue: selectedAuthor.authorId,
        fieldText: selectedAuthor.authorName,
        fieldName: 'authors'
      });
    }

    chipInput.value = '';
    this.authorControl.setValue('');
  }

  addNewAuthor(event: MatChipInputEvent): void {
    // const value = (event.value || '').trim();

    // if (value && !this.authors.includes(value.toLowerCase())) {
    //   this.authors.push(value.toLowerCase());
    // }

    // // Clear the input value
    // event.chipInput!.clear();
  }

  removeAuthor(author: SelectedOption): void {
    if (!author) return;

    const foundIndex = this.authors.findIndex((a: SelectedOption) => a.fieldValue === author.fieldValue);

    if (foundIndex > -1) {
      this.authors.splice(foundIndex, 1);
    }
  }

  applyFilters() {
    if (!this.authors.length && !this.tags.length && !this.publishedDate) {
      this.blogService.openSnack("Please select a filter criteria", "Ok");
      return;
    }

    if(this.screenWidth > 475){
      this.dialogRef.close({
        appliedFilters: {
          authors: this.authors,
          tags: this.tags,
          publishDate: this.publishedDate ? {
            fieldText: moment(this.publishedDate).format('YYYY-MM-DD'),
            fieldValue: moment(this.publishedDate).format(),
            fieldName: 'publishDate'
          } : null
        }
      })
    }
    if (this.screenWidth < 475){
      this._bottomSheetRef.dismiss({
        appliedFilters: {
          authors: this.authors,
          tags: this.tags,
          publishDate: this.publishedDate ? {
            fieldText: moment(this.publishedDate).format('YYYY-MM-DD'),
            fieldValue: moment(this.publishedDate).format(),
            fieldName: 'publishDate'
          } : null
        }
      })
    }
  }
}
