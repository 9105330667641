import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DbService } from 'src/app/services/db-service/db.service';
import { CrmServiceService } from '../../../../service/crm-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';




@Component({
  selector: 'app-add-customizations',
  templateUrl: './add-customizations.component.html',
  styleUrls: ['./add-customizations.component.scss']
})

export class AddCustomizationsComponent {
  constructor(
    public dialogRef: MatDialogRef<AddCustomizationsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dbService: DbService,
    private crmService: CrmServiceService,
    private snackbar: MatSnackBar,
  ){}
  cancel() {
    this.dialogRef.close()
  }
}

  

  

 

