import { ComponentFactoryResolver, ComponentRef, HostListener, Injectable } from '@angular/core';
import { BannerSectionComponent, FaqSectionComponent, FooterSectionComponent, ImageCarouselSectionComponent, ImageGridSectionComponent, ImageSectionComponent, ServiceSectionComponent, TeamMemberSectionComponent, TestimonialSectionComponent, TextImageSectionComponent, TextSectionComponent, VideoSectionComponent } from 'simpo-component-library';
import { V2HorizontalFaqComponent, V2SimpleContactFormComponent, V2SimpleFooterComponent, NollieChooseUsComponent, NollieServiceTechnologyComponent, V2SimpleHerobannerButtonImageComponent, V2ProcessSparkImageSideComponent, V2ServicesectioneditorComponent, V2VerticleServiceSectionComponent, V2SingleHorizontalTestimonialComponent, V2SinglePageDoubleTestimonialComponent, V2SinleVerticleTestimonialComponent, V2HoverHorizontalServiceSectionComponent, V2ProcessNumberTextComponent, V2ProcessCountImageSideComponent, V2ProcessCountImageComponent, NollieServicesComponent, NollieContactUsComponent, V2OpenedFaqComponent, V2SliderFaqComponentsComponent, V2ContactusTextInputsComponent, V2SimpleNavbarPagelinksComponent, NollieFooterComponent, EmploiFaqComponent, NollieServicesHowweworkComponent, NollieTestimonialCustomersComponent, NollieOurValuesComponent, BasicPricingComponentComponent, NollieCaseStudies, NollieBlogComponent, NollieAboutUsComponent, V2AboutusComponent, V2AboutusWithBackgroundImageComponent, AboutusCoreValuesComponent, V2HerobannerStatImageComponent, V2HerobannerRatingReviewBgimgImageComponent, EmploiJoinOurTeamComponent, NollieOurTeamComponent, ExpertTeamComponent, EmploiOurTeamComponent, EmploiHerobannerRightsideImageComponent, V2HerobannerFeatureRightsideImageComponent, NollieHerobannerComponent, V2HerobannerStatImageReverseComponent, V2HerobannerWithReviewComponent, V2SimpleHeroBannerComponent, V2SimpleHerobannerRightsideBackgroundComponent, NewPageComponent, TextElementComponent, CustomImageElementComponent, CustomMultiImageElementComponent, BlurredBgNotifyComponent, SimpoCardComponent, SimpoCaseStudiesComponent, SimpoCtaActionComponent, SimpoFeaturesComponent, SimpoFaqComponent, SimpoFooterComponent, SimpoHerobannerComponent, SimpoPricingComponent, SimpoNavbarComponent, SimpoTestimonialComponent, SimpoWebsiteGenerateComponent } from 'simpo-components';


@Injectable({
  providedIn: 'root'
})
export class DynamicpickcomponentsService {

  componentsReferences = Array<ComponentRef<any>>()

  constructor(
    private comFactoryResolver: ComponentFactoryResolver,
  ) {
    this.getScreenSize();
  }

  screenWidth: any;
  @HostListener("window: resize", ["$event"])
  getScreenSize() {
    this.screenWidth = window.innerWidth;
  }

  listComponents: any = [
  // V3 Components
    {
      name: 'banner',
      component: BannerSectionComponent
  },
  {
    name: 'service',
    component: ServiceSectionComponent
  },
  
  {
    name: 'FAQ',
    component: FaqSectionComponent
  },
  
  {
    name: 'footer',
    component: FooterSectionComponent
  },
  
  {
    name: 'imageCarousel',
    component: ImageCarouselSectionComponent
  },
  
  {
    name: 'imagegrid',
    component: ImageGridSectionComponent
  },
  
  {
    name: 'image',
    component: ImageSectionComponent
  },
  
  {
    name: 'teammember',
    component: TeamMemberSectionComponent
  },
  
  {
    name: 'testimonial',
    component: TestimonialSectionComponent
  },  
  {
    name: 'text_image',
    component: TextImageSectionComponent
  },  
  {
    name: 'text',
    component: TextSectionComponent
  }, 
  {
    name: 'video',
    component: VideoSectionComponent
  },


  // V3 Component Ended
    {
      name:'miscellaneous-blurred-classic',
      component:BlurredBgNotifyComponent
    },
    {
      name:'lm_text',
      component:TextElementComponent
    },
    {
      name:'lm_single_image',
      component:CustomImageElementComponent
    },
    {
      name:'lm_multi_image_gallery',
      component:CustomMultiImageElementComponent
    },
    //herobanners
    {
      name: 'our-team-minimalist',
      component: EmploiJoinOurTeamComponent
    },
    {
      name: 'hero-banner-image',
      component: EmploiHerobannerRightsideImageComponent
    },
    {
      name: 'hero-banner-minimalist',
      component: V2HerobannerFeatureRightsideImageComponent
    },
    {
      name: 'hero-banner-stats-standard',
      component: V2HerobannerRatingReviewBgimgImageComponent
    },
    {
      name: 'hero-banner-stats',
      component: V2HerobannerStatImageComponent
    },
    {
      name: 'hero-banner-stats-patterned',
      component: V2HerobannerStatImageReverseComponent
    },
    {
      name: 'hero-banner-modern',
      component: V2HerobannerWithReviewComponent
    },
    {
      name: 'hero-banner-standard',
      component: V2SimpleHeroBannerComponent
    },
    {
      name: 'new_page',
      component: NewPageComponent
    },
    {
      name: 'hero-banner-gradient',
      component: V2SimpleHerobannerRightsideBackgroundComponent
    },
    {
      name: 'hero-banner-patterned',
      component: V2SimpleHerobannerButtonImageComponent
    },
    //process section components
    {
      name: 'process-classic',
      component: V2ProcessCountImageComponent
    },
    {
      name: 'process-modern',
      component: V2ProcessCountImageSideComponent
    },
    {
      name: 'process-standard',
      component: V2ProcessNumberTextComponent
    },
    {
      name: 'process-minimalist',
      component: V2ProcessSparkImageSideComponent
    },
    //service section components
    {
      name: 'service-modern',
      component: V2HoverHorizontalServiceSectionComponent
    },
    {
      name: 'service-image',
      component: V2ServicesectioneditorComponent
    },
    {
      name: 'service-minimal',
      component: V2VerticleServiceSectionComponent
    },
    //testimonials
    {
      name: 'testmonial-fullwidth',
      component: V2SingleHorizontalTestimonialComponent
    },
    {
      name: 'testimonial-minimalist',
      component: V2SinglePageDoubleTestimonialComponent
    },
    {
      name: 'testimonial-classic',
      component: V2SinleVerticleTestimonialComponent
    },
    //about us
    {
      name: 'about-us-standard',
      component: V2AboutusComponent
    },
    {
      name: 'about-us-modern',
      component: V2AboutusWithBackgroundImageComponent
    },
    //faq components
    {
      name: 'faq-standard',
      component: V2HorizontalFaqComponent
    },
    {
      name: 'faq-minimal',
      component: V2OpenedFaqComponent
    },
    {
      name: 'faq-minimal-slider',
      component: V2SliderFaqComponentsComponent
    },
    {
      name: 'nav-bar-standard',
      component: V2SimpleNavbarPagelinksComponent
    },

    //contact us
    {
      name: 'contact-us-standard',
      component: V2ContactusTextInputsComponent
    },
    {
      name: 'contact-us-modern',
      component: V2SimpleContactFormComponent
    },

    //blogs
    {
      name: 'blog-classic',
      component: NollieBlogComponent
    },

    //footers
    {
      name: 'footer-standard',
      component: V2SimpleFooterComponent
    },
    {
      name: 'footer-classic',
      component: NollieFooterComponent
    },
    {
      name: 'choose-us-standard',
      component: NollieChooseUsComponent
    },
    {
      name: 'service-standard',
      component: NollieServicesComponent
    },
    {
      name: 'service-minimalist',
      component: NollieServiceTechnologyComponent
    },

    {
      name: 'contact-us-classic',
      component: NollieContactUsComponent
    },
    {
      name: 'hero-banner-classic',
      component: NollieHerobannerComponent
    },
    {
      name: 'about-us-classic',
      component: NollieAboutUsComponent
    },
    {
      name: 'choose-stat',
      component: EmploiFaqComponent
    },
    {
      name: 'service-classic',
      component: NollieServicesHowweworkComponent
    },
    {
      name: 'testimonial-standard',
      component: NollieTestimonialCustomersComponent
    },
    {
      name: 'our-values-standard',
      component: NollieOurValuesComponent
    },
    {
      name: 'pricing-standard',
      component: BasicPricingComponentComponent
    },
    {
      name: 'about-us-minimalist',
      component: AboutusCoreValuesComponent
    },
    {
      name: 'case-study-classic',
      component: NollieCaseStudies
    },
    {
      name: 'our-team-standard',
      component: ExpertTeamComponent
    },
    {
      name: "our-team-image",
      component: EmploiOurTeamComponent
    },
    {
      name: "our-team-classic",
      component: NollieOurTeamComponent
    },
    {
      name:"simpo-card",
      component:SimpoCardComponent
    },
    {
      name:"simpo-case-studies",
      component:SimpoCaseStudiesComponent
    },
    {
      name:"simpo-cta-action",
      component:SimpoCtaActionComponent
    },
    {
      name:'simpo-faq',
      component:SimpoFaqComponent
    },
    {
      name:'simpo-feature',
      component:SimpoFeaturesComponent
    },
    {
      name:'simpo-footer',
      component:SimpoFooterComponent
    },
    {
      name:'simpo-hero-banner',
      component:SimpoHerobannerComponent
    },
    {
      name:'simpo-pricing',
      component:SimpoPricingComponent
    },
    {
      name:'simpo-navbar',
      component:SimpoNavbarComponent
    },
    {
      name:'simpo-testimonial',
      component:SimpoTestimonialComponent
    },
    {
      name:'simpo-website-generate',
      component:SimpoWebsiteGenerateComponent
    }
    

  ];


  webStyleSheet = {
    'padding-left': '5%',
    'padding-right': '5%'
  }

  mobileStyleSheet = {
    'padding-left': '3%',
    'padding-right': '3%'
  }

  adjustingFontSizesInStyles(components) {
    Object.keys(components).forEach((item) => {
      if ((components[item]['font-size'] != undefined || components[item]['font-size'] != null)) {
        let fontSize;
        let temp = components[item]['font-size'].replace('px', '');
        fontSize = parseInt(temp, 10);
        if (this.screenWidth >= 810) {
          components[item]['font-size'] = fontSize + 'px';
          components[item]['line-height'] = (fontSize + 5) + 'px';
        } else if (this.screenWidth < 810 && this.screenWidth > 320) {
          let font = fontSize * 0.60;
          if (font < 15) {
            font = 15;
          }
          components[item]['font-size'] = font + 'px';
          components[item]['line-height'] = (font + 5) + 'px';
        } else {
          let font = fontSize * 0.54;
          if (font < 15) {
            font = 15;
          }
          components[item]['line-height'] = (font + 5) + 'px';
          components[item]['font-size'] = font + 'px';
        }
      }
      // if(components[item]['line-height'] != undefined || components[item]['line-height'] != null) {
      //   let fontSize ;
      //     let temp = components[item]['line-height'].replace('px','');
      //     fontSize = parseInt(temp, 10);
      //   if(this.screenWidth >= 810){
      //     components[item]['line-height'] = fontSize + 'px';
      //   } else if(this.screenWidth < 810 && this.screenWidth > 320) {
      //     let font = fontSize * 0.69;
      //     if(font < 20){
      //       font = 20;
      //     }
      //     components[item]['line-height'] = font + 'px';
      //   } else {
      //     let font = fontSize * 0.54;
      //     if(font < 20){
      //       font = 20;
      //     }
      //     components[item]['line-height'] = font + 'px';
      //   }
      // }
    })
  }

  dyanmicComponentPickup(displaySection, items, canEditAll: boolean = false, isV3: boolean = false) {
    this.componentsReferences = [];
    displaySection?.clear();
    ;
    items.forEach((components, i) => {
      this.listComponents.forEach((localComponents, j) => {
        if (localComponents.name === components?.webComponentName || localComponents.name === components?.sectionType) {

          const componentFactory = this.comFactoryResolver.resolveComponentFactory(localComponents.component);
          const k = displaySection.createComponent(componentFactory);

          if (components.styles != null) {
            this.adjustingFontSizesInStyles(components.styles)
          }
          if (isV3) {
            k.instance.data = components;
          } else {
            k.instance.responseData = components;
          }
          // k.instance.edit = edit;
          // k.instance.all = all;
          k.instance.index = i;
          if (this.screenWidth < 480) {
            k.instance.globalStyle = this.mobileStyleSheet;
          }
          if (this.screenWidth > 480) {
            k.instance.globalStyle = this.webStyleSheet;
          }
          k.instance.canEditAll = canEditAll;
          if (isV3)
            k.instance.id = components.id;
          else
            k.instance.id = components.name;
          this.componentsReferences.push(k);
        }
      });
    });
    return this.componentsReferences?.[0]?.instance?.eventService || null;
  }

  dynamicMobilePreviewPickup(docValue, displaySection, items, canEditAll: boolean = false) {
    this.componentsReferences = [];
    displaySection.clear();
    items.forEach((components, i) => {
      this.listComponents.forEach((localComponents, j) => {
        if (localComponents.name === components?.webComponentName) {
          const componentFactory = this.comFactoryResolver.resolveComponentFactory(localComponents.component);
          const k = displaySection.createComponent(componentFactory);
          k.instance.responseData = components;
          // k.instance.edit = edit;
          // k.instance.all = all;
          k.instance.index = i;
          k.instance.canEditAll = canEditAll;
          if (this.screenWidth > 480) {
            k.instance.globalStyle = this.mobileStyleSheet;
          }
          if (this.screenWidth < 900) {
            k.instance.globalStyle = this.webStyleSheet;
          }
          k.instance.id = components.category.name;
          this.componentsReferences.push(k);
          docValue.body.appendChild(k.location.nativeElement);
        }
      });
    });
    return this.componentsReferences?.[0]?.instance?._eventHelper || null;
  }

  addComponent(displaySection, item, canEditAll, index) {
    let componentFact = this.listComponents.forEach((listCompos, i) => {
      if (listCompos.name === item.webComponentName) {
        const componentFactory = this.comFactoryResolver.resolveComponentFactory(listCompos.component);
        const k = displaySection.createComponent(componentFactory, index);
        k.instance.responseData = item;
        // k.instance.edit = edit;
        // k.instance.all = all;
        k.instance.canEditAll = true;
        k.instance.index = index;
        if (this.screenWidth < 480) {
          k.instance.globalStyle = this.mobileStyleSheet;
        }
        if (this.screenWidth > 480) {
          k.instance.globalStyle = this.webStyleSheet;
        }
        // k.instance.id = item.category.name;
        this.componentsReferences.splice(index, 0, k);

        for(let i = index + 10 + 1;i<displaySection._lContainer.length;i++){
          if(displaySection._lContainer[i][8].index){
            displaySection._lContainer[i][8].index = displaySection._lContainer[i][8].index + 1;
          }
        }
          
      }
    });
    return this.componentsReferences?.[0]?.instance?._eventHelper || null;
  }

  updateComponent(displaySection,index:number,newData:any){
    const componentToRemove = displaySection.get(index);
    if (componentToRemove) {
      componentToRemove.destroy();
    }

    this.componentsReferences.splice(index,1);
    this.listComponents.forEach((listCompos, i) => {
      if (listCompos.name === newData.webComponentName) {
        const componentFactory = this.comFactoryResolver.resolveComponentFactory(listCompos.component);
        const k = displaySection.createComponent(componentFactory, index);
        k.instance.responseData = newData;
        // k.instance.edit = edit;
        // k.instance.all = all;
        k.instance.canEditAll = true;
        k.instance.index = index;
        if (this.screenWidth < 480) {
          k.instance.globalStyle = this.mobileStyleSheet;
        }
        if (this.screenWidth > 480) {
          k.instance.globalStyle = this.webStyleSheet;
        }
        this.componentsReferences.splice(index, 0, k);
        
      }
    });
    return this.componentsReferences?.[0]?.instance?._eventHelper || null;
  }

  pickLoaderComponent(displaySection, component) {
    const componentFactory = this.comFactoryResolver.resolveComponentFactory(component)
    const k = displaySection.createComponent(componentFactory);

    return k.instance?._eventHelper || null;
  }

  replaceComponent(displaySection, oldItem, newItem, edit, all) {
    let a = this.listComponents.forEach((listComps, i) => {
      // if(listComps.name === oldItem.webComponentName){
      //   let componentClass = listComps.component
      //   const component = this.componentsReferences.find((component) => component.instance instanceof componentClass);
      //   if(!component) return ;
      //   const index = this.componentsReferences.indexOf(component);
      // const componentFactory = this.comFactoryResolver.resolveComponentFactory(listComps.component);
      // const k = displaySection.createComponent(componentFactory, index);

      //   if(index !== -1){
      //     displaySection.splice(index,1,k);
      //     this.componentsReferences.splice(index,k)
      //   }
      // }
      if (listComps.name === oldItem.webComponentName) {
        let oldCompClass = listComps.component;
        const component = this.componentsReferences.find((component) => component.instance instanceof oldCompClass);
        if (!component) return;
        const oldCompIndex = this.componentsReferences.indexOf(component);
        if (listComps.name === newItem.webComponentName) {
          let newCompClass = listComps.component;
          const componentFactory = this.comFactoryResolver.resolveComponentFactory(newCompClass);
          const k = displaySection.createComponent(componentFactory, oldCompIndex);
          this.componentsReferences.splice(oldCompIndex, 1, k)
        }
      }
    });
    return this.componentsReferences?.[0]?.instance?._eventHelper || null;
  }

  componentPositionSwap(data, displaySection) {
    if (data.movingDirection === 'DOWN') {
      let indexA = data.index;
      let indexB = data.index + 1;

      let viewA = displaySection.get(indexA);
      let viewB = displaySection.get(indexB);

      // move the views to the opposite positions
      let a = displaySection.move(viewA, indexB) && displaySection.move(viewB, indexA) &&
        this.componentsReferences[indexA] &&
        this.componentsReferences.splice(indexB, 1, this.componentsReferences[indexA])[0];
      return this.componentsReferences?.[0]?.instance?._eventHelper || null;;
    }
  }

  removeComponent(displaySection, item) {
    let a = this.listComponents.forEach((listComps, i) => {
      if (listComps.name === item.webComponentName) {
        let componentClass = listComps.component
        const component = this.componentsReferences.find((component) => component.instance instanceof componentClass);
        if (!component) return;
        const index = this.componentsReferences.indexOf(component);

        if (index !== -1) {
          displaySection.remove(index);
          this.componentsReferences.splice(index, 1)
        }
      }
    });
    return this.componentsReferences?.[0]?.instance?._eventHelper || null;
  }

  addNewPage(displaySection, item, edit, all) {
    let component: any = [];

    item.forEach((components, i) => {
      this.listComponents.forEach((localComponents, j) => {
        if (components.webComponentName === localComponents.name) {
          const componentFactory = this.comFactoryResolver.resolveComponentFactory(localComponents.component);
          const k = displaySection.createComponent(componentFactory);
          k.instance.responseData = components;
          // k.instance.edit = edit;
          // k.instance.all = all;

          k.instance.canEditAll = true;
          k.instance.index = i;

          component.push(k);
        }
      });
    });
    return component?.[0]?.instance?._eventHelper || null;
  }

  samePageRedirection(selectedPage, id) {
    let a = document.querySelectorAll('*[id]');
    let b;
    a.forEach((singleNode) => {
      if (singleNode.id === selectedPage.components[id].name) {
        b = document.getElementById(singleNode.id);
        if (!b) return;
        b.scrollIntoView({ behavior: 'smooth' });
      }
    });
    return b;
  }
  externelUrls(newPage) {
    window.open(newPage, '_blank')
  }
}
