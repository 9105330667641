import { Component } from '@angular/core';

@Component({
  selector: 'app-our-blogs',
  templateUrl: './our-blogs.component.html',
  styleUrls: ['./our-blogs.component.scss','./mq-our-blogs.component.scss']
})
export class OurBlogsComponent {

}
