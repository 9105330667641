import { Component, HostListener, Input, OnInit } from '@angular/core';
import { EventEmmiterService } from 'src/app/services/event-emmiter.service';
import { WebisiteService } from '../../../../webisite.service';
import { MobileEditorRightSidePanelComponent } from '../../editor/editor-components/editor-right-side-panel/mobile-editor-right-side-panel.component';

@Component({
  selector: 'app-custom-text-element',
  templateUrl: './custom-text-element.component.html',
  styleUrls: ['./custom-text-element.component.scss']
})
export class CustomTextElementComponent implements OnInit{

  constructor(
    private _eventHelper : EventEmmiterService,
    private _websiteService : WebisiteService
  ){
    this.getScreenSize()
  }

  screenWidth:any;
  @HostListener("window: resize", ["$event"])
  getScreenSize(){
    this.screenWidth = window.innerWidth;
  }

  @Input() responseData : any;
  @Input() edit : any;
  @Input() all : any;
  @Input() index : any;
  @Input() id : any;

  data : any ;

  ngOnInit(): void {
    this.data = this.responseData.styles.title;
  }


  editSection(){
    if(this.screenWidth  > 475){
      this._eventHelper.editSection.emit({index:this.index,data:this.responseData});
    }
    else{
      this.openEditorPopup()
    }
  }

  openEditorPopup(){
    this._websiteService.openBottomSheet(MobileEditorRightSidePanelComponent,{index:this.index,data:this.responseData,pageLevelEdit:false})
  }

  addNewSection(){
    this._eventHelper.addNewSectionCick.emit({status:true, index:this.index})
  }
  positionMoveUp(){
    this._eventHelper.componentPositionMoving.emit({
      movingDirection:'UP',
      index : this.index
    });
  }
  positionMoveDown(){
    this._eventHelper.componentPositionMoving.emit({
      movingDirection:'DOWN',
      index : this.index
    });
  }
  deleteComponent(){
    this._eventHelper.componentDelete.emit({
      index:this.index
    })
  }
  addSection(){
    this._eventHelper.addNewSectionCick.emit({status:true, index:this.index})
  }

}
