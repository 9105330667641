import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TemplateService } from 'src/app/services/template.service';
import { BlogServiceService } from '../../blogs/blog-service.service';
import { DbService } from 'src/app/services/db-service/db.service';
import { StorageKeys } from 'src/app/shared-module-files/simpo.constant';

@Component({
  selector: 'app-blog-page',
  templateUrl: './blog-page.component.html',
  styleUrls: ['./blog-page.component.scss', './mq-blog-page.component.scss']
})
export class BlogPageComponent {

    
  businessId:any="";
  businessName:any="";

  constructor(
    private blogService:BlogServiceService,
    private activatedRoute:ActivatedRoute,
    private templateService: TemplateService,
    private router:Router,
    private dbService:DbService
  ){

  }
  subIndustryId:any="";
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    // let businessDetails = JSON.parse(window.localStorage.getItem('bDetails') || '');
    // this.businessId = businessDetails.id;
    // this.businessName=businessDetails.name;
    this.activatedRoute.queryParams.subscribe(
      {
        next:(value:any)=>{
          if(value.blogId){
            this.blogId=value.blogId;
            this.businessId=value.bId;
           this.subIndustryId=value.cId;
            this.showApiData();
            if(this.businessId){
              this.getblogsByBusinessId();
            }
            else{
              this.getblogsBysubCategoryId(this.subIndustryId);
            }
          } 
        },
      }
    )
  }


blogId:any="";
blog:any="";
blogData:any="";
  showApiData(){
    this.blogService.getMasterBlogById(this.blogId).subscribe(
      (res:any)=>{
        this.blogData=res.data;
        if(!this.businessId){
          this.totalBlogData = res.data; 
        
          if(this.totalBlogData.length>0){
            this.blogRightSide=[];
            for (let index = 0; index < 2; index++) {
              this.blogRightSide.push(this.randomBlogSelect());
            }        
          }
        }
        //console.log("jsonData",this.jsonData);
      }
    )
    // this.barTransy?.reinit();
  }


  displayBlogs:boolean=false;
  getblogsBysubCategoryId(categoryId:any) {
    this.blogService.getBlogsBySubInstryId(categoryId).subscribe
      ({
        next: (res: any) => {
          this.totalBlogData = res.data; 
        
          if(this.totalBlogData.length>0){
            this.blogRightSide=[];
            for (let index = 0; index < 2; index++) {
              this.blogRightSide.push(this.randomBlogSelect());
            }        
          }
          
        },
        error: (err: any) => {
        }
      })

  }

  totalBlogData:any="";
  blogRightSide:any[]=[];
  getblogsByBusinessId() {
    this.templateService.getBlogsByBuisnessId(this.businessId,"ALL").subscribe
      ({
        next: (res: any) => {
          this.totalBlogData = res.data; 
        
          if(this.totalBlogData.length>0){
            this.blogRightSide=[];
            for (let index = 0; index < 2; index++) {
              this.blogRightSide.push(this.randomBlogSelect());
            }        
          }
          console.log("displayBlogs",this.blogRightSide);  
        },
        error: (err: any) => {
        }
      })
  }

 randomBlogSelect()
{
  let blogsSelected:any=this.totalBlogData[Math.floor(Math.random()*this.totalBlogData.length)];
  if(blogsSelected.id==this.blogData.id){
    this.randomBlogSelect();
  }
  if(this.blogRightSide.length>0){
  if(blogsSelected.id==this.blogRightSide[0].id){
      this.randomBlogSelect()
      }
  }

return blogsSelected;     
}

blogRedirection(blog:any){
  this.router.navigate(['/blog'],{queryParams:{blogId:blog.id}});
}
templateId = localStorage.getItem(StorageKeys.WEBSITE_ID);
returnHome()
{
  this.router.navigate(['/generating-website'],{queryParams:{tempId:this.templateId}})
}
}
