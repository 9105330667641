export const buttonStyle =[
    {
        name:'Outline',
        value:'OUT_LINE'
    },
    {
        name:'Solid',
        value:'SOLID'
    }
];

export const buttonShape = [
    {
        name:'Rounded',
        value:'ROUNDED'
    },
    {
        name:'Sharp',
        value:'SHARP'
    }
]
export const fontFamilies = [
    {
        name:'Source Serif Pro',
        value:"sourceserifpro_semibold600",
        type:'heading'
    },
    {
        name:'Merri Weather',
        value:"merriweather_bold900",
        type:"heading"
    },
    {
        name:'Open Sans Regular',
        value:"opensans_reg400",
        type:"body"
    },
    {
        name:'Opens Sans Semibold',
        value:"opensans_semibold600",
        type:"heading"
    },
    {
        name:'Roboto Regular',
        value:"roboto_regular400",
        type:"body"
    },
    {
        name:'Nunito Sans',
        value:"nunito_sans_bold900",
        type:"heading"
    },
    {
        name:'Nunito',
        value:"nunito_reg400",
        type:"body"
    },
    {
        name:'Bitter',
        value:"bitter_semibold600",
        type:"heading"
    },
    {
        name:'Fira Sans',
        value:"firasans_reg400",
        type:"body"
    },
    {
        name:'Lato',
        value:"lato_regular400",
        type:"body"
    }
]

export const combinationFontFamily=[
    {
        name:"Merri Weather + Open Sans Regular",
        value:"merriweather_bold900,opensans_reg400"
    },
    {
        name:"Source Serif Pro + Lato",
        value:"sourceserifpro_semibold600,lato_regular400"
    },
    {
        name:"Opens Sans Semibold + Roboto Regular",
        value:"opensans_semibold600,roboto_regular400"
    },
    {
        name:"Nunito Sans + Nunito",
        value:"nunito_sans_bold900,nunito_reg400"
    },
    {
        name:"Bitter + Fira Sans",
        value:"bitter_semibold600,firasans_reg400"
    }
]

export const fontSizes = [
    12,14,16,18,20,22,24,26,28,30,32,34,36,38,40,42,44,46,48,50,52,54,56,58,60
]


//v3 font families before signup

export const v3FontFamilies =[
    {
        displayHeading :"Cardo",
        displayBody : "Lato for body text",
        headingFontValues:{
            "fontFamily":`"Cardo", serif`,
            "fontWeight": "900",
            "font-style": "normal"
        },
        bodyFontValues:{
            "fontFamily": `"Lato", sans-serif`,
            "fontWeight": "400",
            "font-style": "normal"
        }
    },
    {
        displayHeading :"Cormorant",
        displayBody : "Proza Libre for body text",
        headingFontValues:{
            "fontFamily": ` "Cormorant Garamond", serif`,
            "fontWeight": "900",
            "font-style": "normal"
        },
        bodyFontValues:{
            "fontFamily": `"Proza Libre", sans-serif`,
            "fontWeight": "400",
            "font-style": "normal"
        }
    },
    {
        displayHeading :"Ubuntu",
        displayBody : "Open Sans for body text",
        headingFontValues:{
            "fontFamily": `"Ubuntu", sans-serif`,
            "fontWeight": "900",
            "font-style": "normal"
        },
        bodyFontValues:{
            "fontFamily": `"Open Sans", sans-serif`,
            "fontWeight": "400",
            "font-style": "normal"
        }
    },
    {
        displayHeading :"Oswald",
        displayBody : "Quattrocento for body text",
        headingFontValues:{
            "fontFamily": `"Oswald", sans-serif`,
            'fontWeight': "900",
            "font-style": "normal"
        },
        bodyFontValues:{
            "fontFamily": `"Quattrocento Sans", sans-serif`,
            "fontWeight": "400",
            "font-style": "normal"
        }
    },
    {
        displayHeading :"Merriweather",
        displayBody : "Mulish for body text",
        headingFontValues:{
            "fontFamily": `"Merriweather Sans", sans-serif`,
            "fontWeight": "900",
            "font-style": "normal"
        },
        bodyFontValues:{
            "fontFamily": `"Mulish", sans-serif`,
            "fontWeight": "400",
            "font-style": "normal"
        }
    },
    {
        displayHeading :"Alegreya",
        displayBody : "Open Sans for body text",
        headingFontValues:{
            "fontFamily": `"Alegreya SC", serif`,
            "fontWeight": "900",
            "font-style": "normal"
        },
        bodyFontValues:{
            "fontFamily": `"Open Sans", sans-serif`,
            "fontWeight": "400",
            "font-style": "normal"
        }
    }
]

//v3 font families after signup

export const v3FontFamiliesAfterSignup =[
    {
        displayHeading :"Cardo",
        displayBody : "Lato for body text",
        headingFontValues:{
            "fontFamily":`"Cardo", serif`,
            "fontWeight": "900",
            "font-style": "normal"
        },
        bodyFontValues:{
            "fontFamily": `"Lato", sans-serif`,
            "fontWeight": "400",
            "font-style": "normal"
        }
    },
    {
        displayHeading :"Cormorant",
        displayBody : "Proza Libre for body text",
        headingFontValues:{
            "fontFamily": ` "Cormorant Garamond", serif`,
            "fontWeight": "900",
            "font-style": "normal"
        },
        bodyFontValues:{
            "fontFamily": `"Proza Libre", sans-serif`,
            "fontWeight": "400",
            "font-style": "normal"
        }
    },
    {
        displayHeading :"Ubuntu",
        displayBody : "Open Sans for body text",
        headingFontValues:{
            "fontFamily": `"Ubuntu", sans-serif`,
            "fontWeight": "900",
            "font-style": "normal"
        },
        bodyFontValues:{
            "fontFamily": `"Open Sans", sans-serif`,
            "fontWeight": "400",
            "font-style": "normal"
        }
    },
    {
        displayHeading :"Oswald",
        displayBody : "Quattrocento for body text",
        headingFontValues:{
            "fontFamily": `"Oswald", sans-serif`,
            'fontWeight': "900",
            "font-style": "normal"
        },
        bodyFontValues:{
            "fontFamily": `"Quattrocento Sans", sans-serif`,
            "fontWeight": "400",
            "font-style": "normal"
        }
    },
    {
        displayHeading :"Merriweather",
        displayBody : "Mulish for body text",
        headingFontValues:{
            "fontFamily": `"Merriweather Sans", sans-serif`,
            "fontWeight": "900",
            "font-style": "normal"
        },
        bodyFontValues:{
            "fontFamily": `"Mulish", sans-serif`,
            "fontWeight": "400",
            "font-style": "normal"
        }
    },
    {
        displayHeading :"Alegreya",
        displayBody : "Open Sans for body text",
        headingFontValues:{
            "fontFamily": `"Alegreya SC", serif`,
            "fontWeight": "900",
            "font-style": "normal"
        },
        bodyFontValues:{
            "fontFamily": `"Open Sans", sans-serif`,
            "fontWeight": "400",
            "font-style": "normal"
        }
    },
    {
        displayHeading :"Archivo Narrow",
        displayBody : "Crimson Pro for body text",
        headingFontValues:{
            "fontFamily":`"Archivo Narrow", sans-serif`,
            "fontWeight": "900",
            "font-style": "normal"
        },
        bodyFontValues:{
            "fontFamily": `"Crimson Pro", serif`,
            "fontWeight": "400",
            "font-style": "normal"
        }
    },
    {
        displayHeading :"Arvo",
        displayBody : "Lato for body text",
        headingFontValues:{
            "fontFamily":`"Arvo", serif`,
            "fontWeight": "900",
            "font-style": "normal"
        },
        bodyFontValues:{
            "fontFamily": `"Lato", sans-serif`,
            "fontWeight": "400",
            "font-style": "normal"
        }
    },
    {
        displayHeading :"Bree Serif",
        displayBody : "Nunito Sans for body text",
        headingFontValues:{
            "fontFamily":`"Bree Serif", serif`,
            "fontWeight": "900",
            "font-style": "normal"
        },
        bodyFontValues:{
            "fontFamily": `"Nunito Sans", sans-serif`,
            "fontWeight": "400",
            "font-style": "normal"
        }
    },
    {
        displayHeading :"Josefin Sans",
        displayBody : "Libre Baskerville for body ",
        headingFontValues:{
            "font-family": `"Josefin Sans", sans-serif`,
            "fontWeight": "900",
            "font-style": "normal"
        },
        bodyFontValues:{
            "fontFamily": `"Libre Baskerville", serif`,
            "fontWeight": "400",
            "font-style": "normal"
        }
    },
    {
        displayHeading :"EB Garamond",
        displayBody : "EB Garamond for body text",
        headingFontValues:{
            "fontFamily":`"EB Garamond", serif`,
            "fontWeight": "900",
            "font-style": "normal"
        },
        bodyFontValues:{
            "fontFamily": `"EB Garamond", serif`,
            "fontWeight": "400",
            "font-style": "normal"
        }
    },
    {
        displayHeading :"Itim",
        displayBody : "Poppins for body text",
        headingFontValues:{
            "fontFamily":`"Itim", cursive`,
            "fontWeight": "900",
            "font-style": "normal"
        },
        bodyFontValues:{
            "fontFamily": `"Poppins", sans-serif`,
            "fontWeight": "400",
            "font-style": "normal"
        }
    },
]
